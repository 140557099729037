import { queryFeatures } from '@esri/arcgis-rest-feature-layer';
import { BitmapLayer, GeoJsonLayer } from '@deck.gl/layers';
import { TileLayer } from '@deck.gl/geo-layers';
import { LngLat } from '../../MapUtils/SharedTypes';
import { calculateMinMaxLngLat, getEsriExtents, projectExtents } from '../extras/Functions';

export interface LayerInfo {
  url: string;
  datumTransformationId: number;
  props: any;
}

export interface EsriLayerServiceProperties {
  layerInfo: LayerInfo[];
  token?: string | undefined;
}

export default class EsriLayerService {
  public extent: LngLat[] | undefined = undefined;
  public visibility: boolean[] = [];
  private sourceLayers: LayerInfo[];
  private token: string | undefined = undefined;

  private featuresData: any[] = [];

  constructor(properties: EsriLayerServiceProperties) {
    this.sourceLayers = properties.layerInfo;
    this.token = properties.token;
  }

  // Fetch all the esri data from the server
  // so we dont have to do it each time a property changes/layer rebuild.
  public async initialize(): Promise<any> {
    const features = this.getFeatureLayers(this.sourceLayers);
    const tiles = this.getTileLayers(this.sourceLayers);
    const data: any[] = [];

    // outFields: ["Symbology_ID"],
    const pFeatures = features.map((x: LayerInfo) => {
      let params;

      if (x.datumTransformationId !== -1) {
        params = { datumTransformation: x.datumTransformationId, token: this.token };
      } else {
        params = { token: this.token };
      }

      return queryFeatures({
        url: x.url,
        params,
        where: '1=1',
        outSR: { wkid: 4326 },
        f: 'geojson'
      });
    });

    tiles.map((x: LayerInfo, i: number) => {
      const tileLayer = this.buildEsriTileLayer(x, i);
    });

    await Promise.allSettled(pFeatures).then((results: any[]) => {
      results.forEach((response, i) => {
        data.push({ ...features[i], features: response.value });
      });
    });

    this.featuresData = data;

    return;
  }

  // Determine an extent for all the data in the handler
  public async calculateExtent() {
    if (this.token === undefined) {
      this.extent = [
        { longitude: 0, latitude: 0 },
        { longitude: 0, latitude: 0 }
      ];
      return;
    }

    // Leverage esri services to determine the extent of the
    // feature layers, then again to project them into a format
    // that DeckGL can read. (EN -> LngLat)
    let extents;
    const featureLayers = this.getFeatureLayers(this.sourceLayers);

    if (featureLayers === undefined || featureLayers.length === 0) {
      this.extent = [
        { longitude: 0, latitude: 0 },
        { longitude: 0, latitude: 0 }
      ];
      return;
    }

    await getEsriExtents(
      featureLayers.map((x) => x.url),
      this.token
    ).then((unprojectedExtents: any) => {
      extents = unprojectedExtents;
    });

    await projectExtents(extents).then((projectedExtents: any) => {
      extents = projectedExtents;
    });

    // Determine the MinMax extent of all the return layer's extents
    const combined: LngLat[] = [];
    extents.forEach((extent: any) => {
      combined.push({ longitude: extent.xmin, latitude: extent.ymin });
      combined.push({ longitude: extent.xmax, latitude: extent.ymax });
    });

    const minMax = calculateMinMaxLngLat(combined);
    this.extent = [
      { longitude: minMax.minLng, latitude: minMax.minLat },
      { longitude: minMax.maxLng, latitude: minMax.maxLat }
    ];
  }

  // Build the feature and tile layers, use this to also
  // pass new props to the layers.
  public generateLayers(laps: LayerInfo[]) {
    const layers: any = [];

    // Shift all tile layers to the bottom of the rendering order.
    // This will need to be reworked if layer reordering gets added to the UI
    laps.sort((x: LayerInfo, y: LayerInfo) => {
      if (x.url.toLowerCase().includes('tile')) {
        return -1;
      }
      return 1;
    });

    laps.forEach((lap: LayerInfo) => {
      let newLayer;

      if (this.isFeatureLayer(lap.url)) {
        const data = this.featuresData.filter((x: any) => x.url === lap.url)[0];

        const oldRef = this.sourceLayers.filter((x) => x.url === data.url)[0];
        let index = 0;

        if (oldRef) {
          index = this.sourceLayers.indexOf(oldRef);
        }

        if (data) {
          newLayer = this.buildGeoJsonLayer(data.features, index, lap);
        }
      }
      if (this.isTileLayer(lap.url)) {
        const index = this.sourceLayers.findIndex((x: any) => x.url === lap.url)[0];
        newLayer = this.buildEsriTileLayer(lap, index);
      }

      if (newLayer) {
        layers.push(newLayer);
      }
    });

    return layers;
  }

  private isFeatureLayer(layer: string) {
    const key = 'featureserver';
    return layer.toLowerCase().includes(key);
  }

  private isTileLayer(layer: string) {
    const key = 'mapserver';
    return layer.toLowerCase().includes(key);
  }

  private getFeatureLayers(layers: LayerInfo[]): LayerInfo[] {
    const key = 'featureserver';
    return layers.filter((x: LayerInfo) => x.url.toLowerCase().includes(key));
  }

  private getTileLayers(layers: LayerInfo[]): LayerInfo[] {
    const key = 'mapserver';
    return layers.filter((x: LayerInfo) => x.url.toLowerCase().includes(key));
  }

  private buildGeoJsonLayer(features: any[], index: number, layer: LayerInfo) {
    return new GeoJsonLayer({
      ...layer.props,
      id: `geo-layers-${index}`,
      data: features,
      pointType: 'circle',
      lineWidthUnits: 'pixels',
      lineWidthMinPixels: 1,
      pointRadiusMinPixels: 1,
      getFillColor: [255, 0, 0],
      getLineColor: [0, 255, 0],
      getPointRadius: 1
    });
  }

  private buildEsriTileLayer(layer: LayerInfo, index: number) {
    if (this.token === undefined) {
      console.log('Invalid/Missing session token passed to Esri tile builder.');
      return;
    }

    return new TileLayer({
      ...layer.props,
      id: `tile-${index}`,
      data: `${layer.url}?token=${this.token}`,
      minZoom: 0,
      maxZoom: 19,
      tileSize: 256,
      // @ts-ignore
      renderSubLayers: (props: any) => {
        if (props.data) {
          const {
            bbox: { west, south, east, north }
          } = props.tile;
          return [
            new BitmapLayer(props, {
              data: null,
              image: props.data,
              bounds: [west, south, east, north]
            })
          ];
        }
      }
    });
  }
}
