import { Card, CardContent, Typography } from '@material-ui/core';
import chartjs, { ChartLegendOptions } from 'chart.js';
import React, { useEffect, useRef, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { OperationalCodeColors } from '../../../styles/FugroColors';
import { PieChartData } from '../../../types/pieChartData.type';
import { colorLuminance } from '../../../utils/colorUtils';
import { formatDuration, getDuration } from '../../../utils/dateUtils';
import { StatisticsSkeletonBlock } from '../../skeletons/StatisticsSkeletonBlock';
import { useCardStyles } from '../../styles/CardStyles';
import { WorkpackStatsItem, WorkpackStatsOperationsLogItem, WorkpackStatsTaskProgress } from './workpackStatisticsDashboard';

const colorLuminanceValue = 0.2;

interface OperationCodeData {
  code: string;
  label: string;
  duration: number;
  description: string;
  count: number;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
}

export default function OperationalCodes(props: { loading: boolean; data?: WorkpackStatsItem }): JSX.Element {
  const classes = useCardStyles();
  const [datasource, setDatasource] = useState<any>();
  const legendRef = useRef(null);

  const [reference, setReference] = useState<any>(null);
  const [legendWidth, setLegendWidth] = useState<number>(500);

  useEffect(() => {
    function createPieChartData(operations: WorkpackStatsOperationsLogItem[], taskProgresses: WorkpackStatsTaskProgress[]): PieChartData {
      const opsCodeData: OperationCodeData[] = [];

      // Strip out any operations codes with a duration less than 0
      // due to sims milestones/unclosed tasks/crashes etc..
      operations = operations.filter((op: any) => op.duration > 0);

      let totalSeconds = operations.reduce((acc, obj) => (acc = acc + obj.duration), 0);
      totalSeconds += taskProgresses.reduce((acc, obj) => (acc = acc + obj.actualTimeSeconds), 0);

      const logsByCode = operations.reduce((acc, obj: WorkpackStatsOperationsLogItem) => {
        const key = obj.operationalCode.code;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {} as any);

      logsByCode.Tasks = taskProgresses.map((x) => {
        return { duration: x.actualTimeSeconds };
      });

      for (const code of Object.keys(logsByCode)) {
        const operationalCode = logsByCode[code].filter((code: any) => code.duration > 0);
        const seconds = operationalCode.reduce((acc: number, obj: any) => (acc = acc + obj.duration), 0);
        const duration = getDuration(seconds * 1000);

        const codeCharLength = 6 - code.length;
        let codePadded = `${code} `;
        for (let i = 0; i < codeCharLength; i++) {
          codePadded = `${codePadded}&nbsp;`;
        }

        const label = `${codePadded}${formatDuration(duration)} (${Math.round((seconds / totalSeconds) * 100)}%)`;
        opsCodeData.push({
          code,
          label,
          duration: seconds,
          description: code === 'Tasks' ? 'Tasks' : operationalCode[0].operationalCode.description,
          count: operationalCode.length
        });
      }

      const sortedByDuration = opsCodeData.sort((a, b) => (a.duration > b.duration ? -1 : 1));

      const labels = sortedByDuration.map((x) => x.label);
      const datasets = [
        {
          names: sortedByDuration.map((x) => x.code),
          data: sortedByDuration.map((x) => x.duration),
          description: sortedByDuration.map((x) => x.description),
          count: sortedByDuration.map((x) => x.count),
          backgroundColor: sortedByDuration.map((_x, i) => OperationalCodeColors[i % OperationalCodeColors.length]),
          hoverBackgroundColor: sortedByDuration.map((_x, i) =>
            colorLuminance(OperationalCodeColors[i % OperationalCodeColors.length], colorLuminanceValue)
          )
        }
      ];

      return { labels, datasets };
    }

    if (props.data) {
      const ds = createPieChartData(props.data.operationsLog, props.data.simsTaskProgress);
      if (ds) {
        setDatasource(ds);
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (legendRef !== null && legendRef.current !== null) {
      window.addEventListener('resize', getDivWidth);
    }
  });

  function getDivWidth() {
    const lRef: any = legendRef?.current;
    if (lRef) {
      const divWidth = lRef.clientWidth;
      if (divWidth) {
        setLegendWidth(divWidth);
      }
    }
  }

  function boldCode(input: string) {
    const spaceIndex = input.indexOf(' ');
    const code = input.substr(0, spaceIndex);
    const newCode = `<b>${code}</b>`;
    return input.replace(code, newCode);
  }

  // See https://github.com/reactjs/react-chartjs/issues/97
  const chartOptions: chartjs.ChartOptions = {
    layout: {
      padding: {
        top: 25,
        bottom: 25
      }
    },
    maintainAspectRatio: false,
    legendCallback(chart: any) {
      const renderLabels = (chart: any) => {
        const { data } = chart;
        return data.datasets[0].data
          .map(
            (_: any, i: number) =>
              `<li>
                  <div id="legend-${i}-item" class="legend-item">
                    <span style="background-color:
                      ${data.datasets[0].backgroundColor[i]}">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    ${data.labels[i] && `<span class="label" style="font-size:13px;font-family:monospace">&nbsp;${boldCode(data.labels[i])}</span>`}
                  </div>
              </li>
            `
          )
          .join('');
      };
      return `
      <ul class="chartjs-legend" style="list-style-type: none;">
          ${renderLabels(chart)}
        </ul>`;
    },
    tooltips: {
      callbacks: {
        label(tooltipItem, data: any) {
          if (tooltipItem.index === -1) {
            return '';
          } else if (data.labels) {
            const index = tooltipItem.index;
            const code = data.datasets[0]?.description[index as number];
            const abbrieviation = data.datasets[0]?.names[index as number];
            return ` ${code} (${abbrieviation})`;
          }

          return 'N/A';
        },
        afterLabel(tooltipItem, data) {
          if (tooltipItem.index === -1) {
            return '';
          } else if (data.datasets) {
            const tooltip: string[] = [];
            const index = tooltipItem.index;
            const taskCount = (data.datasets[0] as any)?.count[index as number];
            const taskTime = (data.datasets[0] as any)?.data[index as number] / 60 / 60;
            const grammar = taskCount === 1 ? 'task' : 'tasks';

            tooltip.push(`${taskCount} ${grammar} over ${Math.round(taskTime)} hours`);
            return tooltip;
          }
          return '';
        }
      }
    }
  };

  const legendOpts: ChartLegendOptions = {
    display: false
  };

  function applyRef(ref: any) {
    setReference(ref);
  }

  if (props.loading) {
    return <StatisticsSkeletonBlock />;
  }

  return (
    <Card className={classes.cardDetails}>
      <CardContent>
        <Typography variant="h6" color="textSecondary">
          Operational Codes
        </Typography>
        <div className={classes.chartContainer} style={{ float: 'left' }}>
          {datasource && <Pie data={datasource} options={chartOptions} legend={legendOpts} ref={applyRef} />}
        </div>
        {reference && reference.chartInstance && reference.chartInstance.generateLegend && (
          <div
            style={{ paddingTop: '1vh', marginLeft: '15px', visibility: legendWidth > 455 ? 'visible' : 'hidden' }}
            ref={legendRef}
            dangerouslySetInnerHTML={{ __html: reference.chartInstance.generateLegend() }}
          />
        )}
      </CardContent>
    </Card>
  );
}
