import { createStyles, alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import React, { ReactNode, useEffect, useState } from 'react';
import { Component } from '../../MapUtils/SharedTypes';
import { MinusSquare, PlusSquare, TransitionComponent } from './CheckboxIcons';
import { Box, ListItemText, Typography } from '@material-ui/core';
import { CenterFocusStrong } from '@material-ui/icons';

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400
    }
  })
);

export default function SearchTreeList(props: any): JSX.Element {
  const classes = useStyles();
  const components: Component[] = props.components;
  const [rootNode, setRootNode] = useState<Component[]>();
  const [previousSelectedNode, setPreviousNode] = useState<string>('');

  const StyledTreeItem = withStyles(() =>
    createStyles({
      root: {
        '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
          backgroundColor: 'transparent'
        },
        '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover': {
          backgroundColor: 'transparent'
        }
      },
      iconContainer: {
        '& .close': {
          opacity: 0.3
        }
      },
      group: {
        marginLeft: 12,
        paddingLeft: 12,
        borderLeft: `1px dashed ${alpha('#FFF', 0.2)}`
      },
      label: {
        // fontSize: '11'
      }
    })
  )((props: TreeItemProps) => getTreeListItem(props));

  const getTreeListItem = (props: any) => {
    const [isHovered, setHovered] = useState<boolean>(false);

    function canShowButton() {
      const isRootComponent = !props.label.includes('\\');
      return isHovered;
    }

    const getTreeListContent = (label: any) => {
      return (
        <Box
          sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <Typography variant="body2" style={{ float: 'left', flexGrow: 1, fontWeight: 'inherit' }}>
            {label}
          </Typography>
          <div title="Move camera to component">
            <Box
              component={CenterFocusStrong}
              style={{ float: 'right', display: canShowButton() ? 'block' : 'none', margin: '2px' }}
              color="inherit"
              onClick={() => {
                hNodeClicked(props.nodeId);
              }}
            />
          </div>
        </Box>
      );
    };

    return <TreeItem {...props} label={getTreeListContent(props.label)} />;
  };

  useEffect(() => {
    if (components.length !== 0) {
      const root = components.filter((x) => x.parent_ID === '00000000-0000-0000-0000-000000000000');
      setRootNode(root);
    }
  }, [components]);

  function getChildTreeItems(node: Component) {
    if (node === undefined) {
      return <StyledTreeItem nodeId="None" label="N/A" />;
    }

    const child = components.filter((c) => c.parent_ID === node.id);

    if (child.length === 0) {
      return <StyledTreeItem key={node.id} nodeId={node.id} label={node.componentCode} />;
    }

    return (
      <StyledTreeItem key={node.id} nodeId={node.id} label={node.componentCode}>
        {child.map((n: Component) => getChildTreeItems(n))}
      </StyledTreeItem>
    );
  }

  function hNodeClicked(id: any) {
    // const newValue = (id === previousSelectedNode) ? '' : id;

    // if (props.components.filter((x: any) => x.parent_ID === id).length === 0) {
    props.setSearchItem(id);
    setPreviousNode(id);
    // }
  }

  if (!components || !rootNode) {
    return <div />;
  }

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={['2']}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<p style={{ width: '1px' }} />}
    >
      {rootNode.map((n) => getChildTreeItems(n))}
    </TreeView>
  );
}
