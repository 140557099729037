import { useQuery } from '@apollo/react-hooks';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  CircularProgressProps,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  makeStyles,
  Paper,
  Theme,
  Typography
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, SpaceBar } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useGlobalState } from '../../store/globalState';
import ErrorMessage from '../ErrorMessage';
import { MapConfiguration } from '../map/DeckGL/extras/Types';
import { LayerUrl, LngLat } from '../map/MapUtils/SharedTypes';
// @ts-ignore
import { Ticker } from '../ticker/ticker';
// @ts-ignore
import EpsgWarningModal from '../map/Overlay/EpsgWarningModal';
// @ts-ignore
import TextMvtLayer from '../map/DeckGL/text-mvt-layer/text-mvt-layer';
import LayerList from './LayerList';
import DeckMap from '../map/DeckGL/NewMap/DeckMap';
import { DeckLayerServiceProperties } from '../map/DeckGL/NewMap/DeckLayerService';
import { EsriLayerServiceProperties } from '../map/DeckGL/NewMap/EsriLayerService';

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => (
  <Box position="relative" display="inline-flex" height="40px">
    <CircularProgress variant="determinate" {...props} />
    <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
      <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
    </Box>
  </Box>
);

export interface StructureItem {
  id: string;
  componentCode: string;
  fullComponent: string;
  lnglat: LngLat;
  anomalyCounts: Array<{ workpackId: string; workpackName: string; count: number }>;
  masterAnomalyCounts: Array<{ anomalyCodeId: string; anomalyCode: string; count: number }>;
}

const WorkpackList = (props: { onCollapse: any }) => {
  interface WorkpackListItem {
    id: string;
    name: string;
    description: string;
    progress: {
      completedTaskCount: number;
      totalTaskCount: number;
    };
  }

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: '30em',
      padding: theme.spacing(1)
    },
    description: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: '100%'
    },
    completionStatusNotStarted: {
      color: 'red'
    },
    completionStatusInProgress: {
      color: 'orange'
    },
    completionStatusComplete: {
      color: 'green'
    }
  }));

  const classes = useStyles();

  const completionStatusClass = (progress: number) =>
    progress === 0 ? classes.completionStatusNotStarted : progress === 100 ? classes.completionStatusComplete : classes.completionStatusInProgress;

  const InProgressWorkpack = (props: { progress: number; workpackDescription: string; workpackName: string; workpackId: string }) => (
    <div style={{ display: 'grid', gridTemplateColumns: 'min-content auto min-content', margin: 8, columnGap: 8 }}>
      <CircularProgressWithLabel className={completionStatusClass(props.progress)} value={props.progress} style={{ gridRow: 1, gridColumn: 1 }} />
      <div style={{ gridRow: 1, gridColumn: 2 }}>
        <div>
          <Link component={RouterLink} to={`/workpack-detail-dashboard/${props.workpackId}`}>
            <b>{props.workpackName}</b>
          </Link>
        </div>
        <div>
          <Link component={RouterLink} to={`/workpack-detail-dashboard/${props.workpackId}`} className={classes.description}>
            {props.workpackDescription}
          </Link>
        </div>
      </div>
      <IconButton component={RouterLink} style={{ gridRow: 1, gridColumn: 3 }} to={`/workpack-detail-dashboard/${props.workpackId}`}>
        <ChevronRight />
      </IconButton>
    </div>
  );

  const { loading, error, data } = useQuery<{ workpack: WorkpackListItem[] }, object>(
    gql`
      query overview {
        workpack {
          id
          name
          description
          progress {
            completedTaskCount
            totalTaskCount
          }
        }
      }
    `,
    {}
  );

  const calculateWorkpackProgress = (wp: WorkpackListItem) =>
    wp.progress.totalTaskCount === 0 ? 0 : Math.trunc((wp.progress.completedTaskCount / wp.progress.totalTaskCount) * 100);

  const paperContent = loading ? (
    <Skeleton />
  ) : error ? (
    <ErrorMessage message={error.message} />
  ) : (
    data?.workpack
      .filter((wp) => wp.progress.completedTaskCount <= wp.progress.totalTaskCount && wp.progress.totalTaskCount !== 0)
      .map((wp) => (
        <InProgressWorkpack
          key={wp.id}
          progress={calculateWorkpackProgress(wp)}
          workpackDescription={wp.description}
          workpackName={wp.name}
          workpackId={wp.id}
        />
      ))
  );

  return (
    <Paper className={classes.root}>
      <div>
        <IconButton size="small" onClick={props.onCollapse}>
          <ChevronLeft />
        </IconButton>
        Workpacks
      </div>
      {paperContent}
      <div>
        <Link component={RouterLink} to="/workpacks">
          View all Workpacks
        </Link>
      </div>
    </Paper>
  );
};

const OverviewMap = (props: { onSelectStructure?: any; isMeasuring: boolean; selectedLayers: LayerUrl[] }) => {
  const esriMapRef = React.useRef<any>();
  const [mapProps, setMapProps] = useState<any>(undefined);
  const [epsgDefined, setEpsgDefined] = useState<boolean>(true);
  const [esriTokenDefined, setEsriTokenDefined] = useState<boolean>(true);
  const [mapLoading, setMapLoading] = useState<boolean>(true);
  const {
    loading,
    error,
    data: mapData
  } = useQuery<{ mapConfiguration: MapConfiguration; structure: StructureItem[] }, object>(
    gql`
      query overviewMapConfig {
        mapConfiguration {
          baseLayerTileUrl
          layerUrls
          epsgCodes
          esriToken
          esriFeatureLayers {
            url
            datumTransformationId
          }
          esri3dUrls
          oarsLayerUrls
          esriWmsLayerUrls
          mapBounds {
            latitude
            longitude
          }
          featureTileUrls {
            url
            friendlyName
          }
        }
        structure {
          id
          componentCode
          fullComponent
          lnglat {
            longitude
            latitude
          }
          anomalyCounts {
            workpackId
            workpackName
            count
          }
          masterAnomalyCounts {
            anomalyCodeId
            anomalyCode
            count
          }
        }
      }
    `,
    {}
  );

  function onMapDataChanged() {
    if (mapData) {
      if (!mapData?.mapConfiguration?.esriToken) {
        console.log('No token defined.');
        setEsriTokenDefined(false);
        return;
      }

      const deckProps: DeckLayerServiceProperties = {
        fieldLayoutLayers: mapData.mapConfiguration.layerUrls,
        scene3dLayers: mapData.mapConfiguration.esri3dUrls,
        featureTileLayers: mapData.mapConfiguration.featureTileUrls.map((t) => t.url),
        oarsLayers: mapData.mapConfiguration.oarsLayerUrls,
        esriWmsLayerUrls: mapData.mapConfiguration.esriWmsLayerUrls,
        structures: mapData.structure,
        bounds: mapData?.mapConfiguration?.mapBounds,
        selectedStructureHandler: props.onSelectStructure
      };

      const esriProps: EsriLayerServiceProperties = {
        layerInfo: mapData.mapConfiguration.esriFeatureLayers
          .filter((x) => x !== null)
          .map((x) => ({
            url: x.url,
            props: { visible: true },
            datumTransformationId: x.datumTransformationId
          }))
      };

      const mapProps: any = {
        deckProperties: deckProps,
        esriProperties: esriProps,
        token: mapData.mapConfiguration.esriToken
      };

      setMapProps(mapProps);
    }
  }

  function onLoadStateChange() {
    if (!loading && mapData) {
      const hasEpsg = mapData.mapConfiguration.epsgCodes.some((x) => x != '0');
      setEpsgDefined(hasEpsg);
      if (esriMapRef.current) {
        esriMapRef.current.updateEsriLayers(props.selectedLayers);
      }
    }
  }

  useEffect(onLoadStateChange, [loading]);

  useEffect(onMapDataChanged, [mapData]);

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  if (!esriTokenDefined) {
    return (
      <div
        style={{
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: '45%',
          position: 'absolute',
          zIndex: 9999,
          minWidth: '25px',
          overflow: 'hidden'
        }}
      >
        <ErrorMessage message={'Unable to authenticate with esri services...'} />
      </div>
    );
  }

  const LoadingIndicator = () =>
    loading || mapLoading ? (
      <Dialog open={true}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    ) : null;

  return (
    <div style={{ padding: 0, margin: 0, height: '100%', width: '100%' }}>
      <DeckMap
        properties={mapProps}
        selectedLayers={props.selectedLayers}
        isMeasuring={props.isMeasuring}
        onLoadingChanged={(state: boolean) => {
          setMapLoading(state);
        }}
      />
      <LoadingIndicator />
      <EpsgWarningModal open={!epsgDefined} bottomOffset={120} />
    </div>
  );
};

export const StructureInfo = (props: { structure?: StructureItem }) => {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      marginTop: '1em',
      width: '30em',
      padding: theme.spacing(1)
    },
    countTableRoot: {
      display: 'grid',
      gridTemplateColumns: 'auto min-content',
      margin: 8,
      columnGap: 8
    }
  }));

  const classes = useStyles();

  if (props.structure === undefined) {
    return null;
  }

  const SiteAnomalyCounts = () =>
    props?.structure?.anomalyCounts === null || props?.structure?.anomalyCounts?.length === 0 ? null : (
      <div>
        <Typography variant="subtitle1">Site Anomalies</Typography>
        {props?.structure?.anomalyCounts.map((ac, i) => (
          <div key={ac.workpackId} className={classes.countTableRoot}>
            <div style={{ gridRow: i, gridColumn: 1 }}>
              <Link
                component={RouterLink}
                to={{
                  pathname: `/workpack-detail-dashboard/${ac.workpackId}/Anomalies`,
                  state: JSON.stringify({
                    Filters: `${props?.structure?.componentCode}\\`,
                    Tab: 1,
                    IsComponent: true
                  })
                }}
              >
                {ac.workpackName}
              </Link>
            </div>
            <div style={{ gridRow: i, gridColumn: 2 }}>
              <Badge color="secondary" badgeContent={ac.count} />
            </div>
          </div>
        ))}
      </div>
    );

  const MasterAnomalyCounts = () =>
    props?.structure?.masterAnomalyCounts === null || props?.structure?.masterAnomalyCounts?.length === 0 ? null : (
      <div>
        <Typography variant="subtitle1">Master Anomalies</Typography>
        {props?.structure?.masterAnomalyCounts.map((ac, i) => (
          <div key={ac.anomalyCodeId} className={classes.countTableRoot}>
            <div style={{ gridRow: i, gridColumn: 1 }}>{ac.anomalyCode}</div>
            <div style={{ gridRow: i, gridColumn: 2 }}>
              <Badge color="secondary" badgeContent={ac.count} />
            </div>
          </div>
        ))}
      </div>
    );

  return (
    <Paper className={classes.root}>
      <Link component={RouterLink} to={`/asset-detail/${props.structure.fullComponent}`}>
        <Typography variant="h6">{props.structure.componentCode}</Typography>
      </Link>
      <SiteAnomalyCounts />
      <MasterAnomalyCounts />
    </Paper>
  );
};

export const Overview = () => {
  const useStyles = makeStyles(() => ({
    paperRoot: {
      position: 'absolute',
      zIndex: 5000
    },
    floatingButtonPaper: {
      position: 'absolute',
      zIndex: 5000,
      opacity: '80%',
      '&:hover': {
        opacity: '100%'
      },
      top: '90vh',
      left: '50%',
      transform: 'translateX(-50%)',
      borderRadius: '6px'
    }
  }));

  const classes = useStyles();

  const [selectedLayers, setSelectedLayers] = useState<LayerUrl[]>([]);
  const [focusedStructure, setFocusedStructure] = useState<StructureItem>();
  const [showWorkpackList, setShowWorkpackList] = useState<boolean>(true);
  const [showLayerList, setShowLayerList] = useState<boolean>(true);
  const [isMeasuring, setIsMeasuring] = useState<boolean>(false);

  // Reset the page title here, otherwise when navigating back from a workpack
  // the workpack name will persist.
  const [, setPageTitle] = useGlobalState('globalPageTitle');

  useEffect(() => setPageTitle(''), [setPageTitle]);

  const ExpandWorkpackList = () => (
    <Button size="small" variant="contained" style={{}} onClick={expandWorkpackList}>
      <ChevronRight />
    </Button>
  );

  const ExpandLayerList = () => (
    <Button size="small" variant="contained" style={{ marginTop: '10px' }} onClick={toggleLayerList}>
      <ChevronRight />
    </Button>
  );

  const selectedLayersChanged = (layers: LayerUrl[]) => {
    setSelectedLayers(layers);
  };

  const expandWorkpackList = () => setShowWorkpackList(true);
  const collapseWorkpackList = () => setShowWorkpackList(false);

  const toggleLayerList = () => setShowLayerList(!showLayerList);

  return (
    <React.Fragment>
      <div className={classes.paperRoot} style={{ top: '6em', width: 'calc(100% - 64px)' /*64px is padding x2 (32)*/ }}>
        <Ticker />
      </div>
      <div className={classes.paperRoot} style={{ top: '10em' }}>
        <div>
          {showWorkpackList ? <WorkpackList onCollapse={collapseWorkpackList} /> : <ExpandWorkpackList />}
          <StructureInfo structure={focusedStructure} />
        </div>
        <div>
          <div style={{ display: showLayerList ? 'block' : 'none' }}>
            <LayerList onSelectedLayerChanged={selectedLayersChanged} onCollapse={toggleLayerList} />
          </div>
          <div style={{ display: showLayerList ? 'none' : 'block' }}>
            <ExpandLayerList />
          </div>
        </div>
      </div>
      <div className={classes.floatingButtonPaper} style={{ border: isMeasuring ? '3px solid #2e83ea' : '' }}>
        <Paper style={{ height: '3em' }}>
          <IconButton
            onClick={() => {
              setIsMeasuring(!isMeasuring);
            }}
          >
            <SpaceBar />
          </IconButton>
        </Paper>
      </div>
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, display: 'block', width: '100vw', height: '100vh' }}>
        <OverviewMap onSelectStructure={setFocusedStructure} isMeasuring={isMeasuring} selectedLayers={selectedLayers} />
      </div>
    </React.Fragment>
  );
};
