import { Card, CardContent, Grid, LinearProgress, Typography, withStyles } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { TileType } from '../../../utils/enumerations';
import { timeConvert } from '../../../utils/timeUtils';
import { StatisticsSkeletonCards } from '../../skeletons/StatisticsSkeletonCards';
import { useCardStyles } from '../../styles/CardStyles';
import { roundWithPrecision } from '../../utils/general';
import { WorkpackStatsTaskProgress } from './workpackStatisticsDashboard';

export default function TaskProgress(props: { loading: boolean; data?: WorkpackStatsTaskProgress[] }): JSX.Element {
  const [datasource, setDatasource] = useState<any[]>();

  const classes = useCardStyles();

  useEffect(() => {
    interface TaskProgressItem {
      id: string;
      code: string;
      taskCount: number;
      completedCount: number;
      plannedTime: number;
      actualTime: number;
      remainingTimeSeconds: number;
      complete: number;
    }

    if (props.data) {
      const codes = [...Array.from(new Set(props.data.map((x) => x.code)))];
      const ds: TaskProgressItem[] = [];

      codes.forEach((code) => {
        const currentCodeTasks = props.data?.filter((x) => x.code === code) ?? [];
        const completed = currentCodeTasks.filter((x) => x.complete >= 99).length;
        const remainingTime = currentCodeTasks.reduce((a, b) => a + b.remainingTimeSeconds, 0);

        const newItem: any = {
          id: code,
          code,
          remainingTime,
          completedCount: completed,
          taskCount: currentCodeTasks.length
        };

        ds.push(newItem);
      });

      setDatasource(ds);
    }
  }, [props.data]);

  function generateBar(percentage: number) {
    const completed = percentage === 100;
    const foreColor = completed ? '#007400' : '#ff6c5c';
    const backColor = completed ? '#007400' : lighten('#ff6c5c', 0.5);

    const BorderLinearProgress = withStyles({
      root: {
        height: 5,
        backgroundColor: backColor
      },
      bar: {
        //    borderRadius: 20,
        backgroundColor: foreColor
      }
    })(LinearProgress);

    return <BorderLinearProgress variant="determinate" color="secondary" value={percentage} />;
  }

  function generateTile(task: any, type: TileType) {
    const percentage = (task.completedCount / task.taskCount) * 100;
    const completed = task.completedCount === task.taskCount;

    // Skip tiles we dont need to generate in this grid section
    if ((type === TileType.Completed && !completed) || (type === TileType.Incomplete && completed)) {
      return;
    }

    return (
      <Grid item={true} key={task.code} xs={2} md={2}>
        {/* <CardActionArea component={Link} to={link}> */}
        <Card className={classes.tabCardContainer}>
          <CardContent
            style={{
              background: completed ? 'linear-gradient(45deg, #4cb963a6 0%, #ccff99 100%)' : 'linear-gradient(45deg,  #ffcc99 30%, #ffffcc 90%)',
              paddingBottom: '8px',
              paddingTop: '4px'
            }}
            className={classes.tabCardContent}
          >
            <div>
              <Typography className={classes.tabHeader} variant="subtitle2">
                {task.code}
                <div className={classes.tabHeaderPercentage}>{roundWithPrecision(percentage, 1)}%</div>
              </Typography>

              <div className={classes.testing}>{percentage !== 100 && <div>Remaining: {timeConvert(task.remainingTime)} min</div>}</div>
              <div className={classes.testing}>
                Tasks: {task.completedCount}/{task.taskCount}
              </div>
            </div>
            {generateBar(percentage)}
          </CardContent>
        </Card>
      </Grid>
    );
  }

  if (props.loading || !datasource) {
    return <StatisticsSkeletonCards />;
  }

  return (
    <Card className={classes.cardDetails} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
      <CardContent>
        <Typography component="h4" variant="h6" color="textSecondary">
          Task Progress
        </Typography>
        <Typography component="h4" variant="subtitle1" color="textSecondary" className={classes.tabContainerHeader}>
          Outstanding
        </Typography>
        <Grid container={true} justifyContent="flex-start" spacing={2}>
          {datasource.map((task) => generateTile(task, TileType.Incomplete))}
        </Grid>
        <Typography component="h4" variant="subtitle1" color="textSecondary" className={classes.tabContainerHeader}>
          Complete
        </Typography>
        <Grid container={true} justifyContent="flex-start" spacing={2}>
          {datasource.map((task) => generateTile(task, TileType.Completed))}
        </Grid>
      </CardContent>
    </Card>
  );
}
