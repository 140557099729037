import { useQuery } from '@apollo/react-hooks';
import { Box, Grid } from '@material-ui/core';
import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { MasterAnomalyStatus } from '../../utils/enumerations';
import AssetModelViewer from './3D/AssetModelViewer';
import { FocusedComponentProps } from './3D/ModelViewerHelpers';
import HistoryTab from './Components/Tabs/History/HistoryTab';
import MediaDocumentsTab from './Components/Tabs/MediaDocuments/MediaDocumentsTab';
import _ from 'lodash';
import { Component, AssetDetailProps, SelectedTab } from './Interfaces';
import BreadcrumbBar from './Components/BreadcrumbBar';
import HeaderTabs from './Components/HeaderTabs';
import CurrentStateTab from './Components/Tabs/CurrentState/CurrentStateTab';
import AnomalyTab from './Components/Tabs/Anomalies/AnomalyTab';
import TaskTrendsTab from './Components/Tabs/TaskTrends/TaskTrendsTab';

export default function AssetDetail(props: RouteComponentProps<AssetDetailProps>): JSX.Element {
  const originalRoot = props.match.params.fullComponent.replace('/', '\\');
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(0);
  const [selectedFilterMAStatus, setSelectedFilterMAStatus] = useState<MasterAnomalyStatus | null>(null);
  const [selectedFilterCode, setSelectedFilterCode] = useState<string | null>(null);
  const [selectedComponents, setSelectedComponents] = useState<string[]>([originalRoot]);

  const { error, data, loading } = useQuery<{ components: Component[] }, { fullComponent: string[] }>(
    gql`
      query assetDetailDetails($fullComponent: [String]) {
        components(fullComponent: $fullComponent) {
          id
          name
          description
          componentCode
          category
          fullComponent
          lastTaskCompleted
          document(includeChildComponents: true) {
            id
            filename
          }
          media(includeChildComponents: true) {
            id
            filename
            startTime
            itemType
          }
          incident(includeChildComponents: true) {
            id
            isVideoAvailable
            isMultichannelVideoAvailable
            startTime
            endTime
            eventCode {
              code
            }
            data {
              id
              parameterName
              parameterType
              value
            }
            workpack {
              id
              name
            }
            component {
              id
              fullComponent
              componentCode
              category
            }
          }
          masterAnomaly(includeChildComponents: true) {
            id
            status
            dateFound
            clientID
            description
            anomalyCode {
              code
              description
            }
            component {
              id
              fullComponent
              componentCode
              category
            }
            media {
              id
            }
          }
          anomaly(includeChildComponents: true) {
            id
            status
            startTime
            endTime
            closedBy
            summary
            criticality
            clientID
            anomalyCode {
              code
              description
            }
            component {
              id
              fullComponent
              componentCode
              category
            }
            media {
              id
              filename
              startTime
            }
          }
        }
      }
    `,
    { variables: { fullComponent: selectedComponents } }
  );

  const { data: modelData } = useQuery<{ component: Component }, { rootComponent: string }>(
    gql`
      query rootComponentChildren($rootComponent: String) {
        component(fullComponent: $rootComponent) {
          modelUrl
          heatmapDataUrls
          id
          childComponents {
            id
            componentCode
            fullComponent
            task {
              id
              progress {
                percentage
              }
            }
            anomaly {
              id
            }
            masterAnomaly {
              id
            }
          }
        }
      }
    `,
    { variables: { rootComponent: originalRoot } }
  );

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const hasModel = () => {
    return modelData?.component?.modelUrl !== undefined && modelData?.component?.modelUrl !== '';
  };

  const onFocusedMeshChanged = (focusedCompProps: FocusedComponentProps) => {
    // Reseting to default ( root component )
    if (!focusedCompProps?.components || focusedCompProps.components.length === 0) {
      const originalRoot = props.match.params.fullComponent.replace('/', '\\');
      setSelectedComponents([originalRoot]);
      // setTestProp([originalRoot]);
    } else if (focusedCompProps) {
      // Find the original components before they got sanitized.
      const originals = modelData?.component?.childComponents
        .filter((x: any) => focusedCompProps.components.includes(x.sanitizedFullComponent))
        .map((y: any) => {
          return y.fullComponent;
        });

      const hasValidComponents = originals && originals.length > 0;
      const newFocus: string[] = hasValidComponents ? originals ?? [originalRoot] : [originalRoot];

      setSelectedComponents(newFocus);
    }
  };

  const anomalyTabClearFilters = () => {
    setSelectedFilterMAStatus(null);
    setSelectedFilterCode(null);
  };

  const CurrentTab = (props: { selectedTab: SelectedTab }) => {
    switch (props.selectedTab) {
      case SelectedTab.CurrentState:
        return (
          <CurrentStateTab
            data={data}
            loading={loading}
            setSelectedTab={setSelectedTab}
            selectedFilterMAStatus={selectedFilterMAStatus}
            setSelectedFilterMAStatus={setSelectedFilterMAStatus}
            selectedFilterCode={selectedFilterCode}
            setSelectedFilterCode={setSelectedFilterCode}
          />
        );
      case SelectedTab.History:
        return <HistoryTab selectedComponents={selectedComponents} />;
      case SelectedTab.MediaAndDocuments:
        return <MediaDocumentsTab data={data?.components} loading={loading} />;
      case SelectedTab.Anomalies:
        return (
          <AnomalyTab
            data={data}
            loading={loading}
            selectedFilterMAStatus={selectedFilterMAStatus}
            selectedFilterCode={selectedFilterCode}
            clearFilters={anomalyTabClearFilters}
          />
        );
      case SelectedTab.TaskTrends:
        return <TaskTrendsTab data={data} loading={loading} setSelectedComponent={setSelectedComponents} />;
    }
  };

  function sanitizeArray(toSanitize: string[]) {
    return toSanitize.map((str: string) => sanitize(str));
  }

  function sanitize(toSanitize: string) {
    const _RESERVED_CHARS_RE = '\\[\\]\\.:\\/';
    const _reservedRe = new RegExp('[' + _RESERVED_CHARS_RE + ']', 'g');
    return toSanitize.replace(/\s/g, '_').replace(_reservedRe, '');
  }

  function findComponentInDataset(focusedComponentProps: string[]) {
    const children = modelData?.component?.childComponents;

    if (children) {
      const validComps: any[] = [];
      focusedComponentProps.forEach((selectedComponent: string) => {
        const result = children.filter((component: any) => component.fullComponent === selectedComponent)[0];
        if (result) {
          validComps.push(result);
        }
      });

      return validComps;
    }

    return [];
  }

  // Apply the sanitize regex to the component name
  // so we can match up with the post-gltf loader transformations.
  useEffect(() => {
    const data = modelData?.component?.childComponents;

    if (data) {
      data.forEach((comp: any) => {
        comp.sanitizedFullComponent = sanitize(comp.fullComponent);
      });
    }
  }, [modelData]);

  return (
    <div>
      <BreadcrumbBar
        data={modelData}
        selectedComponent={findComponentInDataset(selectedComponents)}
        raiseSelectedComponentChanged={setSelectedComponents}
        originalRoot={originalRoot}
      />
      <Grid container spacing={1}>
        <Grid item xs={hasModel() ? 8 : 12}>
          <HeaderTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} data={data} />
          <Box>
            <CurrentTab selectedTab={selectedTab} />
          </Box>
        </Grid>
        {modelData?.component?.modelUrl ? (
          <Grid item xs={4}>
            <div style={{ height: '100%' }}>
              <AssetModelViewer
                modelUrl={modelData?.component?.modelUrl}
                modelData={modelData?.component?.childComponents}
                heatmapDataUrls={modelData?.component?.heatmapDataUrls ?? []}
                selectedComponents={sanitizeArray(selectedComponents)}
                onChangedFocus={onFocusedMeshChanged}
              />
            </div>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}
