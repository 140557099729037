import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, IconButton, makeStyles, Paper } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorMessage from '../ErrorMessage';
import { Skeleton } from '@material-ui/lab';
import { ChevronLeft } from '@material-ui/icons';
import { LayerUrl } from '../map/MapUtils/SharedTypes';
import { GetHumanReadableEsriUrl } from '../map/MappingUtils';
import { EsriFeatureLayer, FeatureTileUrl, MapConfiguration } from '../map/DeckGL/extras/Types';

const useStyles = makeStyles(() => ({
  paperRoot: {
    backgroundColor: 'white',
    borderRadius: '4px',
    marginTop: '10px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  checkboxContainer: {
    paddingLeft: '20px'
  }
}));

export default function LayerList(props: any): JSX.Element {
  const [selectedLayers, setSelectedLayers] = useState<LayerUrl[]>([]);
  const styles = useStyles();
  const { loading, error, data } = useQuery<{ mapConfiguration: MapConfiguration }, object>(
    gql`
      query overview {
        mapConfiguration {
          esriFeatureLayers {
            url
            datumTransformationId
          }
          featureTileUrls {
            url
            friendlyName
          }
          esri3dUrls
        }
      }
    `,
    {}
  );

  useEffect(() => {
    if (data) {
      const noLocalStorageSet = localStorage.getItem('selectedGisLayers') === null;
      const localStorageLayers: LayerUrl[] = JSON.parse(localStorage.getItem('selectedGisLayers') ?? '[]');

      const esriLayers = data.mapConfiguration.esriFeatureLayers.filter(
        (featureLayer: EsriFeatureLayer) => featureLayer !== null && featureLayer.url !== ''
      );
      const featureTileLayers = data.mapConfiguration.featureTileUrls.filter((t: FeatureTileUrl) => t !== null && t.url !== '');

      const kpv: LayerUrl[] = esriLayers.map((l: EsriFeatureLayer) => {
        return {
          readableUrl: GetHumanReadableEsriUrl(l.url),
          originalUrl: l.url,
          transformationId: l.datumTransformationId,
          isChecked: noLocalStorageSet ? true : localStorageLayers.some((sl) => sl.originalUrl === l.url && sl.isChecked),
          isEsri: true
        };
      });
      kpv.push(
        ...featureTileLayers.map((t) => {
          return {
            readableUrl: t.friendlyName,
            originalUrl: t.url,
            transformationId: 0,
            isChecked: noLocalStorageSet
              ? true
              : localStorageLayers.some((sl) => sl.originalUrl.split('?')[0] === t.url.split('?')[0] && sl.isChecked),
            isEsri: false
          };
        })
      );
      setSelectedLayers(kpv);
      raiseSelectedLayerChange(kpv);
    }
  }, [data]);

  function raiseSelectedLayerChange(args: LayerUrl[]) {
    if (props.onSelectedLayerChanged) {
      props.onSelectedLayerChanged(args);
    }
  }

  const handleSelectedLayerChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const newState = selectedLayers.filter((id: LayerUrl) => id.originalUrl !== evt.target.name);
    const oldItem = selectedLayers.filter((id: LayerUrl) => id.originalUrl === evt.target.name)[0];
    const newItem: LayerUrl = {
      readableUrl: oldItem.readableUrl,
      originalUrl: oldItem.originalUrl,
      transformationId: oldItem.transformationId,
      isChecked: evt.target.checked,
      isEsri: oldItem.isEsri
    };
    const index = selectedLayers.indexOf(oldItem);

    newState.splice(index, 0, newItem);

    setSelectedLayers(newState);

    if (newState.every((e) => e.isChecked)) {
      localStorage.removeItem('selectedGisLayers');
    } else {
      localStorage.setItem('selectedGisLayers', JSON.stringify(newState));
    }

    raiseSelectedLayerChange(newState);
  };

  const MakeLayerCheckboxes = () => {
    if (!data) {
      return <React.Fragment />;
    }
    if (error) {
      return (
        <Paper className={styles.paperRoot}>
          <ErrorMessage message={error.message} />
        </Paper>
      );
    }
    const cont = (
      <FormGroup>
        {selectedLayers.map((item: LayerUrl, index: number) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  key={`chk-${index}`}
                  onChange={handleSelectedLayerChanged}
                  checked={selectedLayers[index].isChecked}
                  name={item.originalUrl}
                  color="primary"
                />
              }
              label={item.readableUrl}
              key={`fcl-${index}`}
            />
          );
        })}
      </FormGroup>
    );

    return cont;
  };

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  if (selectedLayers?.length <= 0) {
    return <React.Fragment />;
  }

  return (
    <Paper className={styles.paperRoot}>
      <div>
        <IconButton size="small" onClick={props.onCollapse}>
          <ChevronLeft />
        </IconButton>
        GIS Layers
        <div className={styles.checkboxContainer}>{MakeLayerCheckboxes()}</div>
      </div>
    </Paper>
  );
}
