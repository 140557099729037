import { makeStyles } from '@material-ui/styles';

export const useSkeletonStyles = makeStyles(() => ({
  homepageHeader: {},
  lightweightMap: {
    minHeight: '20em',
    minWidth: '-webkit-fill-available',
    height: '100%',
    borderRadius: '4px'
  },
  statistics: {
    // minHeight: '35vh',
    minWidth: '-webkit-fill-available',
    height: '100%',
    borderRadius: '4px'
  },
  table: {
    marginTop: '5px',
    minHeight: '50vh',
    minWidth: '-webkit-fill-available',
    height: '100%'
  }
}));
