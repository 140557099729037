import React, { useState } from 'react';
import MasterAnomalyTable from './MasterAnomalyTable';
import OpenAnomalyTable from './OpenAnomalyTable';
import { MediaViewerDialog } from '../../../../workpacks/workpackDetails/MediaViewerDialog';

// TODO: define props properly
export default function AnomalyTab(props: any): JSX.Element {
  const [selectedAnomalyMediaItemIds, setSelectedAnomalyMediaItemIds] = useState<string[]>();

  const data = props.data;
  const loading = props.loading;
  const selectedFilterMAStatus = props.selectedFilterMAStatus;
  const selectedFilterCode = props.selectedFilterCode;
  const clearFilters = props.clearFilters;

  const handleAnomalyTableCloseViewer = () => {
    setSelectedAnomalyMediaItemIds(undefined);
  };

  return (
    <div style={{ height: '70vh', maxHeight: '70vh' }}>
      <MasterAnomalyTable
        loading={loading}
        data={data}
        filterByStatus={selectedFilterMAStatus}
        filterByCode={selectedFilterCode}
        setSelectedAnomalyMediaItemIds={setSelectedAnomalyMediaItemIds}
        clearFilters={clearFilters}
      />
      <OpenAnomalyTable loading={loading} data={data} setSelectedAnomalyMediaItemIds={setSelectedAnomalyMediaItemIds} />
      {selectedAnomalyMediaItemIds ? (
        <MediaViewerDialog id={selectedAnomalyMediaItemIds[0]} allMediaItems={selectedAnomalyMediaItemIds} onClose={handleAnomalyTableCloseViewer} />
      ) : null}
    </div>
  );
}
