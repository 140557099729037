// tslint:disable-next-line: no-submodule-imports
import 'react-app-polyfill/ie11';
// tslint:disable-next-line: no-submodule-imports
import 'react-app-polyfill/stable';

import { ApolloProvider } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import { createTheme, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import ApolloClient from 'apollo-boost';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { App } from './app';
import history from './History';
import './index.css';
import * as serviceWorker from './serviceWorker';
import './stickyFooter.css';
import { GlobalStateProvider } from './store/globalState';
import { palette } from './styles/FugroColors';
import { Auth } from 'aws-amplify';
import { ErrorCollectorBoundary } from './components/ErrorCollectorBoundary';

export const theme = createTheme({
  palette: {
    primary: {
      main: palette.quantumBlue
    },
    secondary: {
      main: palette.pulseBlue
    },
    text: {
      primary: palette.quantumBlue
    }
  }
});

// note: if an error occurs that isn't caught, apollo will pass through the field to the server
// which will fail because it hates @client. this took me far too long to figure out.
const resolvers = {
  WorkpackType: {
    isFavourite: (parent: any) => {
      try {
        const favouriteWorkpacks = JSON.parse(localStorage.getItem('favouriteWorkpacks') ?? '[]');
        return favouriteWorkpacks.includes(parent.id);
      } catch (e) {
        console.error('Exception in client-side resolver WorkpackType.isFavourite:', e);
        return false;
      }
    }
  },
  Mutation: {
    updateFavouriteWorkpacks: (_: any, args: { id: string }) => {
      let favouriteWorkpacks: string[] = JSON.parse(localStorage.getItem('favouriteWorkpacks') ?? '[]');
      const wasFavourite = favouriteWorkpacks.find((w) => w === args.id);
      favouriteWorkpacks = wasFavourite ? favouriteWorkpacks.filter((w) => w !== args.id) : favouriteWorkpacks.concat([args.id]);
      localStorage.setItem('favouriteWorkpacks', JSON.stringify(favouriteWorkpacks));
      return { __typename: 'WorkpackType', id: args.id, isFavourite: !wasFavourite };
    }
  }
};

const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_API_BASEURL + '/graphql',
  resolvers,
  request: async (operation: any) => {
    if (process.env.REACT_APP_AUTH_REQUIRED) {
      const session = await Auth.currentSession();
      const token = session.getAccessToken().getJwtToken();
      operation.setContext({
        headers: { authorization: token ? `Bearer ${token}` : '' }
      });
    }
  }
});

ReactDOM.render(
  <ErrorCollectorBoundary>
    <GlobalStateProvider>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CssBaseline />
            <Router history={history}>
              <App />
            </Router>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ApolloProvider>
    </GlobalStateProvider>
  </ErrorCollectorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
