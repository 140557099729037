import { Card, CardContent, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useCardStyles } from '../styles/CardStyles';

export function StatisticsSkeletonCards(): JSX.Element {
  const style = useCardStyles();

  return (
    <Card className={style.cardDetails}>
      <CardContent>
        <Skeleton variant="text" style={{ color: 'rgb(0,0,0,0)', minWidth: '33%', marginLeft: '7px', marginBottom: '15px' }}>
          <Typography component="h6" variant="h6">
            Task Progress
          </Typography>
        </Skeleton>
        <div className={style.chartContainer}>
          {Array(9)
            .fill(0)
            .map((x, i) => {
              return (
                <Skeleton key={`k${i}`} style={{ float: 'left', marginLeft: '7px', transform: 'scale(1, 0.80)' }}>
                  <div style={{ width: '175px', height: '100px' }} />
                </Skeleton>
              );
            })}
        </div>
      </CardContent>
    </Card>
  );
}
