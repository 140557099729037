import chartjs from 'chart.js';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Anomaly } from '../../../../map/MapUtils/SharedTypes';
import { Skeleton } from '@material-ui/lab';

export default function AnomalyOpenStatePieGraph(props: { anomalies: Anomaly[] | undefined; loading: boolean }): JSX.Element {
  const chartOptions: chartjs.ChartOptions = {
    layout: {
      padding: {
        top: 25,
        bottom: 25
      }
    },
    maintainAspectRatio: false,
    title: {
      display: true,
      text: 'Site Anomaly Review Status'
    },
    legend: {
      position: 'bottom'
    }
  };

  const [datasource, setDatasource] = useState<any>();

  function formatData(d: Anomaly[]) {
    const openAnoms = () => d.filter((a: Anomaly) => a.status !== 'Closed').length;
    const closedAnoms = () => d.filter((a: Anomaly) => a.status === 'Closed').length;
    const newDataset = {
      labels: ['Open', 'Reviewed'],
      datasets: [
        {
          label: 'OpenClosedAnomalies',
          data: d.length != 0 ? [openAnoms(), closedAnoms()] : [],
          backgroundColor: ['#e6803c', '#4275c6']
        }
      ]
    };

    return newDataset;
  }

  useEffect(() => {
    if (props?.anomalies) {
      const formattedData = formatData(props.anomalies);
      setDatasource(formattedData);
    }
  }, [props.anomalies]);

  if (props.loading) {
    return <Skeleton variant="rect" width={250} height={250} />;
  }

  return <div style={{ height: '250px', width: '250px' }}>{datasource ? <Pie data={datasource} options={chartOptions} /> : <React.Fragment />}</div>;
}
