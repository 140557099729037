import MaterialTable, { Column, Options } from '@material-table/core';
import { ErrorOutline, ImageOutlined, VideocamOutlined, DescriptionOutlined } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { theme } from '../../../../..';
import { MediaType } from '../../../../../utils/enumerations';
import { getMediaType } from '../../../../utils/general';
import { MediaViewerDialog } from '../../../../workpacks/workpackDetails/MediaViewerDialog';
import { Component, Document, ComponentAnomaly, Media } from '../../../Interfaces';

interface Multimedia {
  id: string;
  url?: string;
  filename: string;
  startTime?: Date;
  itemType: string;
}

export default function MediaDocumentsTab(props: any): JSX.Element {
  const [focusedItem, setFocusedItem] = useState<any>();
  const [isViewingOpen, setIsViewingOpen] = useState<boolean>(false);
  const [mergedData, setMergedData] = useState<any>([]);

  useEffect(() => {
    if (!props.data) return;

    const mergedMedia: Array<Multimedia> = [];

    props.data.forEach((comp: Component) => {
      mergedMedia.push(
        ...comp.document.flatMap((d: Document) => ({ id: d.id, filename: d.filename, itemType: 'DOCUMENT', url: d.url } as Multimedia))
      );
      mergedMedia.push(...comp.media);
      mergedMedia.push(
        ...comp.anomaly.flatMap((a: ComponentAnomaly) =>
          a.media.flatMap((m: Media) => ({ id: m.id, filename: m.filename, startTime: m.startTime, itemType: 'ANOMALY_MEDIA' } as Multimedia))
        )
      );
    });

    setMergedData(mergedMedia);
  }, []);

  const columns: Array<Column<Multimedia>> = [
    { title: 'ID', field: 'id', hidden: true },
    { title: 'URL', field: 'url', hidden: true },
    { title: 'File Name', field: 'filename', cellStyle: { whiteSpace: 'nowrap' } },
    {
      title: 'Start Time',
      width: '200px',
      field: 'startTime',
      type: 'datetime',
      searchable: true,
      defaultSort: 'asc',
      render: (row: any) => getMediaTime(row?.startTime)
    },
    { title: 'Media Type', width: '100px', field: 'itemType', render: (row: any) => <div>{getMediaFormatImage(row.filename)}</div> }
  ];

  const tableOptions: Options<any> = {
    padding: 'dense',
    search: false,
    toolbar: false,
    draggable: false,
    pageSizeOptions: [5, 10, 15],
    pageSize: 15,
    headerStyle: {
      fontWeight: 'bold',
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  };

  const hFocusedMediaChanged = (_: any, item: any | undefined) => {
    if (getMediaType(item.filename) === MediaType.Document) {
      if (item?.url) {
        window.open(item.url, '_blank');
      }
      return;
    }

    if (item && item === focusedItem) {
      setFocusedItem(undefined);
      setIsViewingOpen(false);
    } else if (item) {
      setFocusedItem(item);
      setIsViewingOpen(true);
    }
  };

  function getMediaTime(time: Date | undefined) {
    if (time === undefined) return '-';

    return format(new Date(time), 'd/MM/yy\u00a0HH:mm');
  }

  function getMediaFormatImage(fileName: string) {
    const type = getMediaType(fileName);

    switch (type) {
      case MediaType.Image:
        return <ImageOutlined />;
      case MediaType.Video:
        return <VideocamOutlined />;
      case MediaType.Document:
        return <DescriptionOutlined />;
      case MediaType.Unknown:
        return <ErrorOutline />;
    }
  }

  return (
    <div style={{ maxHeight: '70vh', height: '70vh' }}>
      <MaterialTable
        title="Media & Documents"
        columns={columns}
        isLoading={props.loading}
        data={mergedData ?? []}
        options={tableOptions}
        onRowClick={hFocusedMediaChanged}
      />
      {isViewingOpen && focusedItem ? (
        <MediaViewerDialog
          id={focusedItem.id}
          itemType={focusedItem.itemType}
          onClose={() => {
            setIsViewingOpen(false);
          }}
        />
      ) : null}
    </div>
  );
}
