import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { ChartLegendOptions, ChartOptions } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import FugroColors, { downtimeColors, uptimeColors } from '../../../styles/FugroColors';
import { HomepageSkeletonBlock } from '../../skeletons/HomepageSkeletonBlock';
import { WorkpackListItem } from './WorkpacksOverview';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    favouritesHeading: {
      display: 'inline-block',
      margin: theme.spacing(1)
    },
    favouritesHeadingContainer: {
      position: 'relative'
    }
  })
);

export default function WorkpacksSummaryProgress(props: { loading: boolean; workpacks?: WorkpackListItem[] }): JSX.Element {
  const [chartData, setChartData] = useState<any>();
  const classes = useStyles();

  useEffect(() => {
    if (props.workpacks) {
      const newChartData = createDataSet(props.workpacks);
      const mergedChartData = mergeChartDatas(newChartData);

      if (mergedChartData) {
        setChartData(mergedChartData);
      }
    }
  }, [props.workpacks]);

  function mergeChartDatas(data: any[]) {
    const finalChart: {
      labels: string[];
      datasets: any[];
    } = {
      labels: [],
      datasets: [
        {
          label: 'Tasks Completed',
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 2
        },
        {
          label: 'Tasks Not Completed',
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 2
        }
      ]
    };

    for (let i = 0; i <= data.length - 1; i++) {
      finalChart.labels.push(data[i].labels[0]);
      finalChart.datasets[0].data.push(data[i].datasets[0].data[0]);
      finalChart.datasets[1].data.push(data[i].datasets[1].data[0]);

      finalChart.datasets[1].backgroundColor.push(downtimeColors[0]);
      finalChart.datasets[1].borderColor.push(downtimeColors[1]);

      finalChart.datasets[0].backgroundColor.push(uptimeColors[0]);
      finalChart.datasets[0].borderColor.push(uptimeColors[1]);
    }

    return finalChart;
  }

  function createDataSet(data: WorkpackListItem[]) {
    const chartCollection: any[] = [];

    data.forEach((x: WorkpackListItem) => {
      const newChartData = {
        labels: [x.code.slice(0, 10)],
        datasets: [
          {
            label: 'Completed',
            data: [x.progress.completedTaskCount],
            backgroundColor: [uptimeColors[0]],
            borderColor: [uptimeColors[1]]
          },
          {
            label: 'Not Completed',
            data: [Math.abs(x.progress.totalTaskCount - x.progress.completedTaskCount)],
            backgroundColor: [downtimeColors[0]],
            borderColor: [downtimeColors[1]]
          }
        ]
      };

      chartCollection.push(newChartData);
    });

    return chartCollection;
  }

  const legendOptions: ChartLegendOptions = {
    display: false,
    position: 'bottom',
    labels: {
      fontColor: FugroColors.primary.main
    }
  };

  const chartOptions: ChartOptions = {
    layout: {
      padding: {
        left: 15,
        right: 15,
        top: 3,
        bottom: 3
      }
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Tasks'
          }
        }
      ],
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            // display: true
            // labelString: 'Workpack'
          }
        }
      ]
    }
  };

  if (props.loading) {
    return <HomepageSkeletonBlock />;
  }

  return (
    <div>
      <div className={classes.favouritesHeadingContainer}>
        <Typography className={classes.favouritesHeading} component="h6" variant="h6">
          Progress
        </Typography>
      </div>
      {chartData ? <Bar data={chartData} options={chartOptions} legend={legendOptions} /> : <div>No Data</div>}
    </div>
  );
}
