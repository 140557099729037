export const _DEPRICATED_AnomaliesQuery = `query anomalyLocations($id: ID) {
  workpack(id: $id) {
    _itemCount {
      anomaly
    }
    anomaly {
      navigation {
        lnglat {
          longitude
          latitude
        }
        easting
        northing
        altitude
      }
      id
      summary
      criticality
      status
      anomalyCode {
        code
        description
      }
      component {
        fullComponent
      }
    }
  }
}
`;

export const _DEPRICATED_TaskQuery = `
query taskLocations($id: ID) {
  workpack(id: $id) {
    _itemCount {
      anomaly
    }
    task {
      id
      eventCode {
        code
        description
      }
      navigation {
        lnglat {
          longitude
          latitude
        }
        easting
        northing
        altitude
      }
    }
  }
}
`;

export const MapDataQuery = `
query taskLocations($id: ID) {
  workpack(id: $id) {
    name
    field
    description
    _WorkpackPosition {
      lnglat {
        latitude
        longitude
      }
    }
    mapDataRawJson
  }
  mapConfiguration {
    baseLayerTileUrl
    epsgCodes
    layerUrls
    esriToken
    esriFeatureLayers {
      url
      datumTransformationId
    }
    featureTileUrls {
      url
      friendlyName
    }
    esri3dUrls
  }
}
`;

export const ComponentsQuery = `
query taskLocations($id: ID) {
  workpack(id: $id) {
    _componentTree {
      id
      parent_ID
      fullComponent
      componentCode
    }
  }
}
`;

export const WorkpackPositionQuery = `
query workpackLocations {
  workpack {
    name
    _StructureComponent {
      fullComponent
    }
    _WorkpackPosition {
      lnglat {
        latitude
        longitude
      }
    }
  }
}
`;
