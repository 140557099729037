import { Card, CardContent, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useCardStyles } from '../styles/CardStyles';
import { useSkeletonStyles } from '../styles/skeletons';

export function StatisticsSkeletonBlock(): JSX.Element {
  const style = useCardStyles();
  const skeletonStyles = useSkeletonStyles();

  return (
    <Card className={style.cardDetails}>
      <CardContent>
        <Skeleton variant="text">
          <Typography component="h6" variant="h6" style={{ color: 'rgb(0,0,0,0)' }}>
            Dummy Text
          </Typography>
        </Skeleton>
        <div className={style.chartContainer} style={{ paddingBottom: '20px' }}>
          <Skeleton variant="rect" className={skeletonStyles.statistics} />
        </div>
      </CardContent>
    </Card>
  );
}
