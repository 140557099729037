import { createGlobalState } from 'react-hooks-global-state';

interface GlobalStateProps {
  globalOpenDrawer: boolean;
  globalPageTitle?: string;
  globalClientLogo?: string;
}

export const { GlobalStateProvider, useGlobalState } = createGlobalState<GlobalStateProps>({
  globalOpenDrawer: false,
  globalPageTitle: undefined,
  globalClientLogo: undefined
});
