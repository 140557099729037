import { createStyles, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const styles = makeStyles(() =>
  createStyles({
    container: {
      padding: '10px',
      display: 'block',
      height: '100%'
    },
    header: {
      height: '15%'
    },
    skeletonHeader: {
      height: '100%',
      transform: 'scale(1, 0.8)',
      transformOrigin: '0 10%'
    },
    content: {
      height: '85%'
    },
    contentSkeleton: {
      height: '100%',
      borderRadius: '4px'
    }
  })
);

export function HomepageSkeletonBlock(): JSX.Element {
  const style = styles();

  return (
    <div className={style.container}>
      <div className={style.header}>
        <Skeleton variant="text" className={style.skeletonHeader} />
      </div>
      <div className={style.content}>
        <Skeleton variant="rect" className={style.contentSkeleton} />
      </div>
    </div>
  );
}
