function pad(num: number, size: number): string {
  let s = num + '';
  while (s.length < size) {
    s = '0' + s;
  }
  return s;
}

// accepts seconds
export function formatSeconds(n: number): string {
  const sec_num = parseInt(n.toString(), 10); // don't forget the second param
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - hours * 3600) / 60);
  const seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 3600) {
    return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
  }

  return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
}

// accepts minutes
export function timeConvert(n: number): string {
  n = Math.round(n);
  const min = n % 60;
  const h = (n - min) / 60;
  return `${pad(h, 2)}:${pad(min, 2)}`;
}

export function formatSecondsWithStamp(n: number): string {
  const sec_num = parseInt(n.toString(), 10); // don't forget the second param
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - hours * 3600) / 60);
  const seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 3600) {
    if (minutes === 0) {
      return `${pad(hours, 2)}h`;
    }
    return `${pad(hours, 2)}h ${pad(minutes, 2)}m`;
  }
  return `${pad(hours, 2)}h ${pad(minutes, 2)}m ${pad(seconds, 2)}s`;
}

export function minutesToHours(minutes: number) {
  return Math.floor(minutes / 60);
}

export function mergeOverlapTimeRanges(timeRanges: ITimeRange[]): ITimeRange[] {
  if (timeRanges.length === 0) {
    return [];
  }
  // First sort the array by start time
  const sorted = timeRanges.sort((previous, current) => {
    // Get the start date from previous and current
    const previousTime = new Date(previous.startTime).getTime();
    const currentTime = new Date(current.startTime).getTime();

    // If the previous is earlier than the current
    if (previousTime < currentTime) {
      return -1;
    }

    // If the previous time is the same as the current time
    if (previousTime === currentTime) {
      return 0;
    }

    // If the previous time is later than the current time
    return 1;
  });

  const merged: ITimeRange[] = [];
  merged.push(sorted[0]);

  for (let i = 1; i < sorted.length; i++) {
    const mergedLast = merged[merged.length - 1];
    const current = sorted[i];

    if (current.startTime >= mergedLast.startTime && current.startTime < mergedLast.endTime) {
      merge(mergedLast, current);
    } else {
      merged.push(current);
    }
  }

  return merged;
}

function merge(self: ITimeRange, other: ITimeRange) {
  if (self.startTime < other.startTime) {
    other.startTime = self.startTime;
  }
  if (other.endTime > self.endTime) {
    self.endTime = other.endTime;
  }
}

export interface ITimeRange {
  startTime: Date;
  endTime: Date;
}
