import { Card, CardContent, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import chartjs from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Colors } from '../../../styles/FugroColors';
import { LineChartData, LineChartDataset } from '../../../types/chartjs/lineChartData.type';
import { useCardStyles } from '../../styles/CardStyles';
import { useSkeletonStyles } from '../../styles/skeletons';
import { WorkpackStatsItem, WorkpackStatsProgressTaskItem } from './workpackStatisticsDashboard';

export default function WorkpackProgress(props: { loading: boolean; data?: WorkpackStatsItem }): JSX.Element {
  const classes = useCardStyles();
  const skeletonStyles = useSkeletonStyles();

  function createChartData(stats: WorkpackStatsProgressTaskItem[]): LineChartData {
    const validDate = new Date('1/1/2000');

    const taskProgress = stats
      .filter((x) => new Date(x.time) > validDate)
      .map((x) => ({
        x: x.time,
        y: x.completionTaskCumulative
      }));

    const timeWeightedProgress = stats
      .filter((x) => new Date(x.time) > validDate)
      .map((x) => ({
        x: x.time,
        y: x.completionTimeCumulative
      }));

    const taskProgressDataset = new LineChartDataset('Task Progress', Colors.TaskProgress, taskProgress);

    const timeWeightedProgressDataset = new LineChartDataset('Time Weighted Progress', Colors.TimeWeightedProgress, timeWeightedProgress);

    const datasets: any[] = [];
    datasets.push(taskProgressDataset);
    datasets.push(timeWeightedProgressDataset);

    if (props.data) {
      const plannedProgressDataset = new LineChartDataset(
        'Planned Progress',
        Colors.PlannedProgress,
        [
          { x: props.data.startDate, y: 0 },
          { x: props.data.endDate, y: 100 }
        ],
        [2.5, 2.5]
      );

      datasets.push(plannedProgressDataset);
    }

    return {
      labels: [],
      datasets
    };
  }

  const chartOptions: chartjs.ChartOptions = {
    maintainAspectRatio: false,
    tooltips: {
      displayColors: false,
      callbacks: {
        title(tooltipItem: any, data: any) {
          if (tooltipItem.index === -1) {
            return '';
          } else if (data.labels) {
            const data = tooltipItem[0].label;
            const dateObject = new Date(data);
            const labelString = `${dateObject.toLocaleTimeString()} - ${dateObject.toDateString()}`;

            return labelString;
          }

          return 'N/A';
        },
        label() {
          return '';
        },
        afterLabel() {
          return '';
        }
      }
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            unit: 'day',
            unitStepSize: 1,
            displayFormats: {
              day: 'MMM DD'
            }
          }
        }
      ]
    }
  };

  if (props.loading || !props.data) {
    return (
      <Card className={classes.cardDetails}>
        <CardContent>
          <Skeleton variant="text" style={{ color: 'rgb(0,0,0,0)' }}>
            <Typography component="h2" variant="h6">
              Workpack Progress
            </Typography>
          </Skeleton>
          <div className={classes.chartContainer} style={{ paddingBottom: '20px' }}>
            <Skeleton variant="rect" className={skeletonStyles.statistics} />
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={classes.cardDetails}>
      <CardContent>
        <Typography component="h2" variant="h6" color="textSecondary">
          Workpack Progress
        </Typography>
        <div className={classes.chartContainer}>
          <Line data={createChartData(props.data?.progress.task)} options={chartOptions} />
        </div>
      </CardContent>
    </Card>
  );
}
