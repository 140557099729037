import { useQuery } from '@apollo/react-hooks';
import { Box, CircularProgress, createStyles, Dialog, DialogContent, IconButton, makeStyles, Typography } from '@material-ui/core';
import { ErrorOutline, NavigateBefore, NavigateNext } from '@material-ui/icons';
import { gql } from 'apollo-boost';
import Hls from 'hls.js';
import React, { useCallback, useEffect, useRef, useState } from 'react';

interface MediaViewerDialogProps {
  itemType?: MediaItemItemType;
  id: string;
  allMediaItems?: string[];
  onClose: () => void;
}

export type MediaItemItemType = 'ANOMALY_MEDIA' | 'MASTER_ANOMALY_MEDIA' | 'IMAGE_GRAB' | 'VIDEO_LOG' | 'TASK_MEDIA';

export const MediaViewerDialog = (props: MediaViewerDialogProps) => {
  interface MediaItemData {
    mediaItem: {
      url: string;
      urlSignature: string;
      isVideo: boolean;
      skipForwardSeconds: number;
    };
  }

  const [currentMediaItemId, setCurrentMediaItemId] = useState<string>(props.id);
  const [positionInAllMedia, setPositionInAllMedia] = useState<number>(props.allMediaItems?.indexOf(props.id) ?? 0);

  const useStyles = makeStyles(() =>
    createStyles({
      dialogPaper: {
        backgroundColor: 'black',
        overflow: 'hidden'
      },
      dialogContent: {
        textAlign: 'center',
        backgroundColor: 'black',
        color: 'white',
        position: 'relative',
        overflow: 'hidden'
      },
      container: {
        overflow: 'hidden'
      },
      circularProgressColour: { color: 'white' },
      video: {
        width: '100%!important' as any,
        maxHeight: '100%',
        height: 'auto'
      },
      img: {
        maxWidth: '100%',
        maxHeight: '90vh',
        objectFit: 'contain'
      },
      hugeZIndex: {
        zIndex: 999
      },
      carouselButtonWrapper: {
        position: 'absolute',
        height: '100%',
        backgroundColor: 'transparent',
        top: 0
      },
      carouselButton: {
        margin: '0 10px',
        position: 'relative',
        top: 'calc(50% - 20px)',
        backgroundColor: '#494949',
        color: 'white',
        opacity: '0.5!important' as any,
        fontSize: '30px',
        transition: '200ms',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#888888!important' as any,
          opacity: '1!important' as any
        }
      },
      carouselButtonWrapperNext: {
        right: 0
      },
      carouselButtonWrapperPrev: {
        left: 0
      }
    })
  );
  const classes = useStyles();

  const { loading, error, data } = useQuery<MediaItemData, { id: string; itemType?: MediaItemItemType }>(
    gql`
      query mediaItem($id: ID!, $itemType: MediaItemItemType) {
        mediaItem(id: $id, itemType: $itemType) {
          url
          urlSignature
          isVideo
          skipForwardSeconds
        }
      }
    `,
    { variables: { id: currentMediaItemId, itemType: props.itemType } }
  );

  useEffect(() => {
    if (props.allMediaItems) {
      setCurrentMediaItemId(props.allMediaItems[positionInAllMedia]);
    }
  }, [positionInAllMedia, props.allMediaItems, props.itemType]);

  const RenderMediaVideo = () => {
    const [isError, setIsError] = useState<boolean>(false);
    const [errorSubMessage, setErrorSubMessage] = useState<string>();

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      if (!data || !data.mediaItem || !data.mediaItem.url) {
        return;
      }
      if (videoRef.current === null) {
        return;
      }

      const hls = new Hls({
        enableWorker: false,
        xhrSetup: (xhr, url) => {
          const actualUrl =
            data.mediaItem.urlSignature === null || data.mediaItem.urlSignature === undefined ? url : url + data.mediaItem.urlSignature;
          xhr.open('GET', actualUrl, true);
        }
      });
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(data.mediaItem.url);
      });
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (videoRef.current === null) {
          return;
        }
        if ((data.mediaItem.skipForwardSeconds ?? 0) !== 0) {
          videoRef.current.currentTime = data.mediaItem.skipForwardSeconds;
        }
        return videoRef.current.play();
      });
      hls.on(Hls.Events.ERROR, (e, d) => {
        if (d.fatal) {
          switch (d.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              console.log('fatal network error encountered, try to recover');
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.log('fatal media error encountered, try to recover');
              hls.recoverMediaError();
              break;
            default:
              // cannot recover
              hls.destroy();
              setIsError(true);
              setErrorSubMessage(`${d.type}: ${d.details}: ${d.response?.code} ${d.response?.text}`);
              break;
          }
        }
      });

      return () => {
        hls.destroy();
      };
    }, []);

    if (isError) {
      return <MediaError message="There was a problem playing this video." subMessage={errorSubMessage} />;
    }

    return <video controls={true} ref={videoRef} className={classes.video} />;
  };

  const RenderMediaPicture = () => {
    const [isError, setIsError] = useState<boolean>(false);
    const handleImageError = () => setIsError(true);
    if (isError || !data || !data.mediaItem) {
      return <MediaError message="There was a problem loading this image." />;
    }
    return (
      <a href={data.mediaItem.url} rel="noopener noreferrer" target="_blank">
        <img alt="" src={data.mediaItem.url} onError={handleImageError} className={classes.img} />
      </a>
    );
  };

  const dialogContent = () => {
    if (loading) {
      return (
        <CircularProgress
          classes={{
            colorPrimary: classes.circularProgressColour,
            colorSecondary: classes.circularProgressColour
          }}
        />
      );
    }

    if (error || !data || !data.mediaItem) {
      return <MediaError subMessage={error?.message} />;
    }

    return data.mediaItem.isVideo ? <RenderMediaVideo /> : <RenderMediaPicture />;
  };

  const handlePrev = () => setPositionInAllMedia(canGoPrev() ? positionInAllMedia - 1 : (props.allMediaItems?.length ?? 1) - 1);
  const handleNext = () => setPositionInAllMedia(canGoNext() ? positionInAllMedia + 1 : 0);

  const canGoPrev = () => positionInAllMedia > 0;
  const canGoNext = () => positionInAllMedia < (props.allMediaItems?.length ?? 1) - 1;

  const keyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        handlePrev();
      }
      if (event.key === 'ArrowRight') {
        handleNext();
      }
      // eslint-disable-next-line
    },
    [positionInAllMedia]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler, false);
    return () => document.removeEventListener('keydown', keyDownHandler, false);
  }, [keyDownHandler]);

  const navButtons =
    (props.allMediaItems?.length ?? 1) > 1 ? (
      <React.Fragment>
        <div className={`${classes.carouselButtonWrapperPrev} ${classes.carouselButtonWrapper} ${classes.hugeZIndex}`}>
          <IconButton className={`Prev ${classes.carouselButton} mui--align-middle button ${classes.hugeZIndex}`} onClick={handlePrev}>
            <NavigateBefore />
          </IconButton>
        </div>
        <div className={`${classes.carouselButtonWrapperNext} ${classes.carouselButtonWrapper} ${classes.hugeZIndex}`}>
          <IconButton className={`Next ${classes.carouselButton} mui--align-middle button ${classes.hugeZIndex}`} onClick={handleNext}>
            <NavigateNext />
          </IconButton>
        </div>
      </React.Fragment>
    ) : null;

  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={true}
      scroll="body"
      onClose={props.onClose}
      classes={{ paper: classes.dialogPaper, container: classes.container }}
    >
      <DialogContent className={`${classes.dialogContent} Carousel CarouselItem`}>
        {navButtons}
        {dialogContent()}
      </DialogContent>
    </Dialog>
  );
};

const MediaError = (props: { message?: string; subMessage?: string }) => (
  <React.Fragment>
    <Box>
      <ErrorOutline fontSize="large" />
    </Box>
    <Typography>{props.message ?? 'There was a problem loading this content.'}</Typography>
    {props.subMessage ? <Typography variant="subtitle2">{props.subMessage}</Typography> : null}
  </React.Fragment>
);
