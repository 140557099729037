import MaterialTable, { Column, Options } from '@material-table/core';
import { createStyles, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { PlayCircleOutline } from '@material-ui/icons';
import React from 'react';
import { MasterAnomalyStatus } from '../../../../../utils/enumerations';
import { Component, ComponentAnomaly, ComponentAnomalyBase, ComponentMasterAnomaly } from '../../../Interfaces';
import { MAStatusColour, MAStatusText, renderComponentCode } from '../../../Functions';
import { format } from 'date-fns';
import StatusIcon from '../../StatusIcon';

export default function OpenAnomalyTable(props: {
  data: any;
  loading: boolean;
  setSelectedAnomalyMediaItemIds: (arg: string[]) => void;
}): JSX.Element {
  const loading = props.loading;
  const data = props.data;

  const tableOptions: Options<any> = {
    padding: 'dense',
    search: false,
    toolbar: false,
    draggable: false,
    headerStyle: {
      fontWeight: 'bold',
      borderBottom: `2px solid gray` //${theme.palette.primary.main}`
    }
  };

  const handleAnomalyTableOpenViewer = (mediaIds: string[], e: any) => {
    if (!e || !mediaIds) {
      return;
    }
    e.stopPropagation();
    props.setSelectedAnomalyMediaItemIds(mediaIds);
  };

  const renderAnomalyTablePlayMedia = (anomaly: ComponentAnomalyBase) => (
    <div>
      {anomaly.media !== undefined && anomaly.media.length > 0 ? (
        <IconButton
          size="small"
          onClick={(e) =>
            handleAnomalyTableOpenViewer(
              anomaly.media.map((m) => m.id),
              e
            )
          }
        >
          <PlayCircleOutline fontSize="small" />
        </IconButton>
      ) : null}
    </div>
  );

  const columns: Array<Column<ComponentAnomaly>> = [
    { title: 'ID', field: 'clientID' },
    { title: 'Media', field: 'media', type: 'boolean', render: renderAnomalyTablePlayMedia },
    { title: 'Start Time', field: 'startTime', render: (d) => format(new Date(d.startTime), 'd/MM/yy\u00a0HH:mm') },
    { title: 'Summary', field: 'summary' },
    { title: 'Code', field: 'anomalyCode.code', render: (d) => <div title={d.anomalyCode.description}>{d.anomalyCode.code}</div> },
    { title: 'Component', field: 'component[0].componentCode', render: (d) => renderComponentCode(d.component) },
    { title: 'Criticality', field: 'criticality' }
  ];

  return (
    <div>
      <Typography variant="h6">Open Anomalies</Typography>
      <MaterialTable
        title="Open Anomalies"
        columns={columns}
        isLoading={loading}
        data={data?.components?.flatMap((comp: Component) => comp.anomaly) ?? []}
        options={tableOptions}
      />
    </div>
  );
}
