import { useQuery } from '@apollo/react-hooks';
import {
  Checkbox,
  Collapse,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Switch,
  Theme,
  Typography
} from '@material-ui/core';
import { FormatAlignJustify, Layers, Search, SpaceBar } from '@material-ui/icons';
import { gql } from 'apollo-boost';
import clsx from 'clsx';
import React, { useEffect, useState, useRef } from 'react';
import { EsriFeatureLayer, FeatureTileUrl, MapConfiguration } from '../DeckGL/extras/Types';
import { GetHumanReadableEsriUrl, isAnomaly } from '../MappingUtils';
import { Anomaly, LayerUrl, MarkerInfo, Task } from '../MapUtils/SharedTypes';
import { ExpandingMarkerPanel } from './ExpandingMarkerPanel';
import NoMarker from './NoMarker';
import SearchContainer from './panels/SearchContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: '1px dashed black',
      padding: '5px'
    },
    buttonExpander: {
      position: 'absolute',
      bottom: 0
    },
    mapPanel: {
      top: 0,
      height: '100%',
      padding: '10px',
      paddingRight: '0px',
      // backgroundColor: '#181b20', // Make the sidebar transparent
      borderRadius: '9px'
    },
    mapPanelWrapper: {
      padding: '5px',
      position: 'absolute',
      minHeight: '600px',
      height: '100%',
      zIndex: 998,
      top: 0,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: '70px'
    },
    mapPanelWrapperExpanded: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      width: '450px'
    },
    mapPanelForeColor: {
      color: 'white'
    },
    mapPanelIcon: {
      paddingLeft: '8px',
      paddingRight: '8px'
    },
    mapPanelIconContainer: {
      borderRadius: '9px',
      backgroundColor: '#2d3035',
      width: '41px'
    },
    mapPanelIconContainerWrapper: {
      color: 'white',
      float: 'left',
      width: '50px',
      height: '100%'
    },
    mapPanelSeperator: {
      width: '5px',
      backgroundColor: '#2d3035',
      float: 'left',
      height: '100%',
      borderRadius: '9px',
      paddingRight: '8px',
      marginRight: '8px'
    },
    dividerColor: {
      backgroundColor: '#2d3035'
    },
    hidden: {
      display: 'none'
    },
    dragContainer: {
      float: 'right',
      height: '100%'
    },
    dragIcon: {
      top: '45%',
      left: 0,
      position: 'relative',
      cursor: 'w-resize'
    },
    rulerButtonEnabled: {
      border: '1px solid white',
      borderRadius: '9px'
    },
    esriCheckboxLabel: {
      fontSize: '0.8rem'
    }
  })
);

enum View {
  Markers = 'Markers',
  MarkerDetails = 'Marker Details',
  Search = 'Search'
}

export default function SideBar(props: any): JSX.Element {
  const [checkboxes, setCheckbox] = useState({
    events: true,
    tasks: true,
    anomalies: true,
    text: true
  });
  const { events, tasks, anomalies, text } = checkboxes;
  const [mapDarkMode, setMapDarkMode] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<View>(View.Markers);
  const [inTransition, setInTransition] = useState<boolean>(false);
  const [rulerEnabled, setRulerEnabled] = useState<boolean>(false);
  const panelWidth = 450;
  const [selectedEsriLayers, setSelectedEsriLayers] = useState<LayerUrl[]>([]);
  const { loading, error, data } = useQuery<{ mapConfiguration: MapConfiguration }, object>(
    gql`
      query overview {
        mapConfiguration {
          esriFeatureLayers {
            url
            datumTransformationId
          }
          featureTileUrls {
            url
            friendlyName
          }
          esri3dUrls
        }
      }
    `,
    {}
  );

  const focusedMarkers: MarkerInfo[] = props.focusedMarkers;
  const classes = useStyles();
  const mapPanelRef = useRef(null);

  useEffect(() => {
    const darkMode = localStorage.getItem('map.dark');
    if (darkMode !== null) {
      const darkModeVal = JSON.parse(darkMode);
      setMapDarkMode(darkModeVal);
    }
  }, []);

  useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        setInTransition(false);
      }, 225);
    }
  }, [expanded]);

  useEffect(() => {
    if (data) {
      const noLocalStorageSet = localStorage.getItem('selectedGisLayers') === null;
      const localStorageLayers: LayerUrl[] = JSON.parse(localStorage.getItem('selectedGisLayers') ?? '[]');

      const esriLayers = data.mapConfiguration.esriFeatureLayers.filter((layer: EsriFeatureLayer) => layer !== null && layer?.url !== '');
      const featureTileLayers = data.mapConfiguration.featureTileUrls.filter((t: FeatureTileUrl) => t !== null && t.url !== '');

      const kpv: LayerUrl[] = esriLayers.map((l: EsriFeatureLayer) => {
        return {
          readableUrl: GetHumanReadableEsriUrl(l.url),
          originalUrl: l.url,
          transformationId: l.datumTransformationId,
          isChecked: noLocalStorageSet ? true : localStorageLayers.some((sl) => sl.originalUrl === l.url && sl.isChecked),
          isEsri: true
        };
      });

      kpv.push(
        ...featureTileLayers.map((t) => {
          return {
            readableUrl: t.friendlyName,
            originalUrl: t.url,
            transformationId: 0,
            isChecked: noLocalStorageSet
              ? true
              : localStorageLayers.some((sl) => sl.originalUrl.split('?')[0] === t.url.split('?')[0] && sl.isChecked),
            isEsri: false
          };
        })
      );

      setSelectedEsriLayers(kpv);
      props.esriLayers(kpv);
    }
  }, [data]);

  useEffect(() => {
    if (props.onIsMeasureRunning === false) {
      setRulerEnabled(false);
    }
  }, [props.onIsMeasureRunning]);

  const handleInformationCheckboxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    // if(checkboxes.hasOwnProperty(name) !== undefined) {
    //   props.checkboxes
    // }
    setCheckbox({ ...checkboxes, [name]: event.target.checked });
    props.checkboxes(name, event.target.checked);
  };

  const handleEsriLayerCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const newState = selectedEsriLayers.filter((id: LayerUrl) => id.originalUrl !== evt.target.name);
    const oldItem = selectedEsriLayers.filter((id: LayerUrl) => id.originalUrl === evt.target.name)[0];
    const newItem: LayerUrl = {
      readableUrl: oldItem.readableUrl,
      originalUrl: oldItem.originalUrl,
      isChecked: evt.target.checked,
      transformationId: oldItem.transformationId,
      isEsri: oldItem.isEsri
    };
    const index = selectedEsriLayers.indexOf(oldItem);

    newState.splice(index, 0, newItem);

    setSelectedEsriLayers(newState);

    if (newState.every((e) => e.isChecked)) {
      localStorage.removeItem('selectedGisLayers');
    } else {
      localStorage.setItem('selectedGisLayers', JSON.stringify(newState));
    }

    props.esriLayers(newState);
  };

  function toggleExpandedState() {
    setInTransition(true);
    setExpanded(!expanded);
  }

  const handleView = (e: any) => {
    const _view = e.currentTarget.dataset.layer as View;

    if (_view === currentView) {
      toggleExpandedState();
    } else if (!expanded) {
      toggleExpandedState();
    }

    setCurrentView(_view);
  };

  const handleMapToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMapDarkMode(event.target.checked);
    localStorage.setItem('map.dark', JSON.stringify(event.target.checked));
    props.onIsDarkModeToggle(event.target.checked);
  };

  function getEsriCheckboxes() {
    return selectedEsriLayers.map((layer: LayerUrl, index: number) => {
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedEsriLayers[index].isChecked}
              onChange={handleEsriLayerCheckboxChange}
              name={layer.originalUrl}
              style={{ color: 'white' }}
            />
          }
          classes={{ label: classes.esriCheckboxLabel }}
          key={`esri-layer-${index}`}
          label={layer.readableUrl}
        />
      );
    });
  }

  function ShowLayerInfo() {
    return (
      <Collapse in={currentView === View.Markers}>
        <div style={{ padding: '8px' }}>
          <FormControl component="fieldset" style={{ color: 'white', width: '270px', marginTop: '4px' }}>
            <FormLabel component="legend" style={{ color: 'white', marginBottom: '2px' }}>
              {' '}
              Information
            </FormLabel>
            <Divider classes={{ root: classes.dividerColor }} />
            <FormGroup row={false}>
              <FormControlLabel
                control={<Checkbox checked={events} onChange={handleInformationCheckboxChange('events')} style={{ color: 'white' }} />}
                label="Events"
              />
              <FormControlLabel
                control={<Checkbox checked={anomalies} onChange={handleInformationCheckboxChange('anomalies')} style={{ color: 'white' }} />}
                label="Anomalies"
              />
              <FormControlLabel
                control={<Checkbox checked={tasks} onChange={handleInformationCheckboxChange('tasks')} style={{ color: 'white' }} />}
                label="Tasks"
              />
              <FormControlLabel
                control={<Checkbox checked={text} onChange={handleInformationCheckboxChange('text')} style={{ color: 'white' }} />}
                label="Tile Text"
              />
            </FormGroup>
            <FormGroup style={{ marginTop: '4px' }}>
              <Typography component="div">
                {/* <FormLabel
                  disabled={true}
                  component='legend'
                  style={{ color: 'white', marginBottom: '2px' }}
                >  Map Style
                </FormLabel>
                <Divider classes={{ root: classes.dividerColor }} />
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item={true}>Light</Grid>
                  <Grid item={true}>
                    <Switch disabled={true} checked={mapDarkMode} onChange={handleMapToggleChange} name='checkedMapSkin' />
                  </Grid>
                  <Grid item={true}>Dark</Grid>
                </Grid> */}
              </Typography>
            </FormGroup>
            <FormGroup style={{ marginTop: '4px' }}>
              <Typography component="div">
                <FormLabel disabled={true} component="legend" style={{ color: 'white', marginBottom: '2px' }}>
                  {' '}
                  GIS Layers
                </FormLabel>
                <Divider classes={{ root: classes.dividerColor }} />
                {getEsriCheckboxes()}
              </Typography>
            </FormGroup>
          </FormControl>
        </div>
      </Collapse>
    );
  }

  function ShowMarkerInfo() {
    // TODO: Add additional check here if a marker is selected
    if (currentView === View.MarkerDetails) {
      if (focusedMarkers.length === 0) {
        return <NoMarker title={''} text={'No Markers Selected.'} />;
      } else {
        return (
          <div
            style={{
              float: 'left',
              width: '270px',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              maxHeight: '100%'
            }}
          >
            <FormLabel component="legend" style={{ color: 'white', marginBottom: '2px' }}>
              {' '}
              Selected Markers:{' '}
            </FormLabel>
            <Divider classes={{ root: classes.dividerColor }} style={{ marginBottom: '5px' }} />
            {focusedMarkers.map((x: MarkerInfo, index: any) => {
              const isAnom: boolean = isAnomaly(x.data);
              const title = isAnom ? (x.data as Anomaly).anomalyCode.code : (x.data as Task).eventCode.code;
              const description = isAnom ? (x.data as Anomaly).summary : (x.data as Task).eventCode.description;

              return (
                <ExpandingMarkerPanel key={`expand_${index}`} title={title}>
                  {description}
                </ExpandingMarkerPanel>
              );
            })}
          </div>
        );
      }
    }
  }

  function ShowSearchInfo() {
    if (currentView === View.Search) {
      return <SearchContainer workpackId={props.workpackId} setSearchItem={props.searchItem} />;
    }
  }

  function hRulerOnClick() {
    const state = !rulerEnabled;
    setRulerEnabled(state);
    props.onIsMeasuring(state);

    return false;
  }

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div
      className={clsx(classes.mapPanelWrapper, {
        [classes.mapPanelWrapperExpanded]: expanded
      })}
      style={{ width: expanded ? `${panelWidth}px` : '70px' }}
    >
      <div className={classes.mapPanel} ref={mapPanelRef}>
        <div className={classes.mapPanelIconContainerWrapper}>
          <List style={{ paddingTop: '0px', height: '100%' }}>
            <div className={classes.mapPanelIconContainer} data-layer={View.Markers} onClick={handleView}>
              <ListItem button={true} className={classes.mapPanelIcon}>
                <ListItemIcon className={classes.mapPanelForeColor}>
                  <Layers />
                </ListItemIcon>
              </ListItem>
            </div>
            <div style={{ marginTop: '5px' }} className={classes.mapPanelIconContainer} data-layer={View.MarkerDetails} onClick={handleView}>
              <ListItem button={true} className={classes.mapPanelIcon}>
                <ListItemIcon className={classes.mapPanelForeColor}>
                  <FormatAlignJustify />
                </ListItemIcon>
              </ListItem>
            </div>
            <div style={{ marginTop: '5px' }} className={classes.mapPanelIconContainer} data-layer={View.Search} onClick={handleView}>
              <ListItem button={true} className={classes.mapPanelIcon}>
                <ListItemIcon className={classes.mapPanelForeColor}>
                  <Search />
                </ListItemIcon>
              </ListItem>
            </div>
            <div
              style={{ marginTop: '5px' }}
              className={clsx(classes.mapPanelIconContainer, rulerEnabled ? classes.rulerButtonEnabled : '')}
              data-layer={View.Search}
              onClick={hRulerOnClick}
            >
              <ListItem button={true} className={classes.mapPanelIcon}>
                <ListItemIcon className={classes.mapPanelForeColor}>
                  <SpaceBar />
                </ListItemIcon>
              </ListItem>
            </div>
          </List>
          <Divider />
        </div>
        {expanded && !inTransition && (
          <React.Fragment>
            <div
              style={{
                borderRadius: '11px',
                backgroundColor: '#181b20',
                float: 'left',
                height: 'calc(100% - 30px)',
                width: 'calc(100% - 100px)',
                marginLeft: '50px',
                position: 'absolute'
              }}
            >
              {ShowLayerInfo()}
              {ShowMarkerInfo()}
              {ShowSearchInfo()}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
