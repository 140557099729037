import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import WorkpacksOverview from '../workpacks/workpacksOverview/WorkpacksOverview';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    header: {
      position: 'relative',
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      padding: theme.spacing(8, 0, 6)
    },
    headings: {
      marginTop: '2em'
    },
    projectCardGrid: {
      display: 'grid',
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: `repeat(2, 1fr);`,
        gridGap: theme.spacing(2)
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: `repeat(3, 1fr);`,
        gridGap: theme.spacing(2)
      }
    },
    divider: {
      margin: '1em 0em',
      marginBottom: '1.5em',
      height: '2px'
    }
  })
);

export default function Dashboard(props: RouteComponentProps): JSX.Element {
  const classes = useStyles();
  // const [, setPageTitle] = useGlobalState('globalPageTitle');
  // setPageTitle(undefined);

  return (
    <div className={classes.root}>
      <WorkpacksOverview {...props} />
    </div>
  );
}
