import { CameraProperties, LngLat, MvtProperties } from '../../MapUtils/SharedTypes';

export const DEFAULT_ESRI_CAMERA: CameraProperties = {
  position: {
    longitude: 0,
    latitude: 0,
    z: 9999999
  }
};

export const INVALID_LNGLAT: LngLat[] = [
  { longitude: -90, latitude: -45 },
  { longitude: 90, latitude: 45 }
];

export const DUMMY_DATA = [
  { name: 'A (COLM)', coordinates: [148.43547, -38.191754] },
  { name: 'B (COLM)', coordinates: [148.435475, -38.191754] },
  { name: 'C (COLM)', coordinates: [148.43548, -38.191754] },
  { name: 'D (COLM)', coordinates: [148.435485, -38.191754] },
  { name: 'E (COLM)', coordinates: [148.43549, -38.191754] },
  { name: 'F (COLM)', coordinates: [148.435495, -38.191754] }
];

export const ICON_MAPPING_SIMPLE = {
  marker: {
    x: 0,
    y: 128,
    width: 128,
    height: 128,
    mask: true
  },
  'marker-anomaly': {
    x: 128,
    y: 128,
    width: 128,
    height: 128,
    mask: false
  }
};

export const INITIAL_VIEW_STATE = {
  longitude: 0,
  latitude: 0,
  zoom: 1,
  maxZoom: 21,
  pitch: 0,
  bearing: 0
  // width: 500,
  // height: 500
};

export const PBF_LAYER_PROPS: MvtProperties = {
  minZoom: 0,
  maxZoom: 20,
  opacity: 1,
  // @ts-ignore
  stroked: true,
  filled: true,
  getLineColor: [255, 0, 255],
  getFillColor: [0, 35, 102],
  getLineWidth: 1,
  lineWidthUnits: 'pixels',
  lineWidthMinPixels: 1,
  pickable: true,
  darkMode: true,
  showText: true
};
