import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Theme,
  Toolbar,
  Typography
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { Link, RouteProps } from 'react-router-dom';
import { RenderAppBar } from './AppBar';
import Footer from './Footer';
import FugroLogo from '../../images/fugro-logo-white.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    main: {
      flexGrow: 1
    },
    content: {
      margin: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2)
      },
      marginBottom: theme.spacing(1)
    },
    appContainer: {
      display: 'contents'
    },
    appBarSpacer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar
    },
    appBarLogo: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
      height: '1.5em'
    },
    title: {
      flexGrow: 1
    }
  })
);

const NotReadyWrapper = (props: { children: React.ReactNode }) => {
  const classes = useStyles();

  return (
    <div className={classes.appContainer}>
      <div className={classes.root + ' content'}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <IconButton color="inherit" edge="start">
              <img src={FugroLogo} className={classes.appBarLogo} alt="FugroLogo" />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Sense.Structures
            </Typography>
          </Toolbar>
        </AppBar>
        {props.children}
      </div>
    </div>
  );
};

const EulaModalDialog = () => {
  const [acceptChecked, setAcceptChecked] = useState<boolean>();

  const [acceptEula, { loading, error, data }] = useMutation<{}, { acceptEula: boolean }>(
    gql`
      mutation acceptEula {
        acceptEula
      }
    `,
    {
      onCompleted: (d) => {
        if (d) {
          window.location.reload();
        }
      }
    }
  );

  return (
    <Dialog open={true}>
      <DialogTitle>Platform Use Terms and Conditions</DialogTitle>
      <DialogContent>
        <DialogContentText>In order to use this website, you need to accept the Platform Use Terms and Conditions.</DialogContentText>
        <DialogContentText>
          <a target="_new" href="/EULA_202210.pdf">
            Platform Use Terms and Conditions
          </a>
        </DialogContentText>
        <FormControlLabel
          control={<Checkbox checked={acceptChecked} onChange={(e) => setAcceptChecked(e.target.checked)} />}
          label="I agree to the terms of the Platform Use Terms and Conditions"
        />
      </DialogContent>
      <DialogActions>
        {loading ? <CircularProgress size={16} /> : null}
        <Button onClick={() => acceptEula()} disabled={!acceptChecked}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function MainLayout({ children, component }: RouteProps) {
  const classes = useStyles();

  const { data, loading } = useQuery<{ hasAcceptedEula: boolean }, {}>(
    gql`
      query checkEulaAcceptance {
        hasAcceptedEula
      }
    `,
    { variables: { clientImagesOnly: true } }
  );

  if (loading) {
    return (
      <NotReadyWrapper>
        <div></div>
      </NotReadyWrapper>
    );
  }

  if (data?.hasAcceptedEula === false) {
    return (
      <NotReadyWrapper>
        <EulaModalDialog />
      </NotReadyWrapper>
    );
  }

  return (
    <div className={classes.appContainer}>
      <div className={classes.root + ' content'}>
        <CssBaseline />

        <RenderAppBar />
        <div className={classes.main}>
          <div className={classes.appBarSpacer} />
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}
