import { createStyles, Accordion, AccordionDetails, AccordionSummary, makeStyles, Theme, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';

interface ExpandingPanelProps {
  title: string;
  defaultExpanded?: boolean;
  edgeToEdge?: boolean;
  children: React.ReactNode;
}
export const ExpandingMarkerPanel = (props: ExpandingPanelProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      expansionPanel: {},
      expansionPanelSummary: {
        backgroundColor: '#2d3035', //theme.palette.primary.dark,
        color: 'white',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        minHeight: 0
      },
      expansionPanelSummaryExpanded: {
        marginTop: '0 !important',
        marginBottom: '0 !important'
      },
      expansionPanelTitle: {
        margin: 0,
        padding: 0
      },
      expansionPanelContent: {
        paddingLeft: 16,
        paddingRight: 16
      },
      expansionPanelContentEdgeToEdge: {
        padding: 0
      }
    })
  );
  const classes = useStyles();

  return (
    <Accordion defaultExpanded={props.defaultExpanded} classes={{ expanded: classes.expansionPanelSummaryExpanded }}>
      <AccordionSummary expandIcon={<ExpandMore htmlColor="white" />} classes={{ root: classes.expansionPanelSummary }}>
        <Typography className={classes.expansionPanelTitle}>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: props.edgeToEdge ? classes.expansionPanelContentEdgeToEdge : classes.expansionPanelContent
        }}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};
