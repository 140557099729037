import { useQuery } from '@apollo/react-hooks';
import { Box, CircularProgress, createStyles, Link, makeStyles, Table, TableBody, TableCell, TableRow, Theme, Typography } from '@material-ui/core';
import { PlayCircleOutline } from '@material-ui/icons';
import { gql } from 'apollo-boost';
import { format } from 'date-fns';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { determineAverageDimension, ExtractDimensionsFromBase64JpegArray, extraDimensionsFromBase64Jpeg } from '../../../../utils/imageUtils';
import { ExpandingPanel } from '../ExpandingPanel/ExpandingPanel';
import ExpandingPanelErrorText from '../ExpandingPanel/ExpandingPanelErrorText';
import { MediaThumb } from '../MediaThumb';
import { MediaViewerDialog } from '../MediaViewerDialog';

export interface AnomalyDetailProps {
  anomalyId: string;
}

export const AnomalyDetail = (props: AnomalyDetailProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      card: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5)
      },
      cardHeader: {
        marginTop: -theme.spacing(1),
        marginBottom: -theme.spacing(1)
      },
      topBox: {
        marginBottom: theme.spacing(1)
      },
      tableRowTitle: {
        width: '40%'
      },
      spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      },
      carouselPlayButton: {
        fontSize: '5.1875rem',
        left: '40%',
        top: '35%',
        margin: 0,
        position: 'absolute',
        opacity: 0.5,
        zIndex: 999,
        '&:hover': {
          opacity: 1,
          cursor: 'pointer'
        }
      }
    })
  );

  interface AnomalyDetailData {
    anomalyComponent: Array<{
      component: {
        fullComponent: string;
        category: string;
        field: string;
        bestAccess: string;
        easting: number;
        northing: number;
        depthFrom: number;
        depthTo: number;
        startKP: number;
        endKP: number;
      };
    }>;
    anomalyCode: {
      code: string;
      description: string;
    };
    media: Array<{
      id: string;
      filename: string;
      thumbnail: string;
    }>;
    document: Array<{
      id: string;
      filename: string;
      url: string;
    }>;
    startTime: Date;
    endTime: Date;
    criticality: string;
    status: string;
    reviewedBy: string;
    summary: string;
  }

  const [selectedMedia, setSelectedMedia] = useState<string>();
  const classes = useStyles();

  const { loading, error, data } = useQuery<{ anomaly: AnomalyDetailData }, { id: string }>(
    gql`
      query anomalyDetail($id: ID!) {
        anomaly(id: $id) {
          anomalyComponent {
            component {
              fullComponent
              category
              field
              bestAccess
              easting
              northing
              depthFrom
              depthTo
              startKP
              endKP
            }
          }
          anomalyCode {
            code
            description
          }
          media {
            id
            filename
            thumbnail
          }
          document {
            id
            filename
            url
          }
          startTime
          endTime
          criticality
          status
          reviewedBy
          summary
        }
      }
    `,
    { variables: { id: props.anomalyId } }
  );

  if (loading) {
    return (
      <div className={classes.spinnerContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <ExpandingPanelErrorText message={error.message} />;
  }

  if (data === undefined) {
    return <ExpandingPanelErrorText message={'No data available for this task!'} />;
  }

  function anomalyMediaThumbs() {
    const imageData = data?.anomaly.media;
    let averageDimension = { width: 0, height: 0 };

    if (imageData) {
      const dimensions = ExtractDimensionsFromBase64JpegArray(imageData);
      if (dimensions.length !== 0) {
        averageDimension = determineAverageDimension(dimensions);
      } else {
        console.log('Failed determining average thumbnail dimensions.');
      }
    }

    const items = data?.anomaly.media?.map((am: any) => {
      const isVideo = am.filename.endsWith('.mp4');
      let dimension, isIregular;

      if (!isVideo) {
        dimension = extraDimensionsFromBase64Jpeg(am.thumbnail);
        isIregular = dimension.height > averageDimension.height;
      }

      return (
        <div key={am.id} style={{ width: '100%', textAlign: 'center' }}>
          <div
            key={`${am.id}-inner`}
            style={{
              position: 'relative',
              width: '100%',
              height: '100%'
            }}
          >
            {isVideo && <PlayCircleOutline onClick={() => setSelectedMedia(am.id)} className={classes.carouselPlayButton} />}
            <MediaThumb thumbnail={am.thumbnail} mediaId={am.id} onSelect={setSelectedMedia} ignoreWidth={isIregular ?? false} />
          </div>
        </div>
      );
    });
    return items;
  }

  const handleCloseViewer = () => setSelectedMedia(undefined);

  const renderTableRow = (title: string, content: any, largeFont?: boolean) => (
    <TableRow>
      <TableCell className={classes.tableRowTitle} style={{ fontSize: largeFont ? 'larger' : '' }}>
        <b>{title}</b>
      </TableCell>
      <TableCell>{content}</TableCell>
    </TableRow>
  );

  const documentTableRows = data.anomaly.document?.map((am) => (
    <TableRow key={am.id}>
      <TableCell>
        <Link component="a" variant="body2" target="_new" href={am.url}>
          {am.filename}
        </Link>
      </TableCell>
    </TableRow>
  ));

  return (
    <React.Fragment>
      <Box className={classes.topBox}>
        {data.anomaly.anomalyComponent.forEach((ac: any) => {
          return <Typography variant="body1">{ac.component.fullComponent}</Typography>;
        })}
        <Typography variant="h5" color="error">
          {data.anomaly.anomalyCode.code}: {data.anomaly.anomalyCode.description}
        </Typography>
      </Box>

      <Box>
        {data.anomaly.media?.length > 0 ? (
          <Carousel autoPlay={false} key={props.anomalyId} animation="slide">
            {anomalyMediaThumbs()}
          </Carousel>
        ) : null}
      </Box>

      <ExpandingPanel title="Anomaly Details" owner="AnomalyDetail" edgeToEdge={true}>
        <Table size="small">
          <TableBody>
            {renderTableRow('Start Time', format(new Date(data.anomaly.startTime), 'd/MM/yy HH:mm:ss'))}
            {renderTableRow('End Time', format(new Date(data.anomaly.endTime), 'd/MM/yy HH:mm:ss'))}
            {renderTableRow('Criticality', data.anomaly.criticality)}
            {renderTableRow('Status', data.anomaly.status)}
            {renderTableRow('Reviewed By', data.anomaly.reviewedBy)}
          </TableBody>
        </Table>
      </ExpandingPanel>

      {data.anomaly.summary && data.anomaly.summary.length > 0 ? (
        <ExpandingPanel owner="AnomalyDetail" title="Comments">
          <div>{data.anomaly.summary}</div>
        </ExpandingPanel>
      ) : null}

      <ExpandingPanel title="Component Information" edgeToEdge={true} owner="AnomalyDetail">
        <div style={{ marginTop: '10px' }}>
          {data.anomaly.anomalyComponent.map((ac: any) => {
            return (
              <div key={ac.component.id}>
                <Table key={ac.component.id} size="small">
                  <TableBody>
                    {renderTableRow(ac.component.fullComponent, '', true)}
                    {renderTableRow('Category', ac.component.category)}
                    {renderTableRow('Field', ac.component.field)}
                    {renderTableRow('Best Access', ac.component.bestAccess)}
                    {renderTableRow('Easting', ac.component.easting)}
                    {renderTableRow('Northing', ac.component.northing)}
                    {renderTableRow('Depth', `${ac.component.depthFrom} to ${ac.component.depthTo}`)}
                    {renderTableRow('KP', `${ac.component.startKP} to ${ac.component.endKP}`)}
                  </TableBody>
                </Table>
                <br />
              </div>
            );
          })}
        </div>
      </ExpandingPanel>

      {data.anomaly.document && data.anomaly.document.length > 0 ? (
        <ExpandingPanel owner="AnomalyDetail" title="Documents" edgeToEdge={true}>
          <Table size="small">
            <TableBody>{documentTableRows}</TableBody>
          </Table>
        </ExpandingPanel>
      ) : null}

      {selectedMedia ? (
        <MediaViewerDialog
          itemType="ANOMALY_MEDIA"
          id={selectedMedia}
          allMediaItems={data.anomaly.media.map((m) => m.id)}
          onClose={handleCloseViewer}
        />
      ) : null}
    </React.Fragment>
  );
};
