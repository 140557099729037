import { useQuery } from '@apollo/react-hooks';
import { Tooltip } from '@material-ui/core';
import { gql } from 'apollo-boost';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { MapTooltipProps } from '../../../../types/params.type';
import ErrorMessage from '../../../ErrorMessage';
import MaterialTableExtended from '../../../MaterialTableExtended';
import { SkeletonTable } from '../../../skeletons/SkeletonTable';
import { useDatalessStyles } from '../../../styles/noDataStyles';
import { DownloadMediaItem, DownloadPackageDialog } from '../../../DownloadPackageDialog';
interface AnomaliesTabProps {
  workpackId: string;
  onSelectItem?: (id?: string) => void;
  filter: string;
  resetFilter: () => void;
}

export const AnomaliesList = (props: AnomaliesTabProps) => {
  interface AnomalyListData {
    workpack: Array<{
      name: string;
      anomaly: AnomalyListItem[];
    }>;
  }

  interface AnomalyListItem {
    id: string;
    startTime: Date;
    endTime: Date;
    summary: string;
    reviewedBy?: string;
    anomalyCode: { code: string };
    anomalyComponent: Array<{ component: { smartComponent: string } }>;
    // component: { smartComponent: string };
    criticality: string;
    clientID: string;
  }

  const datalessStyles = useDatalessStyles();

  const [dataSource, setDataSource] = useState<any[]>();
  const [focusedRow, setFocusedRow] = useState<AnomalyListItem>();
  const [isMapFiltering, setIsMapFiltering] = useState<boolean>(false);
  const [requestedDownloadItems, setRequestedDownloadItems] = useState<DownloadMediaItem[]>();

  const handleRowClick = (_event?: React.MouseEvent, rowData?: AnomalyListItem) => {
    // Removing focus of a row
    if (props.onSelectItem && focusedRow === rowData) {
      setFocusedRow(undefined);
      props.onSelectItem(undefined);
      return;
    }

    // Setting focus on a row
    if (props.onSelectItem && rowData) {
      props.onSelectItem(rowData.id);
    }

    setFocusedRow(rowData);
  };

  const renderStartTime = (a: AnomalyListItem) => format(new Date(a.startTime), 'd/MM/yy\u00a0HH:mm');

  const renderComponents = (a: any) =>
    a._components.map((c: any) => {
      return <div key={c}>{c}</div>;
    });

  const { loading, error, data } = useQuery<AnomalyListData, { id: string }>(
    gql`
      query anomalyList($id: ID) {
        workpack(id: $id) {
          name
          anomaly {
            clientID
            id
            startTime
            endTime
            summary
            reviewedBy
            anomalyComponent {
              component {
                smartComponent
              }
            }
            anomalyCode {
              code
              description
            }
            criticality
          }
        }
      }
    `,
    { variables: { id: props.workpackId } }
  );

  function resetMapFilter() {
    props.resetFilter();
    setIsMapFiltering(false);
  }

  function onFocusedAnomalyChange() {
    let ds = data?.workpack[0]?.anomaly;
    let mapData: Partial<MapTooltipProps> = {};

    if (props.filter) {
      mapData = JSON.parse(props.filter) as MapTooltipProps;
    }

    if (ds && mapData.Filters && mapData.Filters?.length !== 0) {
      if (mapData.IsComponent && mapData?.Filters !== undefined) {
        // Update filter to only include componentcode items
        setIsMapFiltering(true);
        const component: string = mapData?.Filters as string;
        ds = ds.filter((inc) => inc.anomalyComponent[0].component.smartComponent.startsWith(component));
      } else {
        const items = ds.filter((a) => mapData?.Filters?.includes(a.id));
        setIsMapFiltering(true);
        ds = items;
      }
    }

    // Create custom field for components column, so we can display multiple components in one cell.
    ds?.forEach((anomaly: any) => {
      anomaly._components = anomaly.anomalyComponent.map((ac: any) => ac.component.smartComponent);
    });

    setDataSource(ds);
  }

  useEffect(onFocusedAnomalyChange, [props.filter, data?.workpack[0]?.anomaly]);

  if (loading) {
    // return (<Skeleton variant='rect' animation='wave' className={skeletonStyles.table} />);
    return <SkeletonTable />;
  }

  if (error) {
    return (
      <div className={datalessStyles.error}>
        <ErrorMessage message={error.message} />
      </div>
    );
  }

  const isAnyUnreviewed = () => data?.workpack[0]?.anomaly?.some((a) => a.reviewedBy === undefined || a.reviewedBy.length === 0);

  return (
    <React.Fragment>
      {requestedDownloadItems ? <DownloadPackageDialog items={requestedDownloadItems} onClose={() => setRequestedDownloadItems(undefined)} /> : null}
      <MaterialTableExtended
        isLoading={loading}
        title="Site Anomalies"
        owner={'Anomalies'}
        hasMapFilter={isMapFiltering}
        resetMapFilter={resetMapFilter}
        workpackName={data?.workpack[0]?.name ?? ''}
        exportWatermark={isAnyUnreviewed()}
        isDownloadable={true}
        onDownloadRequested={(data: AnomalyListItem[]) => setRequestedDownloadItems(data.map((d) => ({ id: d.id, itemType: 'ANOMALY_MEDIA' })))}
        columns={[
          {
            title: 'ID',
            field: 'clientID',
            columnWidth: '8em'
          },
          {
            title: 'Start Time',
            type: 'datetime',
            defaultSort: 'asc',
            field: 'startTime',
            columnWidth: '10em',
            render: renderStartTime,
            filter: 'UniversalDate'
          },
          {
            title: 'Summary',
            field: 'summary',
            isCollapsible: true,
            columnWidth: '20em'
          },
          {
            title: 'Reviewed By',
            field: 'reviewedBy',
            columnWidth: '12em',
            filter: 'LookupAuto',
            hidden: true
          },
          {
            title: 'Anomaly Code',
            field: 'anomalyCode.code',
            columnWidth: '12em',
            filter: 'LookupAuto',
            render: (data: any) => {
              return (
                <Tooltip arrow placement="right" title={<h3>{data.anomalyCode.description}</h3>}>
                  <span>{data.anomalyCode.code}</span>
                </Tooltip>
              );
            }
          },
          {
            title: 'Component(s)',
            field: '_components',
            columnWidth: '10em',
            isCollapsible: true,
            isRtlCollapsible: true,
            render: renderComponents
          },
          {
            title: 'Criticality',
            field: 'criticality',
            columnWidth: '10em',
            filter: 'LookupAuto'
          }
        ]}
        data={dataSource ?? []}
        onRowClick={handleRowClick}
        options={{
          rowStyle: (rowData) => ({
            backgroundColor: rowData?.id === focusedRow?.id ? 'rgb(237,237,237)' : '#fff'
          })
        }}
      />
    </React.Fragment>
  );
};
