import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { anomalyLegend, ColoringMethod, LegendItem, taskLegend } from './ModelColourSchema/Constants';
import clsx from 'clsx';
import { HeatmapData } from '../Interfaces';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { SwapHoriz } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  buttonPanel: {
    marginTop: '5px',
    height: '128px',
    width: '32px',
    position: 'absolute',
    alignSelf: 'flex-start',
    marginLeft: '5px',
    marginBottom: '5px',
    zIndex: 2,

    display: 'block',
    left: 0
  },
  buttonContainer: {
    float: 'left',
    width: '32px',
    height: '32px',
    cursor: 'pointer',
    unselectable: 'on',
    userSelect: 'none',
    msUserSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none'
  },
  textButton: {
    textAlign: 'center'
  },
  legend: {
    marginTop: '5px',
    marginRight: '5px',
    // height: '150px',
    zIndex: 2,
    position: 'absolute',
    display: 'block',
    right: 0,
    width: '200px',
    backgroundColor: '#2d3035',
    paddingBottom: '2px',
    borderRadius: '6px',
    opacity: '0.9'
  },
  heatmapLegend: {
    marginTop: '5px',
    marginRight: '5px',
    // height: '150px',
    zIndex: 2,
    position: 'absolute',
    display: 'block',
    right: 0,
    width: '100px',
    backgroundColor: '#2d3035',
    paddingBottom: '2px',
    borderRadius: '6px',
    opacity: '0.9'
  },
  legendContent: {
    width: '100%',
    height: '100%'
  },
  legendItem: {
    height: '26px',
    width: '100%',
    marginLeft: '5px',
    marginRight: '5px',
    marginTop: '5px'
    // border: '1px dashed magenta',
  },
  legendColorCube: {
    height: '21px',
    width: '21px',
    margin: '2px',
    marginRight: '5px',
    border: '1px solid white',
    borderRadius: '9px',
    float: 'left'
  },
  legendText: {
    float: 'left',
    height: '100%',
    textAlign: 'center',
    lineHeight: '26px',
    color: '#FFFFFF'
  }
}));

interface AssetModelCameraControllerProps {
  orbitController: React.MutableRefObject<OrbitControls | undefined>;
  toggleTransparency: () => void;
  setColoringMethod: (e: ColoringMethod) => void;
  heatmapDataUrls: string[];
  allHeatmapData: HeatmapData[];
  setSelectedHeatmapData: (e: number) => void;
}

export default function AssetModelCameraController(props: AssetModelCameraControllerProps): JSX.Element {
  const styles = useStyles();
  const orbitController = props.orbitController.current;
  const [selectedHeatmapData, setSelectedHeatmapData] = useState<number>(0);
  const heatmapData = props.allHeatmapData ? props.allHeatmapData[selectedHeatmapData] : null;
  const [transparencyEnabled, setTransparencyEnabled] = useState(false);
  const [colorMethod, setColorMethod] = useState<ColoringMethod>(ColoringMethod.Normal);

  function manipulateCamera(zoomIn: boolean) {
    if (orbitController) {
      const cameraRef = orbitController.object;

      if (cameraRef) {
        const offset = zoomIn === false ? 10 : -10;
        const newZoomOffset = cameraRef.position.length() + offset;

        const distance = newZoomOffset;
        const currDistance = cameraRef.position.length();
        const factor = distance / currDistance;

        cameraRef.position.x *= factor;
        cameraRef.position.y *= factor;
        cameraRef.position.z *= factor;

        orbitController.update();
      }
    }
  }

  function resetCamera() {
    props.orbitController.current?.reset();
  }

  const toggleTransparency = () => {
    setTransparencyEnabled(!transparencyEnabled);
    props.toggleTransparency();
  };

  const rotateColoringMethod = () => {
    let nextColoringMethod = ColoringMethod.Normal;

    switch (colorMethod) {
      case ColoringMethod.Normal:
        nextColoringMethod = ColoringMethod.Anomalies;
        break;
      case ColoringMethod.Anomalies:
        // If the data exists a URL will be provided, even if the data has not yet been fully downloaded.
        // If the download is pending (or fails), the model will be uncoloured and refresh once the fetch is complete.
        nextColoringMethod = props.heatmapDataUrls && props.heatmapDataUrls.length > 0 ? ColoringMethod.Heatmap : ColoringMethod.Normal;
        break;
      case ColoringMethod.Heatmap:
        nextColoringMethod = ColoringMethod.Normal;
        break;

      // Temporarily disabled: NXINS-781
      // case ColoringMethod.TaskProgress:
      //   nextColoringMethod = ColoringMethod.Normal;
      //   break;
    }

    props?.setColoringMethod(nextColoringMethod);
    setColorMethod(nextColoringMethod);
  };

  const ButtonZoomOut = () => {
    return (
      <div className={styles.buttonContainer} title="Zoom Out" onClick={() => manipulateCamera(false)}>
        <RemoveIcon style={{ fontSize: 32 }} />
      </div>
    );
  };

  const ButtonZoomIn = () => {
    return (
      <div className={styles.buttonContainer} title="Zoom In" onClick={() => manipulateCamera(true)}>
        <AddIcon style={{ fontSize: 32 }} />
      </div>
    );
  };

  const ButtonResetCamera = () => {
    return (
      <div className={styles.buttonContainer} title="Reset Camera" onClick={() => resetCamera()}>
        <FlipCameraAndroidIcon style={{ fontSize: 32 }} />
      </div>
    );
  };

  const ButtonTransparency = () => {
    return (
      <div className={styles.buttonContainer} style={{ marginTop: '5px' }} title="Toggle Transparency" onClick={() => toggleTransparency()}>
        {!transparencyEnabled ? <VisibilityIcon style={{ fontSize: 32 }} /> : <VisibilityOffIcon style={{ fontSize: 32 }} />}
      </div>
    );
  };

  const ButtonColoringMethod = () => {
    let letterIcon = '';
    let tooltip = '';

    switch (colorMethod) {
      case ColoringMethod.Normal:
        letterIcon = 'N';
        tooltip = 'Normal colours. Displays the original colors of the model.';
        break;
      case ColoringMethod.Anomalies:
        letterIcon = 'A';
        tooltip = 'Anomaly colours. Highlights components according to anomaly status.';
        break;
      case ColoringMethod.Heatmap:
        letterIcon = 'FCP';
        tooltip = `Heatmap data generated by Sense.CP, ${heatmapData ? new Date(heatmapData?.FirstDate).toDateString() : 'data not found'}.`;
        break;
      case ColoringMethod.TaskProgress:
        letterIcon = 'T';
        tooltip = 'Task colours. Highlights components according to task status.';
        break;
    }

    return (
      <div
        className={clsx(styles.buttonContainer, styles.textButton)}
        style={{ marginTop: '5px' }}
        title={tooltip}
        onClick={() => rotateColoringMethod()}
      >
        <p style={{ fontSize: '24px', margin: '0px' }}> {letterIcon} </p>
      </div>
    );
  };

  const ButtonChangeHeatmapData = () => {
    return (
      <div
        className={styles.buttonContainer}
        title="Change Heatmap Data"
        onClick={() => {
          const nextDataSet = selectedHeatmapData + 1 >= props.allHeatmapData.length ? 0 : selectedHeatmapData + 1;
          setSelectedHeatmapData(nextDataSet);
          props.setSelectedHeatmapData(nextDataSet);
        }}
      >
        <SwapHoriz style={{ fontSize: 32 }} />
      </div>
    );
  };

  const RGBStringToHex = (str: string) => {
    const rgb = str.split(', ').map((c) => parseInt(c));
    return '#' + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
  };

  const CreateHeatmapLegendItems = (heatmapData: HeatmapData) => {
    return heatmapData.Legend.Colors.map(
      (colour: string, i: number) => ({ name: heatmapData.Legend.TickValues[i].toString(), color: RGBStringToHex(colour) } as LegendItem)
    );
  };

  const ColorModeLegend = () => {
    switch (colorMethod) {
      case ColoringMethod.Anomalies:
        return (
          <div className={styles.legend}>
            {anomalyLegend.map((rowItem: LegendItem, index: number) => {
              return (
                <div className={styles.legendItem} key={index}>
                  <div className={styles.legendColorCube} style={{ backgroundColor: rowItem.color }} />
                  <div className={styles.legendText}>{rowItem.name}</div>
                </div>
              );
            })}
          </div>
        );

      case ColoringMethod.TaskProgress:
        return (
          <div className={styles.legend}>
            {taskLegend.map((rowItem: LegendItem, index: number) => {
              return (
                <div className={styles.legendItem} key={index}>
                  <div className={styles.legendColorCube} style={{ backgroundColor: rowItem.color }} />
                  <div className={styles.legendText}>{rowItem.name}</div>
                </div>
              );
            })}
          </div>
        );
      case ColoringMethod.Heatmap:
        if (!heatmapData)
          return (
            <div className={styles.legend}>
              <div className={styles.legendText}>Heatmap data not found.</div>
            </div>
          );

        return (
          <div className={styles.heatmapLegend}>
            {CreateHeatmapLegendItems(heatmapData).map((rowItem: LegendItem, index: number) => {
              return (
                <div className={styles.legendItem} key={index}>
                  <div className={styles.legendColorCube} style={{ backgroundColor: rowItem.color }} />
                  <div className={styles.legendText}>{rowItem.name}</div>
                </div>
              );
            })}
          </div>
        );

      default:
        return <React.Fragment />;
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.buttonPanel}>
        {ButtonZoomIn()}
        {ButtonZoomOut()}
        {ButtonResetCamera()}
        {ButtonTransparency()}
        {ButtonColoringMethod()}
        {colorMethod == ColoringMethod.Heatmap && props.heatmapDataUrls.length > 1 ? ButtonChangeHeatmapData() : <div />}
      </div>
      {ColorModeLegend()}
    </div>
  );
}
