import Amplify from 'aws-amplify';
import { ConfirmSignIn, FederatedSignIn, ForgotPassword, Loading, OAuthButton, RequireNewPassword, SignIn, VerifyContact } from 'aws-amplify-react';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Routes from './routes/Routes';
import { authenticatorTheme } from './styles/FugroColors';
import '@aws-amplify/ui/dist/style.css';
import { withForkedAuthenticator } from './components/forked-amplify-auth';

if (process.env.REACT_APP_AUTH_REQUIRED) {
  const interceptIdentityProviderUrlOpener = (url: string) => {
    // intercept the sso button redirecting to ugly cognito provider and redirect to Fugro
    // note this assumes the identity provider in aws cognito is named Fugro
    url = url.replace('identity_provider=COGNITO', 'identity_provider=Fugro');
    const windowProxy = window.open(url, '_self');
    if (windowProxy) {
      return Promise.resolve(windowProxy);
    } else {
      return Promise.reject();
    }
  };

  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'ap-southeast-2',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_AUTH_COGNITO_USERPOOLID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_AUTH_COGNITO_USERPOOLWEBCLIENTID,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH',

      identityPoolId: process.env.REACT_APP_AUTH_COGNITO_IDENTITYPOOLID,

      oauth: process.env.REACT_APP_AUTH_COGNITO_USERPOOLDOMAIN
        ? {
            domain: process.env.REACT_APP_AUTH_COGNITO_USERPOOLDOMAIN,
            label: 'Login with your Fugro corporate account',
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: window.location.protocol + '//' + window.location.host,
            redirectSignOut: window.location.protocol + '//' + window.location.host,
            responseType: 'code',
            urlOpener: interceptIdentityProviderUrlOpener
          }
        : undefined
    }
  });
}

export default function SeedApp() {
  return <Routes />;
}

export const App = process.env.REACT_APP_AUTH_REQUIRED
  ? withForkedAuthenticator(
      withRouter(() => <SeedApp />),
      false,
      [
        <SignIn key="signin" />,
        <RequireNewPassword key="requiredNewPassword" />,
        <ConfirmSignIn key="confirmSignIn" />,
        <VerifyContact key="verifyContact" />,
        <ForgotPassword key="forgotPassword" />,
        <Loading key="loading" />
      ],
      null,
      authenticatorTheme
    )
  : withRouter(() => <SeedApp />);
