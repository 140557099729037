import ColorSchema from './colorSchema';

/**
 * Rule classes must be manually created and passed to the
 * ColorSchema class, a transformation and condition function must
 * be provided as well as a unique name. If not, it will always return true.
 */
export default class Rule {
  /**
   * A unique identifier for this rule so it can be deleted from the ColorSchema.
   */
  public Name: string = 'Undefined Rule';
  /**
   *  The color to be applied during transformation. ( Can be modified. )
   */
  public Color: number | string = 0x7c7c7c;
  /**
   * The function that is called when the rule's condition returns true.
   */
  public ApplyTransformation: (mesh: any) => void = (mesh: any) => {
    console.log('Rule not defined.');
  };
  /**
   * A set of user-defined rules which must match before any transformations are applied.
   */
  public Condition: () => boolean = () => {
    return true;
  };
  /**
   * A reference to the parent ColorSchema class,
   * this is always set when we call ColorSchema.Add()
   */
  public Parent: ColorSchema | null = null;

  constructor(name: string) {
    this.Name = name;
  }
}
