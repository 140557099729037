import TextMvtLayer from '../text-mvt-layer/text-mvt-layer';
import { ICON_MAPPING_SIMPLE, INVALID_LNGLAT, PBF_LAYER_PROPS } from '../extras/Constants';
import { Tile3DLayer } from 'deck.gl';
import { I3SLoader } from '@loaders.gl/i3s';
import { StructureItem } from '../../../overview/Overview';
// @ts-ignore
import StructurePinTextLayer from '../../../overview/structure-pin-text-layer';
import { LayerUrl, LngLat } from '../../MapUtils/SharedTypes';
import { AreExtentsEqual, calculateMinMaxLngLat } from '../extras/Functions';
import { MVTLayer, TileLayer } from '@deck.gl/geo-layers';
import { BitmapLayer } from '@deck.gl/layers';
import { load } from '@loaders.gl/core';

export interface DeckLayerServiceProperties {
  fieldLayoutLayers?: string[];
  featureTileLayers?: string[];
  structures?: StructureItem[];
  oarsLayers?: string[];
  scene3dLayers?: any[];
  token?: string;
  bounds?: LngLat[];
  selectedStructureHandler?: any;
  visibleLayerUrls?: string[];
  esriWmsLayerUrls?: string[];
}

export default class DeckLayerService {
  public structureOnClickCallbackRef = () => undefined;
  public extent: LngLat[] | undefined = undefined;

  private fieldLayers: string[] | undefined = [];
  private featureTileLayers: string[] | undefined = [];
  private structureLayers: StructureItem[] | undefined = [];
  private oarsLayers: string[] | undefined = [];
  private scene3dLayers: string[] | undefined = [];
  private esriWmsLayerUrls: string[] | undefined = [];
  private token: string | undefined;
  private bounds: LngLat[] | undefined;
  private refreshKey?: number;
  private visibleLayerUrls: string[] | undefined = [];

  constructor(properties: DeckLayerServiceProperties) {
    this.fieldLayers = properties.fieldLayoutLayers;
    this.featureTileLayers = properties.featureTileLayers;
    this.scene3dLayers = properties.scene3dLayers;
    this.oarsLayers = properties.oarsLayers;
    this.esriWmsLayerUrls = properties.esriWmsLayerUrls;
    this.token = properties.token;
    this.structureLayers = properties.structures;
    this.bounds = properties.bounds;
    this.visibleLayerUrls = properties.visibleLayerUrls;
    this.structureOnClickCallbackRef = properties.selectedStructureHandler;
    this.refreshKey = Date.now();
  }

  public rotateRefreshKey() {
    this.refreshKey = Date.now();
  }

  public generate(): any {
    const fieldLayoutLayers = this.buildFieldLayoutLayers();
    const featureTileLayers = this.buildFeatureTileLayers();
    const scene3dLayers = this.build3dLayers();
    const structuresLayers = this.buildPinLayerAndDetermineExtent();
    const oarsLayers = this.buildOarsLayers();

    return [...(featureTileLayers ?? []), ...(fieldLayoutLayers ?? []), ...(scene3dLayers ?? []), structuresLayers ?? [], ...(oarsLayers ?? [])];
  }

  public setVisibleLayerUrls(layerUrls: string[]) {
    this.visibleLayerUrls = layerUrls;
  }

  private buildFieldLayoutLayers() {
    if (this.fieldLayers === undefined) return;

    const results: any = [];
    this.fieldLayers
      .filter((url) => url !== '')
      .forEach((dataUrl, index) => {
        const newLayer = new TextMvtLayer({
          id: `drawing-layer-${index}`,
          data: dataUrl,
          ...PBF_LAYER_PROPS
        });

        results.push(newLayer);
      });
    return results;
  }

  private buildFeatureTileLayers() {
    if (this.featureTileLayers === undefined) {
      return;
    }

    const results: any = [];
    this.featureTileLayers
      .filter((lu) => lu !== '')
      .forEach((dataUrl, index) => {
        const newLayer = new MVTLayer({
          id: `feature-layer-${index}`,
          data: dataUrl,
          visible: this.visibleLayerUrls?.includes(dataUrl),
          ...PBF_LAYER_PROPS
        });

        results.push(newLayer);
      });

    results.reverse();

    if (this.esriWmsLayerUrls !== undefined) {
      this.esriWmsLayerUrls
        .filter((lu) => lu !== '')
        .forEach((dataUrl, index) => {
          const newLayer = new TileLayer({
            id: `esriwms-layer-${index}`,
            // minZoom: 0,
            // maxZoom: 24,
            // tileSize: 512 / devicePixelRatio,
            getTileData: (props: any) => {
              const { east, north, south, west } = props.bbox;
              const urlQueryStringParams: any = {
                bbox: [west, south, east, north].join(','),
                format: 'image/png',
                height: 512,
                layers: ['0'].join(','),
                request: 'GetMap',
                service: 'WMS',
                srs: 'EPSG:4326',
                styles: '',
                transparent: 'true',
                version: '1.1.0',
                width: 512
              };
              const urlQueryString = Object.keys(urlQueryStringParams)
                .map((key) => key + '=' + urlQueryStringParams[key])
                .join('&');
              return load(dataUrl + '?' + urlQueryString);
            },
            renderSubLayers: (props: any) => {
              if (props.data) {
                const {
                  bbox: { west, south, east, north }
                } = props.tile;
                return [
                  new BitmapLayer(props, {
                    data: null,
                    image: props.data,
                    bounds: [west, south, east, north],
                    transparentColor: [255, 255, 255, 0]
                  })
                ];
              } else {
                return [];
              }
            }
          });

          results.push(newLayer);
        });
    }

    return results;
  }

  private buildOarsLayers() {
    if (this.oarsLayers === undefined) {
      return;
    }

    const results: any = [];

    this.oarsLayers
      .filter((url) => url !== '')
      .forEach((dataUrl, index) => {
        const oarsLayer = new TileLayer({
          id: `oars-layer-${index}`,
          data: dataUrl + '?_=' + this.refreshKey,
          updateTriggers: {
            getTileData: this.refreshKey
          },
          renderSubLayers: (props) => {
            const {
              bbox: { west, south, east, north }
            } = props.tile;

            return [
              new BitmapLayer(props, {
                data: null,
                image: props.data,
                bounds: [west, south, east, north]
              })
            ];
          }
        });

        results.push(oarsLayer);
      });

    return results;
  }

  private build3dLayers() {
    if (this.scene3dLayers === undefined || this.token === undefined) return [];

    const loadOptions: any = {
      i3s: { token: this.token },
      token: this.token
    };

    const esri3dLayers: any[] = [];

    this.scene3dLayers
      .filter((url: string) => url !== '')
      .forEach((url: string, idx: number) => {
        const newLayer = new Tile3DLayer({
          id: `3d-layer-${idx}`,
          data: `${url}?token=${this.token}`,
          loader: I3SLoader,
          pickable: false,
          loadOptions,
          visible: true
        });
        esri3dLayers.push(newLayer);
      });

    return esri3dLayers;
  }

  private buildPinLayerAndDetermineExtent() {
    const layer = new StructurePinTextLayer({
      id: 'structures',
      data: this.structureLayers,
      pickable: true,
      getIcon: () => 'marker',
      getIconSize: () => 45,
      getPosition: (d: StructureItem) => [d.lnglat.longitude, d.lnglat.latitude],
      getIconColor: [128, 128, 128],
      getNameText: (d: StructureItem) => d.componentCode,
      getMAText: (d: StructureItem) => this.buildMapAnomPinText(d),
      iconAtlas: '/map/icon-atlas-update.png',
      iconMapping: ICON_MAPPING_SIMPLE,
      onSelectStructure: this.structureOnClickCallbackRef,
      visible: true
    });

    this.determineExtent(layer);
    return layer;
  }

  private buildMapAnomPinText(d: StructureItem) {
    if (d.masterAnomalyCounts === undefined) {
      return undefined;
    }
    const sum = d.masterAnomalyCounts.reduce((s, c) => s + c.count, 0);
    if (sum === 0) {
      return undefined;
    }
    return `${sum} Master Anomalies`;
  }

  private determineExtent(pinLayer: StructurePinTextLayer) {
    if (this.bounds === undefined) return;

    const coordinates: any[] =
      pinLayer.props.data
        .filter((p: any) => {
          return p?.lnglat != null;
        }) // remove all pins with bad coordinates
        .map((p: any) => {
          return p.lnglat;
        }) ?? []; // return the good ones, if none then emtpy array.

    //  Get Min/Max for tile generator extent
    if (this.bounds && this.bounds.length === 2 && !AreExtentsEqual(INVALID_LNGLAT, this.bounds)) {
      const dMin = { latitude: this.bounds[0]?.latitude, longitude: this.bounds[0]?.longitude };
      const dMax = { latitude: this.bounds[1]?.latitude, longitude: this.bounds[1]?.longitude };
      coordinates.push(dMin, dMax);
    }

    const minMax = calculateMinMaxLngLat(coordinates);

    this.extent = [
      { longitude: minMax.minLng, latitude: minMax.minLat },
      { longitude: minMax.maxLng, latitude: minMax.maxLat }
    ];
  }
}
