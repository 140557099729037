import { makeStyles } from '@material-ui/styles';

export const useDatalessStyles = makeStyles(() => ({
  error: {
    margin: 0,
    textAlign: 'center',
    width: '100%',
    padding: '15%'
  },
  loading: {
    margin: 0,
    textAlign: 'center',
    width: '100%',
    padding: '25%'
  }
}));
