// import { LatLng } from 'react-leaflet';
import { Dispatch, SetStateAction } from 'react';
import { DeckLayerServiceProperties } from '../DeckGL/NewMap/DeckLayerService';
import { EsriLayerServiceProperties } from '../DeckGL/NewMap/EsriLayerService';
import { LatLng } from '../MappingUtils';

export interface LayerUrl {
  [originalUrl: string]: any;
  readableUrl: string;
  transformationId: number;
  isChecked: boolean;
  isEsri: boolean;
}

export type LayerState = 'updating' | 'firstload';

export interface onLayerLoadedProps {
  layerid: string;
  event: any;
}

export interface EsriMapProps {
  token: string;
  deckGlLayers: any;
  esriServiceUrlLayers: any[];
  camera?: CameraProperties;
  extent: any;
  onLoadingChanged?: (state: boolean) => {};
  onLayerLoaded?: (eventArgs: onLayerLoadedProps) => {};
}

export interface CameraProperties {
  position?: {
    longitude?: number;
    latitude?: number;
    z?: number;
  };
  tilt?: number;
  heading?: number;
  fov?: number;
}

export interface MvtProperties {
  minZoom: number;
  maxZoom: number | null;
  opacity: number;
  filled: boolean;
  getLineColor: number[];
  getFillColor: number[];
  getLineWidth: number;
  lineWidthUnits: string;
  lineWidthMinPixels: number;
  pickable: boolean;
  stroked: boolean;
  onHover?: (info: any, event: any) => void;

  // Custom properties
  darkMode?: boolean | undefined;
  showText?: boolean | undefined;
}

export interface CustomKml {
  id: string;
  Kml: File;
}

export interface File {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

export interface Position {
  Longitude: number;
  Latitude: number;
}

export interface MapProperties {
  deckProperties: DeckLayerServiceProperties;
  esriProperties: EsriLayerServiceProperties;
  token: string;
  secondaryMapProperties?: SecondaryMapProperties;
}

export interface LegacyMapProperties {
  Markers: {
    Anomalies: boolean;
    Events: boolean;
    Tasks: boolean;
    Text: boolean;
  };
  Layout: {
    DarkMode: boolean;
  };
  CustomLayers: Map<string, boolean>;
  Handlers: {
    SetMarkers: any;
    IsMeasuring: boolean;
  };
  Properties: {
    searchId: string;
    WorkpackId: string;
    State: Dispatch<SetStateAction<MapLoader>>;
  };
}

export interface SecondaryMapProperties {
  Markers: {
    Anomalies: boolean;
    Events: boolean;
    Tasks: boolean;
    Text: boolean;
  };
  CustomLayers: Map<string, boolean>;
  Handlers: {
    SetMarkers: any;
    IsMeasuring: boolean;
  };
  Queries: {
    searchId: string;
    WorkpackId: string;
    State: Dispatch<SetStateAction<boolean>>;
  };
}

export interface MarkerInfo {
  component: Component;
  // marker: L.Marker;
  data: Anomaly | Task;
}

export interface Anomaly {
  id: string;
  anomalyCode: AnomalyCode;
  component: Component;
  navigation: NavigationData;
  summary: string;
  criticality: string;
  status: string;
  startTime: Date;
  endTime: Date;
  closedBy: string;
}

export interface IncidentNavigationData {
  start: NavigationData;
  end: NavigationData;
}

export interface NavigationData {
  lnglat: LngLat;
  easting: number;
  northing: number;
  altitude: number;
}

export interface LngLat {
  longitude: number;
  latitude: number;
}

export interface AnomalyCode {
  code: string;
  description: string;
}

export interface Component {
  id: string;
  parent_ID: string;
  fullComponent: string;
  componentCode: string;
}

export interface ComponentData extends Component {
  task: Task[];
  anomaly: Anomaly[];
  incident: Incident[];
}

export interface Task {
  id: string;
  eventCode: EventCode;
  navigation: NavigationData;
  component: Component;
  progress: TaskProgress;
}

export interface AnomalyComponent {
  anomaly: Anomaly;
  component: Component;
}

export interface Incident {
  id: string;
  eventCode: EventCode;
  subEventCode: string;
  navigation: NavigationData;
  isAnomalous: boolean;
  anomaly: Anomaly[];
  comment: string;
}

export interface TaskProgress {
  percentage: number;
  completionComment: string;
  startTime: Date;
}

export interface EventCode {
  code: string;
  mode: string | number;
  description: string;
}

export interface WorkpackPositioning {
  id: string;
  name: string;
  code: string;
  _WorkpackPosition: NavigationData;
  _StructureComponent: Component;
}

export interface Workpack {
  name: string;
  description: string;
  field: string;
}

export interface Structure {
  name: string;
  position: LatLng | undefined;
}

export interface StructsWpks {
  [struct: string]: Array<{ Workpack: WorkpackPositioning }>;
}

export interface MapLoader {
  data: boolean;
  layers: boolean;
}

export interface TaskStatistics {
  completed: any[];
  inProgress: any[];
  notStarted: any[];
  anomalies: any[];
  events: any[];
}

export interface Criticality {
  Criticality: string;
  Color: string;
}

export const Criticalities: Criticality[] = [
  { Criticality: 'Information', Color: 'green' },
  { Criticality: 'Insignificant', Color: 'green' },
  { Criticality: 'Monitor', Color: 'yellow' },
  { Criticality: 'Significant', Color: 'orange' },
  { Criticality: 'Critical', Color: 'red' }
];

export interface WorkpackMasterAnomalies {
  anomaly: Array<{
    anomalyCode: { code: string; description: string };
    masterAnomaly: MasterAnomaly;
  }>;
}

export interface MasterAnomaly {
  id: string;
  description: string;
  action: string;
  status: string;
  reviewedBy: string;
  closedBy: string;
  anomalyCode: AnomalyCode;
}

export enum SelectedTab {
  Statistics,
  Anomalies,
  Tasks,
  Media,
  Reports,
  Map
}
