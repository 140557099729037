export enum TileType {
  Completed = 0,
  Incomplete = 1
}

export enum MediaSourceType {
  Anomaly = 0,
  MasterAnomaly = 1,
  ImageGrab = 2,
  VideoLog = 3,
  Document = 4
}

export enum MasterAnomalyStatus {
  New = 0,
  Monitor,
  Rectify,
  Closed
}

export enum AnomalyCriticality {
  Critical = 0,
  Significant = 1,
  Monitor = 2,
  Insignificant = 3,
  Information
}

export enum AnomalyStatus {
  New,
  Update,
  Referred,
  Declined,
  Closed
}

export enum MediaType {
  Document,
  Image,
  Video,
  Unknown
}
