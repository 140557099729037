import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { ChartLegendOptions, ChartOptions } from 'chart.js';
import { compareAsc, max, min, startOfDay, endOfDay, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { OperationalCodeColors } from '../../../styles/FugroColors';
import { HomepageSkeletonBlock } from '../../skeletons/HomepageSkeletonBlock';
import { WorkpackListItem } from './WorkpacksOverview';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    favouritesHeading: {
      display: 'inline-block',
      margin: theme.spacing(1)
    },
    favouritesHeadingContainer: {
      position: 'relative'
    }
  })
);

export default function WorkpacksSummaryPerformance(props: { loading: boolean; workpacks?: WorkpackListItem[] }): JSX.Element {
  const [chartData, setChartData] = useState<any>();
  const classes = useStyles();

  const legendOptions: ChartLegendOptions = {
    display: false
  };

  const chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        left: 15,
        right: 15,
        top: 3,
        bottom: 3
      }
    },
    scales: {
      xAxes: [
        {
          // type: 'time',
          // type: 'category',
          position: 'bottom',
          ticks: {
            callback: function (val, index) {
              // @ts-ignore
              const dataset = this.chart.data.datasets.filter((x) => x.label === 'Completed Tasks');
              if (dataset !== []) {
                const curr = dataset[0].data[index];
                if (curr.y === null) {
                  return ''; // No data associated
                } else {
                  return val;
                }
              }

              return val;
            }
          }
          // time: {
          //   unit: 'day'
          // }
        }
      ],
      yAxes: [
        {
          id: 'y-axis-hours',
          position: 'left',
          scaleLabel: { labelString: 'Effort (h)', display: true },
          ticks: {
            min: 0
          }
        },
        {
          id: 'y-axis-tasks',
          position: 'right',
          scaleLabel: { labelString: 'Tasks', display: true }
        }
      ]
    }
  };

  useEffect(() => {
    if (props.workpacks) {
      const finalChart = buildChartData(props.workpacks);
      setChartData(finalChart);
    }
  }, [props.workpacks]);

  function getLastDayAndIncrementByOne(input: WorkpackListItem) {
    const i = input.progress.dailyProgress.sort((a: any, b: any) => new Date(b.day).getTime() - new Date(a.day).getTime())[0]?.day;
    const d = new Date(i);
    const result = d.setDate(d.getDate() + 1);
    return result;
  }

  function filterUniqueDays(value: Date, index: number, self: any) {
    return self.map((x: Date) => x.toString()).indexOf(value.toString()) === index;
  }

  function buildChartData(input: WorkpackListItem[]) {
    const totalEstimatedTimeHours = input.reduce((s, c) => s + c.progress.totalEstimatedTime, 0) / 60 / 60;

    // This firstDay calculation takes either the firstIncidentDate or startDate of all workpacks, then takes the earliest
    // const firstDay = startOfDay(min(input.map(i => i.firstIncidentDate ? new Date(i.firstIncidentDate) : new Date(i.startDate))));

    // Ideal Burndown: calculated by:
    //  Start: taking the earliest StartDate of all the workpacks and assigning that TotalEstimatedTimeHours
    //  End:   taking the latest EndDate of all the workpacks and assigning that 0
    const firstDay = startOfDay(min(input.map((i) => new Date(i.startDate))));
    const lastDay = endOfDay(max(input.map((i) => new Date(i.endDate))));

    const idealBurndown = {
      label: 'Ideal Burndown',
      type: 'line',
      yAxisID: 'y-axis-hours',
      data: [
        { x: firstDay, y: totalEstimatedTimeHours },
        { x: lastDay, y: 0 }
      ],
      fill: false,
      borderColor: '#71B37C',
      borderDash: [5, 5],
      pointRadius: 0,
      pointHitRadius: 0,
      pointHoverRadius: 0
    };

    const progressPerDay: Map<Date, { tasksCompleted: number; timeCompleted: number; burndownTimeEstimated: number }> = input
      .flatMap((i) => i.progress.dailyProgress)
      .filter((i) => i.tasksCompleted !== 0)
      .sort((a, b) => compareAsc(new Date(a.day), new Date(b.day)))
      .reduce(
        (s, c) =>
          s.has(c.day)
            ? s.set(c.day, {
                tasksCompleted: s.get(c.day).tasksCompleted + c.tasksCompleted,
                timeCompleted: s.get(c.day).timeCompleted + c.timeCompleted,
                burndownTimeEstimated: c.burndownTimeEstimated
              })
            : s.set(c.day, { tasksCompleted: c.tasksCompleted, timeCompleted: c.timeCompleted, burndownTimeEstimated: c.burndownTimeEstimated }),
        new Map()
      );

    if (!progressPerDay.has(firstDay)) {
      progressPerDay.set(firstDay, { tasksCompleted: 0, timeCompleted: 0, burndownTimeEstimated: totalEstimatedTimeHours * 60 * 60 });
    }

    const workpackSeperators = Array.from(
      // Get an array of all the last days of a workpack (generated from progress list not the endDate property)
      input
        .flatMap((x: any) => new Date(getLastDayAndIncrementByOne(x)))
        .filter((x: any) => !isNaN(x))
        .filter(filterUniqueDays),
      (endDate: Date) => ({ d: new Date(endDate), x: format(new Date(endDate), 'd MMM yy'), y: null })
    );

    const wormData = Array.from(progressPerDay, ([date, progress]) => ({
      d: new Date(date),
      x: format(new Date(date), 'd MMM yy'),
      y: progress.burndownTimeEstimated / 60 / 60
    }))
      // @ts-ignore
      .concat(workpackSeperators)
      .sort((a, b) => compareAsc(a.d, b.d));

    if (wormData.length > 1 && wormData[0].x === wormData[1].x) {
      // if day 0 and 1 are the same, move 0 back a day to make graph look better
      wormData[0].d.setDate(wormData[0].d.getDate() - 1);
      wormData[0].x = format(wormData[0].d, 'd MMM yy');
    }

    const worm = {
      label: 'Burndown',
      type: 'line',
      yAxisID: 'y-axis-hours',
      data: wormData,
      fill: false,
      borderColor: OperationalCodeColors[0],
      lineTension: 0,
      pointRadius: 2,
      pointHitRadius: 1,
      pointHoverRadius: 1,
      spanGaps: false
    };

    const completedTasksData = Array.from(progressPerDay, ([date, progress]) => ({
      d: new Date(date),
      x: format(new Date(date), 'd MMM yy'),
      y: progress.tasksCompleted
    }))
      // @ts-ignore
      .concat(workpackSeperators)
      .sort((a, b) => compareAsc(a.d, b.d));

    const completedTasksHistogram = {
      label: 'Completed Tasks',
      type: 'bar',
      yAxisID: 'y-axis-tasks',
      data: completedTasksData,
      borderColor: '#FFCC00',
      backgroundColor: '#FFCC00'
    };

    const wormLabels = wormData.map((w) => w.x).filter((l) => l.length > 0);
    const histoLabels = completedTasksData.map((t) => t.x);
    const combinedLabels = wormLabels.concat(histoLabels.filter((v) => wormLabels.indexOf(v) === -1));

    // console.table(wormData);

    const finalChart = {
      labels: combinedLabels,
      datasets: [idealBurndown, worm, completedTasksHistogram]
    };

    return finalChart;
  }

  if (props.loading) {
    return <HomepageSkeletonBlock />;
  }

  return (
    <div>
      <div className={classes.favouritesHeadingContainer}>
        <Typography className={classes.favouritesHeading} component="h6" variant="h6">
          Performance
        </Typography>
      </div>
      <div style={{ height: '240px' }}>{chartData ? <Bar data={chartData} options={chartOptions} legend={legendOptions} /> : <div>No Data</div>}</div>
    </div>
  );
}
