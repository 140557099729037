import { Card, CardContent, Grid, CardActions } from '@material-ui/core';
import { Skeleton, ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import React, { CSSProperties } from 'react';
import { MasterAnomalyStatus } from '../../../../../utils/enumerations';
import { Component } from '../../../Interfaces';
import StatusTile from './StatusTile';
import PlayLatestButton from './PlayLatestButton';

export default function HeaderTiles(props: {
  loading: boolean;
  data: any;
  selectedFilterMAStatus: any;
  setSelectedFilterMAStatus: (val: MasterAnomalyStatus | null) => void;
}) {
  const loading = props.loading;
  const data = props.data;
  const selectedFilterMAStatus = props.selectedFilterMAStatus;
  const setSelectedFilterMAStatus = props.setSelectedFilterMAStatus;

  if (loading) {
    return (
      <Card>
        <Skeleton variant="rect" height={72} />
      </Card>
    );
  }

  const toggleButtonGroupStyle: CSSProperties = { width: '100%', height: '100%' };
  const toggleButtonItemStyle: CSSProperties = { width: '100%', height: '100%', textAlign: 'left', textTransform: 'none', border: 'none' };

  const cNew = data?.components?.flatMap((comp: Component) => {
    return comp.masterAnomaly.filter((ma: any) => ma.status === MasterAnomalyStatus.New);
  })?.length;
  const cRectify = data?.components?.flatMap((comp: Component) => {
    return comp.masterAnomaly.filter((ma: any) => ma.status === MasterAnomalyStatus.Rectify);
  })?.length;
  const cClosed = data?.components?.flatMap((comp: Component) => {
    return comp.masterAnomaly.filter((ma: any) => ma.status === MasterAnomalyStatus.Closed);
  })?.length;
  const cMonitor = data?.components?.flatMap((comp: Component) => {
    return comp.masterAnomaly.filter((ma: any) => ma.status === MasterAnomalyStatus.Monitor);
  })?.length;

  return (
    <Card>
      <CardContent>
        <Grid container justify="flex-start" alignItems="center" spacing={1}>
          <Grid item xs={8}>
            <ToggleButtonGroup
              exclusive
              style={toggleButtonGroupStyle}
              value={selectedFilterMAStatus}
              onChange={(e, v) => {
                setSelectedFilterMAStatus(v);
              }}
            >
              <ToggleButton value={MasterAnomalyStatus.New} style={toggleButtonItemStyle}>
                <StatusTile status={MasterAnomalyStatus.New} count={cNew} />
              </ToggleButton>
              <ToggleButton value={MasterAnomalyStatus.Rectify} style={toggleButtonItemStyle}>
                <StatusTile status={MasterAnomalyStatus.Rectify} count={cRectify} />
              </ToggleButton>
              <ToggleButton value={MasterAnomalyStatus.Monitor} style={toggleButtonItemStyle}>
                <StatusTile status={MasterAnomalyStatus.Monitor} count={cMonitor} />
              </ToggleButton>
              <ToggleButton value={MasterAnomalyStatus.Closed} style={toggleButtonItemStyle}>
                <StatusTile status={MasterAnomalyStatus.Closed} count={cClosed} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <PlayLatestButton loading={loading} data={data} />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{ display: 'none' }}></CardActions>
    </Card>
  );
}
