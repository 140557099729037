import { CircularProgress, createStyles, Fade, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WorkpackId } from '../../types/params.type';
import { MapLoader, LegacyMapProperties, MarkerInfo } from './MapUtils/SharedTypes';
import LegacySideBar from './Overlay/LegacySideBar';
import LegacyMapDeckGl from './DeckGL/LegacyMapDeckGl';

const useStyles = makeStyles(() =>
  createStyles({
    normal: {
      zIndex: 999,
      width: '100%',
      height: '100%',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0, 0, 0, 0.9)'
    }
  })
);

export default function LegacyMapContainer(props: WorkpackId & RouteComponentProps): JSX.Element {
  const styles = useStyles();
  //const skeletonStyles = useSkeletonStyles();
  const [focusedMarkers, setFocusedMarkers] = useState<MarkerInfo[]>([]);
  const [checkboxes, setCheckbox] = useState({
    events: true,
    tasks: true,
    anomalies: true,
    text: true
  });
  const { events, tasks, anomalies, text } = checkboxes;
  const [searchItem, setSearchItem] = useState<string>('');
  const [isLoading, setLoading] = useState<MapLoader>({ layers: false, data: false });
  const [isMeasuring, setIsMeasuring] = useState<boolean>(false);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(fetchLocalStorageTheme());

  const [customCheckboxes, setCustomCheckboxes] = useState<Map<string, boolean>>(new Map());

  useEffect(() => {
    if (searchItem !== '') {
      console.log('[MapContainer.tsx] Recieved Search Id:' + searchItem);
    }
  }, [searchItem]);

  useEffect(() => {
    // console.log(checkboxes);
  }, [checkboxes]);

  function fetchLocalStorageTheme(): boolean {
    const value = localStorage.getItem('map.dark');
    if (value) {
      return JSON.parse(value);
    }
    return true;
  }

  const hCheckboxUpdate = (name: string, enabled: boolean) => {
    setCheckbox({ ...checkboxes, [name]: enabled });
  };

  const hCustomCheckboxUpdate = (name: string, enabled: boolean) => {
    const cloned = customCheckboxes;
    cloned.set(name, enabled);
    setCustomCheckboxes(cloned);
  };

  const hSetMarkers = (markers: MarkerInfo[]) => {
    setFocusedMarkers(markers);
  };

  const hIsMeasuring = (enabled: boolean) => {
    setIsMeasuring(enabled);
  };

  const hOnDarkModeToggle = (enabled: boolean) => {
    setIsDarkMode(enabled);
  };

  const MapProps: LegacyMapProperties = {
    Markers: {
      Events: events,
      Anomalies: anomalies,
      Tasks: tasks,
      Text: text
    },
    Layout: {
      DarkMode: isDarkMode
    },
    Properties: {
      searchId: searchItem,
      WorkpackId: props.workpackId,
      State: setLoading
    },
    CustomLayers: customCheckboxes,
    Handlers: {
      SetMarkers: hSetMarkers,
      IsMeasuring: isMeasuring
    }
  };

  function isMapLoading() {
    return !isLoading.data || !isLoading.layers;
  }

  return (
    <React.Fragment>
      <div style={{ position: 'relative' }}>
        <Fade in={isMapLoading()}>
          <div className={styles.normal} style={{ minWidth: '100%', height: '76vh' }}>
            <CircularProgress style={{ position: 'absolute', marginBottom: '80px', height: '40px', width: '40px', color: 'white' }} />
            <div style={{ fontWeight: 'bold', position: 'relative', color: 'white' }}>Fetching Map Information...</div>
          </div>
        </Fade>
        <LegacyMapDeckGl
          {...props}
          Markers={MapProps.Markers}
          Handlers={MapProps.Handlers}
          CustomLayers={MapProps.CustomLayers}
          Properties={MapProps.Properties}
          Layout={MapProps.Layout}
        />
        <LegacySideBar
          workpackId={props.workpackId}
          checkboxes={hCheckboxUpdate}
          customCheckboxes={hCustomCheckboxUpdate}
          focusedMarkers={focusedMarkers}
          searchItem={setSearchItem}
          onIsMeasuring={hIsMeasuring}
          onIsMeasureRunning={isMeasuring}
          onIsDarkModeToggle={hOnDarkModeToggle}
        />
      </div>
    </React.Fragment>
  );
}
