import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import ErrorMessage from './ErrorMessage';

function ErrorFallback(props: FallbackProps) {
  return <ErrorMessage message={props.error.message} />;
}
export function ErrorCollectorBoundary(props: { children?: React.ReactNode }) {
  return <ErrorBoundary FallbackComponent={ErrorFallback}>{props.children}</ErrorBoundary>;
}
