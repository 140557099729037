import { Link } from '@material-ui/core';
import React from 'react';

export const MediaLink = (props: { mediaId: string; text: string; onSelect: (mediaId: string) => void }) => {
  const handleLinkClick = () => {
    console.log('handleLinkClick ' + props.mediaId);
    props.onSelect(props.mediaId);
    return false;
  };

  return (
    <Link component="button" variant="body2" onClick={handleLinkClick}>
      {props.text}
    </Link>
  );
};
