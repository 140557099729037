import chartjs from 'chart.js';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { Anomaly } from '../../../../map/MapUtils/SharedTypes';

export default function AnomalyDurationBarGraph(props: { anomalies: Anomaly[] | undefined; loading: boolean }): JSX.Element {
  const [datasource, setDatasource] = useState<any>();
  const [maxGridValue, setMaxGridValue] = useState<number>(0);

  const chartOptions: chartjs.ChartOptions = {
    layout: {
      padding: {
        top: 25,
        bottom: 25
      }
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true
          },
          ticks: {
            // callback: (value: string) => {
            //         if (value.length >= 8) {
            //                 return `${value.substring(0, 8)}..`
            //         }
            //         return value;
            // }
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 1,
            beginAtZero: true,
            max: 60, // (maxGridValue <= 1 ? 1 : maxGridValue + 1),
            maxTicksLimit: 5,
            precision: 0,
            min: 0,
            // mirror: true,
            callback: (value: any) => {
              return value === 60 ? `> ${value}` : value;
            }
          },
          scaleLabel: {
            display: true,
            labelString: 'Open Duration (days)'
          }
        }
      ]
    },
    title: {
      display: true,
      text: 'Site Anomaly Open Duration'
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    hover: {
      animationDuration: 0
    },
    animation: {
      duration: 0,
      onComplete: (arg: any) => {
        const ctx = arg.chart.ctx;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        ctx.fillStyle = '#000000';

        arg.chart.data.datasets.forEach((dataset: any, i: any) => {
          const meta = arg.chart.controller.getDatasetMeta(i);

          meta.data.forEach((bar: any, index: number) => {
            let data = dataset.data[index];
            data = Number(data).toFixed(0);

            if (data > 60) {
              ctx.fillText(data, bar._model.x, bar._model.base - 40);
            } else {
              ctx.fillText(data, bar._model.x, bar._model.y);
            }
          });
        });
      }
    }
  };

  function formatData(d: Anomaly[]) {
    // const durations = d.map((a: Anomaly) => { return (new Date(a.endTime).getTime() - new Date(a.startTime).getTime()) });
    // const labels = d.map((a: Anomaly) => { return (a.criticality) });
    const test = _.groupBy(d, 'criticality');
    const data: number[] = [];

    Object.keys(test).forEach((criticality: any, k: any) => {
      let sum = 0;

      test[criticality].forEach((anomaly: Anomaly, index: number) => {
        const isClosed = anomaly.closedBy != '';
        // @ts-ignore
        const duration = isClosed ? new Date(anomaly.endTime) - new Date(anomaly.startTime) : Date.now() - new Date(anomaly.startTime);
        sum += duration;
      });

      data.push(sum === 0 ? 0 : sum / 1000 / 60 / 60 / 24);
    });

    // Save the longest anomaly duration so we can adjust to y axis max ticks.
    const gridUpperBounds = data.reduce((a, b) => {
      return Math.max(a, b);
    }, 0);
    setMaxGridValue(gridUpperBounds);

    const newDataset = {
      labels: Object.keys(test),
      datasets: [
        {
          label: 'AnomalyDurations',
          data: data,
          backgroundColor: ['#4275c6', '#e6803c', '#9198a1', '#f5c748', '#6f9fda']
        }
      ]
    };

    return newDataset;
  }

  useEffect(() => {
    if (props?.anomalies) {
      const formattedData = formatData(props.anomalies);
      setDatasource(formattedData);
    }
  }, [props.anomalies]);

  if (props.loading) {
    return <Skeleton variant="rect" width={250} height={250} />;
  }

  return (
    <div style={{ height: '250px', width: '250px', paddingRight: '20px' }}>
      {datasource ? <Bar data={datasource} options={chartOptions} /> : <React.Fragment />}
    </div>
  );
}
