import { useMutation, useQuery } from '@apollo/react-hooks';
import { createStyles, Grid, makeStyles, Paper, Theme } from '@material-ui/core';
import { gql } from 'apollo-boost';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { useGlobalState } from '../../../store/globalState';
import { Ticker } from '../../ticker/ticker';
import WorkpacksSummary from './WorkpacksSummary';
import WorkpacksTable from './WorkpacksTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingBottom: theme.spacing(3)
    },
    grid: {
      paddingTop: '1em'
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: 'center',
      color: theme.palette.text.primary
    },
    card: {
      padding: theme.spacing(2)
    },
    cardRow: {
      height: '30em'
    }
  })
);

export interface WorkpackListItem {
  favourite: boolean;
  id: string;
  code: string;
  workpackCode: string;
  name: string;
  startDate: Date;
  endDate: Date;
  firstIncidentDate?: Date;
  parentAsset: string[];
  uptime: number;
  milestone: WorkpackMilestone[];
  _itemCount: { anomaly: number };
  progress: {
    totalEstimatedTime: number;
    completedTaskCount: number;
    totalTaskCount: number;
    estimatedCompletionTime: number;
    actualCompletionTime: number;
    dailyProgress: WorkpackProgressCompletedDaily[];
  };
  isFavourite?: boolean;
}

export interface WorkpackProgressCompletedDaily {
  day: Date;
  tasksCompleted: number;
  timeCompleted: number;
  burndownTimeEstimated: number;
}

export interface WorkpackMilestone {
  time: Date;
  comment: string;
}

export default function WorkpacksOverview(props: RouteComponentProps): JSX.Element {
  const classes = useStyles();

  const [updateFavouriteWorkpacks] = useMutation(gql`
    mutation updateFavouriteWorkpacks($id: ID!) {
      updateFavouriteWorkpacks(id: $id) @client {
        id
        isFavourite @client
      }
    }
  `);

  // Reset the page title here, otherwise when navigating back from a workpack
  // the workpack name will persist.
  const [, setPageTitle] = useGlobalState('globalPageTitle');
  useEffect(() => setPageTitle(''), [setPageTitle]);

  const { loading, error, data } = useQuery<{ workpack: WorkpackListItem[] }, object>(
    gql`
      query workpackList {
        workpack {
          id
          code
          workpackCode
          name
          startDate
          endDate
          firstIncidentDate
          parentAsset
          uptime
          milestone {
            time
            comment
          }
          _itemCount {
            anomaly
          }
          progress {
            totalEstimatedTime
            completedTaskCount
            totalTaskCount
            estimatedCompletionTime
            actualCompletionTime
            dailyProgress {
              day
              tasksCompleted
              timeCompleted
              burndownTimeEstimated
            }
          }
          isFavourite @client
        }
      }
    `,
    { variables: {} }
  );

  const handleWorkpacksTableOnSelectionChange = (changedRow?: WorkpackListItem) => {
    if (changedRow) {
      updateFavouriteWorkpacks({ variables: { id: changedRow.id } });
    }
  };

  const workpacksAllOrFavourites = () => (data?.workpack.find((w) => w.isFavourite) ? data.workpack.filter((w) => w.isFavourite) : data?.workpack);

  return (
    <div className={classes.root}>
      <Grid item={true} xs={12}>
        <Ticker />
      </Grid>
      <Grid className={classes.grid} container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <WorkpacksSummary loading={loading} workpacks={workpacksAllOrFavourites()} />
        </Grid>
        <Grid item={true} xs={12}>
          <Paper className={classes.paper}>
            <WorkpacksTable
              {...props}
              loading={loading}
              error={error}
              workpacks={data?.workpack}
              onSelectionChange={handleWorkpacksTableOnSelectionChange}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
