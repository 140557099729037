export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function colorLuminance(hex: string, lum: number) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#';
  for (let i = 0; i < 3; i++) {
    const color = parseInt(hex.substr(i * 2, 2), 16);
    const result = Math.round(Math.min(Math.max(0, color + color * lum), 255)).toString(16);
    rgb += ('00' + result).substr(result.length);
  }

  return rgb;
}
