import Rule from './Rule';
import { HeatmapData } from '../../Interfaces';
import { BufferAttribute } from 'three';

function isRuleValid(rule: Rule) {
  if (!rule) {
    console.log(`Failed to build rule, please supply valid rule object.`);
    return false;
  }

  return true;
}

function isDataSetValid(data: any[]) {
  if (!data || data?.length === 0) {
    console.log(`Failed to build rule, invalid datasource provided.`);
    return false;
  }
  return true;
}

export function BuildNormalRule(rule: Rule) {
  if (!isRuleValid(rule)) return;

  rule.Color = 0xffffff;
  rule.Condition = () => {
    return true;
  };
  rule.ApplyTransformation = (mesh: any) => {
    mesh.material.vertexColors = false; // Necessary since Normal is directly after Heatmap
    mesh.material.needsUpdate = true;

    if (mesh?.originalColor) {
      mesh.material.color.setHex(mesh.originalColor);
    }
  };
}

export function BuildTaskProgressRule(rule: Rule, dataset: any[]) {
  if (!isRuleValid(rule) || !isDataSetValid(dataset)) return;

  rule.Color = 0xffffff;
  rule.Condition = () => {
    const fMesh = rule.Parent?.CurrentMesh;
    const component = dataset.filter((comp: any) => comp.fullComponent === fMesh.name)[0];

    if (component && fMesh) {
      const progresses = component.task;
      const currentProgress = Math.max(...progresses.flatMap((x: any) => x.progress).map((y: any) => y.percentage));

      if (currentProgress >= 99) rule.Color = 0x00ff00;
      else if (currentProgress > 0) rule.Color = 0xff5e00;
      else rule.Color = 0xff0000;

      return true;
    }
    // Temporarily set component to obvious no data color
    // Delete this else statement l8r
    rule.Color = 0xffffff;
    return true;
  };
  rule.ApplyTransformation = (mesh: any) => {
    mesh.material.color.setHex(rule.Color);
  };
}

export function BuildAnomalyRule(rule: Rule, dataset: any[]) {
  if (!isRuleValid(rule) || !isDataSetValid(dataset)) return;

  rule.Color = 0xffffff;
  rule.Condition = () => {
    const fMesh = rule.Parent?.CurrentMesh;
    const component = dataset.filter((comp: any) => comp.sanitizedFullComponent === fMesh.name)[0];

    if (component && fMesh) {
      const hasAnoms = component.anomaly.length !== 0;
      const hasMAs = component.masterAnomaly.length !== 0;

      if (hasMAs) rule.Color = 0xff8d00;
      else if (hasAnoms) rule.Color = 0xff0000;
      else rule.Color = 0x00ff00;

      return true;
    }
    // Temporarily set component to obvious no data color
    // Delete this else statement l8r
    rule.Color = 0xffffff;
    return true;
  };
  rule.ApplyTransformation = (mesh: any) => {
    mesh.material.color.setHex(rule.Color);
  };
}

export function BuildHeatmapRule(rule: Rule, heatmapData?: HeatmapData) {
  if (!isRuleValid(rule) || !heatmapData) return;

  rule.Condition = () => {
    return true;
  };

  rule.ApplyTransformation = (mesh: any) => {
    mesh.material.color.setHex(0xffffff);
    mesh.material.vertexColors = true;
    const colours =
      heatmapData.Nodes.find((n) => n.Name == mesh.name)?.VertexColors.flatMap((s) =>
        s
          .split(', ')
          .slice(1, 4) // ARGB values in, RGB out
          .map((c) => parseInt(c) / 255)
      ) ?? [];
    const attr = new BufferAttribute(new Float32Array(colours), 3);
    mesh.geometry.setAttribute('color', attr);
    mesh.material.needsUpdate = true;
  };
}
