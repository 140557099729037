import { useQuery } from '@apollo/react-hooks';
import { createStyles, makeStyles } from '@material-ui/core';
import { gql } from 'apollo-boost';
import DeckGL, { MapView, MVTLayer } from 'deck.gl';
import React, { useEffect, useState } from 'react';
import { StaticMap } from 'react-map-gl';
import { RouteComponentProps } from 'react-router-dom';
import { MapTooltipProps } from '../../../types/params.type';
import { MapDataQuery } from '../MapUtils/MapQueries';
import { LngLat, LegacyMapProperties, MvtProperties, SelectedTab } from '../MapUtils/SharedTypes';
import EpsgWarningModal from '../Overlay/EpsgWarningModal';
import MeasureModel from '../Overlay/MeasureModal';
import '../styles.css';
import DebugTooltip from './components/debug-tooltip';
import ExpandableIconTooltip from './components/icon-tooltip';
import { ICON_MAPPING_SIMPLE, INITIAL_VIEW_STATE } from './extras/Constants';
import { FilterAndSortData } from './extras/Functions';
import { MapConfiguration, WorkpackData } from './extras/Types';
// @ts-ignore
import RulerLayer from './ruler-layer/ruler-layer';
// @ts-ignore
import TextClusterLayer from './text-cluster-layer/text-cluster-layer';
// @ts-ignore
import TextMvtLayer from './text-mvt-layer/text-mvt-layer';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '76vh',
      minWidth: '100%',
      position: 'relative'
      // border: '1px dashed red',
    }
  })
);

// tslint:disable-next-line: max-line-length
const FieldLayoutLayers = (mvtUrls: string[], layerType: new (props: any) => MVTLayer<MvtProperties>, layerProperties: MvtProperties) =>
  mvtUrls.map((lu, i) => {
    return new layerType({
      id: `drawing-layer-${i}`,
      data: lu,
      onTileError,
      ...layerProperties
    });
  });

const onTileError = (error: Error) => {
  // If we get a 403 Redirect, supress it, but we shouldnt cause S3 now returns a blank.pbf
  // Only print out non-403 errors.
  if (!error.message.includes('403')) {
    console.log(error);
  }
};

// tslint:disable: max-line-length
export default function LegacyMapDeckGl(props: LegacyMapProperties & RouteComponentProps): JSX.Element {
  const WORKPACK_ID = props.Properties.WorkpackId;
  const MAP_STYLE_TOKEN = 'pk.eyJ1Ijoib2xpdmVyYnVja2xlciIsImEiOiJja2x0MmxrbTgwYWUwMnZvNTlsZGtocmlhIn0.ut0usMOiygSF99KtCA0djw';
  const ICONS = './map/icon-atlas-update.png';
  const MAP_VIEW = new MapView({ repeat: false });
  const classes = useStyles();

  const [initialCameraPosition, setInitialCameraPosition] = useState<LngLat>();
  const [hoverInfo, setHoverInfo] = useState<any>({});
  const [tooltipIconData, setTooltipIconData] = useState<any>([]);
  const [tooltipIconVisible, setTooltipIconVisible] = useState<boolean>(false);
  const [tooltipIconPosition, setTooltipIconPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [measurementData, setMeasurementData] = useState<any>([]);
  const [debuggingTools, enableDebuggingTools] = useState<boolean>(false);
  const [viewState, setViewState] = useState<any>(INITIAL_VIEW_STATE);
  const [formattedData, setFormattedData] = useState<any[]>([]); // Used to store our base formatted data.
  const [filteredData, setFilteredData] = useState<any[]>([]); // Used to store formattedData but with a filter.
  const [darkMode, setDarkMode] = useState<boolean>();
  const [epsgDefined, setEpsgDefined] = useState<boolean>(true);
  const { loading, data: mapData } = useQuery<{ workpack: WorkpackData[]; mapConfiguration: MapConfiguration }, { id: string }>(
    gql`
      ${MapDataQuery}
    `,
    { variables: { id: WORKPACK_ID } }
  );

  useEffect(initialLoad, [loading]);

  useEffect(onMarkerSelectionChanged, [props.Markers]);

  useEffect(onComponentSearchChanged, [props.Properties.searchId]);

  useEffect(() => setDarkMode(props.Layout.DarkMode), [props.Layout.DarkMode]);

  function initialLoad() {
    if (!loading) {
      // var darkMode = localStorage.GetItem('map.skin');
      if (mapData) {
        const workpackPosition = mapData?.workpack[0]?._WorkpackPosition?.lnglat;
        const hasEpsg = mapData.mapConfiguration.epsgCodes.some((x) => x != '0');

        console.log('EPSG: ', mapData.mapConfiguration.epsgCodes);
        setEpsgDefined(hasEpsg);

        if (workpackPosition) {
          const _initialPoint = { longitude: workpackPosition.longitude, latitude: workpackPosition.latitude };
          setInitialCameraPosition(_initialPoint);
          moveCameraToLongLat(_initialPoint);
        }
        const sortedData = FilterAndSortData(mapData, props.Markers);
        setFormattedData(sortedData);
        props.Properties.State({ data: true, layers: true });
      }
    }
  }

  function onComponentSearchChanged() {
    const query = props.Properties.searchId;
    if (query !== '') {
      const _data = formattedData.filter((x) => x.id === query);
      if (_data.length > 0) {
        setFilteredData(_data);
        moveCameraToLongLat(_data[0].estimatedLocation);
      }
    } else {
      setFilteredData([]);
      moveCameraToLongLat(initialCameraPosition);
    }
  }

  function onMarkerSelectionChanged() {
    if (mapData) {
      const sortedData = FilterAndSortData(mapData, props.Markers);
      setFormattedData(sortedData);
    }
  }

  function moveCameraToLongLat(lnglat: LngLat | undefined) {
    setViewState({
      ...viewState,
      longitude: lnglat?.longitude || 0,
      latitude: lnglat?.latitude || 0,
      zoom: 14,
      pitch: 0,
      bearing: 0
    });
  }

  const onHoverMvt = (info: any) => {
    setHoverInfo(info);
  };

  const onHoverIcon = (info: any) => {
    if (!info.objects && !info.object) {
      return;
    }

    if (info.objects) {
      setTooltipIconData(info.objects);
    } else if (info.object) {
      setTooltipIconData([info.object]);
    }

    if (debuggingTools) {
      const data = info.objects ? info.objects : [info.objects];
      console.log('=======');
      console.log(data);
      console.log('==END==');
      // setHoverInfo(info);
    }

    setTooltipIconPosition({ x: info.x + 15, y: info.y - 36 });
    setTooltipIconVisible(true);
  };

  function onViewStateChange(viewStateProps: { viewState: any }) {
    setTooltipIconVisible(false);
    setViewState(viewStateProps.viewState);
  }

  const onIconTooltipLeave = () => {
    setTooltipIconVisible(false);
  };

  function setResponseHeaders(args: MapTooltipProps) {
    const tab = SelectedTab[args.Tab];
    const data = JSON.stringify(args);
    props.history.replace(`/workpack-detail-dashboard/${WORKPACK_ID}/${tab}`, data);
  }

  const onClick = (args: any) => {
    if (props.Handlers.IsMeasuring) {
      setMeasurementData([...measurementData, args.coordinate]);
    }
  };

  const onKeyDown = (args: any) => {
    const key = args.key;

    switch (key) {
      case 'D':
        enableDebuggingTools(!debuggingTools);
        break;
      case 'Escape':
        setMeasurementData([]);
        break;
    }
  };

  const pbfLayerProperties: MvtProperties = {
    minZoom: 0,
    maxZoom: 20,
    opacity: 1,
    stroked: true,
    filled: true,
    getLineColor: [255, 0, 255],
    getFillColor: [0, 35, 102],
    getLineWidth: 1,
    lineWidthUnits: 'pixels',
    lineWidthMinPixels: 1,
    pickable: true,
    onHover: onHoverMvt,
    darkMode,
    showText: props.Markers.Text
  };

  const pbfLayers = mapData ? FieldLayoutLayers(mapData.mapConfiguration.layerUrls, TextMvtLayer, pbfLayerProperties) : null;

  const layers: any[] = [
    pbfLayers,
    new TextClusterLayer({
      id: 'text-cluster',
      sizeScale: 45,
      data: filteredData?.length > 0 ? filteredData : formattedData,
      pickable: true,
      getPosition: (d: any) => {
        // Reverse cause of latlng not lnglat
        return [d.estimatedLocation.y, d.estimatedLocation.x];
      },
      iconAtlas: ICONS,
      iconMapping: ICON_MAPPING_SIMPLE,
      onHover: onHoverIcon
    }),
    new RulerLayer({
      id: 'ruler-layer',
      data: measurementData
    })
  ];

  if (loading) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <div className={classes.container} onKeyDown={onKeyDown}>
        <DeckGL controller={true} layers={layers} views={[MAP_VIEW]} viewState={viewState} onViewStateChange={onViewStateChange} onClick={onClick}>
          <StaticMap
            reuseMaps={true}
            mapStyle={darkMode ? '/map/styles/blackwater.json' : '/map/styles/bluewater.json'}
            width={256}
            height={256}
            mapboxApiAccessToken={MAP_STYLE_TOKEN}
            preventStyleDiffing={true}
          />
        </DeckGL>
        <ExpandableIconTooltip
          onMouseLeave={onIconTooltipLeave}
          data={tooltipIconData}
          visible={tooltipIconVisible}
          position={tooltipIconPosition}
          onItemClick={setResponseHeaders}
        />
        <DebugTooltip entity={hoverInfo} visible={debuggingTools} />
        <MeasureModel open={props.Handlers.IsMeasuring} />
        <EpsgWarningModal open={!epsgDefined} bottomOffset={10} />
      </div>
    </React.Fragment>
  );
}
