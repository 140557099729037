import React from 'react';
import { useFrame, useThree, extend } from '@react-three/fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { PointLight } from 'three/src/lights/PointLight';

extend({ OrbitControls });

export default function CameraController(props: any): JSX.Element {
  const {
    camera,
    scene,
    gl: { domElement }
  } = useThree();
  const hasExistingPointLight = scene.children.filter((child: any) => child.type === 'PerspectiveCamera').length !== 0;

  useFrame((_: any) => {
    props.orbitRef.current.update();
  });

  if (!hasExistingPointLight) {
    // We have to add the light to the camera, and the camera to the scene
    // otherwise the pointlight wont be taken into effect.
    // https://stackoverflow.com/questions/29086457/three-js-how-to-link-add-a-light-to-the-camera-since-r69
    const pLight = new PointLight(0xffffff, 0.5);
    camera.translateZ(-175);
    camera.add(pLight);
    scene.add(camera);
  }

  //@ts-ignore required as OrbitControls interacts strangely with the JSX.Element return type.
  return <orbitControls ref={props.orbitRef} args={[camera, domElement]} enableZoom={true} zoomSpeed={5} />;
}
