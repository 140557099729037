import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Radio,
  Select,
  Switch,
  TablePagination,
  TextField,
  Theme
} from '@material-ui/core';
import { Clear, ExploreOff, FilterList } from '@material-ui/icons';
// tslint:disable-next-line: no-submodule-imports
// import ClearIcon from '@material-ui/icons/Clear';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import {
  addSeconds,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  formatISO,
  isSameDay,
  isValid,
  isWithinInterval,
  parseISO,
  set,
  setSeconds,
  startOfToday
} from 'date-fns';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';
import MaterialTable, { Column, Filter, MaterialTableProps } from '@material-table/core';
import React, { useEffect, useState } from 'react';
import { theme } from '..';
import DraftWatermark from '../images/draft-watermark.png';
import FugroLogoExcel from '../images/fugro-logo-qb-excel.png';
import { useGlobalState } from '../store/globalState';
import { UniversalDatePicker, UniversalDatePickerValue, UniversalDatePickerValueType } from './UniversalDatePicker';
import { FilterTabType } from './workpacks/workpackDetails/FilterObject';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { arrayMove } from './utils/general';

// tslint:disable: jsx-no-lambda
type MaterialTableExtendedFilterType =
  | 'None'
  | 'DateOnDay'
  | 'TimeBetweenStartEnd'
  | 'LookupAuto'
  | 'TextEditor'
  | 'Checkbox'
  | 'UniversalDate'
  | 'EventOrTask';

export type MaterialTableExtendedColumn<T extends object> = Column<T> & {
  filter?: MaterialTableExtendedFilterType;
  columnWidth?: string;
  isCollapsible?: boolean;
  isRtlCollapsible?: boolean;
  tableData?: any;
};

interface MaterialTableExtendedProps<T extends object> extends MaterialTableProps<T> {
  columns: Array<MaterialTableExtendedColumn<T>>;
  owner: FilterTabType;
  workpackName: string;
  hasMapFilter?: boolean;
  resetMapFilter?: () => void;
  exportWatermark?: boolean;
  isDownloadable?: boolean;
  onDownloadRequested?: (data: T[]) => void;
}

interface Filters {
  [column: string]: string;
}

interface FilterComponentProps {
  columnDef: any;
  onFilterChanged: (rowId: string, value: any) => void;
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    footer: {
      display: 'flex'
    },
    paginator: {
      flex: 1,
      right: 0,
      textAlign: '-webkit-right' as 'right'
    },
    toggleContainer: {
      alignSelf: 'center',
      paddingLeft: '10px',
      zIndex: 999
    },
    keyboardDatePickerInput: {
      fontSize: _.typography.body2.fontSize
    },
    keyboardDateTimePickerInput: {
      fontSize: '9pt'
    },
    menuProp: {
      color: 'red',
      fontSize: _.typography.body2.fontSize
    },
    lookupAutoSelect: {
      fontSize: _.typography.body2.fontSize,
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  })
);

let _tableName: string;
const tableReference = React.createRef();

const MaterialTableExtended = <T extends object>(props: MaterialTableExtendedProps<T>) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [cachedFilters, setFilters] = useState<any[]>([]);
  const [columnVisibilityState, setColumnVisibilityState] = useState<visibility[]>([]);
  const [showAllCommentText, setShowAllCommentText] = useState<boolean>(localStorage.getItem('showAllCommentText') === 'true');
  const [clientLogo] = useGlobalState('globalClientLogo');
  const [inDownloadMode, setInDownloadMode] = useState<boolean>(false);
  let newProps: MaterialTableExtendedProps<T> = props;
  _tableName = props.owner;

  const handleShowAllCommentTextChange = () => {
    const newValue = !showAllCommentText;
    setShowAllCommentText(newValue);
    localStorage.setItem('showAllCommentText', newValue.toString());
  };

  const hasVisibilityStateValue = (field: string) => {
    return columnVisibilityState.map((i) => i.field).indexOf(field);
  };

  const hasFilterStateValue = (field: string) => {
    let _index = -1;

    cachedFilters.some((item: { column: any; value: any; operator: any }, index: number) => {
      if (item.column.field === field) {
        _index = index;
        return true;
      }

      return false;
    });

    return _index;
  };

  const handleChangeRowsPerPage = (pageSize: number) => localStorage.setItem('rowsPerPage', pageSize.toString());

  const classes = useStyles();

  const mapExtendedColumnToMTColumn = (columnProps: MaterialTableExtendedColumn<T>, index: number): MaterialTableExtendedColumn<T> => {
    interface FilterComponentProps {
      columnDef: Column<T>;
      onFilterChanged: (rowId: string, value: any) => void;
    }
    const isRedirectingFromMap = props.hasMapFilter;

    // Clear filters if we're redirecting from the map
    if (isRedirectingFromMap) {
      const data = localStorage.getItem(_tableName);

      if (data) {
        localStorage.removeItem(_tableName);
        setShowFilters(false);
      }
    }

    const visibilityIndex = hasVisibilityStateValue(columnProps.field as string);
    if (visibilityIndex !== -1) {
      columnProps.hidden = columnVisibilityState[visibilityIndex].hidden;
    }

    const filterIndex = hasFilterStateValue(columnProps.field as string);
    if (filterIndex !== -1) {
      if (!columnProps.tableData) {
        // The tableData property is missing when we rerender from opening the sidebar
        columnProps.tableData = cachedFilters[filterIndex].column.tableData;
      } else {
        // We can just set the filter normally if just updating it
        columnProps.tableData.filterValue = cachedFilters[filterIndex].value;
      }
    }

    if (columnProps.filter) {
      switch (columnProps.filter) {
        case 'None': {
          columnProps.filtering = false;
          break;
        }
        case 'DateOnDay': {
          columnProps.filterComponent = (fcProps: FilterComponentProps) =>
            DateFilterComponentDateOnDay({ columnDef: columnProps, onFilterChanged: fcProps.onFilterChanged });
          columnProps.customFilterAndSearch = (filterValue: any, rowData: any, columnDef: Column<T>) =>
            dateFilterActionDateOnDay(filterValue, rowData, columnDef);
          break;
        }
        case 'LookupAuto': {
          columnProps.lookup = buildLookupAuto(columnProps, props.data);
          columnProps.filterCellStyle = {
            ...columnProps.filterCellStyle,
            minWidth: columnProps.columnWidth,
            maxWidth: columnProps.columnWidth,
            width: columnProps.columnWidth
          };
          columnProps.filterComponent = (fcProps: FilterComponentProps) =>
            LookupFilter({ columnDef: columnProps, onFilterChanged: fcProps.onFilterChanged });
          break;
        }
        case 'TimeBetweenStartEnd': {
          columnProps.filterComponent = (fcProps: FilterComponentProps) =>
            DateTimeFilterComponentTimeBetweenStartEnd({ columnDef: columnProps, onFilterChanged: fcProps.onFilterChanged });
          columnProps.customFilterAndSearch = (filterValue: any, rowData: any) => dateTimeFilterActionTimeBetweenStartEnd(filterValue, rowData);
          break;
        }
        case 'UniversalDate': {
          columnProps.filterComponent = (fcProps: FilterComponentProps) =>
            UniversalDateFilterComponent({ columnDef: columnProps, onFilterChanged: fcProps.onFilterChanged });
          columnProps.customFilterAndSearch = (filterValue: any, rowData: any, columnDef: Column<T>) =>
            universalDateFilterAction(filterValue, rowData, columnDef);
          break;
        }
        case 'EventOrTask': {
          columnProps.lookup = { Event: 'Event', Task: 'Task' };
          columnProps.filterCellStyle = {
            ...columnProps.filterCellStyle,
            minWidth: columnProps.columnWidth,
            maxWidth: columnProps.columnWidth,
            width: columnProps.columnWidth
          };
          columnProps.filterComponent = (fcProps: FilterComponentProps) =>
            LookupRadioFilter({ columnDef: columnProps, onFilterChanged: fcProps.onFilterChanged });
          columnProps.customFilterAndSearch = (filterValue: string[], rowData: any) => {
            if (filterValue.length === 0) {
              return true;
            }
            if (filterValue.includes('Event')) {
              return rowData.eventCode.mode === 'EVENT' || rowData.eventCode.mode === 'SYSTEM_EVENT';
            }
            if (filterValue.includes('Task')) {
              return rowData.eventCode.mode === 'TASK' || rowData.eventCode.mode === 'SYSTEM_TASK';
            }
            return false;
          };
          break;
        }

        //  Have to add saving filter functionality to default editors too
        case 'TextEditor': {
          columnProps.filterComponent = (fcProps: FilterComponentProps) =>
            DefaultFilter({ columnDef: columnProps, onFilterChanged: fcProps.onFilterChanged });
          break;
        }
        case 'Checkbox': {
          columnProps.filterComponent = (fcProps: FilterComponentProps) =>
            BooleanFilter({ columnDef: columnProps, onFilterChanged: fcProps.onFilterChanged });
          break;
        }
      }
    }

    if (columnProps.columnWidth && !columnProps.isCollapsible) {
      // @ts-ignore
      columnProps.width = columnProps.columnWidth;
    }

    if (columnProps.isCollapsible) {
      columnProps.cellStyle = {
        ...columnProps.cellStyle,
        whiteSpace: showAllCommentText ? 'initial' : 'nowrap',
        maxWidth: columnProps.columnWidth,
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      };
      if (columnProps.isRtlCollapsible) {
        columnProps.cellStyle = {
          ...columnProps.cellStyle,
          direction: 'rtl',
          textAlign: 'left'
        };
      }
      if (!columnProps.render && columnProps.field) {
        columnProps.render = (data: T) => {
          const value = getPropertyValueByPath(data, columnProps.field);
          return <span title={value.replace(/\r/g, '')}>{value}</span>;
        };
      }
      // @ts-ignore
      // columnProps.width = columnProps.columnWidth;
    }

    // Dont apply local storage filters when redirecting from the map.
    if (isLocalStorageForSortingDefined()) {
      columnProps.defaultSort = undefined;
      const dir = readLocalStorageForSorting(index);
      if (dir) {
        columnProps.defaultSort = dir;
      }
    }

    return columnProps;
  };

  const [mtColumns, setMtColumns] = useState<Array<MaterialTableExtendedColumn<T>>>(props.columns.map((c, i) => mapExtendedColumnToMTColumn(c, i)));
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);

  useEffect(onDataChangeLoadCachedColumns, [props.data]);

  useEffect(onDataChange, [props.data]);

  useEffect(() => {
    if (checkIfHasPresetFilters()) {
      setShowFilters(true);
    }
  }, []);

  function onDataChange() {
    if (props?.data != null) {
      setData(props.data);
      setFilteredData(props.data);
    }
  }

  function onDataChangeLoadCachedColumns() {
    // We can load preset column orders here from localstorage, if none then use default.
    let columns: Array<MaterialTableExtendedColumn<T>> = [];
    const localStorageColumnOrder = localStorage.getItem(`${_tableName}_order`);
    const localStorageColumnVisibility = localStorage.getItem(`${_tableName}_visibility`);

    if (localStorageColumnOrder) {
      columns = JSON.parse(localStorageColumnOrder);
      // We need to manually map the original render functions cause they dont serialize to json.
      assignColumnRenderFunctions(props.columns, columns);
    } else {
      columns = props.columns.map((c, i) => mapExtendedColumnToMTColumn(c, i));
    }

    if (localStorageColumnVisibility) {
      const columnState: visibility[] = JSON.parse(localStorageColumnVisibility);

      columnState.forEach((col: visibility) => {
        updateColumnVisiblity(col);
      });
    }

    setMtColumns(columns);
  }

  function assignColumnRenderFunctions(sourceColumns: MaterialTableExtendedColumn<T>[], jsonColumns: MaterialTableExtendedColumn<T>[]) {
    sourceColumns.forEach((sCol: any) => {
      if (sCol.render !== undefined) {
        const colId = sCol.field;
        const equivColumnId = jsonColumns.findIndex((x: any) => x.field === colId);

        if (equivColumnId !== -1) {
          jsonColumns[equivColumnId].render = sCol.render;
          // console.log('Assigned render function for ', jsonColumns[equivColumnId].field);
        }
      }
    });
  }

  const handleOnOrderChange = (columnId: number, dir: 'desc' | 'asc') => {
    updateLocalStorageForSorting(columnId, dir);
  };

  function updateColumnVisiblity(column: visibility) {
    const existingIndex = columnVisibilityState.map((i) => i.field).indexOf(column.field);

    if (existingIndex !== -1) {
      columnVisibilityState[existingIndex] = { field: column.field, hidden: column.hidden };
    } else {
      columnVisibilityState.push({ field: column.field, hidden: column.hidden });
    }

    setColumnVisibilityState([...columnVisibilityState]);
  }

  const handleOnVisibillityChanged = (column: Column<any>) => {
    updateColumnVisiblity({ field: column.field, hidden: column.hidden });

    const columnsToSave = JSON.stringify(columnVisibilityState);
    localStorage.setItem(`${_tableName}_visibility`, columnsToSave);
  };

  const handleOnColumnDragged = (sourceIndex: number, destinationIndex: number) => {
    const columns: Array<MaterialTableExtendedColumn<T>> = props.columns;

    // Shift all hidden columns to the end of the column list, otherwise it affects the sorting.
    for (let i = 0; i <= columns.length - 1; i++) {
      const c = columns[i];
      if (c.hidden === true) {
        arrayMove(columns, i, columns.length - 1);
      }
    }

    const cSource = columns[sourceIndex];
    const cDest = columns[destinationIndex];

    columns[sourceIndex] = cDest;
    columns[destinationIndex] = cSource;

    const jsonObject = JSON.stringify(columns);
    localStorage.setItem(`${_tableName}_order`, jsonObject);
    setMtColumns(columns);
  };

  function handleFilterChanged(tableFilters: Filter<any>[]) {
    // If we've removed all filters, then only update the state if it needs to be cleared.
    // otherwise we get infinite rerenders.
    if (tableFilters.length === 0) {
      if (cachedFilters.length !== 0) {
        setFilters([]);
      }
      return;
    }

    // Double check the data has changed, if not dont rerender the control
    let hasChanges = false;
    const localFilters = cachedFilters;

    tableFilters.forEach((f: any) => {
      const filterIndex = cachedFilters.map((x: any) => x.column.field).indexOf(f.column.field);

      // Check combobox filter changes.
      if (f.column.filter === 'LookupAuto' && filterIndex !== -1) {
        const incomingCheckboxFilters: any[] = f.value;
        const existingCheckboxFilters: any[] = cachedFilters[filterIndex].value;

        existingCheckboxFilters.forEach((ef: string) => {
          if (!incomingCheckboxFilters.includes(ef)) {
            hasChanges = true;
          }
        });
      }
      // Check datetime filter changes
      else if (f.column.filter === 'UniversalDate' && filterIndex !== -1) {
        const incomingFrom = f.value.from;
        const incomingTo = f.value.to;
        const existingFrom = cachedFilters[filterIndex].value.from;
        const existingTo = cachedFilters[filterIndex].value.to;

        if (incomingFrom?.getTime() !== existingFrom?.getTime()) hasChanges = true;
        if (incomingTo?.getTime() !== existingTo?.getTime()) hasChanges = true;
      }
      // Check textbox/other control changes
      else if (
        cachedFilters.length !== 0 &&
        filterIndex !== -1 &&
        f.column.field === cachedFilters[filterIndex].column.field &&
        f.value === cachedFilters[filterIndex].value
      ) {
        console.log('<Material Table>');
      } else {
        hasChanges = true;
      }
    });

    if (tableFilters.length < localFilters.length) {
      // We've set a filter to empty then the filters won't be the same length.
      hasChanges = true;
    }

    if (!hasChanges) {
      return;
    }

    const _filters: any[] = [];

    tableFilters.forEach((f: any) => {
      _filters.push({ column: f.column, operator: f.operator, value: f.value });
    });

    setFilters([..._filters]);
  }

  const handleOnSelectionChange = (data: T[], rowData?: T) => {
    if (data.length === 0) {
      setInDownloadMode(false);
    }
  };

  const deselectAll = () => (tableReference as any).current.onAllSelected(false);

  const formatSeconds = (seconds: number) => {
    if (seconds < 0) {
      return 'n/a';
    }
    const d1 = startOfToday();
    const d2 = addSeconds(d1, seconds);
    const hours = differenceInHours(d2, d1);
    const minutes = differenceInMinutes(d2, d1) - hours * 60;
    const _seconds = differenceInSeconds(d2, d1) - differenceInMinutes(d2, d1) * 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${_seconds.toString().padStart(2, '0')}`;
  };

  const exportExcel = async (columns: Array<MaterialTableExtendedColumn<T>>, data: T[]) => {
    const buildRowData = (row: T, column: MaterialTableExtendedColumn<T>) => {
      const val = getPropertyValueByPath(row, column.field);
      switch (column.type) {
        case 'date':
        case 'datetime':
          return new Date(val);
        case 'boolean':
          return val ? val : null;
        default:
          return val;
      }
    };

    const getColumnWidth = (column: MaterialTableExtendedColumn<T>) => {
      if (column.type === 'date' || column.type === 'datetime') {
        return 20;
      }
      if (column.isCollapsible) {
        return 100;
      }
      return undefined;
    };

    const workbook = new Excel.Workbook();
    const fugroLogoImage = workbook.addImage({
      base64: FugroLogoExcel,
      extension: 'png'
    });

    const clientLogoImage =
      clientLogo === undefined
        ? undefined
        : workbook.addImage({
            base64: clientLogo,
            extension: 'png'
          });

    const worksheet = workbook.addWorksheet(`${props.workpackName} ${props.owner}`, {
      headerFooter: { oddHeader: 'Fugro Sense.Structures', oddFooter: props.exportWatermark ? 'Draft ONLY' : '' },
      pageSetup: { fitToPage: true }
    });

    if (props.exportWatermark) {
      const watermarkImage = workbook.addImage({
        base64: DraftWatermark,
        extension: 'png'
      });
      worksheet.addBackgroundImage(watermarkImage);
    }

    // Make it so when exported the column order is preserved,
    // and that only visible columns are exported.
    const sortedAndVisibleColumns = columns.sort((a, b) => (a.tableData.columnOrder > b.tableData.columnOrder ? 1 : -1)).filter((c) => !c.hidden);

    worksheet.columns = sortedAndVisibleColumns.map((c) => ({
      header: c.title?.toString(),
      key: c.field?.toString(),
      width: getColumnWidth(c)
    }));

    worksheet.getRow(1).font = { size: 12, bold: true };
    worksheet.getRow(1).border = { bottom: { style: 'medium' } };
    data.forEach((row) => {
      const xlRow = worksheet.addRow(sortedAndVisibleColumns.map((c) => buildRowData(row, c)));
      let cell: Excel.Cell;
      let seconds: number;

      sortedAndVisibleColumns.forEach((c, i) => {
        switch (c.field) {
          case 'durationSeconds':
            cell = xlRow.getCell(c.field?.toString() ?? i);
            seconds = cell.value as number;
            cell.value = formatSeconds(seconds);
            break;
        }
        switch (c.type) {
          case 'date':
          case 'datetime':
            cell = xlRow.getCell(c.field?.toString() ?? i);
            cell.value = format(new Date(cell.value as Date), 'd/MM/yy\u00a0HH:mm');
            break;
        }
      });
    });

    // Handle the title row (images/sense.structures text) in row1.
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const columnsLength = sortedAndVisibleColumns.length;
    worksheet.insertRow(1, Array.from(''.repeat(columnsLength)));
    worksheet.getCell(1, 1).value = 'Sense.Structures';
    worksheet.getCell(1, 1).alignment = { horizontal: 'center' };
    worksheet.mergeCells(`A1:${alphabet[columnsLength - 1]}1`);
    worksheet.getRow(1).font = { size: 14, bold: true };
    worksheet.getRow(1).height = 30; // this unit isn't pixels

    worksheet.views = [{ zoomScale: 85, zoomScaleNormal: 100, state: 'normal' }];
    worksheet.addImage(fugroLogoImage, { tl: { col: 0, row: 0 }, ext: { width: 71, height: 32 } });
    if (clientLogoImage !== undefined && clientLogo !== undefined) {
      const imageDimensions = await getImageDimensions(clientLogo);
      if (imageDimensions !== undefined) {
        worksheet.addImage(clientLogoImage, {
          tl: { col: columnsLength - 1, row: 0 },
          ext: {
            width: (imageDimensions.width / imageDimensions.height) * 32,
            height: 32
          }
        });
      }
    }

    const xlsx = await workbook.xlsx.writeBuffer();
    const filename = `${props.workpackName} ${props.owner}.xlsx`;
    saveAs(new Blob([xlsx], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), filename);
  };

  const searchHook = (searchText: string) => {
    const tableRef = (tableReference as any).current;
    const originalData = data;
    const columns = tableRef.dataManager.columns;

    if (searchText === '') {
      setFilteredData(originalData);
      return;
    }

    const rows = originalData.filter((row: any) => {
      return columns.some((col: any) => {
        const value = tableRef.dataManager.getFieldValue(row, col);

        // if the value of the field isnt a boolean/checkbox/etc
        if (value !== undefined) {
          return value.toString().toUpperCase().includes(searchText.toUpperCase());
        }
        return false;
      });
    });

    setFilteredData(rows);
  };

  function exportOurPdf(cols: any[], data: any[]) {
    // Fix up any UTC times into a readable format.
    const dateColumns = cols.filter((c: any) => {
      return c.type === 'datetime';
    });
    dateColumns.forEach((dc) => {
      const colIndex = cols.indexOf(dc);
      data.forEach((row) => {
        const cellValue = row[colIndex];
        row[colIndex] = new Date(cellValue).toLocaleString();
      });
    });

    let finalData: any = data;

    if (data.length && !Array.isArray(data[0])) {
      if (typeof data[0] === 'object') {
        // Turn data into an array of string arrays, without the `tableData` prop
        // @ts-ignore
        finalData = data.map(({ tableData, ...row }) => Object.values(row));
      }
    }

    // Create the callback function to be used to generate the pdf's header images.
    const imgClient = 'data:image/png;base64,' + clientLogo;
    const headerFunction = () => {
      doc.addImage(imgClient, 'PNG', pageWidth - 71, 5, 71, 32);
      doc.addImage(FugroLogoExcel, 'PNG', 10, 5, 71, 32);
    };

    // Set up auto-table properties
    const content = {
      startY: 40,
      head: [cols.map((col: any) => col.title)],
      body: finalData,
      beforePageContent: headerFunction
    };

    const unit = 'pt';
    const size = 'A4';
    const orientation = 'landscape';
    const doc = new jsPDF(orientation, unit, size);
    // @ts-ignore
    const pageWidth = doc.getPageWidth();

    autoTable(doc, content);

    doc.save(`${props.workpackName} ${props.owner}` + '.pdf');
  }

  newProps = {
    ...props,
    data: filteredData,
    onChangeColumnHidden: handleOnVisibillityChanged,
    onOrderChange: handleOnOrderChange,
    onFilterChange: handleFilterChanged,
    onColumnDragged: handleOnColumnDragged,
    onSelectionChange: handleOnSelectionChange,
    style: {
      ...props.style,
      width: '100%',
      tableLayout: 'fixed'
    },
    options: {
      ...props.options,
      filtering: showFilters,
      columnsButton: true,
      padding: 'dense',
      sorting: true,
      thirdSortClick: false,
      emptyRowsWhenPaging: false,
      pageSizeOptions: [100, 500, 1000],
      headerStyle: {
        fontWeight: 'bold',
        borderBottom: `2px solid ${theme.palette.primary.main}`
      },
      exportMenu: [
        {
          label: 'PDF Export',
          exportFunc: (cols, datas) => {
            exportOurPdf(cols, datas);
          }
        },
        {
          label: 'Excel Export',
          exportFunc: (cols) => {
            exportExcel(cols, data);
          }
        }
      ],
      pageSize: parseInt(localStorage.getItem('rowsPerPage') ?? '100', undefined),
      selection: inDownloadMode
    },
    localization: {
      ...props.localization,
      toolbar: {
        ...props.localization?.toolbar,
        // @ts-ignore
        exportCSVName: 'Save as Excel'
      }
    },
    components: {
      ...props.components,
      Pagination: (p) => (
        <React.Fragment>
          <td className={classes.footer}>
            <div className={classes.toggleContainer}>
              <FormControlLabel
                label="Show All Comment Text"
                checked={showAllCommentText}
                onClick={handleShowAllCommentTextChange}
                control={<Switch />}
              />
            </div>
            <div className={classes.paginator}>
              <table>
                <tbody>
                  <tr>
                    <TablePagination {...p} />
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </React.Fragment>
      )
    },
    actions: [
      {
        icon: ExploreOff,
        tooltip: 'Reset Map Filter',
        isFreeAction: true,
        hidden: !props?.hasMapFilter,
        onClick: () => {
          if (props?.resetMapFilter) {
            props?.resetMapFilter();
          }
        }
      },
      {
        icon: 'filter_list',
        iconProps: { color: showFilters ? 'action' : 'disabled' },
        tooltip: 'Show Filters',
        isFreeAction: true,
        onClick: () => setShowFilters(!showFilters)
      },
      {
        icon: 'archive',
        iconProps: { color: inDownloadMode ? 'primary' : 'action' },
        tooltip: 'Enter Download mode',
        isFreeAction: true,
        hidden: !props.isDownloadable || process.env.REACT_APP_SHOW_DOWNLOAD !== 'true',
        onClick: () => {
          setInDownloadMode(!inDownloadMode);
        }
      },
      {
        icon: 'beenhere',
        tooltip: 'Download package',
        position: 'toolbarOnSelect',
        onClick: (_: any, data: T | T[]) => {
          if (!Array.isArray(data)) {
            data = [data];
          }
          if (props.onDownloadRequested) {
            props.onDownloadRequested(data);
          }
          deselectAll();
        }
      },
      {
        icon: 'cancel',
        tooltip: 'Cancel',
        position: 'toolbarOnSelect',
        onClick: () => {
          setInDownloadMode(false);
          deselectAll();
        }
      }
    ],
    onChangeRowsPerPage: handleChangeRowsPerPage,
    columns: mtColumns // props.columns// mtColumns
  };

  return <MaterialTable onSearchChange={searchHook} tableRef={tableReference} {...newProps} />;
};

// https://github.com/mbrn/material-table/blob/master/src/components/m-table-filter-row.js#L79
function DefaultFilter(props: FilterComponentProps) {
  useRunOnceSetupFiltersHook(props);

  return (
    <TextField
      style={props.columnDef.type === 'numeric' ? { float: 'right' } : {}}
      type={props.columnDef.type === 'numeric' ? 'number' : 'search'}
      value={props.columnDef.tableData.filterValue || ''}
      placeholder={props.columnDef.filterPlaceholder || ''}
      onChange={(e: any) => hOnFilterChanged(e, props)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FilterList />
          </InputAdornment>
        )
      }}
    />
  );
}

function BooleanFilter(props: FilterComponentProps) {
  useRunOnceSetupFiltersHook(props);

  return (
    <Checkbox
      indeterminate={props.columnDef.tableData.filterValue === undefined}
      checked={props.columnDef.tableData.filterValue === 'checked'}
      onChange={() => {
        let val;
        if (props.columnDef.tableData.filterValue === undefined) {
          val = 'checked';
        } else if (props.columnDef.tableData.filterValue === 'checked') {
          val = 'unchecked';
        }

        updateLocalStorageForFilters(props.columnDef.field, val);
        props.onFilterChanged(props.columnDef.tableData.id, val);
      }}
    />
  );
}

const DateFilterComponentDateOnDay = (props: FilterComponentProps) => {
  const classes = useStyles();
  let date = props.columnDef.tableData.filterValue ?? null;
  const handleDateChange = (d: any) => {
    const cField = props.columnDef.field;

    if (d === null) {
      date = null;
      props.onFilterChanged(props.columnDef.tableData.id, null);
      updateLocalStorageForFilters(cField, []);
      return;
    }
    if (isValid(d)) {
      date = d;
      props.onFilterChanged(props.columnDef.tableData.id, formatISO(d));
      updateLocalStorageForFilters(cField, formatISO(d));
    }
  };

  useRunOnceSetupFiltersHook(props);

  return (
    <React.Fragment>
      <KeyboardDatePicker
        InputProps={{
          className: classes.keyboardDatePickerInput
        }}
        KeyboardButtonProps={{ edge: 'end', size: 'small' }}
        clearable={true}
        value={date}
        onChange={handleDateChange}
        format="dd/MM/yy"
        disableFuture={true}
      />
    </React.Fragment>
  );
};

const DateTimeFilterComponentTimeBetweenStartEnd = (props: FilterComponentProps) => {
  let date = props.columnDef.tableData.filterValue ?? null;
  const classes = useStyles();

  const handleDateChange = (d: any) => {
    const cField = props.columnDef.field;

    if (d === null) {
      date = null;
      props.onFilterChanged(props.columnDef.tableData.id, null);
      updateLocalStorageForFilters(cField, []);
      return;
    }
    if (isValid(d)) {
      date = d;
      props.onFilterChanged(props.columnDef.tableData.id, formatISO(d));
      updateLocalStorageForFilters(cField, formatISO(d));
    }
  };

  useRunOnceSetupFiltersHook(props);

  return (
    <React.Fragment>
      <KeyboardDateTimePicker
        InputProps={{ className: classes.keyboardDateTimePickerInput }}
        KeyboardButtonProps={{ edge: 'end', size: 'small' }}
        ampm={false}
        clearable={true}
        value={date}
        onChange={handleDateChange}
        format="dd/MM/yy HH:mm"
        disableFuture={true}
      />
    </React.Fragment>
  );
};

const UniversalDateFilterComponent = (props: FilterComponentProps) => {
  let filterValue = props.columnDef.tableData.filterValue ?? null;

  const handleFilterChanged = (result: any) => {
    filterValue = result;
    const columnIndex = props.columnDef.tableData.id;
    const columnField = props.columnDef.field;
    updateLocalStorageForFilters(columnField, JSON.stringify(result));
    props.onFilterChanged(columnIndex, result);
  };

  useRunOnceSetupFiltersHook(props);

  return (
    <React.Fragment>
      <UniversalDatePicker value={filterValue} onChange={handleFilterChanged} />
    </React.Fragment>
  );
};

// parts were taken from the material table source code:
// https://github.com/mbrn/material-table/blob/master/src/components/m-table-filter-row.js#L36
const LookupFilter = (props: FilterComponentProps) => {
  const classes = useStyles();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const renderValue = (selecteds: any) => selecteds.map((selected: any) => props.columnDef.lookup[selected]).join(', ');

  const items = Object.keys(props.columnDef.lookup)
    .map((key) => (
      <MenuItem key={key} value={key} dense={true}>
        <Checkbox
          size="small"
          checked={props.columnDef.tableData.filterValue ? props.columnDef.tableData.filterValue.indexOf(key.toString()) > -1 : false}
        />
        <ListItemText primary={props.columnDef.lookup[key]} />
      </MenuItem>
    ))
    .sort((a: any, b: any) => (a.key > b.key ? 1 : -1));

  const clearFilter = () => {
    props.columnDef.tableData.filterValue = [];
    props.onFilterChanged(props.columnDef.tableData.id, []);
    updateLocalStorageForFilters(props.columnDef.field, []);
  };

  useRunOnceSetupFiltersHook(props);

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel htmlFor="select-multiple-checkbox">{props.columnDef.filterPlaceholder}</InputLabel>
      <Select
        multiple={true}
        value={props.columnDef.tableData.filterValue || []}
        onChange={(e) => hOnFilterChanged(e, props)}
        input={<Input id="select-multiple-checkbox" />}
        renderValue={renderValue}
        MenuProps={MenuProps}
        style={{ marginTop: 0 }}
        className={classes.lookupAutoSelect}
        startAdornment={
          <InputAdornment position="start">
            <IconButton size="small" onClick={clearFilter}>
              <Clear />
            </IconButton>
          </InputAdornment>
        }
      >
        {items}
      </Select>
    </FormControl>
  );
};

const LookupRadioFilter = (props: FilterComponentProps) => {
  const classes = useStyles();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const renderValue = (selected: any) => props.columnDef.lookup[selected] ?? '';

  const items = Object.keys(props.columnDef.lookup).map((key) => (
    <MenuItem key={key} value={key} dense={true}>
      <Radio
        size="small"
        name={props.columnDef.tableData.field + '-filter-radio'}
        checked={props.columnDef.tableData.filterValue ? props.columnDef.tableData.filterValue.indexOf(key.toString()) > -1 : false}
      />
      <ListItemText primary={props.columnDef.lookup[key]} />
    </MenuItem>
  ));

  const clearFilter = () => {
    props.columnDef.tableData.filterValue = [];
    props.onFilterChanged(props.columnDef.tableData.id, []);
    updateLocalStorageForFilters(props.columnDef.field, []);
  };

  useRunOnceSetupFiltersHook(props);

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel htmlFor="select-multiple-checkbox">{props.columnDef.filterPlaceholder}</InputLabel>
      <Select
        multiple={false}
        value={props.columnDef.tableData.filterValue || ''}
        onChange={(e) => hOnFilterChanged(e, props)}
        input={<Input id="select-multiple-checkbox" />}
        renderValue={renderValue}
        MenuProps={MenuProps}
        style={{ marginTop: 0 }}
        className={classes.lookupAutoSelect}
        startAdornment={
          <InputAdornment position="start">
            <IconButton size="small" onClick={clearFilter}>
              <Clear />
            </IconButton>
          </InputAdornment>
        }
      >
        {items}
      </Select>
    </FormControl>
  );
};

const dateFilterActionDateOnDay = <T extends object>(filterValue: any, rowData: any, columnDef: Column<T>) => {
  const value = rowData[columnDef.field];
  return isSameDay(parseISO(filterValue), new Date(value));
};

const dateTimeFilterActionTimeBetweenStartEnd = (filterValue: any, rowData: any) => {
  const startTime = setSeconds(new Date(rowData['startTime']), 0);
  let endTime = new Date(rowData['endTime']);
  if (!isValid(endTime)) {
    endTime = startTime;
  }

  endTime = setSeconds(endTime, 0);
  if (!isValid(startTime) || !(startTime <= endTime)) {
    return false;
  }

  return isWithinInterval(parseISO(filterValue), { start: startTime, end: endTime });
};

const universalDateFilterAction = <T extends object>(filterValue: any, rowData: any, columnDef: Column<T>) => {
  switch (filterValue.type) {
    case UniversalDatePickerValueType.OnDay: {
      if (!isValid(filterValue.from)) {
        return true;
      }
      const value = rowData[columnDef.field];
      return isSameDay(new Date(filterValue.from), parseISO(value));
    }
    case UniversalDatePickerValueType.AtTime: {
      if (!isValid(filterValue.from)) {
        return true;
      }
      const startTime = setSeconds(new Date(rowData['startTime']), 0);
      let endTime = new Date(rowData['endTime']);
      if (!isValid(endTime)) {
        endTime = startTime;
      }
      endTime = setSeconds(endTime, 59);
      if (!isValid(startTime) || !(startTime <= endTime)) {
        return false;
      }
      return isWithinInterval(setSeconds(new Date(filterValue.from), 30), { start: startTime, end: endTime });
    }
    case UniversalDatePickerValueType.InRange: {
      if (!isValid(filterValue.from) || !isValid(filterValue.to)) {
        return true;
      }
      const time = setSeconds(new Date(rowData['startTime']), 0);
      const filterFrom = set(new Date(filterValue.from), { hours: 0, minutes: 0, seconds: 0 });
      const filterTo = set(new Date(filterValue.to), { hours: 23, minutes: 59, seconds: 59 });
      return isWithinInterval(time, { start: filterFrom, end: filterTo });
    }
    default: {
      return true;
    }
  }
};

const buildLookupAuto = (columnDef: any, rootData: any) => {
  const ret = rootData
    .map((r: any) => getPropertyValueByPath(r, columnDef.field))
    .filter((o: any, i: any, s: string | any[]) => s.indexOf(o) === i)
    .reduce((acc: any, v: any) => {
      acc[v] = v;
      return acc;
    }, {});

  return ret;
};

const getPropertyValueByPath = (obj: any, propertyPath: any) => {
  if (!propertyPath) {
    return;
  }

  propertyPath = propertyPath.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  propertyPath = propertyPath.replace(/^\./, ''); // strip a leading dot
  const propertyPathSplit = propertyPath.split('.');
  for (let i = 0, n = propertyPathSplit.length; i < n; ++i) {
    const x = propertyPathSplit[i];
    if (obj && x in obj) {
      obj = obj[x];
    } else {
      return;
    }
  }
  return obj;
};

// ==== Saving to local storage functionality =====

function useRunOnceSetupFiltersHook(props: FilterComponentProps) {
  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    if (!init) {
      setOnFirstLoadFilters(props);
      setInit(true);
    }
  }, [props, init]);
}

function setOnFirstLoadFilters(props: FilterComponentProps) {
  const key = props.columnDef.field;
  const value: Filters | undefined = readLocalStorageForFilters();

  if (value) {
    let kv: any = value[key];
    if (props.columnDef.filter === 'UniversalDate') {
      try {
        kv = JSON.parse(kv);
        const result: UniversalDatePickerValue = {
          type: kv.type as UniversalDatePickerValueType,
          from: kv.from ? parseISO(kv.from) : undefined,
          to: kv.to ? parseISO(kv.to) : undefined
        };
        props.columnDef.tableData.filterValue = result;
        props.onFilterChanged(props.columnDef.tableData.id, result);
      } catch {
        props.columnDef.tableData.filterValue = undefined;
        props.onFilterChanged(props.columnDef.tableData.id, undefined);
      }
    } else {
      props.columnDef.tableData.filterValue = kv;
      props.onFilterChanged(props.columnDef.tableData.id, kv);
    }
  }
}

const hOnFilterChanged = (event: React.ChangeEvent<{ name?: string; value: unknown }>, props: any) => {
  const columnIndex = props.columnDef.tableData.id;
  const columnField = props.columnDef.field;
  const newValue = event.target.value;

  updateLocalStorageForFilters(columnField, newValue);
  props.onFilterChanged(columnIndex, newValue);
};

function updateLocalStorageForFilters(columnName: string, value: any) {
  const data = localStorage.getItem(_tableName);
  if (data) {
    const raw: Filters = JSON.parse(data);

    if (value?.length > 0) {
      // Save item to storage
      raw[columnName] = value;
    } else {
      // Clear the item from storage
      raw[columnName] = '';
    }

    localStorage.setItem(_tableName, JSON.stringify(raw));
  } else {
    const newTableObject: Filters = {};
    newTableObject[columnName] = value;
    localStorage.setItem(_tableName, JSON.stringify(newTableObject));
  }
}

function readLocalStorageForFilters() {
  const data = localStorage.getItem(_tableName);

  if (data) {
    const raw: Filters = JSON.parse(data);
    return raw;
  }

  return;
}

function updateLocalStorageForSorting(columnId: number, direction: any) {
  const localStorageKey = `${_tableName}_sort`;
  localStorage.setItem(localStorageKey, `${columnId}:${direction}`);
}

function readLocalStorageForSorting(columnId: number) {
  const localStorageKey = `${_tableName}_sort`;
  const data = localStorage.getItem(localStorageKey) ?? undefined;
  if (data === undefined) {
    return undefined;
  }
  if (!data.startsWith(columnId.toString())) {
    return undefined;
  }
  const direction = data.split(':')[1];
  if (direction === 'asc' || direction === 'desc') {
    return direction as 'asc' | 'desc' | undefined;
  } else {
    return undefined;
  }
}

const isLocalStorageForSortingDefined = () => {
  return localStorage.getItem(`${_tableName}_sort`) !== null;
};

function checkIfHasPresetFilters() {
  const data = localStorage.getItem(_tableName);

  if (data) {
    const raw: Filters = JSON.parse(data);
    for (const property in raw) {
      if (raw[property].length > 0) {
        return true;
      }
    }
  }

  return false;
}

function getImageDimensions(imageBase64: string): Promise<{ width: number; height: number }> {
  return new Promise<{ width: number; height: number }>(function (resolved) {
    const i = new Image();
    i.onload = function () {
      resolved({ width: i.width, height: i.height });
    };
    i.src = 'data:image/png;base64,' + imageBase64;
  });
}

type visibility = {
  field: string | number | symbol | undefined;
  hidden: boolean | undefined;
};

export default MaterialTableExtended;
