import { red, orange, indigo, green } from '@material-ui/core/colors';
import { MasterAnomalyStatus } from '../../utils/enumerations';
import { ComponentAnomalyComponent } from './Interfaces';

export function renderComponentCode(components: ComponentAnomalyComponent[]) {
  components = components.filter((x) => x); // Remove null components (happens when creating test anomalies and a component isnt set in SIMS.)
  return components.length === 1 ? components[0].componentCode : `${components[0].componentCode} (+${components.length - 1} components)`;
}

export function MAStatusColour(status: MasterAnomalyStatus) {
  switch (status) {
    case MasterAnomalyStatus.New:
      return red[500];
    case MasterAnomalyStatus.Rectify:
      return orange[600];
    case MasterAnomalyStatus.Monitor:
      return indigo[500];
    case MasterAnomalyStatus.Closed:
      return green[500];
  }
}

export function MAStatusText(status: MasterAnomalyStatus) {
  switch (status) {
    case MasterAnomalyStatus.New:
      return 'New';
    case MasterAnomalyStatus.Rectify:
      return 'Rectify';
    case MasterAnomalyStatus.Monitor:
      return 'Monitor';
    case MasterAnomalyStatus.Closed:
      return 'Closed';
  }
}
