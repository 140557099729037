import { useQuery } from '@apollo/react-hooks';
import { CircularProgress, createStyles, Divider, makeStyles, Typography } from '@material-ui/core';
import { gql } from 'apollo-boost';
import React, { Component, useEffect, useState } from 'react';
import { ComponentsQuery } from '../../MapUtils/MapQueries';
import SearchBar from './SearchBar';
import SearchTreeList from './SearchTreeList';

const useStyles = makeStyles(() =>
  createStyles({
    searchWrapper: {
      float: 'left',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    treeListWrapper: {
      width: '100%',
      height: '100%',
      borderRadius: '6px',
      color: 'white',
      marginTop: '5px',
      position: 'relative',
      overflowY: 'auto',
      overflowX: 'hidden',
      backgroundColor: '#2d3035',
      padding: '5px'
    },
    spinnerPanel: {
      height: '100%',
      boxShadow: 'none',
      position: 'relative',
      top: '0',
      left: '0',
      backgroundColor: 'transparent'
    },
    loadingContentWrapper: {
      color: 'white',
      textAlign: 'center',
      position: 'relative',
      top: '40%'
    },
    loadingTextWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      backgroundColor: 'transparent',
      color: 'white',
      marginTop: '15px'
    }
  })
);

export default function SearchCtainer(props: any): JSX.Element {
  const workpackId = props.workpackId;
  const classes = useStyles();
  const { data } = useQuery<{ workpack: Array<{ _componentTree: Component[] }> }>(
    gql`
      ${ComponentsQuery}
    `,
    { variables: { id: workpackId } }
  );

  // Loading conditions
  const [loading, setLoading] = useState<boolean>(true);
  const [focusedItem, setFocusedItem] = useState<string>('');

  useEffect(() => {
    if (data?.workpack) {
      setLoading(false);
    }
  }, [data]);

  if (loading) {
    return (
      <div className={classes.spinnerPanel}>
        <div className={classes.loadingContentWrapper}>
          <CircularProgress
            style={{
              color: 'white',
              fontSize: '3em',
              verticalAlign: 'middle',
              marginRight: '5px'
            }}
          />
          <div className={classes.loadingTextWrapper}>
            <Typography variant="subtitle1" component="h2">
              Fetching Components...
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  function interrupt(arg1: any) {
    props.setSearchItem(arg1);
    setFocusedItem(arg1);
  }

  return (
    <div className={classes.searchWrapper}>
      <SearchBar components={data?.workpack[0]._componentTree} getSearchItem={focusedItem} setSearchItem={interrupt} />
      <Divider />
      <div style={{ position: 'relative', width: '100%', height: '100%', marginBottom: '5px' }}>
        <div className={classes.treeListWrapper}>
          <SearchTreeList components={data?.workpack[0]._componentTree} setSearchItem={interrupt} />
        </div>
      </div>
    </div>
  );
}
