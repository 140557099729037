import { useQuery } from '@apollo/react-hooks';
import { IconButton, Tooltip } from '@material-ui/core'; // tslint:disable: jsx-no-lambda
// tslint:disable-next-line: no-submodule-imports
import { red } from '@material-ui/core/colors';
import { AssignmentOutlined, ErrorOutlineOutlined, LocationOnOutlined, PlayCircleFilledOutlined, PlayCircleOutline } from '@material-ui/icons';
import { gql } from 'apollo-boost';
import { addSeconds, differenceInHours, differenceInMinutes, differenceInSeconds, format, startOfToday } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { MapTooltipProps } from '../../../../types/params.type';
import { DownloadMediaItem, DownloadPackageDialog } from '../../../DownloadPackageDialog';
import ErrorMessage from '../../../ErrorMessage';
import MaterialTableExtended from '../../../MaterialTableExtended';
import { SkeletonTable } from '../../../skeletons/SkeletonTable';
import { useDatalessStyles } from '../../../styles/noDataStyles';
import { MediaViewerDialog } from '../MediaViewerDialog';
import { MultiChannelVideoDialog } from '../MultiChannelVideoDialog';

interface TaskListTabProps {
  workpackId: string;
  onSelectItem?: (id?: string) => void;
  filter: string;
  resetFilter: () => void;
}

export default function TaskListDetail(props: TaskListTabProps): JSX.Element {
  interface TaskListData {
    workpack: Array<{
      name: string;
      incident: TaskListItem[];
    }>;
  }

  interface TaskListItem {
    id: string;
    startTime: Date;
    endTime: Date;
    isVideoAvailable: boolean;
    isMultichannelVideoAvailable: boolean;
    // video: Array<{ channel: number, videoLogIds: string[] }>;
    isReviewed: boolean;
    durationSeconds: number;
    eventCode: { code: string; mode: 'TASK' | 'SYSTEM_TASK' | 'EVENT' | 'SYSTEM_EVENT' };
    subEventCode?: string;
    subEventCodeEnd?: string;
    component: { smartComponent: string; fullComponent: string };
    comment: string;
    workpackTasks: any;
    navigation: { start: { easting: number; northing: number; kp: number } };
    location: string;
    isAnomalous: boolean;
  }

  const datalessStyles = useDatalessStyles();
  const [focusedRow, setFocusedRow] = useState<TaskListItem>();
  const [focusedVideo, setFocusedVideo] = useState<TaskListItem>();
  const [focusedMultichannelVideo, setFocusedMultichannelVideo] = useState<TaskListItem>();
  const [dataSource, setDataSource] = useState<any[]>();
  const [isMapFiltering, setIsMapFiltering] = useState<boolean>(false);
  const [requestedDownloadItems, setRequestedDownloadItems] = useState<DownloadMediaItem[]>();

  const handleRowClick = (_event?: React.MouseEvent, rowData?: TaskListItem) => {
    // Removing focus of a row
    if (props.onSelectItem && focusedRow === rowData) {
      setFocusedRow(undefined);
      props.onSelectItem(undefined);
      return;
    }

    // Setting focus on a row
    if (props.onSelectItem && rowData) {
      props.onSelectItem(rowData.id);
    }

    setFocusedRow(rowData);
  };

  const renderStartTime = (a: TaskListItem) => format(new Date(a.startTime), 'd/MM/yy\u00a0HH:mm');

  const renderDuration = (a: TaskListItem) => {
    if (a.durationSeconds < 0) {
      return 'n/a';
    }
    const d1 = startOfToday();
    const d2 = addSeconds(d1, a.durationSeconds);
    const hours = differenceInHours(d2, d1);
    const minutes = differenceInMinutes(d2, d1) - hours * 60;
    const seconds = differenceInSeconds(d2, d1) - differenceInMinutes(d2, d1) * 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const renderAnomalous = (a: TaskListItem) => {
    return <div> {a.isAnomalous === true && <ErrorOutlineOutlined style={{ color: red[500] }} />}</div>;
  };

  const renderEventCodeMode = (a: TaskListItem) => {
    switch (a.eventCode.mode) {
      case 'EVENT':
      case 'SYSTEM_EVENT':
        return <LocationOnOutlined fontSize="small" color="action" titleAccess="Event" />;
      case 'TASK':
      case 'SYSTEM_TASK':
        return <AssignmentOutlined fontSize="small" color="action" titleAccess="Task" />;
      default:
        return null;
    }
  };

  const renderPlayMedia = (task: TaskListItem) => (
    <div
    // onMouseOver={_c => onPlayButtonHover(_c)}
    // onMouseLeave={_c => onPlayButtonLeave(_c)}
    >
      {task.isVideoAvailable ? (
        task.isMultichannelVideoAvailable ? (
          <IconButton size="small" onClick={(e) => handleOpenMultichannelViewer(task, e)}>
            <PlayCircleFilledOutlined fontSize="small" />
          </IconButton>
        ) : (
          <IconButton size="small" onClick={(e) => handleOpenViewer(task, e)}>
            <PlayCircleOutline fontSize="small" />
          </IconButton>
        )
      ) : null}
    </div>
  );

  const handleCloseViewer = () => {
    setFocusedVideo(undefined);
    setFocusedMultichannelVideo(undefined);
  };

  const handleOpenViewer = (task: TaskListItem, e: any) => {
    if (!e || !task) {
      return;
    }
    e.stopPropagation();
    setFocusedVideo(task);
  };

  const handleOpenMultichannelViewer = (task: TaskListItem, e: any) => {
    if (!e || !task) {
      return;
    }
    e.stopPropagation();
    setFocusedMultichannelVideo(task);
  };

  const { loading, error, data } = useQuery<TaskListData, { id: string }>(
    gql`
      query incidentList($id: ID) {
        workpack(id: $id) {
          name
          incident {
            id
            startTime
            endTime
            isVideoAvailable
            isMultichannelVideoAvailable
            isReviewed
            durationSeconds
            subEventCode
            subEventCodeEnd
            subEventCodeDescription
            eventCode {
              code
              description
              mode
            }
            component {
              smartComponent
              fullComponent
            }
            workpackTasks {
              id
            }
            navigation {
              start {
                easting
                northing
                kp
              }
            }
            comment
            location
            isAnomalous
          }
        }
      }
    `,
    { variables: { id: props.workpackId } }
  );

  useEffect(updateListFilters, [props.filter, data?.workpack[0]?.incident]);

  function updateListFilters() {
    let filter: Partial<MapTooltipProps> = {};
    let ds = data?.workpack[0]?.incident;

    if (!ds) {
      return;
    }

    // Create a new field for event's subcodes so the filter component automatically handles it.
    ds.forEach((incident: any) => {
      const eventCode = incident.eventCode;

      if (incident.subEventCode != null && incident.subEventCodeEnd != null) {
        eventCode.combinedCode = `${eventCode.code} (${incident.subEventCode}-${incident.subEventCodeEnd})`;
      } else if (incident.subEventCode != null) {
        eventCode.combinedCode = `${eventCode.code} (${incident.subEventCode})`;
      } else if (incident.subEventCodeEnd != null) {
        eventCode.combinedCode = `${eventCode.code} (${incident.subEventCodeEnd})`;
      } else {
        eventCode.combinedCode = eventCode.code;
      }
    });

    if (props.filter) {
      filter = JSON.parse(props.filter);
    }

    if (ds && filter && filter.IsComponent === false && filter.Filters) {
      setIsMapFiltering(true);
      const filterObject: { code: string; comp: string } = JSON.parse(filter.Filters);
      const tasks = ds.filter((inc) => inc.component.fullComponent === filterObject.comp && inc.eventCode.code === filterObject.code);
      ds = tasks;
    } else if (filter.IsComponent && filter?.Filters !== undefined) {
      // Update filter to only include componentcode items
      setIsMapFiltering(true);
      const component: string = filter?.Filters as string;
      ds = ds.filter((inc) => inc.component.fullComponent.includes(component));
    }

    setDataSource(ds);
  }

  function resetMapFilter() {
    props.resetFilter();
    setIsMapFiltering(false);
  }

  if (loading) {
    return <SkeletonTable />;
  }

  if (error) {
    return (
      <div className={datalessStyles.error}>
        <ErrorMessage message={error.message} />
      </div>
    );
  }

  const isAnyUnreviewed = () => data?.workpack[0]?.incident?.some((i) => !i.isReviewed);

  return (
    <React.Fragment>
      {requestedDownloadItems ? <DownloadPackageDialog items={requestedDownloadItems} onClose={() => setRequestedDownloadItems(undefined)} /> : null}
      <MaterialTableExtended
        owner={'Tasks'}
        hasMapFilter={isMapFiltering}
        resetMapFilter={resetMapFilter}
        workpackName={data?.workpack[0]?.name ?? ''}
        exportWatermark={isAnyUnreviewed()}
        isDownloadable={true}
        onDownloadRequested={(data: TaskListItem[]) => setRequestedDownloadItems(data.map((d) => ({ id: d.id, itemType: 'TASK_MEDIA' })))}
        columns={[
          {
            title: 'Start Time',
            field: 'startTime',
            type: 'datetime',
            searchable: true,
            defaultSort: 'asc',
            columnWidth: '10em',
            render: renderStartTime,
            filter: 'UniversalDate'
          },
          {
            title: 'Duration',
            field: 'durationSeconds',
            type: 'numeric',
            align: 'left',
            searchable: false,
            defaultSort: 'asc',
            columnWidth: '9em',
            render: renderDuration,
            filter: 'DateOnDay'
          },
          {
            title: 'Type',
            field: 'eventCode.mode',
            searchable: false,
            sorting: false,
            render: renderEventCodeMode,
            filter: 'EventOrTask',
            columnWidth: '10em',
            align: 'center',
            cellStyle: {
              paddingTop: 0,
              paddingBottom: 0
            }
          },
          {
            title: 'Media',
            field: 'isVideoAvailable',
            type: 'boolean',
            searchable: false,
            sorting: false,
            render: renderPlayMedia,
            filter: 'None',
            columnWidth: '2em',
            align: 'center',
            cellStyle: {
              paddingTop: 0,
              paddingBottom: 0
            }
          },
          {
            title: 'Reviewed',
            field: 'isReviewed',
            type: 'boolean',
            searchable: false,
            filter: 'Checkbox',
            columnWidth: '3em',
            align: 'center',
            hidden: true
            // hiddenByColumnsButton: true,
          },
          {
            title: 'Task Code',
            field: 'eventCode.combinedCode',
            columnWidth: '10em',
            filter: 'LookupAuto',
            render: (data: any) => {
              return (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    <h3>
                      {data.eventCode.description} {data.subEventCodeDescription ? `- ${data.subEventCodeDescription}` : ''}
                    </h3>
                  }
                >
                  <span>{data.eventCode.combinedCode}</span>
                </Tooltip>
              );
            }
          },
          {
            title: 'Component',
            field: 'component.smartComponent',
            columnWidth: '15em',
            isCollapsible: true,
            isRtlCollapsible: true,
            filter: 'TextEditor'
          },
          {
            title: 'Easting',
            field: 'navigation.start.easting',
            type: 'numeric',
            columnWidth: '5em',
            hidden: true
          },
          {
            title: 'Northing',
            field: 'navigation.start.northing',
            type: 'numeric',
            columnWidth: '5em',
            hidden: true
          },
          {
            title: 'KP',
            field: 'navigation.start.kp',
            type: 'numeric',
            columnWidth: '5em',
            hidden: true
          },

          {
            title: 'Comment',
            field: 'comment',
            columnWidth: '15em',
            isCollapsible: true,
            filter: 'TextEditor'
          },
          {
            title: 'Location',
            field: 'location',
            columnWidth: '15em',
            isCollapsible: true,
            filter: 'TextEditor'
          },
          {
            title: 'Anomalous',
            field: 'isAnomalous',
            render: renderAnomalous,
            type: 'boolean',
            columnWidth: '2em',
            cellStyle: {
              paddingTop: 0,
              paddingBottom: 0,
              textAlign: 'center'
            },
            filter: 'Checkbox'
          }
        ]}
        options={{
          rowStyle: (rowData) => ({
            backgroundColor: rowData?.id === focusedRow?.id ? 'rgb(237,237,237)' : '#fff',
            color: rowData?.isReviewed ? 'black' : 'rgb(200, 200, 200)'
          })
        }}
        title="Tasks and Events"
        data={dataSource ?? []}
        isLoading={loading}
        onRowClick={handleRowClick}
      />
      {focusedVideo ? <MediaViewerDialog itemType="TASK_MEDIA" id={focusedVideo?.id} onClose={handleCloseViewer} /> : null}
      {focusedMultichannelVideo ? <MultiChannelVideoDialog id={focusedMultichannelVideo?.id} onClose={handleCloseViewer} /> : null}
    </React.Fragment>
  );
}
