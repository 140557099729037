import { Card, colors, createStyles, Link, makeStyles, Table, TableCell, TableHead, TableRow, Theme, Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { MasterAnomalyStatus } from '../../../../../utils/enumerations';
import { Component, ComponentMasterAnomaly } from '../../../Interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    maGridStatusHeader: {
      fontWeight: 'bold',
      width: '5em'
    },
    maGridAnomalyCodeHeader: {
      width: '2em',
      textAlign: 'center',
      fontWeight: 'bold'
    },
    maGridCell: {
      width: '2em',
      textAlign: 'center',
      padding: 0
    }
  })
);

interface MasterAnomalySummaryProps {
  data: any;
  loading: boolean;
  setSelectedFilterMAStatus: any;
  setSelectedFilterCode: any;
  switchToAnomaliesTab: any;
}

export default function MasterAnomalySummary(props: MasterAnomalySummaryProps): JSX.Element {
  const loading = props.loading;
  const data = props.data;

  if (loading) {
    return (
      <p>
        <Card>
          <Skeleton variant="rect" height={150} />
        </Card>
      </p>
    );
  }

  if (data === undefined || data.components === undefined) {
    return <React.Fragment />;
  }

  const classes = useStyles();
  const mas = data.components
    .flatMap((c: Component) => c.masterAnomaly.flatMap((ma: ComponentMasterAnomaly) => ({ anomalyCode: ma.anomalyCode, status: ma.status })))
    .sort((a, b) => a.status - b.status);
  let allCodes = mas.map((m) => m.anomalyCode).filter((c, i, a) => a.indexOf(c) === i);
  if (allCodes.length === 0) {
    allCodes = [{ code: '', description: '' }];
  }
  const allStatuses = Object.keys(MasterAnomalyStatus)
    .filter((key) => typeof MasterAnomalyStatus[key] === 'number' && key !== 'Closed')
    .map((key) => ({ id: MasterAnomalyStatus[key], name: key }));
  const allMapped = mas.reduce((acc, v) => acc.set(v.status, [...(acc.get(v.status) || []), v.anomalyCode]), new Map());
  const codeCounts = Array.from(allMapped, ([key, value]) => [key, value])
    .flatMap((a) => a[1])
    .reduce((acc, v) => acc.set(v.code, acc.get(v.code) + 1 || 1), new Map());
  const maximumCount = Array.from(codeCounts, ([_, value]) => value).reduce((t, v) => t + v, 0);

  const getColorForPercentage = (pct: number) => {
    const percentColors = [
      { pct: 0.0, color: { r: 0, g: 200, b: 0 } },
      { pct: 1.0, color: { r: 255, g: 0, b: 0 } }
    ];

    let i = 1;

    for (i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }

    const lower = percentColors[i - 1];
    const upper = percentColors[i];
    const range = upper.pct - lower.pct;
    const rangePct = (pct - lower.pct) / range;
    const pctLower = 1 - rangePct;
    const pctUpper = rangePct;
    const color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  };

  const buildCell = (key: React.Key, status: number, anomalyCode: any) => {
    const cellLinkOnClick = (status: number, anomalyCode: string) => {
      props.setSelectedFilterCode(anomalyCode);
      props.setSelectedFilterMAStatus(status);
      props.switchToAnomaliesTab();
    };

    const c = allMapped.has(status) ? allMapped.get(status).filter((v: any) => v.code === anomalyCode.code).length : 0;
    const colour = c === 0 ? colors.common.white : c === 1 ? 'rgb(0,200,0)' : getColorForPercentage(c / maximumCount);
    return (
      <TableCell
        className={classes.maGridCell}
        style={{ backgroundColor: colour, color: c === 0 ? colors.common.black : colors.common.white }}
        key={key}
      >
        <Link component="button" onClick={() => cellLinkOnClick(status, anomalyCode)}>
          {c === 0 ? '' : c}
        </Link>
      </TableCell>
    );
  };

  const uniqueKey = (value, index, self) => self.findIndex((x) => x.code == value.code) == index;

  return (
    <p>
      <Card>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.maGridStatusHeader}>&nbsp;</TableCell>
              {allCodes.filter(uniqueKey).map((anomalyCode, i) => (
                <Tooltip key={i} title={anomalyCode.description}>
                  <TableCell className={classes.maGridAnomalyCodeHeader}>{anomalyCode.code}</TableCell>
                </Tooltip>
              ))}
              <TableCell style={{ width: '100%' }} />
            </TableRow>
            {allStatuses.map((status, i) => (
              <TableRow key={i}>
                <TableCell className={classes.maGridStatusHeader}>{status.name}</TableCell>
                {allCodes.filter(uniqueKey).map((anomalyCode, i) => buildCell(i, status.id, anomalyCode))}
                <TableCell style={{ width: '100%' }} />
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </Card>
    </p>
  );
}
