// tslint:disable: jsx-no-lambda
import { createStyles, makeStyles, Paper, TextField, Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState, useEffect } from 'react';
import { Component } from '../../MapUtils/SharedTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#2d3035'
      // width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      opacity: 1,
      backgroundColor: '#2d3035',
      color: 'white'
    },
    iconButton: {
      color: 'white',
      padding: 10
    },
    dropdownButton: {
      color: 'white'
    },
    divider: {
      height: 28,
      margin: 4
    },
    inputOveride: {
      color: 'white'
    }
  })
);

export default function SearchBar(props: any): JSX.Element {
  const classes = useStyles();
  const components: Component[] = props.components;
  const focusedItemGetter = props.getSearchItem;
  const [searchValue, setSearchValue] = useState<any>(null);

  useEffect(onFocusedComponentChanged, [focusedItemGetter]);

  function onFocusedComponentChanged() {
    if (!components) return;

    const focusedComp = focusedItemGetter ? components.filter((c) => c.id === focusedItemGetter.id)[0] : []; // focusedItemGetter is null if multiple selected.
    if (focusedComp) {
      setSearchValue(focusedComp);
    } else {
      setSearchValue(null);
    }
  }

  function idLookup(_: any, componentName: any) {
    const focusedId = components.find((x: any) => x.fullComponent === componentName)?.id;

    if (componentName === '' || !focusedId) {
      props.setSearchItem('');
      return;
    }

    props.setSearchItem(focusedId);
  }

  function keydownHook(arg1: any) {
    if (arg1.key === 'Enter') {
      arg1.preventDefault();
    }
  }

  return (
    <Paper component="form" className={classes.root}>
      <Autocomplete
        id="component-search"
        options={components ?? []}
        style={{ width: '100%' }}
        clearOnEscape={true}
        classes={{ input: classes.inputOveride, clearIndicator: classes.iconButton, popupIndicator: classes.dropdownButton }}
        // onInputChange={_.debounce((value) => test(value), 1000)}
        value={searchValue}
        onKeyDown={keydownHook}
        onInputChange={(event, newInputValue) => {
          idLookup(event, newInputValue);
        }}
        getOptionLabel={(option) => {
          // if (Object.keys(option).length === 0)
          //   return 'Search Components...';
          return option?.fullComponent;
        }}
        // getOptionLabel={option => {
        //   if (option?.fullComponent) {
        //     return option.fullComponent;
        //   } else {
        //     return "None";
        //   }
        // }}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.input}
            InputLabelProps={{ style: { color: 'white' } }}
            style={{ marginLeft: '0px' }}
            label="Search Components..."
            variant="outlined"
            fullWidth={true}
          />
        )}
      />
    </Paper>
  );
}
