import { Box, Typography } from '@material-ui/core';
import { ErrorOutlineOutlined } from '@material-ui/icons';
import React from 'react';

const ErrorMessage = (props: { message: string }) => (
  <Box style={{ width: '100%', textAlign: 'center' }}>
    <ErrorOutlineOutlined />
    <Typography variant="h5">An error occurred.</Typography>
    <Typography variant="body2">{props.message}</Typography>
  </Box>
);

export default ErrorMessage;
