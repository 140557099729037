import { createStyles, Fade, makeStyles, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: '#181b20',
      position: 'absolute',
      left: '40%',
      bottom: '10px',
      maxHeight: '200px',
      zIndex: 999999,
      marginTop: '4px',
      borderRadius: '2px',
      boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3)',
      padding: '14px',
      minWidth: '25px',
      lineHeight: '19px',
      overflow: 'hidden',
      border: '1px solid white'
    },
    header: {
      opacity: 0.87
    },
    content: {
      opacity: 0.54
    },
    text: {
      fontSize: '12px',
      color: '#fff'
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      cursor: 'pointer',
      color: 'white'
    }
  })
);

export default function MeasureModel(props: { open: boolean; onClose?: any }): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const styles = useStyles();

  useEffect(() => {
    setIsOpen(props.open);
  }, [props.open]);

  const hOnCloseClick = () => {
    if (props.onClose) {
      props.onClose();
    }
    setIsOpen(false);
  };

  return (
    <Fade in={isOpen}>
      <div className={styles.container}>
        <Close className={styles.closeButton} onClick={hOnCloseClick} />
        <Typography className={clsx(styles.text, styles.header)}>Measure distance &amp; bearing</Typography>
        <div className={clsx(styles.text, styles.content)}>
          Click on the map to trace a line you want to measure.
          <br />
          Press &lsquo;ESC&rsquo; to clear.
        </div>
      </div>
    </Fade>
  );
}
