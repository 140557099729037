import { CompositeLayer } from '@deck.gl/core';
import { IconLayer, TextLayer } from '@deck.gl/layers';
import { calculateMarkerColor, calculateTaskStatusDeckGl } from '../../../map/MappingUtils';
import Supercluster from 'supercluster';
import { getClusterData } from '../extras/Functions';

export default class TextClusterLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale || props.data.length !== oldProps.data.length;

    if (rebuildIndex) {
      const index = new Supercluster({ maxZoom: 21, radius: props.sizeScale });
      index.load(
        props.data.map((d) => ({
          geometry: { coordinates: props.getPosition(d) },
          properties: d
        }))
      );
      this.setState({ index });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z
      });
    }
  }

  getPickingInfo({ info, mode }) {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      // if (pickedObject.cluster && mode !== 'hover') {
      if (pickedObject.cluster && mode) {
        info.objects = this.state.index.getLeaves(pickedObject.cluster_id, Infinity).map((f) => f.properties);
      }
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers() {
    const { data } = this.state;
    const { iconAtlas, iconMapping } = this.props;

    return [
      new IconLayer({
        id: 'icon-layer',
        data,
        iconAtlas,
        iconMapping,
        sizeScale: 8, // 7.5
        visible: this.props.visible,
        getPosition: (d) => d.geometry.coordinates,
        getPixelOffset: [0, -25],
        getIcon: (d) => 'marker',
        // {
        //     const { index } = this.state;
        //     let clusterData = getClusterData(d, index);
        //     let markerType = clusterDataHasAnomaliesDeckGl(clusterData) ? 'marker-anomaly' : 'marker'
        //     return markerType;
        // },
        getSize: 5,
        getColor: (d) => {
          const { index } = this.state;
          let clusterData = getClusterData(d, index);
          let status = calculateTaskStatusDeckGl(clusterData);
          let clusterColor = calculateMarkerColor(status);
          return clusterColor;
        }
      }),
      new TextLayer({
        id: 'text-layer',
        data,
        pickable: true,
        getPixelOffset: [0, -28],
        getPosition: (d) => d.geometry.coordinates,
        // [d.geometry.coordinates.x, d.geometry.coordinates.y, 15], //d.geometry.coordinates,
        getText: (d, f) => {
          const { index } = this.state;
          let clusterData = getClusterData(d, index);
          return `${clusterData.length}`;
        },
        fontFamily: 'sans-serif',
        fontSettings: { sdf: false },
        getColor: [255, 255, 255],
        // fontWeight: 600,
        getSize: 16,
        visible: this.props.visible,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center'
      })
    ];
  }
}
