import { Card, Grid } from '@material-ui/core';
import React from 'react';
import { SelectedTab } from '../../../Interfaces';
import HeaderTiles from './HeaderTiles';
import MasterAnomalySummary from './MasterAnomalySummary';
import AnomalyOpenStatePieGraph from './AnomalyOpenStatePieGraph';
import AnomalyDurationBarGraph from './AnomalyDurationBarGraph';
import { MasterAnomalyStatus } from '../../../../../utils/enumerations';

interface CurrrentStateTabProps {
  data: any;
  loading: boolean;
  setSelectedTab: (tabIndex: number) => void;
  selectedFilterMAStatus: any;
  setSelectedFilterMAStatus: (arg: MasterAnomalyStatus | null) => void;
  selectedFilterCode: string | null;
  setSelectedFilterCode: any;
}

export default function CurrentStateTab(props: CurrrentStateTabProps): JSX.Element {
  const data = props.data;
  const setSelectedTab = props.setSelectedTab;
  const loading = props.loading;
  const selectedFilterMAStatus = props.selectedFilterMAStatus;
  const setSelectedFilterMAStatus = props.setSelectedFilterMAStatus;
  const setSelectedFilterCode = props.setSelectedFilterCode;

  return (
    <div style={{ height: '70vh', maxHeight: '70vh' }}>
      <HeaderTiles
        data={data}
        loading={loading}
        selectedFilterMAStatus={selectedFilterMAStatus}
        setSelectedFilterMAStatus={setSelectedFilterMAStatus}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} xl={12}>
          <MasterAnomalySummary
            loading={loading}
            data={data}
            setSelectedFilterMAStatus={setSelectedFilterMAStatus}
            setSelectedFilterCode={setSelectedFilterCode}
            switchToAnomaliesTab={() => setSelectedTab(SelectedTab.Anomalies)}
          />
        </Grid>
      </Grid>
      <Grid container justify="flex-start" alignItems="center" spacing={1}>
        <Grid item>
          <Card
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSelectedTab(SelectedTab.Anomalies);
            }}
          >
            <AnomalyOpenStatePieGraph
              loading={loading}
              anomalies={data?.components.flatMap((c: any) => {
                return c.anomaly;
              })}
            />
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <AnomalyDurationBarGraph
              loading={loading}
              anomalies={data?.components.flatMap((c: any) => {
                return c.anomaly;
              })}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
