import { useQuery } from '@apollo/react-hooks';
import { AppBar, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Theme, Toolbar, Typography } from '@material-ui/core';
import { HomeOutlined } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { gql } from 'apollo-boost';
import { SignOut } from 'aws-amplify-react';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import FugroLogo from '../../images/fugro-logo-white.svg';
import { UiRoutes } from '../../routes/Routes';
import { useGlobalState } from '../../store/globalState';
import { authenticatorTheme } from '../../styles/FugroColors';

const clientLogoQuery = `query fetchClientImage($clientImagesOnly: Boolean) {
  icon(clientImagesOnly: $clientImagesOnly) {
  	thumbnail
  }
}`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    hide: {
      display: 'none'
    },
    appBarLogo: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
      height: '1.5em'
    },
    title: {
      flexGrow: 1
    }
  })
);

export function RenderAppBar(): JSX.Element {
  const [pageTitle] = useGlobalState('globalPageTitle');
  const [, setClientLogo] = useGlobalState('globalClientLogo');
  const classes = useStyles();
  const { data } = useQuery<{ icon: Array<{ thumbnail: string }>; hasAcceptedEula: boolean }, { clientImagesOnly: boolean }>(
    gql`
      ${clientLogoQuery}
    `,
    { variables: { clientImagesOnly: true } }
  );

  useEffect(onDataChangeSetClientImage, [data]);

  function onDataChangeSetClientImage() {
    if (data?.icon[0]?.thumbnail) {
      setClientLogo(data.icon[0].thumbnail);
    }
  }

  function getClientLogo() {
    if (data?.icon[0]?.thumbnail) {
      return (
        <IconButton color="inherit" edge="end">
          <img src={`data:image/png;base64,${data.icon[0].thumbnail}`} className={classes.appBarLogo} alt="ClientLogo" />
        </IconButton>
      );
    }

    return <div />;
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton color="inherit" edge="start" component={Link} to={UiRoutes.root}>
          <HomeOutlined />
        </IconButton>
        <IconButton color="inherit" edge="start" component={Link} to={UiRoutes.root}>
          <img src={FugroLogo} className={classes.appBarLogo} alt="FugroLogo" />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {pageTitle}
        </Typography>
        {process.env.REACT_APP_AUTH_REQUIRED ? <SignOut theme={authenticatorTheme} /> : null}
        {getClientLogo()}
      </Toolbar>
    </AppBar>
  );
}
