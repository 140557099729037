import Rule from './Rule';
import { PickingColor } from './Constants';

export default class ColorSchema {
  private _Rules: Array<Rule> = [];
  public CurrentMesh: any = null;

  public IsValid() {
    return this._Rules.length !== 0;
  }

  public AddRule(rule: Rule) {
    rule.Parent = this;
    this._Rules.push(rule);
  }

  public RemoveRule(ruleToFind: Rule) {
    const index = this._Rules.findIndex((rule: Rule) => rule.Name === ruleToFind.Name);

    if (index !== -1) this._Rules.splice(index, 1);
  }

  public UpdateMesh(mesh: any): void {
    let isApplied = false;
    this.CurrentMesh = mesh;

    this._Rules.forEach((rule: Rule) => {
      if (isApplied) return;

      if (rule.Condition() === true) {
        rule.ApplyTransformation(mesh);
        mesh.currentHex = undefined;
        isApplied = true;
      }
    });
  }
}
