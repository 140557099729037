import { useQuery } from '@apollo/react-hooks';
import { Box, CircularProgress, createStyles, Link, makeStyles, Table, TableBody, TableCell, TableRow, Theme, Typography } from '@material-ui/core';
import { gql } from 'apollo-boost';
import { format } from 'date-fns';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { ExpandingPanel } from '../ExpandingPanel/ExpandingPanel';
import { MediaLink } from '../MediaLink';
import { MediaThumb } from '../MediaThumb';
import { MediaViewerDialog } from '../MediaViewerDialog';
import ExpandingPanelErrorText from '../ExpandingPanel/ExpandingPanelErrorText';
import { ExtractDimensionsFromBase64JpegArray, determineAverageDimension, extraDimensionsFromBase64Jpeg } from '../../../../utils/imageUtils';

export interface TaskPanelProps {
  taskId: string;
}

export const TaskListInfoPanel = (props: TaskPanelProps) => {
  const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
      zeroSpaceImage: {
        position: 'relative',
        marginLeft: '35%',
        marginTop: '50%',
        opacity: 0.2
      },
      zeroSpaceText: {
        textAlign: 'center',
        opacity: 0.5
      },
      card: {
        marginTop: _theme.spacing(0.5),
        marginBottom: _theme.spacing(0.5)
      },
      cardHeader: {
        marginTop: -_theme.spacing(1),
        marginBottom: -_theme.spacing(1)
      },
      topBox: {
        marginBottom: _theme.spacing(1)
      },
      tableRowTitle: {
        width: '40%'
      },
      imageSlider: {
        height: '240px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.4,
        overflow: 'hidden'
      },
      spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      },
      accordian: {
        backgroundColor: '#011E41',
        color: '#FFFFFF',
        cursor: 'pointer',
        padding: '18px',
        width: '100%',
        textAlign: 'left',
        fontWeight: 'bold',
        border: 'none',
        outline: 'none',
        transition: '0.4s',
        '&:hover': {
          backgroundColor: '#011E64'
        }
      },
      panel: {
        padding: '0 18px',
        backgroundColor: 'white',
        display: 'none',
        overflow: 'hidden'
      },
      carouselContainer: {
        width: '100%',
        textAlign: 'center',
        overflow: 'hidden'
      }
    })
  );
  interface TaskDetailData {
    component: {
      fullComponent: string;
    };
    eventCode: {
      code: string;
      description: string;
    };
    data: Array<{
      id: string;
      parameterName: string;
      value: string;
    }>;
    media: Array<{
      id: string;
      thumbnail: string;
      startTime: Date;
      filename: string;
    }>;
    document: Array<{
      id: string;
      filename: string;
      documentType: string;
      revision: number;
    }>;
    comment: string;
  }

  const [selectedMedia, setSelectedMedia] = useState<string>();
  const classes = useStyles();

  const { loading, error, data } = useQuery<{ incident: TaskDetailData }, { id: string }>(
    gql`
      query incidentDetail($id: ID!) {
        incident(id: $id) {
          component {
            fullComponent
          }
          eventCode {
            code
            description
          }
          data {
            id
            parameterName
            value
          }
          media {
            id
            startTime
            thumbnail
            filename
          }
          comment
          document {
            id
            filename
            documentType
          }
        }
      }
    `,
    { variables: { id: props.taskId } }
  );

  if (loading) {
    return (
      <div style={{ textAlign: 'center', paddingTop: '5em' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <ExpandingPanelErrorText message={error.message} />;
  }

  if (data === undefined) {
    return <ExpandingPanelErrorText message={'No data available for this task!'} />;
  }

  let averageDimension = { width: 0, height: 0 };

  if (data.incident.media) {
    const dimensions = ExtractDimensionsFromBase64JpegArray(data.incident.media);
    if (dimensions.length !== 0) {
      averageDimension = determineAverageDimension(dimensions);
    } else {
      console.log('Failed determining average thumbnail dimensions.');
    }
  }

  const mediaThumbs = data.incident.media?.map((am: any) => (
    <div key={am.id} style={{ width: '100%', textAlign: 'center' }}>
      <MediaThumb
        thumbnail={am.thumbnail}
        mediaId={am.id}
        onSelect={setSelectedMedia}
        ignoreWidth={extraDimensionsFromBase64Jpeg(am.thumbnail).height > averageDimension.height ?? false}
      />
    </div>
  ));

  const mediaTableRows = data.incident.media?.map((am) => (
    <TableRow key={am.id}>
      <TableCell>{format(new Date(am.startTime), 'd/MM/yy HH:mm')}</TableCell>
      <TableCell>
        <MediaLink mediaId={am.id} text={am.filename} onSelect={setSelectedMedia} />
      </TableCell>
    </TableRow>
  ));

  const documentTableRows = data.incident.document?.map((am) => (
    <TableRow key={am.id}>
      <TableCell>{am.revision}</TableCell>
      <TableCell>
        <Link component="button" variant="body2" target="_new" href={`/report/redirect?documentId=${am.id}`}>
          {am.filename}
        </Link>
      </TableCell>
    </TableRow>
  ));

  const handleCloseViewer = () => setSelectedMedia(undefined);

  const renderTableRow = (title: string, content: any, key?: any) => (
    <TableRow key={key}>
      <TableCell className={classes.tableRowTitle}>
        <b>{title}</b>
      </TableCell>
      <TableCell>{content}</TableCell>
    </TableRow>
  );

  return (
    <React.Fragment>
      <Box className={classes.topBox}>
        <Typography variant="body1">{data.incident.component.fullComponent}</Typography>
        <Typography variant="h5">
          {data.incident.eventCode.code}: {data.incident.eventCode.description}
        </Typography>
      </Box>

      <Box>
        {data.incident.media?.length > 0 ? (
          <Carousel autoPlay={false} key={props.taskId} animation="slide">
            {mediaThumbs}
          </Carousel>
        ) : null}
      </Box>

      {data.incident.data && data.incident.data.length > 0 ? (
        <ExpandingPanel owner="TaskListInfoPanel" title="Task Data" edgeToEdge={true}>
          <Table size="small">
            <TableBody>{data.incident.data.map((dd) => renderTableRow(dd.parameterName, dd.value, dd.id))}</TableBody>
          </Table>
        </ExpandingPanel>
      ) : null}

      {data.incident.comment && data.incident.comment.length > 0 ? (
        <ExpandingPanel owner="TaskListInfoPanel" title="Comments">
          <div>{data.incident.comment}</div>
        </ExpandingPanel>
      ) : null}

      {data.incident.media && data.incident.media.length > 0 ? (
        <ExpandingPanel owner="TaskListInfoPanel" title="Media" edgeToEdge={true}>
          <Table size="small">
            <TableBody>{mediaTableRows}</TableBody>
          </Table>
        </ExpandingPanel>
      ) : null}

      {data.incident.document && data.incident.document.length > 0 ? (
        <ExpandingPanel owner="TaskListInfoPanel" title="Documents" edgeToEdge={true}>
          <Table size="small">
            <TableBody>{documentTableRows}</TableBody>
          </Table>
        </ExpandingPanel>
      ) : null}

      {selectedMedia ? (
        <MediaViewerDialog
          itemType="IMAGE_GRAB"
          id={selectedMedia}
          allMediaItems={data.incident.media.map((m) => m.id)}
          onClose={handleCloseViewer}
        />
      ) : null}
    </React.Fragment>
  );
};
