export enum ColoringMethod {
  Normal,
  Anomalies,
  TaskProgress,
  Heatmap
}

export const PickingColor = 0xff00ff; // Magenta

export interface LegendItem {
  name: string;
  color: string;
}

export const anomalyLegend: Array<LegendItem> = [
  { name: 'No Anomalies', color: '#00FF00' },
  { name: 'Anomalous', color: '#FF0000' },
  { name: 'Master Anomalies', color: '#FF8D00' },
  { name: 'Unmapped Component', color: '#B8B8B8' }
];

export const taskLegend: Array<LegendItem> = [
  { name: 'Tasks Completed', color: '#00FF00' },
  { name: 'Tasks in Progress', color: '#FF5E00' },
  { name: 'Not Started', color: '#FF0000' },
  { name: 'Unmapped Component', color: '#B8B8B8' }
];
