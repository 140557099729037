import { createStyles, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, makeStyles, Theme, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

interface PanelState {
  [header: string]: boolean;
}

interface ExpandingPanelProps {
  title: string;
  owner: string;
  edgeToEdge?: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expansionPanel: {},
    expansionPanelSummary: {
      backgroundColor: theme.palette.primary.dark,
      color: 'white',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      minHeight: 0
    },
    expansionPanelSummaryExpanded: {
      marginTop: '0 !important',
      marginBottom: '0 !important'
    },
    expansionPanelTitle: {
      margin: 0,
      padding: 0
    },
    expansionPanelContent: {
      paddingLeft: 16,
      paddingRight: 16
    },
    expansionPanelContentEdgeToEdge: {
      padding: 0
    }
  })
);

export const ExpandingPanel = (props: ExpandingPanelProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(loadCachedPanelState, [props.owner]);

  function onChange() {
    const data = localStorage.getItem(props.owner);
    const isExpanded = !expanded;

    if (data) {
      const raw: PanelState = JSON.parse(data);
      raw[props.title] = isExpanded;
      localStorage.setItem(props.owner, JSON.stringify(raw));
    } else {
      const newPanelStateObject: PanelState = {};
      newPanelStateObject[props.title] = isExpanded;
      localStorage.setItem(props.owner, JSON.stringify(newPanelStateObject));
    }

    setExpanded(isExpanded);
  }

  function loadCachedPanelState() {
    if (props.owner) {
      const item = localStorage.getItem(props.owner);
      if (item) {
        const parsed: PanelState = JSON.parse(item);
        if (parsed) {
          setExpanded(parsed[props.title]);
        }
      }
    }
  }

  return (
    <ExpansionPanel expanded={expanded} onClick={onChange}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMore htmlColor="white" />}
        classes={{ root: classes.expansionPanelSummary, expanded: classes.expansionPanelSummaryExpanded }}
      >
        <Typography variant="h6" className={classes.expansionPanelTitle}>
          {props.title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        classes={{
          root: props.edgeToEdge ? classes.expansionPanelContentEdgeToEdge : classes.expansionPanelContent
        }}
      >
        {props.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
