import { Button, ButtonGroup, Card, makeStyles, Typography, createStyles, Box } from '@material-ui/core';
import MaterialTable, { Column, Options } from '@material-table/core';
import React, { useState } from 'react';
import { Component, ComponentIncident, IncidentData, ParameterType } from '../../../Interfaces';
import { Pie, Bar } from 'react-chartjs-2';
import { chartColors } from '../../../../../styles/FugroColors';
import { theme } from '../../../../..';
import { format } from 'date-fns';
import { ChartDataSets } from 'chart.js';

interface TaskTrendsTabProps {
  data: any;
  loading: boolean;
  setSelectedComponent: Function;
}

interface Task {
  code: string;
  parameters: Array<Parameter>;
}

interface Parameter {
  taskCode: string;
  parameterName: string;
  parameterType: ParameterType;
  decimalPlaces: number;
  count: number;
  maximum: number;
  minimum: number;
  average: number;
}

interface DataPoint {
  time: Date;
  workpackName: string;
  fullComponent: string;
  taskCode: string;
  parameterName: string;
  parameterType: ParameterType;
  value: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    sourceButtonSelected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main)
    }
  })
);

let focusedParameterKey = '';

export default function TaskTrendsTab(props: TaskTrendsTabProps): JSX.Element {
  const [dataCategoryType, setDataCategoryType] = useState<string>('Year');
  const [focusedParameter, setFocusedParameter] = useState<Parameter>();
  const [refreshKey, setRefreshKey] = useState<number>();
  const classes = useStyles();

  const data = props.data;
  const loading = props.loading;

  const incidents: Array<ComponentIncident> = data?.components?.flatMap((c: Component) => c.incident);

  const tasks = Array<Task>();

  const focusedParamChanged = (_: any, item: Parameter | undefined) => {
    setFocusedParameter(item);
    focusedParameterKey = item ? `${item.taskCode},${item.parameterName}` : '';
  };

  if (!loading && incidents) {
    for (const incident of incidents) {
      const validData = incident.data;

      if (validData.length < 1) {
        continue;
      }

      if (!tasks.some((t) => t.code == incident.eventCode.code)) {
        tasks.push({ code: incident.eventCode.code, parameters: [] } as Task);
      }

      const task = tasks.find((t) => t.code == incident.eventCode.code);

      for (const data of validData) {
        if (!task?.parameters.some((p) => p.parameterName == data.parameterName && p.parameterType == data.parameterType)) {
          task?.parameters.push({
            taskCode: task.code,
            parameterName: data.parameterName,
            parameterType: data.parameterType,
            decimalPlaces: data.decimalPlaces
          } as Parameter);
        }
      }
    }

    // Extract this as seperate parameter rendering method?
    for (const task of tasks) {
      for (const param of task.parameters) {
        const paramData = incidents
          .filter((i: ComponentIncident) => i.eventCode.code == task.code)
          .flatMap((i: ComponentIncident) => i.data)
          .filter((d: IncidentData) => d.parameterName == param.parameterName);

        param.count = paramData.length;

        if (param.parameterType == 2) {
          const values = paramData.map((d) => parseFloat(d.value));

          param.maximum = Math.max(...values);
          param.minimum = Math.min(...values);
          param.average = +(values.reduce((sum, a) => sum + a, 0) / param.count).toFixed(param.decimalPlaces);
          // "+" is not a typo, handles certain rounding cases.
        }
      }
    }

    if (!focusedParameter && focusedParameterKey != '') {
      const findParam = tasks.flatMap((t) => t.parameters).find((p) => `${p.taskCode},${p.parameterName}` == focusedParameterKey);
      if (findParam) focusedParamChanged(null, findParam);
    }
  }

  const paramColumns: Array<Column<Parameter>> = [
    { title: 'ID', field: 'id', hidden: true },
    { title: 'Parameter', field: 'parameterName', hidden: false }
  ];

  const taskSummaryColumns: Array<Column<Parameter>> = [
    { title: 'ID', field: 'id', hidden: true },
    { title: 'Parameter', field: 'parameterName', hidden: false },
    { title: 'Count', field: 'count', hidden: false },
    { title: 'Min.', field: 'minimum', hidden: false },
    { title: 'Max.', field: 'maximum', hidden: false },
    { title: 'Avg.', field: 'average', hidden: false }
  ];

  const paramTableOptions: Options<any> = {
    paging: false,
    padding: 'dense',
    search: false,
    toolbar: false,
    draggable: false,
    header: false
  };

  const taskSummaryTableOptions: Options<any> = {
    paging: false,
    padding: 'dense',
    search: false,
    toolbar: true,
    draggable: false,
    headerStyle: {
      fontWeight: 'bold',
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  };

  const selectedData =
    incidents
      ?.filter((i: ComponentIncident) => i.eventCode.code == focusedParameter?.taskCode) // Match task code
      .flatMap((i: ComponentIncident) =>
        i.data.flatMap(
          (d: IncidentData) =>
            ({
              time: new Date(i.startTime),
              workpackName: i.workpack?.name,
              fullComponent: i.component.fullComponent,
              parameterName: d.parameterName,
              parameterType: d.parameterType,
              value: d.value
            } as DataPoint) // Convert to list of data points
        )
      )
      .filter((d) => d.parameterName == focusedParameter?.parameterName) // Match parameter name
      .filter(
        (d, i, arr) =>
          arr.findIndex((ele) => ele.fullComponent === d.fullComponent && ele.time.getTime() === d.time.getTime() && ele.value === d.value) === i
      ) // Filter out repeated values
      .sort((a: DataPoint, b: DataPoint) => a.time.getTime() - b.time.getTime()) ?? []; // Sort by time

  function formatPieData(data: DataPoint[]) {
    const dataCounts = data.reduce((counts, e) => counts.set(e.value, (counts.get(e.value) || 0) + 1), new Map<string, number>());

    return {
      labels: [...dataCounts.keys()],
      datasets: [
        {
          data: [...dataCounts.values()],
          backgroundColor: [...dataCounts.keys()].map((_, i) => chartColors[i % chartColors.length])
        }
      ]
    };
  }

  function replaceEmptyLabels(chart: Chart) {
    const data = chart.data;
    if (data?.labels?.length && data?.datasets?.length) {
      return data.labels.map((label, i) => {
        const meta = chart.getDatasetMeta(0);
        const style = meta.controller.getStyle(i);

        return {
          text: label ? label.toString() : '(no data)',
          fillStyle: style.backgroundColor,
          strokeStyle: style.borderColor,
          lineWidth: style.borderWidth,
          index: i
        };
      });
    }
    return [];
  }

  function formatBarData(data: DataPoint[]) {
    const dataToCategoryKey = (d: DataPoint) => (dataCategoryType == 'Workpack' ? d.workpackName : d.time.getFullYear().toString());

    const categories = [...new Set(data.map((d) => dataToCategoryKey(d)))];
    const components = [...new Set(data.map((d) => d.fullComponent))];

    return {
      labels: components,
      datasets: categories.map(
        (category, i) =>
          ({
            label: category,
            backgroundColor: chartColors[i % chartColors.length],
            data: components.flatMap((component: string) => {
              const matchingData = data.filter((d) => d.fullComponent === component && dataToCategoryKey(d) === category);
              if (matchingData.length < 1) return NaN;
              return matchingData.map((d) => parseFloat(d.value));
            })
          } as ChartDataSets)
      )
    };
  }

  function getChart(data: DataPoint[]) {
    switch (data.length > 0 ? data[0]?.parameterType : undefined) {
      case ParameterType.Alphanumeric:
      case ParameterType.Boolean:
        return (
          <Card style={{ width: '100%', padding: 15, height: 500 }}>
            <Pie
              data={formatPieData(data)}
              options={{
                legend: {
                  labels: {
                    generateLabels: replaceEmptyLabels
                  }
                }
              }}
            />
          </Card>
        );

      case ParameterType.Numeric: {
        const values = data.map((d) => parseFloat(d.value));
        const max = Math.max(...values);
        const min = Math.min(...values);

        return (
          <Card id="bar_chart_card" style={{ width: window.innerWidth / 2 - 40, padding: 15, overflowX: 'auto' }}>
            <div style={{ width: Math.max(values.length * 20, 700), height: 500 }}>
              <Bar
                width={Math.max(values.length * 10, 700)}
                data={formatBarData(data)}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        ticks:
                          max - min < 100
                            ? {
                                suggestedMax: max + 2,
                                suggestedMin: min - 2
                              }
                            : {}
                      }
                    ],
                    xAxes: [
                      {
                        ticks: {
                          callback(fullComponent: string) {
                            if (fullComponent.length < 20) return fullComponent;
                            return `...${fullComponent.slice(-20)}`;
                          }
                        }
                      }
                    ]
                  },
                  onClick(_: MouseEvent, activeElements: {}[]) {
                    if (activeElements.length < 1) return;
                    const element = activeElements[0] as { _chart: Chart; _index: number }; // as ChartElement; // Apparently the 'ChartElement' class does not exist in Chart.js?
                    const chart = element._chart;
                    const label = (chart.data.labels as string[])[element._index];
                    props.setSelectedComponent([label]);
                  }
                }}
              />
            </div>
          </Card>
        );
      }

      default:
        return (
          <Card style={{ width: '100%', padding: 15, height: 500 }}>
            <Typography align="center">No records to display</Typography>
          </Card>
        );
    }
  }

  const dataColumns: Array<Column<DataPoint>> = [
    { title: 'ID', field: 'id', hidden: true },
    { title: 'Time', field: 'time', render: (row: DataPoint) => format(row.time, 'd/MM/yy\u00a0HH:mm:ss') },
    { title: 'Value', field: 'value', emptyValue: '(no data)' },
    { title: 'Component', field: 'fullComponent' }
  ];

  const dataTableOptions: Options<any> = {
    padding: 'dense',
    search: false,
    toolbar: true,
    draggable: false,
    headerStyle: {
      fontWeight: 'bold',
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  };

  window.onresize = () => setRefreshKey(Date.now());

  return (
    <Box flexDirection="row" display="flex" width="100%">
      <Box flex margin={1}>
        <Card>
          {tasks.map((task: Task, i) => (
            <Card key={i} style={{ margin: '15px' }}>
              <Typography align="center" style={{ fontWeight: 'bold' }}>
                {task.code}
              </Typography>
              <br />
              <MaterialTable
                columns={paramColumns}
                isLoading={props.loading}
                data={task.parameters}
                options={paramTableOptions}
                onRowClick={focusedParamChanged}
              />
            </Card>
          ))}
        </Card>
        <br />
        <Card style={{ padding: '15px' }}>
          <Typography align="center">Colour bar chart by:</Typography>
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup orientation="horizontal" size="small">
              <Button
                classes={{
                  outlinedPrimary: classes.sourceButtonSelected
                }}
                color={dataCategoryType === 'Year' ? 'primary' : undefined}
                onClick={() => setDataCategoryType('Year')}
              >
                Year
              </Button>
              <Button
                classes={{
                  outlinedPrimary: classes.sourceButtonSelected
                }}
                color={dataCategoryType === 'Workpack' ? 'primary' : undefined}
                onClick={() => setDataCategoryType('Workpack')}
              >
                Workpack
              </Button>
            </ButtonGroup>
          </div>
        </Card>
      </Box>
      <Box width="75%" margin={1}>
        {getChart(selectedData)}
        <br />
        <MaterialTable
          title={focusedParameter ? `${focusedParameter.taskCode} : ${focusedParameter.parameterName}` : ''}
          columns={dataColumns}
          isLoading={props.loading}
          data={selectedData}
          options={dataTableOptions}
        />
        <br />
        {focusedParameter ? (
          <MaterialTable
            title={`${focusedParameter.taskCode} Summary`}
            columns={taskSummaryColumns}
            isLoading={props.loading}
            data={tasks.find((t) => t.code == focusedParameter.taskCode)?.parameters ?? []}
            options={taskSummaryTableOptions}
            onRowClick={focusedParamChanged}
          />
        ) : (
          <React.Fragment />
        )}
      </Box>
    </Box>
  );
}
