import { Card, CardContent, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { Chart, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useCallback, useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { OperationalCodeColors } from '../../../styles/FugroColors';
import { StatisticsSkeletonBlock } from '../../skeletons/StatisticsSkeletonBlock';
import { useCardStyles } from '../../styles/CardStyles';
import { WorkpackStatsItem } from './workpackStatisticsDashboard';
// tslint:disable: max-line-length

Chart.plugins.unregister(ChartDataLabels);

export default function SiteAnomalies(props: { loading: boolean; data?: WorkpackStatsItem }): JSX.Element {
  const cardStyles = useCardStyles();
  const [datasource, setDatasource] = useState<any>();
  const [filter, setFilter] = useState<string>('None');
  const [cutoutSize, setCutoutSize] = useState<number>(25);

  const chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    cutoutPercentage: cutoutSize,
    layout: {
      padding: {
        left: 45,
        right: 45,
        top: 15,
        bottom: 15
      }
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        label(tooltipItem, data: any) {
          if (tooltipItem.index === -1) {
            return '';
          } else if (data.labels) {
            const index = tooltipItem.index as number;
            const datasetIndex = tooltipItem.datasetIndex as number;
            const code = data.datasets[datasetIndex].names[index];

            return `${code}`;
          }

          return 'N/A';
        },
        afterLabel(tooltipItem, data: any) {
          if (tooltipItem.index === -1) {
            return '';
          } else if (data.datasets) {
            const tooltip: string[] = [];
            const index = tooltipItem.index as number;
            const datasetIndex = tooltipItem.datasetIndex as number;

            const fullName = data.datasets[datasetIndex].description[index];
            const taskCount = (data.datasets[datasetIndex] as any)?.count[index];
            const type = data.datasets[datasetIndex].label === 'Incidents' ? 'Anomalous Incidents' : 'Site Anomalies';

            tooltip.push(`${fullName}`);
            tooltip.push(`${type}: ${taskCount}`);
            return tooltip;
          }
          return '';
        }
      }
    },
    plugins: {
      datalabels: {
        color: '#FFFFFF',
        formatter: (value: any, ctx: any) => {
          const dataArr = ctx.chart.data.datasets[ctx.datasetIndex].names[ctx.dataIndex];
          const labelToReturn = value < 3 ? '' : dataArr;
          return labelToReturn;
        }
      }
    }
  };

  // const legendOptions: ChartLegendOptions = {
  //   // display: true,
  //   position: 'right',
  //   labels: {
  //     boxWidth: 20
  //   }
  // };

  const createDataSet = useCallback(
    (
      anomalies: Array<{ code: string; status: string; tooltip: string }>,
      incidents: Array<{ code: string; subCode: string; tooltip: string; status: string }>
    ) => {
      const distinctAnomalyCodes = [...Array.from(new Set(anomalies.map((x) => x.code)))];
      const distinctIncidentCodes = [...Array.from(new Set(incidents.map((x) => x.code)))]; // ------- Updated based on M's reponse

      const anomaliesDatasource: any[] = [];
      const incidentsDatasource: any[] = [];

      distinctAnomalyCodes.forEach((x) => {
        const _count =
          filter === 'None'
            ? anomalies.filter((_x) => _x.code === x).length
            : anomalies.filter((_x) => _x.code === x && _x.status === filter.toUpperCase()).length;
        if (_count !== 0) {
          anomaliesDatasource.push({
            code: x,
            description: anomalies.find((y) => y.code === x)?.tooltip,
            count: _count
          });
        }
      });

      distinctIncidentCodes.forEach((x) => {
        const _count =
          filter === 'None'
            ? incidents.filter((_x) => _x.code === x).length
            : incidents.filter((_x) => _x.code === x && _x.status === filter.toUpperCase()).length;
        if (_count !== 0) {
          incidentsDatasource.push({
            code: x,
            description: incidents.find((y) => y.code === x)?.tooltip,
            count: _count
          });
        }
      });

      const datasets: Array<{
        label: string;
        names: any[];
        data: any[];
        description: any[];
        count: any[];
        backgroundColor: string[];
      }> = [];

      if (anomaliesDatasource.length !== 0) {
        datasets.push({
          label: 'Anomalies',
          names: anomaliesDatasource.map((x) => x.code),
          data: anomaliesDatasource.map((x) => x.count),
          description: anomaliesDatasource.map((x) => x.description),
          count: anomaliesDatasource.map((x) => x.count),
          backgroundColor: distinctAnomalyCodes.map((_x, i) => OperationalCodeColors[i % OperationalCodeColors.length])
        });
      }

      if (incidentsDatasource.length !== 0) {
        datasets.push({
          label: 'Incidents',
          names: incidentsDatasource.map((x) => x.code),
          data: incidentsDatasource.map((x) => x.count),
          description: incidentsDatasource.map((x) => x.description),
          count: incidentsDatasource.map((x) => x.count),
          backgroundColor: distinctIncidentCodes.map(
            (_x, i) => OperationalCodeColors[i % OperationalCodeColors.length]
            // (_x, i) => chartColors[i % chartColors.length]
          )
        });
      }

      return { datasets };
    },
    [filter]
  );

  useEffect(() => {
    if (props.data) {
      const anomalies = props.data.anomaly.map((a) => ({
        code: a.anomalyCode.code,
        status: a.criticality,
        tooltip: a.anomalyCode.description
      }));

      // const tasks = props.data.incident.filter(x => x.anomaly.length !== 0).map(a => ({
      const tasks = props.data.incident
        .filter((x) => x.isAnomalous)
        .map((a) => ({
          code: a.eventCode.code,
          subCode: a.subEventCode,
          tooltip: a.eventCode.description,
          status: a.anomaly.length === 0 ? 'None' : a.anomaly[0].criticality
        }));

      const newChartData = createDataSet(anomalies, tasks);

      setCutoutSize(newChartData.datasets.length === 2 ? 25 : 50);
      setDatasource(newChartData);
    }
  }, [props.data, filter, createDataSet]);

  const filterOnChange = (event: any) => {
    setFilter(event.target.value);
  };

  const filterControl = () => {
    return (
      <FormControl style={{ float: 'right', width: '150px' }}>
        <InputLabel>Filter By</InputLabel>
        <Select value={filter} onChange={filterOnChange} label="filter">
          <MenuItem value="None">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'Information'}>Information</MenuItem>
          <MenuItem value={'Insignificant'}>Insignificant</MenuItem>
          <MenuItem value={'Monitor'}>Monitor</MenuItem>
          <MenuItem value={'Significant'}>Significant</MenuItem>
          <MenuItem value={'Critical'}>Critical</MenuItem>
        </Select>
      </FormControl>
    );
  };

  if (props.loading) {
    return <StatisticsSkeletonBlock />;
  }

  if (!datasource || datasource?.datasets?.length === 0) {
    return (
      <Card className={cardStyles.cardDetails}>
        <CardContent>
          <div style={{ height: '48px', width: '100%', position: 'relative' }}>
            <Typography variant="h6" color="textSecondary" style={{ float: 'left' }}>
              Recorded Anomalies
            </Typography>
            {filterControl()}
          </div>
          <div className={cardStyles.cardDetailsLoading}>
            <div>No Anomalies Recorded.</div>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={cardStyles.cardDetails}>
      <CardContent>
        <Typography variant="h6" color="textSecondary">
          Recorded Anomalies
          {filterControl()}
        </Typography>
        <div className={cardStyles.chartContainer}>
          <Pie
            data={datasource}
            options={chartOptions}
            // legend={legendOptions}
            // plugins={[calloutPlugin, ChartDataLabels]}
            plugins={[ChartDataLabels]}
          />
        </div>
      </CardContent>
    </Card>
  );
}
