import { Card, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { NotListedLocationOutlined } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 14,
      height: '10%',
      padding: theme.spacing(1),
      color: 'white'
    },
    card: {
      height: '100%',
      boxShadow: 'none',
      position: 'relative',
      top: '0',
      left: '0',
      backgroundColor: 'transparent'
    },
    aligner: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      backgroundColor: 'transparent',
      color: 'white'
    },
    icon: {
      fontSize: '3em',
      verticalAlign: 'middle',
      marginRight: '5px'
    }
  })
);

interface NoDataProps {
  title?: string;
  text?: string;
}

export default function NoMarker(props: NoDataProps): JSX.Element {
  const classes = useStyles();
  return (
    <Card color="inherit" className={classes.card}>
      {props.title ? (
        <Typography className={classes.title} color="textSecondary" gutterBottom={true}>
          {props.title}
        </Typography>
      ) : (
        ''
      )}
      <div style={{ color: 'white', textAlign: 'center', position: 'relative', top: '40%' }}>
        <NotListedLocationOutlined className={classes.icon} />
        <div className={classes.aligner}>
          <Typography variant="subtitle1" component="h2">
            {props.text ? props.text : 'No Data'}
          </Typography>
        </div>
        <div className={classes.aligner}>
          <Typography variant="subtitle2" component="h3">
            {/* Tip: Hold shift and drag over markers. */}
          </Typography>
        </div>
      </div>
    </Card>
  );
}
