import { useMutation, useQuery } from '@apollo/react-hooks';
import { Box, CircularProgress, createStyles, Dialog, DialogContent, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { ErrorOutline, NavigateBefore, NavigateNext, RepeatOneSharp } from '@material-ui/icons';
import { gql } from 'apollo-boost';
import Hls from 'hls.js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MediaItemItemType } from './workpacks/workpackDetails/MediaViewerDialog';

export interface DownloadMediaItem {
  id: string;
  itemType?: MediaItemItemType;
}

export const DownloadPackageDialog = (props: { items: DownloadMediaItem[]; onClose: () => void }) => {
  const [downloadReady, setDownloadReady] = useState<boolean>(false);

  const [downloadMedia, { loading, error, data }] = useMutation<
    { downloadMedia: { url: string; availabilityCheckUrl: string } },
    { items: DownloadMediaItem[] }
  >(
    gql`
      mutation downloadMedia($items: [DownloadMediaItemInputType]!) {
        downloadMedia(items: $items) {
          url
          availabilityCheckUrl
        }
      }
    `,
    {
      onCompleted: (d) => {
        availabilityCheck(d.downloadMedia.availabilityCheckUrl, d.downloadMedia.url);
      }
    }
  );

  const availabilityCheck = (availabiltyCheckUrl: string, url: string) => {
    isDownloadAvailable(availabiltyCheckUrl).then((r) => {
      if (r) {
        setDownloadReady(true);
        downloadFile(url);
      } else {
        setTimeout(availabilityCheck, 500, availabiltyCheckUrl, url);
      }
    });
  };

  const isDownloadAvailable = async (availabiltyCheckUrl: string) => {
    const fetchResult = await fetch(availabiltyCheckUrl);
    if (fetchResult.ok) {
      const responseText = await fetchResult.text();
      if (responseText === 'Available') {
        return true;
      }
    }
    return false;
  };

  const downloadFile = (url: string) => {
    const a = document.createElement('a');
    a.href = url;
    // a.setAttribute("download", "Sense.Insights Download.zip");
    a.click();
    props.onClose();
  };

  const dialogContent = () => {
    if (loading) {
      return (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item>Requesting download ...</Grid>
        </Grid>
      );
    }

    if (error || !data || !data.downloadMedia) {
      return <div>{error}</div>;
    }

    if (!loading && !downloadReady) {
      return (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item>Waiting for download ...</Grid>
        </Grid>
      );
    }

    if (!loading && downloadReady) {
      return (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item>Starting download ...</Grid>
        </Grid>
      );
    }
  };

  useEffect(() => {
    downloadMedia({ variables: { items: props.items } });
  }, [props.items]);

  return (
    <Dialog maxWidth="lg" fullWidth={false} open={true} scroll="body">
      <DialogContent>{dialogContent()}</DialogContent>
    </Dialog>
  );
};

/*
          onClose={props.onClose}
          {classes={{ paper: classes.dialogPaper, container: classes.container }}>

          className={`${classes.dialogContent} Carousel CarouselItem`}
*/
