/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { objectLessAttributes } from '@aws-amplify/core';
import * as AmplifyUI from '@aws-amplify/ui';
import { AppBar, createStyles, IconButton, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';
import { AmplifyTheme, Authenticator, beforeAfter, propStyle } from 'aws-amplify-react';
import * as React from 'react';
import FugroLogo from '../../images/fugro-logo-white.svg';
import { authenticatorTheme } from '../../styles/FugroColors';

export function withForkedAuthenticator(
  Comp: any,
  includeGreetings = false,
  authenticatorComponents: any[] = [],
  federated: any = null,
  theme: any = null,
  signUpConfig: any = {}
) {
  theme = { ...theme, ...authenticatorTheme };

  const ForkedContainer = (props: { children: React.ReactNode }) => {
    const style = propStyle(props, AmplifyTheme.container);
    const p = objectLessAttributes(props, 'theme');
    const useStyles = makeStyles((t: Theme) =>
      createStyles({
        appBar: {
          zIndex: t.zIndex.drawer + 1
        },
        hide: {
          display: 'none'
        },
        appBarLogo: {
          marginLeft: '0.5em',
          marginRight: '0.5em',
          height: '1.5em'
        },
        title: {
          flexGrow: 1
        }
      })
    );

    const classes = useStyles();
    return beforeAfter(
      <div {...p} className={AmplifyUI.container} style={style}>
        <React.Fragment>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton color="inherit" edge="start">
                <img src={FugroLogo} className={classes.appBarLogo} alt="FugroLogo" />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Sense.Structures
              </Typography>
            </Toolbar>
          </AppBar>
          {props.children}
        </React.Fragment>
      </div>
    );
  };

  return class extends React.Component<any, any> {
    public authConfig: any;
    constructor(props: any) {
      super(props);

      this.handleAuthStateChange = this.handleAuthStateChange.bind(this);

      this.state = {
        authState: props.authState || null,
        authData: props.authData || null
      };

      this.authConfig = {};

      if (typeof includeGreetings === 'object' && includeGreetings !== null) {
        // tslint:disable-next-line: prefer-object-spread
        this.authConfig = Object.assign(this.authConfig, includeGreetings);
      } else {
        this.authConfig = {
          includeGreetings,
          authenticatorComponents,
          federated,
          theme,
          signUpConfig
        };
      }
    }

    handleAuthStateChange(state: any, data: any) {
      this.setState({ authState: state, authData: data });
    }

    render() {
      const { authState, authData } = this.state;

      if (authState === 'signedIn') {
        return (
          <React.Fragment>
            {this.authConfig.includeGreetings ? (
              <Authenticator
                {...this.props}
                theme={this.authConfig.theme}
                federated={this.authConfig.federated || this.props.federated}
                hideDefault={this.authConfig.authenticatorComponents && this.authConfig.authenticatorComponents.length > 0}
                signUpConfig={this.authConfig.signUpConfig}
                usernameAttributes={this.authConfig.usernameAttributes}
                onStateChange={this.handleAuthStateChange}
                container={ForkedContainer}
              >
                {this.authConfig.authenticatorComponents || []}
              </Authenticator>
            ) : null}
            <Comp {...this.props} authState={authState} authData={authData} onStateChange={this.handleAuthStateChange} />
          </React.Fragment>
        );
      }

      return (
        <Authenticator
          {...this.props}
          theme={this.authConfig.theme}
          federated={this.authConfig.federated || this.props.federated}
          hideDefault={this.authConfig.authenticatorComponents && this.authConfig.authenticatorComponents.length > 0}
          signUpConfig={this.authConfig.signUpConfig}
          usernameAttributes={this.authConfig.usernameAttributes}
          onStateChange={this.handleAuthStateChange}
          container={ForkedContainer}
        >
          {this.authConfig.authenticatorComponents || []}
        </Authenticator>
      );
    }
  };
}

/* eslint react/prop-types: "off" */
export class AuthenticatorWrapper extends React.Component {
  constructor(props: any) {
    super(props);

    this.state = { auth: 'init' };

    this.handleAuthState = this.handleAuthState.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
  }

  handleAuthState(state: any, data: any) {
    this.setState({ auth: state, authData: data });
  }

  renderChildren() {
    // @ts-ignore
    return this.props.children(this.state.auth);
  }

  render() {
    return (
      <div>
        <Authenticator {...this.props} onStateChange={this.handleAuthState} />
        {this.renderChildren()}
      </div>
    );
  }
}
