import { useQuery } from '@apollo/react-hooks';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { gql } from 'apollo-boost';
import DeckGL, { MapView, FlyToInterpolator } from 'deck.gl';
import React, { useCallback, useEffect, useState } from 'react';
import { StaticMap } from 'react-map-gl';
import { RouteComponentProps } from 'react-router-dom';
import { MapTooltipProps } from '../../../../types/params.type';
import { MapDataQuery } from '../../MapUtils/MapQueries';
import { LngLat, MapProperties, MvtProperties, SecondaryMapProperties, SelectedTab } from '../../MapUtils/SharedTypes';
import MeasureModel from '../../Overlay/MeasureModal';
import ExpandableIconTooltip from '../components/icon-tooltip';
import { INITIAL_VIEW_STATE, ICON_MAPPING_SIMPLE } from '../extras/Constants';
import { calculateMinMaxLngLat, createLayerPropsFromStorage, FieldLayoutLayers, FilterAndSortData } from '../extras/Functions';
import { WorkpackData, MapConfiguration } from '../extras/Types';
import { BASEMAP } from '@deck.gl/carto';
// @ts-ignore
import RulerLayer from '../ruler-layer/ruler-layer';
// @ts-ignore
import TextClusterLayer from '../text-cluster-layer/text-cluster-layer';
// @ts-ignore
import TextMvtLayer from '../text-mvt-layer/text-mvt-layer';
import EsriLayerService, { LayerInfo } from './EsriLayerService';
import { WebMercatorViewport } from '@deck.gl/core';
import DeckLayerService from './DeckLayerService';

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    container: {
      height: '76vh',
      minWidth: '100%',
      position: 'relative'
      // border: '1px dashed red',
    }
  })
);

const pbfLayerProperties: MvtProperties = {
  minZoom: 0,
  maxZoom: 20,
  opacity: 1,
  // @ts-ignore
  stroked: true,
  filled: true,
  getLineColor: [255, 0, 255],
  getFillColor: [0, 35, 102],
  getLineWidth: 1,
  lineWidthUnits: 'pixels',
  lineWidthMinPixels: 1,
  pickable: true
  // onHover: onHoverMvt,
  // showText: props.Markers.Text
};

export default function SecondaryDeckMap(props: MapProperties & SecondaryMapProperties & RouteComponentProps): JSX.Element {
  const WORKPACK_ID = props.Queries.WorkpackId;
  const MAP_STYLE_TOKEN = 'pk.eyJ1Ijoib2xpdmVyYnVja2xlciIsImEiOiJja2x0MmxrbTgwYWUwMnZvNTlsZGtocmlhIn0.ut0usMOiygSF99KtCA0djw';
  const ICONS = './map/icon-atlas-update.png';
  const MAP_VIEW = new MapView({ repeat: false });
  const [viewport, setViewport] = useState(INITIAL_VIEW_STATE);
  const classes = useStyles();

  const [tooltipIconData, setTooltipIconData] = useState<any>([]);
  const [tooltipIconVisible, setTooltipIconVisible] = useState<boolean>(false);
  const [tooltipIconPosition, setTooltipIconPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [measurementData, setMeasurementData] = useState<any>([]);

  const [formattedData, setFormattedData] = useState<any[]>(); // Used to store our base formatted data.
  const [filteredData, setFilteredData] = useState<any[]>([]); // Used to store formattedData but with a filter.
  const { loading, data: mapData } = useQuery<{ workpack: WorkpackData[]; mapConfiguration: MapConfiguration }, { id: string }>(
    gql`
      ${MapDataQuery}
    `,
    { variables: { id: WORKPACK_ID } }
  );

  const [esriService, setEsriService] = useState<EsriLayerService>();
  const [deckService, setDeckService] = useState<DeckLayerService>();

  const [esriLayers, setEsriLayers] = useState<any[]>();
  const [deckLayers, setDeckLayers] = useState<any[]>();

  const [initialCameraPosition, setInitialCameraPosition] = useState<LngLat>();
  const [viewState, setViewState] = useState<any>(INITIAL_VIEW_STATE);
  const [extent, setExtent] = useState<any>();

  useEffect(() => {
    initializeEsriHandler();
  }, [props.esriProperties]);

  useEffect(onMarkerSelectionChanged, [props.Markers]);

  useEffect(toggleEsriLayer, [props.CustomLayers]);

  useEffect(onComponentSearchChanged, [props.Queries.searchId]);

  useEffect(fetchData, [loading]);

  useEffect(buildDeckLayersAndSetExtent, [formattedData]);

  useEffect(setInitialView, [extent]);

  useEffect(updateRulerLayer, [measurementData]);

  const initializeEsriHandler = useCallback(async () => {
    // if (!props?.esriProperties || props.esriProperties.layerInfo.length === 0) return;

    const info: LayerInfo[] = createLayerPropsFromStorage(props.CustomLayers); //props.esriProperties.layerInfo.map((x) => ({ url: x.url, props: { visible: true } }));
    const esriHandler = new EsriLayerService({ layerInfo: info, token: props.token });
    await esriHandler.initialize();
    const layers = esriHandler.generateLayers(info);

    console.log(info);

    const deckHandler = new DeckLayerService({
      ...props.deckProperties,
      token: props.token,
      visibleLayerUrls: info?.filter((l) => l.props.visible).map((l) => l.url)
    });
    const deckLayers = deckHandler.generate();

    console.log(deckLayers);

    setEsriService(esriHandler);
    setDeckService(deckHandler);
    setEsriLayers(layers);
    setDeckLayers(deckLayers);
  }, [props.esriProperties, props.deckProperties]);

  function updateRulerLayer() {
    if (!deckLayers) return;

    const layer = new RulerLayer({
      id: 'ruler-layer',
      data: measurementData
    });

    const existing = deckLayers.filter((x) => x.props.id !== 'ruler-layer');

    setDeckLayers([layer, ...existing]);
  }

  function fetchData() {
    if (!loading) {
      // var darkMode = localStorage.GetItem('map.skin');
      if (mapData) {
        const workpackPosition = mapData?.workpack[0]?._WorkpackPosition?.lnglat;
        if (workpackPosition) {
          const _initialPoint = { longitude: workpackPosition.longitude, latitude: workpackPosition.latitude };
          setInitialCameraPosition(_initialPoint);
          moveCameraToLongLat(_initialPoint);
        }
        const sortedData = FilterAndSortData(mapData, props.Markers);
        setFormattedData(sortedData);

        props.Queries.State(false);
      }
    }
  }

  function setInitialView() {
    if (!extent) return;

    let newViewPort = new WebMercatorViewport({ width: 500, height: 500, ...viewport });

    // @ts-ignore
    const { longitude, latitude, zoom } = newViewPort.fitBounds(
      [
        [extent.minLng, extent.minLat],
        [extent.maxLng, extent.maxLat]
      ],
      {}
    );

    newViewPort = {
      ...viewport,
      // @ts-ignore
      longitude,
      latitude,
      zoom: zoom
    };

    // @ts-ignore
    setViewport(newViewPort);
  }

  function toggleEsriLayer() {
    if (!esriLayers && !deckLayers) return;

    // Create a new list of properties with visibility set accordingly.
    const newLayerInfo: LayerInfo[] = [];

    props.CustomLayers.forEach((layer: any) => {
      const item: LayerInfo = { url: layer.originalUrl, props: { visible: layer.isChecked }, datumTransformationId: layer.transformationId };
      newLayerInfo.push(item);
    });

    console.log(newLayerInfo);

    // Rebuild the layers with the visibilty applied.
    const layers = esriService?.generateLayers(newLayerInfo);
    deckService?.setVisibleLayerUrls(newLayerInfo.filter((l) => l.props.visible).map((l) => l.url));
    setDeckLayers(deckService?.generate());
    setEsriLayers(layers);
  }

  function buildDeckLayersAndSetExtent() {
    const data = filteredData?.length > 0 ? filteredData : formattedData;

    const deckLayers = [
      new RulerLayer({
        id: 'ruler-layer',
        data: measurementData
      })
    ];

    if (data && data.length !== 0) {
      deckLayers.push(
        new TextClusterLayer({
          id: 'text-cluster',
          sizeScale: 45,
          data,
          pickable: true,
          getPosition: (d: any) => {
            // Reverse cause of latlng not lnglat
            return [d.estimatedLocation.y, d.estimatedLocation.x];
          },
          iconAtlas: ICONS,
          iconMapping: ICON_MAPPING_SIMPLE,
          onHover: onHoverIcon
        })
      );

      const positions = data.map((x: any) => ({ longitude: x.estimatedLocation.y, latitude: x.estimatedLocation.x }));
      const extent = calculateMinMaxLngLat(positions);

      setExtent(extent);
    }

    if (mapData) {
      const fieldLayouts = FieldLayoutLayers(mapData.mapConfiguration.layerUrls, TextMvtLayer, pbfLayerProperties);
      deckLayers.push(...fieldLayouts);
    }

    setDeckLayers(deckLayers);
  }

  function onComponentSearchChanged() {
    if (!formattedData) return;

    const query = props.Queries.searchId;
    if (query !== '') {
      const _data = formattedData.filter((x) => x.id === query);
      if (_data.length > 0) {
        setFilteredData(_data);
        moveCameraToLongLat(_data[0].estimatedLocation);
      }
    } else {
      setFilteredData([]);
      moveCameraToLongLat(initialCameraPosition);
    }
  }

  function onMarkerSelectionChanged() {
    if (mapData) {
      const sortedData = FilterAndSortData(mapData, props.Markers);
      setFormattedData(sortedData);
    }
  }

  function moveCameraToLongLat(lnglat: LngLat | undefined) {
    setViewState({
      ...viewState,
      longitude: lnglat?.longitude || 0,
      latitude: lnglat?.latitude || 0,
      zoom: 14,
      pitch: 0,
      bearing: 0,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator()
    });
  }

  const onHoverIcon = (info: any, event: any) => {
    if (!info.objects && !info.object) {
      return;
    }

    if (info.objects) {
      setTooltipIconData(info.objects);
    } else if (info.object) {
      setTooltipIconData([info.object]);
    }

    setTooltipIconPosition({ x: info.x + 15, y: info.y - 36 });
    setTooltipIconVisible(true);
  };

  const onIconTooltipLeave = (info: any, event: any) => {
    setTooltipIconVisible(false);
  };

  function setResponseHeaders(args: MapTooltipProps) {
    const tab = SelectedTab[args.Tab];
    const data = JSON.stringify(args);
    props.history.replace(`/workpack-detail-dashboard/${WORKPACK_ID}/${tab}`, data);
  }

  const onClick = (args: any) => {
    if (props.Handlers.IsMeasuring) {
      setMeasurementData([...measurementData, args.coordinate]);
    }
  };

  const onKeyDown = (args: any) => {
    const key = args.key;

    switch (key) {
      case 'Escape':
        setMeasurementData([]);
        break;
    }
  };

  if (loading || (deckLayers === undefined && esriLayers === undefined)) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <div className={classes.container} onKeyDown={onKeyDown}>
        <DeckGL
          controller={true}
          layers={[...(esriLayers ?? []), ...(deckLayers ?? [])]}
          views={[MAP_VIEW]}
          initialViewState={viewport}
          onClick={onClick}
        >
          <StaticMap
            reuseMaps={true}
            mapStyle={BASEMAP.POSITRON}
            width={256}
            height={256}
            mapboxApiAccessToken={MAP_STYLE_TOKEN}
            preventStyleDiffing={true}
          />
        </DeckGL>
        <ExpandableIconTooltip
          onMouseLeave={onIconTooltipLeave}
          data={tooltipIconData}
          visible={tooltipIconVisible}
          position={tooltipIconPosition}
          onItemClick={setResponseHeaders}
        />
        <MeasureModel open={props.Handlers.IsMeasuring} />
      </div>
    </React.Fragment>
  );
}
