import { Skeleton } from '@material-ui/lab';
import MaterialTable from '@material-table/core';
import React from 'react';

export function SkeletonTable(): JSX.Element {
  const dummyRows = [{}, {}, {}, {}, {}];
  const columns: any = [
    {
      title: <Skeleton variant="text" style={{ minWidth: '6em' }} />,
      field: 'completionStatus',
      width: '6em',
      render: () => <Skeleton variant="text" />
    },
    {
      title: <Skeleton variant="text" style={{ minWidth: '6em' }} />,
      field: 'startDate',
      width: '10em',
      render: () => <Skeleton variant="text" />,
      type: 'datetime'
    },
    {
      title: <Skeleton variant="text" style={{ minWidth: '6em' }} />,
      field: 'workpackCode',
      width: '15em',
      render: () => <Skeleton variant="text" />
    },
    {
      title: <Skeleton variant="text" style={{ minWidth: '6em' }} />,
      field: 'parentAsset',
      render: () => <Skeleton variant="text" />,
      cellStyle: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '20em'
      }
    },
    {
      title: <Skeleton variant="text" style={{ minWidth: '6em' }} />,
      field: 'progress.totalTaskCount',
      width: '10em',
      render: () => <Skeleton variant="text" />
    },
    {
      title: <Skeleton variant="text" style={{ minWidth: '6em' }} />,
      field: '_itemCount.anomaly',
      width: '15em',
      render: () => <Skeleton variant="text" />
    },
    {
      title: <Skeleton variant="text" style={{ minWidth: '6em' }} />,
      field: 'name',
      cellStyle: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '10em'
      },
      render: () => <Skeleton variant="text" />
    }
  ];

  return <MaterialTable title={<Skeleton variant="text" style={{ minWidth: '6em' }} />} columns={columns} data={dummyRows} />;
}
