import { useQuery } from '@apollo/react-hooks';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { gql } from 'apollo-boost';
import { format } from 'date-fns';
import React from 'react';
import { Document } from '../../../../types/document.type';
import MaterialTableExtended from '../../../MaterialTableExtended';
import { SkeletonTable } from '../../../skeletons/SkeletonTable';

interface ReportsTabProps {
  workpackId: string;
  onSelectItem?: (item?: Document) => void;
}

export const ReportsList = (props: ReportsTabProps) => {
  const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
      downloadLink: {
        textDecoration: 'none',
        color: _theme.palette.text.primary,
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    })
  );

  interface ReportListDataItem {
    id: string;
    name: string;
    filename: string;
    revision: number;
    documentType: string;
    time: Date;
    url: string;
  }

  const { loading, error, data } = useQuery<{ workpack: Array<{ name: string; document: ReportListDataItem[] }> }, { id: string }>(
    gql`
      query reportList($id: ID) {
        workpack(id: $id) {
          name
          document {
            id
            name
            filename
            revision
            documentType
            time
            url
          }
        }
      }
    `,
    { variables: { id: props.workpackId } }
  );

  const classes = useStyles();

  const renderTime = (doc: ReportListDataItem) => format(new Date(doc.time), 'd/MM/yy\u00a0HH:mm');
  const renderName = (doc: ReportListDataItem) =>
    doc.url === null ? (
      <div>
        {doc.name} <small>(not found)</small>
      </div>
    ) : (
      <a className={classes.downloadLink} target="_new" href={`${doc.url}`}>
        {doc.name}
      </a>
    );

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  if (loading) {
    return <SkeletonTable />;
  }

  return (
    <React.Fragment>
      <MaterialTableExtended
        owner={'Reports'}
        workpackName={data?.workpack[0]?.name ?? ''}
        isLoading={loading}
        title="Reports"
        columns={[
          {
            title: 'Time',
            field: 'time',
            type: 'datetime',
            defaultSort: 'asc',
            columnWidth: '10em',
            filter: 'DateOnDay',
            render: renderTime
          },
          {
            title: 'Type',
            field: 'documentType',
            defaultSort: 'asc',
            columnWidth: '25em',
            filter: 'LookupAuto'
          },
          {
            title: 'Name',
            field: 'name',
            render: renderName,
            filter: 'TextEditor'
          }
        ]}
        data={data?.workpack[0]?.document ?? []}
      />
    </React.Fragment>
  );
};
