import React from 'react';
import { NavLink, NavLinkProps as RouterLinkProps, Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import ApiError from '../components/errors/ApiError';
import AssetDetail from '../components/asset-detail/AssetDetail';
import AssetDetailToV2 from '../components/asset-detail/AssetDetailToV2';
import NotFoundError from '../components/errors/NotFoundError';
import MainLayout from '../components/layouts/MainLayout';
import { Overview } from '../components/overview/Overview';
import WorkpackDetailDashboard from '../components/workpacks/workpackDetails/WorkpackDetailDashboard';

const root = '/';
const workpacks = '/workpacks';
const apiError = '/api-error';
const overview = '/overview';
const workpackDetail = '/workpack-detail-dashboard/:workpackId/:tab?'; // Workpack Tasks/Anomalies/Media/Documents
const assetDetail = '/asset-detail/:fullComponent*/';

export const UiRoutes = {
  root,
  workpacks,
  workpackDetail,
  overview
};

export const workpackLink = (workpackId: string, tab?: string): string => {
  let url = workpackDetail;

  if (tab) {
    url = url.replace(':tab', tab);
  }

  return url.replace(':workpackId', workpackId);
};

// see https://github.com/ReactTraining/react-router/issues/6056#issuecomment-435524678
export const AdapterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => <NavLink innerRef={ref as any} {...props} />);

export default function Routes(): JSX.Element {
  return (
    <MainLayout>
      <Switch>
        <Route exact={true} path={root}>
          <Redirect to={process.env.REACT_APP_ON_PREMISE === 'true' ? workpacks : overview} />
        </Route>
        <Route exact={true} path={workpacks} component={Dashboard} />
        <Route path={workpackDetail} component={WorkpackDetailDashboard} />
        {process.env.REACT_APP_V2_REDIRECT === 'true' ? (
          <Route path={assetDetail} component={AssetDetailToV2} />
        ) : (
          <Route path={assetDetail} component={AssetDetail} />
        )}
        <Route path={overview} component={Overview} />
        <Route path={apiError} component={ApiError} />
        <Route component={NotFoundError} />
      </Switch>
    </MainLayout>
  );
}
