import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Theme,
  Toolbar,
  Typography
} from '@material-ui/core';
import { ErrorOutline, ImageOutlined, PlayCircleOutline, Search as SearchIcon, VideocamOutlined } from '@material-ui/icons';
import { UniversalDatePicker, UniversalDatePickerValue, UniversalDatePickerValueType } from '../../../UniversalDatePicker';
import MaterialTable, { MTableToolbar, Options } from '@material-table/core';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { MediaType } from '../../../../utils/enumerations';
import { getMediaType } from '../../../utils/general';
import { format } from 'date-fns';
import { MediaViewerDialog } from '../MediaViewerDialog';
import { Skeleton } from '@material-ui/lab';
import { unLocalTime } from '../../../../utils/dateUtils';

export default function MediaSearch(props: { workpackId: string }): JSX.Element {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      title: {
        paddingRight: theme.spacing(4)
      },
      spacer: {
        flex: '1 1 10%'
      },
      datePickerWrapper: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
      }
    })
  );

  interface MediaSearchItem {
    id: string;
    itemType: string;
    mediaType: string;
    filename: string;
    resource: { name: string; vessel: { code: string } };
    startTime: Date;
    endTime: Date;
  }

  interface VesselItem {
    id: string;
    code: string;
    resource: Array<{ id: string; name: string }>;
  }

  const classes = useStyles();

  const [fromTime, setFromTime] = useState<UniversalDatePickerValue>();
  const [toTime, setToTime] = useState<UniversalDatePickerValue>();
  const [resourceId, setResourceId] = useState<string>();
  const [focusedVideo, setFocusedVideo] = useState<string>();

  const {
    loading: mediaSearchParametersLoading,
    data: mediaSearchParameters,
    error: mediaSearchParametersError
  } = useQuery<{ workpack: Array<{ vessel: VesselItem[]; videoLogDays: Date[] }> }, { workpackId: string }>(
    gql`
      query mediaSearchParameters($workpackId: ID) {
        workpack(id: $workpackId) {
          vessel {
            id
            code
            resource {
              id
              name
            }
          }
          videoLogDays
        }
      }
    `,
    { variables: { workpackId: props.workpackId } }
  );

  const [executeSearch, { called, loading, data, error }] = useLazyQuery<
    { workpack: Array<{ mediaSearch: MediaSearchItem[] }> },
    { workpackId: string; from?: Date; to?: Date; resourceId?: string }
  >(
    gql`
      query mediaSearch($workpackId: ID, $from: DateTime, $to: DateTime, $resourceId: ID) {
        workpack(id: $workpackId) {
          mediaSearch(from: $from, to: $to, resourceId: $resourceId) {
            id
            itemType
            filename
            startTime
            endTime
            resource {
              name
              vessel {
                code
              }
            }
          }
        }
      }
    `,
    { variables: { workpackId: props.workpackId } }
  );

  useEffect(() => {
    if (fromTime?.from !== undefined || toTime?.from !== undefined || resourceId !== undefined) {
      executeSearch({
        variables: { workpackId: props.workpackId, from: unLocalTime(fromTime?.from), to: unLocalTime(toTime?.from), resourceId: resourceId }
      });
    }
  }, [fromTime, toTime, resourceId]);

  console.log(mediaSearchParameters);

  const SearchBox = () => {
    return (
      <Toolbar>
        <Typography className={classes.title} variant="h6">
          Search Image Grabs and Video Log
        </Typography>
        <div className={classes.spacer} />
        <div className={classes.datePickerWrapper}>
          <UniversalDatePicker
            label="From"
            lockToType={UniversalDatePickerValueType.AtTime}
            value={fromTime}
            onChange={(result) => setFromTime(result ?? undefined)}
            hideAt={true}
            minDate={mediaSearchParameters?.workpack[0]?.videoLogDays[0]}
            maxDate={mediaSearchParameters?.workpack[0]?.videoLogDays[mediaSearchParameters?.workpack[0]?.videoLogDays.length - 1]}
            highlightDates={mediaSearchParameters?.workpack[0]?.videoLogDays}
          />
        </div>
        <div className={classes.datePickerWrapper}>
          <UniversalDatePicker
            label="To"
            lockToType={UniversalDatePickerValueType.AtTime}
            value={toTime}
            onChange={(result) => setToTime(result ?? undefined)}
            hideAt={true}
            minDate={mediaSearchParameters?.workpack[0]?.videoLogDays[0]}
            maxDate={mediaSearchParameters?.workpack[0]?.videoLogDays[mediaSearchParameters?.workpack[0]?.videoLogDays.length - 1]}
            highlightDates={mediaSearchParameters?.workpack[0]?.videoLogDays}
          />
        </div>
        <div className={classes.datePickerWrapper}>
          <FormControl>
            <InputLabel shrink={true}>Vessel/Resource</InputLabel>
            {mediaSearchParametersLoading ? (
              <div>
                <Skeleton width="10em" height="2.5em" style={{ padding: 0, margin: 0, marginTop: '1em' }} />
              </div>
            ) : (
              <Select style={{ width: '10em' }} value={resourceId} onChange={(e) => setResourceId(e.target.value as string)}>
                <MenuItem value={undefined}>&nbsp;</MenuItem>
                {mediaSearchParameters?.workpack[0]?.vessel
                  ?.flatMap((v) => v.resource.map((r) => ({ resourceId: r.id, vesselCode: v.code, resourceName: r.name })))
                  .map((vr) => (
                    <MenuItem key={vr.resourceId} value={vr.resourceId}>
                      {vr.vesselCode} {vr.resourceName}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormControl>
        </div>
      </Toolbar>
    );
  };

  const tableOptions: Options<any> = {
    padding: 'dense',
    search: false,
    draggable: false,
    emptyRowsWhenPaging: false,
    pageSizeOptions: [100, 500, 1000],
    headerStyle: {
      fontWeight: 'bold',
      borderBottom: `2px solid black`
    },
    pageSize: parseInt(localStorage.getItem('rowsPerPage') ?? '100', undefined)
  };

  function getMediaFormatImage(fileName: string) {
    const type = getMediaType(fileName);

    switch (type) {
      case MediaType.Image:
        return <ImageOutlined />;
      case MediaType.Video:
        return <VideocamOutlined />;
      case MediaType.Unknown:
        return <ErrorOutline />;
    }
  }

  const sortByMediaType = (a: string, b: string) => {
    if (getMediaType(a) < getMediaType(b)) {
      return -1;
    }

    if (getMediaType(a) > getMediaType(b)) {
      return 1;
    }

    return 0;
  };

  const renderPlayMedia = (i: MediaSearchItem) => (
    <div>
      <IconButton size="small" onClick={(e) => handleOpenViewer(i, e)}>
        <PlayCircleOutline fontSize="small" />
      </IconButton>
    </div>
  );

  const handleCloseViewer = () => {
    setFocusedVideo(undefined);
  };

  const handleOpenViewer = (i: MediaSearchItem, e: any) => {
    if (!e || !i) {
      return;
    }
    e.stopPropagation();
    setFocusedVideo(i.id);
  };

  return (
    <React.Fragment>
      <Box style={{ width: '100%' }}>
        <Paper>
          <MaterialTable
            isLoading={loading}
            options={tableOptions}
            columns={[
              {
                title: 'Type',
                field: 'mediaType',
                width: '6em',
                render: (row) => <div>{getMediaFormatImage(row.filename)}</div>,
                customSort: (a, b) => sortByMediaType(a.filename, b.filename)
              },
              {
                title: 'Start Time',
                field: 'startTime',
                defaultSort: 'asc',
                width: '150px',
                type: 'datetime',
                render: (a) => format(new Date(a.startTime), 'd/MM/yy\u00a0HH:mm')
              },
              {
                title: 'End Time',
                field: 'endTime',
                width: '150px',
                type: 'datetime',
                render: (a) => (a.endTime === null ? '' : format(new Date(a.endTime), 'd/MM/yy\u00a0HH:mm'))
              },
              {
                title: 'Resource',
                field: 'resource.name',
                width: '250px',
                render: (a) => `${a.resource.vessel.code} ${a.resource.name}`
              },
              {
                title: 'Media',
                field: 'isVideoAvailable',
                type: 'boolean',
                searchable: false,
                sorting: false,
                render: renderPlayMedia,
                width: '2em',
                align: 'center',
                cellStyle: {
                  paddingTop: 0,
                  paddingBottom: 0
                }
              },
              {
                title: 'File Name',
                field: 'filename',
                searchable: true
              }
            ]}
            data={data?.workpack[0]?.mediaSearch ?? []}
            components={{ Toolbar: SearchBox }}
          />
        </Paper>
      </Box>
      {focusedVideo ? <MediaViewerDialog id={focusedVideo} onClose={handleCloseViewer} /> : null}
    </React.Fragment>
  );
}
