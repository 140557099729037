import React, { useEffect, useState } from 'react';
import { MapProperties, SecondaryMapProperties } from '../../MapUtils/SharedTypes';
import { RouteComponentProps } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { MapDataQuery } from '../../MapUtils/MapQueries';
import { MapConfiguration, WorkpackData } from '../extras/Types';
import { EsriLayerServiceProperties } from './EsriLayerService';
import { DeckLayerServiceProperties } from './DeckLayerService';
import SecondaryDeckMap from './SecondaryDeckMap';

export default function DeckMapWrapper(props: SecondaryMapProperties & RouteComponentProps): JSX.Element {
  const { loading, data: mapData } = useQuery<{ workpack: WorkpackData[]; mapConfiguration: MapConfiguration }, { id: string }>(
    gql`
      ${MapDataQuery}
    `,
    { variables: { id: props.Queries.WorkpackId } }
  );
  const [properties, setProperties] = useState<MapProperties>();

  useEffect(() => {
    if (!mapData) return;

    const deckProps: DeckLayerServiceProperties = {
      fieldLayoutLayers: mapData.mapConfiguration.layerUrls,
      scene3dLayers: mapData.mapConfiguration.esri3dUrls,
      bounds: mapData?.mapConfiguration?.mapBounds,
      featureTileLayers: mapData?.mapConfiguration?.featureTileUrls?.map((ft) => ft.url)
    };

    const esriProps: EsriLayerServiceProperties = {
      layerInfo: mapData.mapConfiguration.esriFeatureLayers
        .filter((x) => x !== null)
        .map((x) => ({
          url: x.url,
          props: { visible: true },
          datumTransformationId: x.datumTransformationId
        }))
    };

    const newMapProps: MapProperties = {
      esriProperties: esriProps,
      deckProperties: deckProps,
      token: mapData.mapConfiguration.esriToken
    };

    setProperties(newMapProps);
  }, [mapData]);

  if (!properties) {
    return <React.Fragment />;
  }

  return <SecondaryDeckMap {...properties} {...props} />;
}
