import { CompositeLayer } from '@deck.gl/core';
import { ScatterplotLayer, LineLayer, TextLayer } from '@deck.gl/layers';
import { calculateBearing, LatLng } from '../../MappingUtils';

function GetBearings(data) {
  let bearings = [];
  for (let i = 0; i < data.length - 1; i++) {
    const p1 = new LatLng(data[i][1], data[i][0]); // flip the latlngs
    const p2 = new LatLng(data[i + 1][1], data[i + 1][0]);
    const b = calculateBearing(p1, p2);
    bearings.push(b);
  }

  return bearings;
}

export default class RulerLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    const rebuildIndex = changeFlags.dataChanged || props.data.length !== oldProps.data.length;
    let positioningData = [];

    if (rebuildIndex) {
      if (props.data.length > 1) {
        positioningData = GetBearings(props.data);
      }
      this.setState({ data: props.data, positioningData });
    }
  }

  renderLayers() {
    const { data } = this.state;

    return [
      new LineLayer(
        this.getSubLayerProps({
          id: 'ruler-line-layer',
          data,
          pickable: true,
          getWidth: 1,
          getSourcePosition: (d) => {
            return d;
          },
          getTargetPosition: (d) => {
            const { data } = this.state;
            const index = data.indexOf(d);
            const next = data[index + 1];

            if (next) {
              return next;
            } else {
              return d;
            }
          },
          getColor: (d) => [45, 48, 53, 255]
        })
      ),
      new ScatterplotLayer(
        this.getSubLayerProps({
          id: 'ruler-point-layer',
          data,
          pickable: true,
          opacity: 0.8,
          stroked: true,
          filled: true,
          radiusScale: 1,
          radiusMinPixels: 5,
          radiusMaxPixels: 5,
          lineWidthMinPixels: 1,
          lineWidthMaxPixels: 1,
          getRadius: 5,
          getPosition: (d) => d,
          getFillColor: (d) => [45, 48, 53, 255],
          getLineColor: (d) => [255, 255, 255]
        })
      ),
      new TextLayer({
        id: 'ruler-text-layer',
        data,
        pickable: true,
        getPixelOffset: (d) => [30, -15],
        getPosition: (d) => d,
        getText: (d) => {
          const { data, positioningData } = this.state;
          const index = data.indexOf(d);
          if (!positioningData[index - 1]) {
            return '';
          }
          return `Bearing: ${positioningData[index - 1].Bearing}° \nDistance: ${positioningData[index - 1].Distance} km`;
        },
        getColor: [45, 48, 53, 255],
        backgroundColor: [0, 0, 0, 0],
        getSize: 22,
        getAngle: 0,
        getTextAnchor: 'start',
        getAlignmentBaseline: 'top'
      })
    ];
  }
}
