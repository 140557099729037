import { ReportProblemOutlined, BuildOutlined, VisibilityOutlined, CheckCircleOutline } from '@material-ui/icons';
import React from 'react';
import { MasterAnomalyStatus } from '../../../utils/enumerations';
import { MAStatusColour } from '../Functions';

export default function StatusIcon(props: { status: MasterAnomalyStatus; fontSize?: number | 'inherit' | 'default' | 'small' | 'large' }) {
  const fontSize = props.fontSize ?? 64;
  switch (props.status) {
    case MasterAnomalyStatus.New:
      return <ReportProblemOutlined style={{ fontSize, color: MAStatusColour(props.status) }} />;
    case MasterAnomalyStatus.Rectify:
      return <BuildOutlined style={{ fontSize, color: MAStatusColour(props.status) }} />;
    case MasterAnomalyStatus.Monitor:
      return <VisibilityOutlined style={{ fontSize, color: MAStatusColour(props.status) }} />;
    case MasterAnomalyStatus.Closed:
      return <CheckCircleOutline style={{ fontSize, color: MAStatusColour(props.status) }} />;
  }
}
