import React, { Suspense, useRef, useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { createStyles, makeStyles } from '@material-ui/core';
import AssetModelCameraController from './AssetModelCameraController';
import { FocusedComponentProps, isRootComponent } from './ModelViewerHelpers';
import Model from './Model';
import CameraController from './CameraController';
import { ColoringMethod } from './ModelColourSchema/Constants';
import { ErrorCollectorBoundary } from '../../ErrorCollectorBoundary';
import { HeatmapData } from '../Interfaces';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// 25A9A5AB-45E6-45D1-9F49-42E6E78752DC

interface ModelViewerProps {
  modelUrl: string;
  modelData: any;
  heatmapDataUrls: string[];
  selectedComponents: string[];
  onChangedFocus: (e: any) => void;
}

export default function AssetModelViewer(props: ModelViewerProps): JSX.Element {
  const [transparency, setTransparency] = useState(false);
  const [coloringMethod, setColoringMethod] = useState<ColoringMethod>(ColoringMethod.Normal);
  const [allHeatmapData, setHeatmapData] = useState<HeatmapData[]>(new Array<HeatmapData>());
  const [selectedHeatmapData, setSelectedHeatmapData] = useState<number>(0);
  const [fetchingHeatmapData, setFetchingHeatmapData] = useState(false);

  const focused = { components: props.selectedComponents, lookAt: false } as FocusedComponentProps;
  const rOrbit = useRef<OrbitControls>();
  const rModel = useRef();

  useEffect(() => {
    if (!focused || focused?.components.length === 0) {
      console.log('exiting early');
      return;
    }

    if (isRootComponent(focused.components[0]) && focused.lookAt) {
      rOrbit?.current?.reset();
    }
  }, [focused]);

  const classes = makeStyles(() =>
    createStyles({
      parentContainer: {
        position: 'relative',
        height: '99%'
        // height: '100%',
        // width: '100%'
      }
    })
  )();

  if (props.heatmapDataUrls && !fetchingHeatmapData) {
    setFetchingHeatmapData(true);

    // https://stackoverflow.com/questions/29246444/fetch-how-do-you-make-a-non-cached-request
    Promise.all(props.heatmapDataUrls.map((url) => fetch(url, { cache: 'no-cache' }).then((res) => res.json())))
      .then((dataSet) => {
        console.log(dataSet);
        setHeatmapData(dataSet.map((data) => data as HeatmapData));
      })
      .catch((error) => console.log(error));
  }

  return (
    <div className={classes.parentContainer}>
      <ErrorCollectorBoundary>
        <AssetModelCameraController
          orbitController={rOrbit}
          toggleTransparency={() => setTransparency(!transparency)}
          setColoringMethod={(mode: ColoringMethod) => setColoringMethod(mode)}
          heatmapDataUrls={props.heatmapDataUrls} // Data is not downloaded a second time, only used to confirm data existence.
          allHeatmapData={allHeatmapData}
          setSelectedHeatmapData={(index: number) => setSelectedHeatmapData(index)}
        />
        <Canvas style={{ width: '100%', height: '100%' }}>
          <CameraController orbitRef={rOrbit} />
          <Suspense
            fallback={
              <mesh>
                <sphereGeometry />
                <meshStandardMaterial color="hotpink" />
              </mesh>
            }
          >
            <Model
              modelUrl={props.modelUrl}
              modelData={props.modelData}
              heatmapData={allHeatmapData[selectedHeatmapData]}
              coloringMethod={coloringMethod}
              focused={focused}
              onChangedFocus={props.onChangedFocus}
              transparency={transparency}
              modelRef={rModel}
            />
          </Suspense>
        </Canvas>
      </ErrorCollectorBoundary>
    </div>
  );
}
