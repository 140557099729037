import { CircularProgress, createStyles, Fade, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WorkpackId } from '../../types/params.type';
import { LayerUrl, MapLoader, MapProperties, MarkerInfo, SecondaryMapProperties } from './MapUtils/SharedTypes';
import SideBar from './Overlay/SideBar';
import DeckMapWrapper from './DeckGL/NewMap/DeckMapWrapper';

const useStyles = makeStyles(() =>
  createStyles({
    normal: {
      zIndex: 999,
      width: '100%',
      height: '100%',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0, 0, 0, 0.9)'
    }
  })
);

export default function MapContainer(props: WorkpackId & RouteComponentProps): JSX.Element {
  const styles = useStyles();
  //const skeletonStyles = useSkeletonStyles();
  const [focusedMarkers, setFocusedMarkers] = useState<MarkerInfo[]>([]);
  const [checkboxes, setCheckbox] = useState({
    events: true,
    tasks: true,
    anomalies: true,
    text: true
  });
  const { events, tasks, anomalies, text } = checkboxes;
  const [searchItem, setSearchItem] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isMeasuring, setIsMeasuring] = useState<boolean>(false);

  const [customCheckboxes, setCustomCheckboxes] = useState<Map<string, boolean>>(new Map());
  const [esriCheckboxes, setEsriCheckboxes] = useState<Map<string, boolean>>(new Map());

  useEffect(() => {
    if (searchItem !== '') {
      console.log('[MapContainer.tsx] Recieved Search Id:' + searchItem);
    }
  }, [searchItem]);

  useEffect(() => {
    // console.log(checkboxes);
  }, [checkboxes]);

  useEffect(() => {
    if (!isLoading) {
      setEsriCheckboxes(esriCheckboxes);
    }
  }, [isLoading]);

  const hCheckboxUpdate = (name: string, enabled: boolean) => {
    setCheckbox({ ...checkboxes, [name]: enabled });
  };

  const hCustomCheckboxUpdate = (name: string, enabled: boolean) => {
    const cloned = customCheckboxes;
    cloned.set(name, enabled);
    setCustomCheckboxes(cloned);
  };

  const hSetMarkers = (markers: MarkerInfo[]) => {
    setFocusedMarkers(markers);
  };

  const hEsriLayerUpdate = (layers: Map<string, boolean>) => {
    setEsriCheckboxes(layers);
  };

  const hIsMeasuring = (enabled: boolean) => {
    setIsMeasuring(enabled);
  };

  const mapProps: SecondaryMapProperties = {
    Markers: {
      Events: events,
      Anomalies: anomalies,
      Tasks: tasks,
      Text: text
    },
    Queries: {
      searchId: searchItem,
      WorkpackId: props.workpackId,
      State: setLoading
    },
    CustomLayers: esriCheckboxes,
    Handlers: {
      SetMarkers: hSetMarkers,
      IsMeasuring: isMeasuring
    }
  };

  return (
    <React.Fragment>
      <div style={{ position: 'relative', minHeight: '50vh' }}>
        <Fade in={isLoading}>
          <div className={styles.normal} style={{ minWidth: '100%', height: '76vh' }}>
            <CircularProgress style={{ position: 'absolute', marginBottom: '80px', height: '40px', width: '40px', color: 'white' }} />
            <div style={{ fontWeight: 'bold', position: 'relative', color: 'white' }}>Fetching Map Information...</div>
          </div>
        </Fade>
        <DeckMapWrapper {...props} {...mapProps} />
        <SideBar
          workpackId={props.workpackId}
          checkboxes={hCheckboxUpdate}
          customCheckboxes={hCustomCheckboxUpdate}
          esriLayers={hEsriLayerUpdate}
          focusedMarkers={focusedMarkers}
          searchItem={setSearchItem}
          onIsMeasuring={hIsMeasuring}
          onIsMeasureRunning={isMeasuring}
        />
      </div>
    </React.Fragment>
  );
}
