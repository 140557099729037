import React, { useEffect } from 'react';
import { Breadcrumbs, Grid, Link, Typography } from '@material-ui/core';
import SearchBar from '../../map/Overlay/panels/SearchBar';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Component } from '../Interfaces';
import { Skeleton } from '@material-ui/lab';

export default function BreadCrumbBar(props: {
  data: any;
  selectedComponent: any;
  originalRoot: any;
  raiseSelectedComponentChanged: (arg: any) => void;
}): JSX.Element {
  const selectedComponentData = props.data;
  const originalRoot = props.originalRoot;
  const selectedComponent: Component[] = props.selectedComponent;

  const { data: dataChild } = useQuery<{ component: any }, { rootComponent: string }>(
    gql`
      query rootComponentChildren($rootComponent: String) {
        component(fullComponent: $rootComponent) {
          id
          childComponents {
            componentCode
            fullComponent
            id
          }
        }
      }
    `,
    { variables: { rootComponent: originalRoot } }
  );

  const breadcrumbText = (focusedComponents: any) => {
    if (isLoading()) {
      return <Skeleton variant="rect" width={100} />;
    }

    if (focusedComponents?.length == 1) {
      return focusedComponents[0]?.fullComponent?.split('\\').map((component: string, index: number) => {
        return (
          <div key={`${component}-${index}`} style={{ float: 'left' }}>
            <Link onClick={() => onBreadcrumbTextOnClick(selectedComponent, component)}>{`${component}\\`}</Link>
          </div>
        );
      });
    } else {
      return <React.Fragment />;
    }
  };

  const headerText = (focusedComponents: any) => {
    if (isLoading()) {
      return <Skeleton variant="rect" width={200} />;
    }

    if (focusedComponents?.length === 1) {
      const comp = focusedComponents[0];
      if (!comp) {
        return <Typography variant="h5">Invalid Component</Typography>;
      } else if (!comp?.category) {
        return <Typography variant="h5">{comp.componentCode}</Typography>;
      } else if (comp) {
        return (
          <Typography variant="h5">
            {comp?.category} - {comp.componentCode}
          </Typography>
        );
      }
    } else if (focusedComponents?.length === 0) {
      return <Typography variant="h5">Invalid Component</Typography>;
    } else if (focusedComponents === undefined) {
      return <React.Fragment />;
    } else {
      return <Typography variant="h5">{focusedComponents.length} Component(s) Selected</Typography>;
    }

    return <React.Fragment />;
  };

  function buildComponentTree(component: any, clickedComponent: string) {
    const fullComponent = component[0]?.fullComponent;
    let _index = -1;
    let newCompTree = '';

    if (fullComponent !== undefined) {
      const childComponents = fullComponent.split('\\');

      childComponents.forEach((childComp: string, i: number) => {
        if (_index !== -1) return;

        if (childComp === clickedComponent) {
          _index = i;
          newCompTree += `${childComp}`;
        } else {
          newCompTree += `${childComp}\\`;
        }
      });
    }

    return newCompTree;
  }

  function onSetSearchItem(id: string) {
    const focusedComp = dataChild?.component?.childComponents.filter((c: any) => c.id === id)[0];

    if (focusedComp) {
      props.raiseSelectedComponentChanged([focusedComp.fullComponent]);
    }
  }

  function onBreadcrumbTextOnClick(component: any, clickedComponent: string) {
    const compHierachy = buildComponentTree(component, clickedComponent);
    props.raiseSelectedComponentChanged([compHierachy]);
  }

  function isLoading() {
    return !selectedComponentData || !originalRoot || !selectedComponent;
  }

  return (
    <Grid container>
      <Grid item xs={8}>
        <Breadcrumbs>
          <div style={{ minHeight: '24px' }}>{breadcrumbText(selectedComponent)}</div>
        </Breadcrumbs>
        <div style={{ minHeight: '32px' }}>{headerText(selectedComponent)}</div>
      </Grid>
      <Grid item xs={4}>
        <div style={{ padding: '5px', paddingRight: '0px' }}>
          <SearchBar
            components={dataChild?.component?.childComponents}
            setSearchItem={onSetSearchItem}
            getSearchItem={selectedComponent.length === 1 ? selectedComponent[0] : null}
          />
        </div>
      </Grid>
    </Grid>
  );
}
