import { useQuery } from '@apollo/react-hooks';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { gql } from 'apollo-boost';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { WorkpackId } from '../../../types/params.type';
import ErrorMessage from '../../ErrorMessage';
import SiteAnomalies from './SiteAnomalies';
import TaskProgress from './TaskProgress';
import WorkpackOperationalCodes from './WorkpackOperationalCodes';
import WorkpackProgress from './WorkpackProgress';
import WorkpackStatisticsHeader from './WorkpackStatisticsHeader';
import MasterAnomaliesChart from './MasterAnomaliesBarGraph';
import { AnomalyCode, Incident } from '../../map/MapUtils/SharedTypes';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // paddingTop: '1em'
      maxWidth: '95vw'
    },
    divider: {
      marginTop: '10px'
    }
  })
);

export interface WorkpackStatsTaskProgress {
  id: string;
  code: string;
  completionCode: string;
  component: string;
  complete: number;
  estimatedTimeMinutes: number;
  actualTimeSeconds: number;
  remainingTimeSeconds: number;
  preferredResourceType: number;
  passes: number;
  lastProgressTime: Date;
  variance: number;
  varianceSeconds: number;
}

export interface WorkpackStatsItem {
  name: string;
  startDate: Date;
  endDate: Date;
  _itemCount: { anomaly: number };
  incident: Incident[];
  operationsLog: WorkpackStatsOperationsLogItem[];
  anomaly: Array<{
    criticality: string;
    anomalyCode: AnomalyCode;
    masterAnomaly: {
      id: string;
      description: string;
      action: string;
      status: string;
      reviewedBy: string;
      closedBy: string;
      anomalyCode: AnomalyCode;
    };
  }>;
  progress: {
    completedTaskCount: number;
    totalTaskCount: number;
    estimatedCompletionDate: number;
    actualCompletionTime: number;
    overallTaskTime: number;
    task: WorkpackStatsProgressTaskItem[];
  };
  uptime: number;
  simsTaskProgress: WorkpackStatsTaskProgress[];
}

export interface WorkpackStatsProgressTaskItem {
  time: Date;
  completionTaskCumulative: number;
  completionTimeCumulative: number;
}

export interface WorkpackStatsOperationsLogItem {
  duration: number;
  startTime: Date;
  endTime: Date;
  operationalCode: { code: string; description: string };
}

export default function WorkpackStatisticsDashboard(props: RouteComponentProps<WorkpackId>): JSX.Element {
  const classes = useStyles();

  const { loading, error, data } = useQuery<{ workpack: WorkpackStatsItem[] }, object>(
    gql`
      query workpackStats($id: ID!) {
        workpack(id: $id) {
          name
          startDate
          endDate
          _itemCount {
            anomaly
          }
          incident {
            id
            anomaly {
              id
              criticality
            }
            eventCode {
              code
              mode
              description
            }
            subEventCode
            comment
            isAnomalous
          }
          operationsLog {
            duration
            startTime
            endTime
            operationalCode {
              code
              description
            }
          }
          anomaly {
            criticality
            anomalyCode {
              code
              description
            }
            masterAnomaly {
              id
              description
              action
              status
              reviewedBy
              closedBy
              anomalyCode {
                code
                description
              }
            }
          }
          progress {
            completedTaskCount
            totalTaskCount
            estimatedCompletionTime
            actualCompletionTime
            overallTaskTime
            estimatedCompletionDate
            task {
              time
              completionTaskCumulative
              completionTimeCumulative
            }
          }
          uptime
          simsTaskProgress {
            id
            code
            completionCode
            component
            complete
            estimatedTimeMinutes
            actualTimeSeconds
            remainingTimeSeconds
            preferredResourceType
            passes
            lastProgressTime
            variance
            varianceSeconds
          }
        }
      }
    `,
    { variables: { id: props.match.params.workpackId } }
  );

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  return (
    <div>
      <Grid className={classes.root} container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <WorkpackStatisticsHeader loading={loading} data={data?.workpack[0]} />
        </Grid>
      </Grid>
      <Grid className={classes.root} container={true} spacing={1}>
        <Grid item={true} xs={8}>
          <WorkpackProgress loading={loading} data={data?.workpack[0]} />
        </Grid>
        <Grid item={true} xs={4}>
          <WorkpackOperationalCodes loading={loading} data={data?.workpack[0]} />
        </Grid>
        <Grid container={true} item={true} xs={8}>
          <TaskProgress loading={loading} data={data?.workpack[0]?.simsTaskProgress} />
        </Grid>
        {/* <Grid item={true} xs={4}>
          <MasterAnomaliesChart loading={loading} data={data?.workpack[0]} />
        </Grid> */}
        <Grid item={true} xs={4}>
          <SiteAnomalies loading={loading} data={data?.workpack[0]} />
        </Grid>
      </Grid>
    </div>
  );
}
