import { TextLayer } from '@deck.gl/layers';
import Supercluster from 'supercluster';
import { getClusterData } from '../extras/Functions';
import { MVTLayer } from 'deck.gl';

export default class TextMvtLayer extends MVTLayer {
  shouldUpdateState({ changeFlags }) {
    // console.log(this.props.data);
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    var dataChanged = this.state.dataChanged;
    const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale || dataChanged;

    if (rebuildIndex) {
      const index = new Supercluster({ maxZoom: 21, radius: 45 });
      if (this.state.newData) {
        index.load(
          this.state.newData.map((d) => ({
            geometry: { coordinates: d.geometry.coordinates },
            properties: d
          }))
        );
        this.setState({ index });
      }

      this.setState({ dataChanged: false });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if ((rebuildIndex || z !== this.state.z) && this.state.index) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z
      });
    }
  }

  getFeatureUniqueId(feature, uniqueIdProperty) {
    if (uniqueIdProperty) {
      return feature.properties[uniqueIdProperty];
    }

    if ('id' in feature) {
      return feature.id;
    }

    return -1;
  }

  onHover(info, pickingEvent) {
    const { uniqueIdProperty, autoHighlight } = this.props;

    if (autoHighlight) {
      const { hoveredFeatureId } = this.state;
      const hoveredFeature = info.object;
      let newHoveredFeatureId;

      if (hoveredFeature) {
        newHoveredFeatureId = this.getFeatureUniqueId(hoveredFeature, uniqueIdProperty);
      }

      if (hoveredFeatureId !== newHoveredFeatureId && newHoveredFeatureId !== -1) {
        this.setState({ hoveredFeatureId: newHoveredFeatureId });
      }
    }

    return super.onHover(info, pickingEvent);
  }

  onError(error) {
    // If we get a 403 Redirect, supress it, but we shouldnt cause S3 now returns a blank.pbf - (but actually it doesnt)
    // Only print out non-403 errors.
    if (!error?.message?.includes('403')) {
      // console.log(error);
    }
  }

  // Shitty hotfix for using mismatching versions of loaders.gl/deck.gl
  // fixes subtext layers not having a isVisible property by always returning true.
  filterSubLayer({ _layer }) {
    return true;
  }

  // getPickingInfo({ info, mode }) {
  //     const pickedObject = info.object && info.object.properties;
  //     if (pickedObject) {
  //         // patched this to always return all the information regardless of hovering/clicking
  //         // also updated the maximum getLeaves nodes from 25 -> 100, don't know if affects performance
  //         // if (pickedObject.cluster && mode !== 'hover') {
  //         if (pickedObject.cluster && mode) {
  //             info.objects = this.state.index
  //                 .getLeaves(pickedObject.cluster_id, 100)
  //                 .map(f => f.properties);
  //         }
  //         info.object = pickedObject;
  //     }
  //     return info;
  // }

  renderLayers() {
    const { data } = this.state;

    return [
      new MVTLayer({
        onTileError: this.onError,
        id: `m-layer`,
        data: this.props.data,
        minZoom: 0,
        maxZoom: 20,
        uniqueIdProperty: this.props.uniqueIdProperty, // 'EntityHandle',
        // @ts-ignore
        filled: true,
        onTileLoad: (tile, arg2) => {
          tile.dataInWGS84.forEach((feature) => {
            if (feature.properties.SubClasses?.toLowerCase().endsWith('text')) {
              if (feature) {
                let textEnts = this.state.newData ? this.state.newData : [];
                if (textEnts.filter((x) => x.properties.EntityHandle === feature.properties.EntityHandle).length === 0) {
                  let test = [...textEnts, feature];
                  // console.log("[Tile Loaded] text entities length: ", test.length, " added: ", feature.properties.EntityHandle);

                  this.setState({ dataChanged: true });
                  this.setState({ newData: test });
                }
              }
            }
          });
        },
        // getLineColor: () => [Math.floor(Math.random() * 255), Math.floor(Math.random() * 255), Math.floor(Math.random() * 255)],
        getLineColor: [255, 0, 255],
        getFillColor: [0, 35, 102],
        highlightColor: this.props.highlightColor,
        getLineWidth: 1,
        lineWidthMinPixels: 1,
        lineWidthUnits: 'pixels',
        // pickable: true,
        // onHover: this.props.onHoverMvt,
        autoHighlight: this.props.autoHighlight,
        updateTriggers: {
          getFillColor: [this.state.hoveredFeatureId ? this.state.hoveredFeatureId : null],
          highlightColor: [this.state.hoveredFeatureId ? this.state.hoveredFeatureId : null]
        },
        parameters: {
          depthTest: false
        }
      }),
      new TextLayer({
        id: 't-layer',
        visible: this.props.showText,
        data: data ? data : null,
        uniqueIdProperty: 'EntityHandle',
        getPosition: (d) => {
          return d.geometry.coordinates;
        },
        getText: (d, f) => {
          const { index } = this.state;
          let clusterData = getClusterData(d, index);
          return `${clusterData[0].properties.Text}`;
        },
        fontFamily: 'sans-serif',
        fontSettings: { sdf: false },
        getColor: [0, 0, 0, 255],
        getSize: 18,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        parameters: {
          depthTest: false
        }
      })
    ];
  }
}
