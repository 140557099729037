import { addMinutes } from 'date-fns';

const ONE_HOUR = 1000 * 60 * 60;

export function formatDateApi(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function getDaysBetween(date1: Date, date2: Date) {
  const diff = Math.abs(date1.getTime() - date2.getTime());
  return Math.ceil(diff / (1000 * 3600 * 24));
}

export function getHoursBetween(date1: Date, date2: Date) {
  return Math.round(Math.abs((date1.getTime() - date2.getTime()) / ONE_HOUR));
}

function pad(num: number, size: number): string {
  let s = num + '';
  while (s.length < size) {
    s = '0' + s;
  }
  return s;
}

export const unLocalTime = (src?: Date) => (src ? addMinutes(src, src.getTimezoneOffset() * -1) : undefined);

export function formatDate(currentDate: Date): string {
  const formattedDate = pad(currentDate.getDate(), 2) + '-' + pad(currentDate.getMonth() + 1, 2) + '-' + pad(currentDate.getFullYear(), 2);
  return formattedDate;
}

export function formatDateReverse(currentDate: Date): string {
  const formattedDate = pad(currentDate.getFullYear(), 2) + '-' + pad(currentDate.getMonth() + 1, 2) + '-' + pad(currentDate.getDate(), 2);
  return formattedDate;
}

export function addDays(date: Date, days: number): Date {
  date.setDate(date.getDate() + days);
  return date;
}

export interface Duration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export function getDuration(milliseconds: number): Duration {
  let hours;
  let minutes;
  let seconds;

  seconds = Math.floor(milliseconds / 1000);
  minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  const days = Math.floor(hours / 24);
  hours = hours % 24;

  return {
    days,
    hours,
    minutes,
    seconds
  };
}

export function formatDuration(duration: Duration): string {
  const days = duration.days !== 0 ? `${duration.days}d ` : '';
  const hours = duration.hours !== 0 ? `${duration.hours}h ` : '';
  const minutes = duration.minutes !== 0 ? `${duration.minutes}m` : '';
  return `${days}${hours}${minutes}`;
}

export function prettifyDate(dateTime: string) {
  const options = {
    weekday: 'short' as 'short',
    year: 'numeric' as 'numeric',
    month: 'long' as 'long',
    day: 'numeric' as 'numeric',
    hour: 'numeric' as 'numeric',
    minute: 'numeric' as 'numeric'
  };

  const today = new Date(dateTime);

  return today.toLocaleDateString('en-AU', options);
}

// accepts minutes
export function timeConvert(n: number): string {
  n = Math.round(n);
  const min = n % 60;
  const h = (n - min) / 60;
  return `${pad(h, 2)}:${pad(min, 2)}`;
}

// accepts seconds
export function formatSeconds(n: number): string {
  const sec_num = parseInt(n.toString(), 10); // don't forget the second param
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - hours * 3600) / 60);
  const seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 3600) {
    return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
  }

  return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
}
