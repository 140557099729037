import {
  Badge,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  makeStyles,
  Tab,
  Tabs,
  TextField
} from '@material-ui/core';
import { InsertInvitation } from '@material-ui/icons';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

export enum UniversalDatePickerValueType {
  OnDay,
  AtTime,
  InRange
}

interface UniversalDatePickerDialogProps {
  value?: UniversalDatePickerValue;
  lockToType?: UniversalDatePickerValueType;
  minDate?: Date;
  maxDate?: Date;
  highlightDates?: Date[];

  open: boolean;
  onOk: (result?: UniversalDatePickerValue) => void;
  onClose: () => void;
}

interface UniversalDatePickerProps {
  value?: UniversalDatePickerValue;
  lockToType?: UniversalDatePickerValueType;
  minDate?: Date;
  maxDate?: Date;
  highlightDates?: Date[];

  label?: string;
  onChange: (result?: UniversalDatePickerValue) => void;
  hideAt?: boolean;
}
export interface UniversalDatePickerValue {
  type: UniversalDatePickerValueType;
  from?: Date;
  to?: Date;
}

export const UniversalDatePicker = (props: UniversalDatePickerProps) => {
  const useStyles = makeStyles(() =>
    createStyles({
      textField: {
        fontSize: '9pt'
      }
    })
  );
  const classes = useStyles();

  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<UniversalDatePickerValue | undefined>(props.value ?? undefined);

  useEffect(() => {
    setSelectedValue(props.value);
  }, [props.value]);

  const valueDisplayText = () => {
    if (selectedValue === undefined || selectedValue === null) {
      return '';
    }
    switch (selectedValue.type) {
      case UniversalDatePickerValueType.OnDay: {
        return `on ${selectedValue.from ? format(selectedValue.from, 'dd/MM/yy') : ''}`;
      }
      case UniversalDatePickerValueType.AtTime: {
        return `${props.hideAt ? '' : 'at '} ${selectedValue.from ? format(selectedValue.from, 'dd/MM/yy HH:mm') : ''}`;
      }
      case UniversalDatePickerValueType.InRange: {
        if (selectedValue.from === undefined || selectedValue.to === undefined) {
          return '';
        }
        return `${format(selectedValue.from, 'dd/MM/yy')} - ${format(selectedValue.to, 'dd/MM/yy')}`;
      }
      default: {
        return '';
      }
    }
  };

  const handleOpenDialog = () => setPickerOpen(true);
  const handleOnOk = (result?: UniversalDatePickerValue) => {
    setSelectedValue(result);
    if (props.onChange) {
      props.onChange(result);
    }
  };
  const handleOnClose = () => setPickerOpen(false);

  return (
    <React.Fragment>
      <TextField
        value={valueDisplayText()}
        label={props?.label}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          className: classes.textField,
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={handleOpenDialog}>
                <InsertInvitation />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <UniversalDatePickerDialog
        lockToType={props.lockToType}
        open={pickerOpen}
        value={selectedValue}
        onOk={handleOnOk}
        onClose={handleOnClose}
        minDate={props.minDate}
        maxDate={props.maxDate}
        highlightDates={props.highlightDates}
      />
    </React.Fragment>
  );
};

const UniversalDatePickerDialog = (props: UniversalDatePickerDialogProps) => {
  const TAB_WIDTH = 180;
  const PICKER_WIDTH = 310;

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      height: 400
    },
    tabs: {
      width: TAB_WIDTH,
      borderRight: `1px solid ${theme.palette.divider}`
    },
    dialogWidthSingleControl: {
      padding: '0!important',
      width: TAB_WIDTH + PICKER_WIDTH
    },
    dialogWidthDoubleControl: {
      padding: '0!important',
      width: TAB_WIDTH + PICKER_WIDTH + PICKER_WIDTH
    },
    dialogWidthSingleControlNoTab: {
      padding: '0!important',
      width: PICKER_WIDTH
    },
    dialogWidthDoubleControlNoTab: {
      padding: '0!important',
      width: PICKER_WIDTH + PICKER_WIDTH
    },
    dialogActionsFirstLeft: {
      justifyContent: 'flex-start',

      '& > *:first-child': {
        marginRight: 'auto'
      }
    }
  }));

  const classes = useStyles();

  const [selectedTab, setSelectedTab] = React.useState<UniversalDatePickerValueType>(
    props?.value?.type ?? props?.lockToType ?? UniversalDatePickerValueType.OnDay
  );

  // useEffect(() => setSelectedTab(props?.value?.type ?? UniversalDatePickerValueType.OnDay), [props?.value?.type]);

  useEffect(() => {
    const type = props?.value?.type;
    const from = props?.value?.from;
    setSelectedTab(type ?? props?.lockToType ?? UniversalDatePickerValueType.OnDay);
    setSelectedFromDate(from);
  }, [props.value]);

  const handleTabChange = (_event: any, newValue: React.SetStateAction<UniversalDatePickerValueType>) => {
    setSelectedTab(newValue);
  };

  const [dialogOpen, setDialogOpen] = useState<boolean>(props.open);
  useEffect(() => setDialogOpen(props.open), [props.open]);

  const [selectedFromDate, setSelectedFromDate] = useState(props?.value?.from);
  const [selectedToDate, setSelectedToDate] = useState(props?.value?.to);

  const handleFromDateChange = (date: any) => setSelectedFromDate(date);
  const handleToDateChange = (date: any) => setSelectedToDate(date);

  const handleClose = () => {
    setDialogOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleClear = () => {
    setSelectedFromDate(undefined);
    setSelectedToDate(undefined);
    handleClose();
    if (props.onOk) {
      props.onOk(undefined);
    }
  };
  const handleOk = () => {
    handleClose();
    if (props.onOk) {
      props.onOk({
        type: selectedTab,
        from: selectedFromDate ?? new Date(),
        to: selectedTab === UniversalDatePickerValueType.InRange ? selectedToDate ?? new Date() : undefined
      });
    }
  };
  const handleCancel = () => {
    handleClose();
  };

  const datePickerRenderDay = (day: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean, dayComponent: any) => {
    if (props.highlightDates && day !== null && dayInCurrentMonth) {
      const df = format(day, 'yyyy-MM-dd');
      if ((props.highlightDates as unknown as string[]).includes(df)) {
        return (
          <Badge variant="dot" color="secondary" overlap="circular">
            {dayComponent}
          </Badge>
        );
      }
    }
    return dayComponent;
  };

  const tabContent = () => {
    switch (selectedTab) {
      case UniversalDatePickerValueType.OnDay: {
        return (
          <DatePicker
            variant="static"
            value={selectedFromDate}
            onChange={handleFromDateChange}
            disableFuture={true}
            minDate={props.minDate}
            maxDate={props.maxDate}
            renderDay={datePickerRenderDay}
          />
        );
      }
      case UniversalDatePickerValueType.AtTime: {
        return (
          <React.Fragment>
            <DatePicker
              variant="static"
              value={selectedFromDate}
              onChange={handleFromDateChange}
              disableFuture={true}
              minDate={props.minDate}
              maxDate={props.maxDate}
              renderDay={datePickerRenderDay}
            />
            <TimePicker autoOk={false} variant="static" ampm={false} value={selectedFromDate} onChange={handleFromDateChange} />
          </React.Fragment>
        );
      }
      case UniversalDatePickerValueType.InRange: {
        return (
          <React.Fragment>
            <DatePicker
              variant="static"
              value={selectedFromDate}
              onChange={handleFromDateChange}
              disableFuture={true}
              minDate={props.minDate}
              maxDate={props.maxDate}
              renderDay={datePickerRenderDay}
            />
            <DatePicker
              variant="static"
              minDate={selectedFromDate}
              value={selectedToDate}
              onChange={handleToDateChange}
              disableFuture={true}
              maxDate={props.maxDate}
              renderDay={datePickerRenderDay}
            />
          </React.Fragment>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Dialog open={dialogOpen} maxWidth={false} onClose={handleCancel}>
      <DialogContent
        className={
          selectedTab === UniversalDatePickerValueType.OnDay
            ? props.lockToType
              ? classes.dialogWidthSingleControlNoTab
              : classes.dialogWidthSingleControl
            : props.lockToType
            ? classes.dialogWidthDoubleControlNoTab
            : classes.dialogWidthDoubleControl
        }
      >
        <div className={classes.root}>
          {props.lockToType === undefined ? (
            <Tabs orientation="vertical" value={selectedTab} onChange={handleTabChange} className={classes.tabs}>
              <Tab label="Events on Day" value={UniversalDatePickerValueType.OnDay} />
              <Tab label="Events at Time" value={UniversalDatePickerValueType.AtTime} />
              <Tab label="Events in Range" value={UniversalDatePickerValueType.InRange} />
            </Tabs>
          ) : null}
          {tabContent()}
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActionsFirstLeft}>
        <Button onClick={handleClear}>Clear</Button>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleOk}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};
