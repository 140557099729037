import { Box, Typography } from '@material-ui/core';
import React from 'react';

const ExpandingPanelErrorText = (props: { message: string }) => (
  <Box style={{ width: '100%', textAlign: 'center', paddingTop: '5em' }}>
    <Typography>
      <b>Uh oh!</b>
    </Typography>
    <Typography>{props.message}</Typography>
  </Box>
);

export default ExpandingPanelErrorText;
