import { CompositeLayer } from '@deck.gl/core';
import Supercluster from 'supercluster';
import { IconLayer, TextLayer } from '@deck.gl/layers';
import { getClusterData } from '../map/DeckGL/extras/Functions.tsx';

export default class StructurePinTextLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale || props.data.length !== oldProps.data.length;
    if (rebuildIndex) {
      const index = new Supercluster({ maxZoom: 21, radius: 45 });
      if (props.data) {
        index.load(
          props.data.map((d) => ({
            geometry: { coordinates: props.getPosition(d) },
            properties: d
          }))
        );
        this.setState({ index });
      }
    }
    const z = Math.floor(this.context.viewport.zoom);
    if ((rebuildIndex || z !== this.state.z) && this.state.index) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z
      });
    }
  }

  onClick(e) {
    const { index } = this.state;

    if (e.object !== undefined && this.props.onSelectStructure) {
      if (e.object.properties.cluster) {
        // Just return the topmost cluster structure when clicked.
        let clusterData = getClusterData(e.object, index);
        this.props.onSelectStructure(clusterData[0]);
      } else {
        this.props.onSelectStructure(e.object.properties);
      }
    }
  }

  renderLayers() {
    const { data } = this.state;

    return [
      // the icons
      new IconLayer({
        id: 'icon',
        data: data,
        iconAtlas: this.props.iconAtlas,
        iconMapping: this.props.iconMapping,
        getPosition: (d) => d.geometry.coordinates,
        getIcon: this.props.getIcon,
        getSize: this.props.getIconSize,
        getColor: this.props.getIconColor,
        updateTriggers: {
          getPosition: this.props.updateTriggers.getPosition,
          getIcon: this.props.updateTriggers.getIcon,
          getSize: this.props.updateTriggers.getIconSize,
          getColor: this.props.updateTriggers.getIconColor
        },
        pickable: true,
        visible: this.props.visible,
        parameters: {
          depthTest: false
        }
      }),
      // the labels
      new TextLayer({
        id: 'name',
        data: data,
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        pickable: true,
        getPosition: (d) => d.geometry.coordinates,
        getText: (d) => {
          const { index } = this.state;
          let clusterData = getClusterData(d, index);
          const topmostCluster = this.props.getNameText(clusterData[0]);
          return topmostCluster;
        },
        getSize: 20,
        getColor: [255, 255, 255],
        getPixelOffset: [24, -8],
        getTextAnchor: 'start',
        visible: this.props.visible,
        updateTriggers: {
          getPosition: this.props.updateTriggers.getPosition,
          getText: this.props.getNameText
        },
        parameters: {
          depthTest: false
        }
      }),
      new TextLayer({
        id: 'masteranom',
        data: data,
        fontFamily: 'sans-serif',
        fontWeight: 'normal',
        getPosition: (d) => d.geometry.coordinates,
        getText: (d) => {
          const { index } = this.state;
          let clusterData = getClusterData(d, index);
          let x = this.props.getMAText(clusterData[0]);
          return x;
        },
        getSize: 16,
        getColor: [255, 125, 125],
        getPixelOffset: [24, 10],
        getTextAnchor: 'start',
        visible: this.props.visible,
        updateTriggers: {
          getPosition: this.props.updateTriggers.getPosition,
          getText: this.props.getMAText
        },
        parameters: {
          depthTest: false
        }
      })
    ];
  }
}
