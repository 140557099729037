import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { MasterAnomalyStatus } from '../../../../../utils/enumerations';
import { MAStatusColour, MAStatusText } from '../../../Functions';
import StatusIcon from '../../StatusIcon';

export default function StatusTile(props: { status: MasterAnomalyStatus; count?: number; onClick?: any; isSelected?: boolean }): JSX.Element {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <StatusIcon status={props.status} />
      </Grid>
      <Grid item>
        <Typography variant="h6" style={{ color: MAStatusColour(props.status) }}>
          {MAStatusText(props.status)}
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 'bold', color: MAStatusColour(props.status) }}>
          {props.count ?? '--'}
        </Typography>
      </Grid>
    </Grid>
  );
}
