import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useCardStyles = makeStyles((theme: Theme) => ({
  testing: {
    fontSize: '0.805em',
    paddingBottom: '3px',
    minHeight: '15px'
  },
  cardDetails: {
    flex: 1,
    height: '35vh'
  },
  cardDetailsLoading: {
    flex: 1,
    height: '25vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'inherit'
  },
  cardHeader: {
    flex: 1,
    height: '5vh'
  },
  cardHeaderLoading: {
    flex: 1,
    height: '5vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'inherit'
  },
  // See https://github.com/jtblin/angular-chart.js/issues/84 for details
  // on chartjs issues with growing height and aspect ratio.
  chartContainer: {
    position: 'relative',
    margin: 'auto',
    height: 'calc(35vh - 2.85rem - 10px)'
  },
  tabCardContainer: {
    borderRadius: '0px',
    border: '1px solid #00000066'
  },
  tabCardContent: {
    padding: '8px'
  },
  tabHeader: {
    display: 'inline',
    width: '100%',
    fontWeight: 'bold'
  },
  tabHeaderPercentage: {
    display: 'inline-block',
    float: 'right'
  },
  tabContainerHeader: {
    paddingTop: '10px'
  },
  cardHeaderPanel: {
    //border: '1px solid red',
    textAlign: 'center'
  },
  cardHeaderContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardHeaderIcon: {
    paddingRight: '5px'
  },
  tooltip: {
    textAlign: 'center',
    fontSize: theme.typography.fontSize
  }
}));
