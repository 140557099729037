import { Grid, Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import WorkpacksSummaryEfficiency from './WorkpacksSummaryEfficiency';
import WorkpacksSummaryPerformance from './WorkpacksSummaryPerformance';
import WorkpacksSummaryProgress from './WorkpacksSummaryProgress';
import WorkpacksSummaryStructures from './WorkpacksSummaryStructures';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.primary,
    minHeight: '20em',
    height: '100%'
  },
  skeletonContainer: {
    height: 0,
    overflow: 'hidden',
    paddingTop: '100%',
    position: 'relative'
  },
  skeleton: {
    width: '100px !important',
    display: 'flex',
    justifySelf: 'flex-end',
    marginRight: '10px'
  }
}));

export default function WorkpacksSummary(props: any): JSX.Element {
  const classes = useStyles();

  const onPremise = process.env.REACT_APP_ON_PREMISE === 'true';

  return (
    <div>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={3}>
          <Paper className={classes.paper}>
            <WorkpacksSummaryProgress loading={props.loading} workpacks={props.workpacks} />
          </Paper>
        </Grid>
        <Grid item={true} xs={onPremise ? 3 : 6}>
          <Paper className={classes.paper}>
            <WorkpacksSummaryPerformance loading={props.loading} workpacks={props.workpacks} />
          </Paper>
        </Grid>
        <Grid item={true} xs={3}>
          <Paper className={classes.paper}>
            <WorkpacksSummaryEfficiency loading={props.loading} workpacks={props.workpacks} />
          </Paper>
        </Grid>
        {onPremise && (
          <Grid item={true} xs={3}>
            <Paper className={classes.paper}>
              <WorkpacksSummaryStructures loading={props.loading} />
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
