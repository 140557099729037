import { loadArcGISModules } from '@deck.gl/arcgis';
import { AnomalyComponent, Incident, LayerUrl, LngLat, MvtProperties } from '../../MapUtils/SharedTypes';
import { LayerInfo } from '../NewMap/EsriLayerService';

export function createLayerPropsFromStorage(storageLayers: any): LayerInfo[] {
  if (!storageLayers) return [];

  const newProps: LayerInfo[] = [];

  storageLayers.forEach((layer: LayerUrl) => {
    newProps.push({ props: { visible: layer.isChecked }, url: layer.originalUrl, datumTransformationId: layer.transformationId });
  });

  return newProps;
}

export function isValidDeckGlExtent(extent: any) {
  return !isNaN(extent.maxLat) && !isNaN(extent.minLat) && !isNaN(extent.maxLng) && !isNaN(extent.minLng);
}

export async function getEsriExtents(urls: string[], token: string) {
  const extents: any[] = [];

  await Promise.all(
    urls.map(async (layer: string) => {
      const fullUrl = `${layer}?f=pjson&token=${token}`;
      const response = await fetch(fullUrl);
      const data = await response.json();

      // extent     = Feature Layers
      // fullExtent = 3d Layers
      const validExtent = data.extent ?? data.fullExtent;

      extents.push(validExtent);
    })
  );

  return extents;
}

export async function projectExtents(extents: any[]) {
  const lnglatExtents: any[] = [];

  const { _, modules } = await loadArcGISModules(
    [
      'esri/rest/geometryService',
      'esri/geometry/SpatialReference',
      'esri/rest/support/ProjectParameters',
      'esri/geometry/Extent',
      'esri/geometry/support/webMercatorUtils'
    ],
    {}
  );

  const [utils, SpatialReference, ProjectParameters, Extent, webMercUtils] = modules;
  // const lnglatExtents: typeof Extent[] = [];
  const xyExtents: typeof Extent[] = [];

  // Cast all our extents into esri Point objects.
  extents.forEach((extent: any) => {
    const sr = new SpatialReference({ wkid: extent.spatialReference.wkid });
    const xyExtent = new Extent({ xmin: extent.xmin, ymin: extent.ymin, xmax: extent.xmax, ymax: extent.ymax, spatialReference: sr });
    xyExtents.push(xyExtent);
  });

  // Create esri projection parameters object>
  const projectParams = new ProjectParameters({
    geometries: xyExtents,
    outSpatialReference: new SpatialReference({ wkid: 102100 }) // Cast everything into webmercator cause thats our default
  });

  // Hit the API endpoint with our projection request, with the result update the original extent object.
  const projectedResults = await utils.project(
    'https://sampleserver6.arcgisonline.com/arcgis/rest/services/Utilities/Geometry/GeometryServer/',
    projectParams
  );

  projectedResults.forEach((result: any) => {
    [result.xmin, result.ymin] = webMercUtils.xyToLngLat(result.xmin, result.ymin);
    [result.xmax, result.ymax] = webMercUtils.xyToLngLat(result.xmax, result.ymax);
    lnglatExtents.push(result);
  });

  return lnglatExtents;
}

export function calculateMinMaxLngLat(coordinates: any[]) {
  const lngs = coordinates.map((e: LngLat) => {
    return e.longitude;
  });
  const lats = coordinates.map((e: LngLat) => {
    return e.latitude;
  });

  const minLng = Math.min(...lngs);
  const minLat = Math.min(...lats);
  const maxLng = Math.max(...lngs);
  const maxLat = Math.max(...lats);

  return {
    minLng,
    minLat,
    maxLng,
    maxLat
  };
}

export function getClusterData(data: any, index: any) {
  let clusterData;
  if (data.properties.cluster) {
    const clusterId = data.properties.cluster_id;
    clusterData = index.getLeaves(clusterId, Infinity).map((x: any) => x.properties);
  } else {
    clusterData = [data.properties];
  }

  return clusterData;
}

// tslint:disable: max-line-length
export function FilterAndSortData(d: any, layers: any) {
  let taskCount = 0;
  let anomCount = 0;
  const pins: any[] = [];
  const mapData = JSON.parse(d.workpack[0].mapDataRawJson);

  mapData.forEach((comp: any) => {
    // Combine all anomalies and incidents and find the first valid location.
    let validIncs = [];
    let validAnoms = [];

    if (layers.Events) {
      if (comp.incidents) {
        validIncs = comp.incidents.filter((i: Incident) => i.navigation.easting !== 0 && i.navigation.northing !== 0);
      }
    }

    if (layers.Anomalies) {
      if (comp.anomalyComponents) {
        validAnoms = comp.anomalyComponents
          .filter((a: AnomalyComponent) => a.anomaly.navigation.easting !== 0 && a.anomaly.navigation.northing !== 0)
          .map((b: AnomalyComponent) => {
            return b.anomaly;
          });
      }
    }

    taskCount += validIncs.length;
    anomCount += validAnoms.length;

    const validItems = [...validIncs, ...validAnoms];
    if (validItems.length !== 0) {
      validItems.forEach((item: any) => {
        const pos = item?.navigation?.lngLat;

        // Cooper dataset has a lot of invalid positions, filter them out here.
        if (pos && (pos.x === -90 || pos.y === undefined)) {
          return;
        }

        if (pos) {
          pins.push({
            id: comp.ComponentID,
            component: comp.fullComponent,
            name: comp.name,
            parent: comp.parent_ID,
            estimatedLocation: pos,
            data: item
          });
        }
      });
    }
  });

  console.log('===================');
  console.log(`Anomalies: ${anomCount} Incidents: ${taskCount}`);
  // Clear out undefined entries
  return pins.filter((item) => item);
}

export function hookEsriWidgetPosition(esriMapRef: any) {
  const container = esriMapRef.current.querySelector('div.esri-overlay-surface');

  if (container) {
    // Fix the main tooltips for each axis, and hide everything else.
    container.childNodes.forEach((childNode: any) => {
      if (childNode.className === 'esri-text-overlay-item esri-text-overlay-item-anchor-center') {
        childNode.style.border = '1px solid white';
        childNode.style.pointerEvents = 'none';
        childNode.style.position = 'absolute';
      } else {
        childNode.style.display = 'none';
      }
    });
  }
}

export function AreExtentsEqual(e1: LngLat[], e2: LngLat[]) {
  if (e1.length !== e2.length) return false;

  for (let i = 0; i < e1.length; i++) {
    if (e1[i].latitude !== e2[i].latitude || e1[i].longitude !== e2[i].longitude) {
      return false;
    }
  }

  return true;
}

export function FieldLayoutLayers(layerUrls: string[], TextMvtLayer: any, pbfLayerProperties: MvtProperties) {
  const results: any = [];
  layerUrls
    .filter((url) => url !== '')
    .forEach((dataUrl, index) => {
      const newLayer = new TextMvtLayer({
        id: `drawing-layer-${index}`,
        data: dataUrl,
        ...pbfLayerProperties
      });

      results.push(newLayer);
    });

  return results;
}
