import { AnomalyCriticality, AnomalyStatus, MasterAnomalyStatus } from '../../utils/enumerations';

export enum SelectedTab {
  CurrentState,
  History,
  MediaAndDocuments,
  Anomalies,
  TaskTrends
}

export interface Component {
  id: string;
  name: string;
  description: string;
  lastTaskCompleted?: Date;
  incident: ComponentIncident[];
  componentCode: string;
  category: string;
  fullComponent: string;
  masterAnomaly: ComponentMasterAnomaly[];
  anomaly: ComponentAnomaly[];
  childComponents: Array<ChildComponent>;
  modelUrl?: string;
  heatmapDataUrls: string[];
  document: Document[];
  media: Media[];
}

export interface Document {
  id: string;
  filename: string;
  url: string;
}

export interface Media {
  id: string;
  filename: string;
  startTime: Date;
  itemType: string;
}

export interface ChildComponent {
  componentCode: string;
  fullComponent: string;
}

export interface ComponentAnomalyAnomalyCode {
  code: string;
  description: string;
}

export interface ComponentAnomalyBase {
  id: string;
  dateFound: string;
  anomalyCode: ComponentAnomalyAnomalyCode;
  component: ComponentAnomalyComponent[];
  media: Media[];
}

export interface ComponentMasterAnomaly extends ComponentAnomalyBase {
  id: string;
  status: MasterAnomalyStatus;
  description: string;
}

export interface ComponentAnomaly extends ComponentAnomalyBase {
  id: string;
  status: AnomalyStatus;
  startTime: Date;
  summary: string;
  criticality: AnomalyCriticality;
}

export interface ComponentAnomalyComponent {
  id: string;
  fullComponent: string;
  componentCode: string;
  category: string;
}

export interface ComponentIncident {
  id: string;
  isVideoAvailable: boolean;
  isMultichannelVideoAvailable: boolean;
  startTime: Date;
  endTime: Date;
  eventCode: { code: string };
  data: IncidentData[];
  workpack: ComponentWorkpack;
  component: ComponentAnomalyComponent;
}

export interface AssetDetailProps {
  fullComponent: string;
}

export enum ParameterType {
  Alphanumeric,
  Boolean,
  Numeric
}

export interface IncidentData {
  id: string;
  parameterName: string;
  parameterType: ParameterType;
  decimalPlaces: number;
  value: string;
}

export interface ComponentWorkpack {
  id: string;
  name: string;
}

export interface HeatmapData {
  FirstDate: Date;
  Legend: {
    LowerThreshold: number;
    UpperThreshold: number;
    Colors: Array<string>;
    TickValues: Array<number>;
  };
  Nodes: Array<{
    Name: string;
    VertexColors: Array<string>;
  }>;
}
