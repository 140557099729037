import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { ChartLegendOptions, ChartOptions } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import FugroColors, { downtimeColors, uptimeColors } from '../../../styles/FugroColors';
import { HomepageSkeletonBlock } from '../../skeletons/HomepageSkeletonBlock';
import { WorkpackListItem } from './WorkpacksOverview';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    favouritesHeading: {
      display: 'inline-block',
      margin: theme.spacing(1)
    },
    favouritesHeadingContainer: {
      position: 'relative'
    }
  })
);

export default function WorkpacksSummaryEfficiency(props: { loading: boolean; workpacks?: WorkpackListItem[] }): JSX.Element {
  const [chartData, setChartData] = useState<{ labels: string[]; datasets: any[] }>();
  const classes = useStyles();

  const legendOptions: ChartLegendOptions = {
    display: false,
    position: 'bottom',
    labels: {
      fontColor: FugroColors.primary.main
    }
  };

  const chartOptions: ChartOptions = {
    layout: {
      padding: {
        left: 15,
        right: 15,
        top: 3,
        bottom: 3
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            padding: 25,
            stepSize: 25,
            callback(value) {
              return value + '%';
            }
          },
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Uptime / Downtime'
          }
        }
      ],
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            // display: true
            // labelString: 'Workpack'
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          if (data.labels) {
            const barType = tooltipItem.datasetIndex === 0 ? 'Uptime' : 'Downtime';
            const value = tooltipItem.value ? parseFloat(tooltipItem.value) : 0;
            return `${barType}: ${value.toFixed(2)}%`;
          }
          return 'N/A';
        }
      }
    }
  };

  useEffect(() => {
    if (props.workpacks) {
      const newChartData = mergeChartDatas(props.workpacks);
      setChartData(newChartData);
    }
  }, [props.workpacks]);

  function mergeChartDatas(data: WorkpackListItem[]) {
    const finalChart: {
      labels: string[];
      datasets: any[];
    } = {
      labels: [],
      datasets: [
        {
          label: 'Uptime',
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 2
        },
        {
          label: 'Downtime',
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 2
        }
      ]
    };

    for (let i = 0; i <= data.length - 1; i++) {
      const uTime = data[i].uptime;

      finalChart.labels.push(data[i].code);
      finalChart.datasets[0].data.push(uTime);
      finalChart.datasets[1].data.push(100 - uTime);

      finalChart.datasets[1].backgroundColor.push(downtimeColors[0]);
      finalChart.datasets[1].borderColor.push(downtimeColors[1]);

      finalChart.datasets[0].backgroundColor.push(uptimeColors[0]);
      finalChart.datasets[0].borderColor.push(uptimeColors[1]);
    }

    return finalChart;
  }

  if (props.loading) {
    return <HomepageSkeletonBlock />;
  }

  return (
    <div>
      <div className={classes.favouritesHeadingContainer}>
        <Typography className={classes.favouritesHeading} component="h6" variant="h6">
          Efficiency
        </Typography>
        {chartData ? <Bar data={chartData} options={chartOptions} legend={legendOptions} /> : <div>No Data</div>}
      </div>
    </div>
  );
}
