import { ButtonGroup, Button, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Popper } from '@material-ui/core';
import { PlayCircleOutline, ArrowDropDown } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Component } from '../../../Interfaces';
import { format } from 'date-fns';
import { MediaViewerDialog } from '../../../../workpacks/workpackDetails/MediaViewerDialog';
import { MultiChannelVideoDialog } from '../../../../workpacks/workpackDetails/MultiChannelVideoDialog';

export default function PlayLatestButton(props: { loading: boolean; data: any }): JSX.Element {
  const loading = props.loading;
  const data = props.data;
  const anchorRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [focusedVideo, setFocusedVideo] = useState<string>();
  const [focusedMultichannelVideo, setFocusedMultichannelVideo] = useState<string>();

  const handleClick = () => {
    const selectedVideo = incidents[selectedIndex];
    if (selectedVideo.isMultiChannel) {
      setFocusedMultichannelVideo(selectedVideo.id);
    } else {
      setFocusedVideo(selectedVideo.id);
    }
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  if (loading) {
    return <div>Loading ...</div>;
  }

  if (!data?.components) {
    return <React.Fragment />;
  }

  const incidents = data?.components.flatMap((comp: Component) => {
    return comp.incident.map((inc: any) => ({
      id: inc.id,
      startTime: inc.startTime,
      endTime: inc.endTime,
      isMultiChannel: inc.isMultichannelVideoAvailable,
      display: `${inc.eventCode.code} on ${format(new Date(inc.endTime), 'd/MM/yy HH:mm')}`
    }));
  });

  useEffect(() => setSelectedIndex(0), [data?.components]);

  const handleCloseViewer = () => {
    setFocusedVideo(undefined);
    setFocusedMultichannelVideo(undefined);
  };

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <Button onClick={handleClick} startIcon={<PlayCircleOutline />}>
          {incidents[selectedIndex]?.display ?? ''}
        </Button>
        <Button onClick={handleToggle} size="small">
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end" transition disablePortal style={{ zIndex: 9999 }}>
        {({ TransitionProps }: any) => (
          <Grow {...TransitionProps}>
            <Paper style={{ zIndex: 9999 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {incidents.map((inc, index) => (
                    <MenuItem
                      key={inc.id}
                      selected={index === selectedIndex}
                      dense={true}
                      onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => handleMenuItemClick(event, index)}
                    >
                      {inc.display}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {focusedVideo ? <MediaViewerDialog itemType="TASK_MEDIA" id={focusedVideo} onClose={handleCloseViewer} /> : null}
      {focusedMultichannelVideo ? <MultiChannelVideoDialog id={focusedMultichannelVideo} onClose={handleCloseViewer} /> : null}
    </React.Fragment>
  );
}
