import { useQuery } from '@apollo/react-hooks';
import { Button, ButtonGroup, createStyles, makeStyles, Paper, Theme, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import Marquee from 'react-marquee-slider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(0.5),
      textAlign: 'center',
      color: theme.palette.text.primary,
      minHeight: '40px',
      display: 'flex'
    },
    sourceButtonSelected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    tickerItem: {
      float: 'left',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '2em',
      textAlign: 'center',
      marginRight: '15px',
      padding: '2px'
    },
    tooltip: {
      textAlign: 'center',
      fontSize: theme.typography.fontSize
    },
    tooltipInner: {
      float: 'left'
    }
  })
);

type Sources = '12' | '24';

interface Workpack {
  name: string;
  recentEvents: {
    anomalies: Anomaly[];
    tasks: Task[];
    anomalousEvents: WorkpackTask[];
  };
}

interface Anomaly {
  id: string;
}

interface Task {
  id: string;
  code: string;
}

interface WorkpackTask {
  id: string;
  eventCode: {
    code: string;
  };
}

interface TaskCount {
  taskCode: string;
  count: number;
}

interface AnomalyCount {
  workpack: string;
  count: number;
}

export function Ticker() {
  const [selectedSource, setSelectedSource] = useState<Sources>('12');
  const [velocity, setVelocity] = useState<number>(44);
  const [scrollingTaskData, setScrollingTaskData] = useState<TaskCount[]>([]);
  const [scrollingAnomalyData, setScrollingAnomalyData] = useState<AnomalyCount[]>([]);
  const [scrollingAnomalousEvents, setScrollingAnomalousEvents] = useState<AnomalyCount[]>([]);
  const [tooltipData, setTooltipData] = useState<JSX.Element>(<React.Fragment />);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const classes = useStyles();

  const { loading, data, refetch } = useQuery<{ workpack: Workpack[] }>(
    gql`query workpackStats {
            workpack {
              name
              recentEvents(hoursOffset: ${selectedSource}) {
                 anomalies {
                   id
                }
                tasks {
                  id
                  code
                }
                anomalousEvents {
                  id
                  eventCode {
                      code
                  }
                }
              }
            }
          }`
  );

  useEffect(() => {
    if (data) {
      const taskData: TaskCount[] = [];
      const eventData: AnomalyCount[] = [];
      const anomalyData: AnomalyCount[] = [];

      data.workpack.forEach((workpack: Workpack) => {
        workpack.recentEvents.tasks.forEach((task: Task) => {
          const index = taskData.findIndex((i) => i.taskCode === task.code);
          if (index !== -1) {
            taskData[index].count += 1;
          } else {
            const newItem: TaskCount = {
              taskCode: task.code,
              count: 1
            };
            taskData.push(newItem);
          }
        });

        eventData.push({ workpack: workpack.name, count: workpack.recentEvents.anomalousEvents.length });
        anomalyData.push({ workpack: workpack.name, count: workpack.recentEvents.anomalies.length });
      });

      setScrollingAnomalyData(anomalyData);
      setScrollingTaskData(taskData);
      setScrollingAnomalousEvents(eventData);
      setFirstLoad(false);
    }
  }, [data]);

  useEffect(() => {
    // Refetch new task data every 2 minutes.
    const interval = setInterval(() => {
      console.log('Refetching task/anomaly ticker code data...');
      refetch();
    }, 120000);
    return () => clearInterval(interval);
  }, [refetch]);

  const onMouseEnterTask = (event: React.MouseEvent<HTMLElement>) => {
    const code = event.currentTarget.id;
    if (code === '') {
      return;
    }

    const collection: Array<{
      workpack: string;
      count: number;
    }> = [];

    for (let i = 0; i < (data?.workpack.length ?? 0); i++) {
      const wpackRef = data?.workpack[i];
      if (wpackRef !== undefined) {
        const count = wpackRef.recentEvents.tasks.filter((x) => x.code === code).length;
        collection.push({ workpack: wpackRef.name, count });
      }
    }

    const cTooltip = (
      <React.Fragment>
        <div>
          <Typography variant="body1">
            <b>{code}</b>
          </Typography>
        </div>
        {collection.map((x: any, idx: number) => {
          return (
            <div key={`tooltip-${code}-${idx}`}>
              <Typography variant="body2">
                {x.workpack}: {x.count}
              </Typography>
            </div>
          );
        })}
      </React.Fragment>
    );

    setTooltipData(cTooltip);
    setVelocity(0);
  };

  const onMouseEnterAnomaly = () => {
    const cTooltip = (
      <React.Fragment>
        <div>
          <Typography variant="body1">
            <b>Site Anomalies</b>
          </Typography>
        </div>
        {scrollingAnomalyData.map((x) => {
          return (
            <div key={`tooltip-${x.workpack}`}>
              <Typography variant="body2">
                {x.workpack}: {x.count}
              </Typography>
            </div>
          );
        })}
      </React.Fragment>
    );

    setTooltipData(cTooltip);
    setVelocity(0);
  };

  const onMouseEnterEvents = () => {
    const cTooltip = (
      <React.Fragment>
        <div>
          <Typography variant="body1">
            <b>Anomalous Events</b>
          </Typography>
        </div>
        {scrollingAnomalousEvents.map((x) => {
          return (
            <div key={`tooltip-${x.workpack}`}>
              <Typography variant="body2">
                {x.workpack}: {x.count}
              </Typography>
            </div>
          );
        })}
      </React.Fragment>
    );

    setTooltipData(cTooltip);
    setVelocity(0);
  };

  const onMouseLeaveCode = () => {
    setVelocity(44);
  };

  function getTickerBlocks(): JSX.Element {
    const totalAnoms = scrollingAnomalyData.reduce((a, b) => a + (b['count'] || 0), 0);
    const totalAnomalousEvents = scrollingAnomalousEvents.reduce((a, b) => a + (b['count'] || 0), 0);

    if (scrollingTaskData.length === 0 && totalAnoms === 0 && totalAnomalousEvents === 0) {
      return <div className={classes.tickerItem}>No Anomalies, Tasks or Anomalous Events recorded in the last {selectedSource} hours.</div>;
    }

    return (
      <React.Fragment>
        <Tooltip title={tooltipData} arrow={true}>
          <div className={classes.tickerItem} onMouseEnter={onMouseEnterAnomaly}>
            <b>Site Anomalies</b>: {scrollingAnomalyData.reduce((a, b) => a + (b['count'] || 0), 0)}
          </div>
        </Tooltip>
        <Tooltip title={tooltipData} arrow={true}>
          <div className={classes.tickerItem} onMouseEnter={onMouseEnterEvents}>
            <b>Anomalous Events</b>: {totalAnomalousEvents}
          </div>
        </Tooltip>
        {scrollingTaskData.map((task: TaskCount, idx: number) => (
          <Tooltip key={`tooltip-${task.taskCode}-${idx}`} title={tooltipData} arrow={true}>
            <div key={task.taskCode} id={task.taskCode} className={classes.tickerItem} onMouseEnter={onMouseEnterTask}>
              <b>{task.taskCode}</b>: {task.count}
            </div>
          </Tooltip>
        ))}
      </React.Fragment>
    );
  }

  // if (loading) {
  //     return <div> Fetching Data...</div>
  // }

  if (loading && firstLoad) {
    return (
      <Paper className={classes.paper}>
        <div style={{ width: '100%', height: '100%' }}>
          <Skeleton style={{ maxWidth: 'inherit', transform: 'scale(1, 0.8)' }} animation="wave">
            <Paper className={classes.paper} />
          </Skeleton>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      <div>
        <ButtonGroup style={{ marginLeft: '2px', float: 'left' }} variant="outlined" size="small" aria-label="small outlined button group">
          <Button
            classes={{ outlinedPrimary: classes.sourceButtonSelected }}
            color={selectedSource === '12' ? 'primary' : undefined}
            onClick={() => setSelectedSource('12')}
          >
            12h
          </Button>
          <Button
            classes={{ outlinedPrimary: classes.sourceButtonSelected }}
            color={selectedSource === '24' ? 'primary' : undefined}
            onClick={() => setSelectedSource('24')}
          >
            24h
          </Button>
        </ButtonGroup>
      </div>
      <div style={{ height: '100%', float: 'left', paddingLeft: '15px', width: '100%' }} onMouseLeave={onMouseLeaveCode}>
        <Marquee direction={'rtl'} onInit={() => {}} onFinish={() => {}} scatterRandomly={false} velocity={velocity} resetAfterTries={200}>
          <div id="dummyblock" />
          {getTickerBlocks()}
        </Marquee>
      </div>
    </Paper>
  );
}
