import { useQuery } from '@apollo/react-hooks';
import { Badge, createStyles, Drawer, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import { gql } from 'apollo-boost';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useGlobalState } from '../../../store/globalState';
import { WorkpackDetailProps } from '../../../types/params.type';
import ErrorMessage from '../../ErrorMessage';
import LegacyMapContainer from '../../map/LegacyMapContainer';
import MapContainer from '../../map/MapContainer';
import { SelectedTab } from '../../map/MapUtils/SharedTypes';
import { useDatalessStyles } from '../../styles/noDataStyles';
import WorkpackStatisticsDashboard from '../workpackStatistics/workpackStatisticsDashboard';
import { AnomaliesList } from './Anomalies/AnomaliesList';
import { AnomalyDetail } from './Anomalies/AnomalyDetail';
// import MediaLog from './MediaLog/MediaLog';
import MediaSearch from './MediaSearch/MediaSearch';
import { ReportsList } from './Reports/ReportsList';
import TaskListDetail from './Tasks/TaskListDetail';
import { TaskListInfoPanel } from './Tasks/TaskListInfoPanel';

const drawerWidth = 400;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: '1em'
    },
    tab: {
      minWidth: '240px'
    },
    badge: {
      transform: 'scale(1) translate(120%, 0%)'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 1
    },
    drawerPaper: {
      display: 'inline',
      width: drawerWidth,
      paddingTop: 80,
      padding: theme.spacing(2)
    },
    mainContentArea: {
      maxWidth: '100%',
      flexGrow: 1,
      padding: theme.spacing(2),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginRight: -drawerWidth
    },
    mainContentAreaShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginRight: 0
    }
  })
);

interface SelectedDetail {
  type: SelectedTab;
  id: string;
  mediaSource?: number;
}

export default function WorkpackDetailDashboard(props: RouteComponentProps<WorkpackDetailProps>): JSX.Element {
  interface WorkpackNameAndCounts {
    name: string;
    _itemCount: {
      anomaly: number;
      incident: number;
      media: number;
      document: number;
    };
    progress: {
      completedTaskCount: number;
      totalTaskCount: number;
    };
  }

  const classes = useStyles();
  const datalessStyles = useDatalessStyles();
  const [, setPageTitle] = useGlobalState('globalPageTitle');

  const [selectedTab, setSelectedTab] = useState<SelectedTab>(SelectedTab.Statistics);
  const [selectedDetail, setSelectedDetail] = useState<SelectedDetail>();
  const [filter, setFilters] = useState<string>('');

  // Properties for updating tab slider
  const tabRef = React.useRef<any>();
  const tabContainerRef = React.createRef<any>();
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

  const { error, data } = useQuery<{ workpack: WorkpackNameAndCounts[] }, { id: string }>(
    gql`
      query workpackNameAndCounts($id: ID!) {
        workpack(id: $id) {
          name
          _itemCount {
            anomaly
            incident
            media
            document
          }
          progress {
            completedTaskCount
            totalTaskCount
          }
        }
      }
    `,
    { variables: { id: props.match.params.workpackId } }
  );

  function onTabSelectionChanged() {
    const urlTab = props.match.params.tab;
    const filters = props.location.state as string;

    for (const _tab of Object.keys(SelectedTab)) {
      if (typeof _tab === 'string') {
        if (_tab === urlTab) {
          const index = SelectedTab[_tab as keyof typeof SelectedTab];
          setSelectedDetail(undefined);
          setSelectedTab(index);
          setFilters(filters);
        }
      }
    }
  }

  const calculatePercentage = useCallback(() => {
    const completedTasks = data?.workpack[0]?.progress.completedTaskCount as number;
    const totalTasksCount = data?.workpack[0]?.progress.totalTaskCount as number;
    const result = Math.trunc((completedTasks / totalTasksCount) * 100);

    if (isNaN(result)) {
      return '';
    } else {
      return `| ${result}%`;
    }
  }, [data]);

  useEffect(onTabSelectionChanged, [props.match.params.tab]);

  useEffect(() => setPageTitle(`${data?.workpack[0].name} ${calculatePercentage()}`), [data, setPageTitle, calculatePercentage]);

  const handleTabChange = (_: React.ChangeEvent<object>, tabIndex: SelectedTab) => {
    const workpackId = props.match.params.workpackId;

    setSelectedDetail(undefined);
    setSelectedTab(tabIndex);
    props.history.replace(`/workpack-detail-dashboard/${workpackId}/${SelectedTab[tabIndex]}`);
  };

  const handleAnomalyClick = (id?: string) => {
    if (id === undefined) {
      setSelectedDetail(undefined);
      return;
    }

    setSelectedDetail({ type: SelectedTab.Anomalies, id });
  };

  const handleTaskClick = (id?: string) => {
    if (id === undefined) {
      setSelectedDetail(undefined);
      return;
    }

    setSelectedDetail({ type: SelectedTab.Tasks, id });
  };

  function resetMapFilters() {
    const workpackId = props.match.params.workpackId;

    props.history.replace(`/workpack-detail-dashboard/${workpackId}/${SelectedTab[selectedTab]}`, null);
    props.location.state = '';
    setFilters('');
  }

  useEffect(() => {
    const panelDOM = tabContainerRef.current.classList;

    if (panelDOM) {
      const isPanelVisible = panelDOM.length === 2;
      setIsPanelOpen(isPanelVisible);
    }
  }, [tabContainerRef]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tabRef?.current) {
        tabRef.current.updateIndicator();
      }
    }, 250);

    return () => clearTimeout(timer);
  }, [isPanelOpen]);

  const tab = (
    <div>
      <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered={false} action={tabRef}>
        <Tab
          className={classes.tab}
          label={
            <Badge classes={{ badge: classes.badge }} color="secondary">
              Statistics
            </Badge>
          }
        />
        <Tab
          className={classes.tab}
          label={
            <Badge classes={{ badge: classes.badge }} color="secondary" badgeContent={data?.workpack[0]?._itemCount?.anomaly} max={99999}>
              Site Anomalies
            </Badge>
          }
        />
        <Tab
          className={classes.tab}
          label={
            <Badge classes={{ badge: classes.badge }} color="secondary" badgeContent={data?.workpack[0]?._itemCount?.incident} max={99999}>
              Tasks / Events
            </Badge>
          }
        />
        <Tab
          label={
            <Badge classes={{ badge: classes.badge }} color="secondary" badgeContent={data?.workpack[0]?._itemCount?.media} max={99999}>
              Media
            </Badge>
          }
        />
        <Tab
          className={classes.tab}
          label={
            <Badge classes={{ badge: classes.badge }} color="secondary" badgeContent={data?.workpack[0]?._itemCount?.document} max={99999}>
              Reports
            </Badge>
          }
        />
        {process.env.REACT_APP_ON_PREMISE === 'true' ? null : (
          <Tab
            className={classes.tab}
            label={
              <Badge classes={{ badge: classes.badge }} color="secondary">
                Map
              </Badge>
            }
          />
        )}
      </Tabs>
    </div>
  );

  const displaySelectedPage = (page: SelectedTab) => {
    switch (page) {
      case SelectedTab.Statistics:
        return <WorkpackStatisticsDashboard {...props} />;
      case SelectedTab.Anomalies:
        return (
          <AnomaliesList workpackId={props.match.params.workpackId} onSelectItem={handleAnomalyClick} filter={filter} resetFilter={resetMapFilters} />
        );
      case SelectedTab.Tasks:
        return (
          <TaskListDetail onSelectItem={handleTaskClick} workpackId={props.match.params.workpackId} filter={filter} resetFilter={resetMapFilters} />
        );
      case SelectedTab.Media:
        return <MediaSearch workpackId={props.match.params.workpackId} />;
      case SelectedTab.Reports:
        return <ReportsList workpackId={props.match.params.workpackId} />;
      case SelectedTab.Map:
        return process.env.REACT_APP_ON_PREMISE === 'true' ? (
          <LegacyMapContainer {...props} workpackId={props.match.params.workpackId} />
        ) : (
          <MapContainer {...props} workpackId={props.match.params.workpackId} />
        );
    }
  };

  const displayDrawer = (page: SelectedTab, detail?: SelectedDetail) => {
    if (!detail) {
      return null;
    }
    switch (page) {
      case SelectedTab.Anomalies:
        return <AnomalyDetail anomalyId={detail.id} />;
      case SelectedTab.Media:
        return <div />;
      case SelectedTab.Tasks:
        return <TaskListInfoPanel taskId={detail.id} />;
      default:
        return;
    }
  };

  if (error) {
    return (
      <div ref={tabContainerRef} className={datalessStyles.error}>
        <ErrorMessage message={error.message} />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      <div
        ref={tabContainerRef}
        className={clsx(classes.mainContentArea, {
          [classes.mainContentAreaShift]: selectedDetail !== undefined
        })}
      >
        {tab}
        {displaySelectedPage(selectedTab)}
      </div>
      <Drawer
        variant="persistent"
        anchor="right"
        open={selectedDetail !== undefined}
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        {displayDrawer(selectedTab, selectedDetail)}
      </Drawer>
    </div>
  );
}
