import {
  Button,
  ButtonGroup,
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  Switch,
  TablePagination,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Star, StarBorder } from '@material-ui/icons';
import clsx from 'clsx';
import { format } from 'date-fns';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { theme } from '../../..';
import { SkeletonTable } from '../../skeletons/SkeletonTable';
import { WorkpackListItem } from './WorkpacksOverview';

const useStyles = makeStyles(() =>
  createStyles({
    completionStatus: {
      borderRadius: '50%',
      height: '1em',
      width: '1em',
      marginRight: '1em',
      display: 'inline-block'
    },
    progress: {
      display: 'inline-block'
    },
    completionStatusNotStarted: {
      backgroundColor: 'red'
    },
    completionStatusInProgress: {
      backgroundColor: 'orange'
    },
    completionStatusComplete: {
      backgroundColor: 'green'
    },
    titleOverriden: {
      width: '100%'
    },
    spacerOverriden: {
      flex: '1 1 0%'
    },
    workpackHeader: {
      color: '#011e41'
    },
    sourceButtonSelected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    footer: {
      display: 'flex'
    },
    paginator: {
      flex: 1,
      right: 0,
      textAlign: '-webkit-right' as 'right'
    },
    toggleContainer: {
      alignSelf: 'center',
      paddingLeft: '10px',
      zIndex: 999
    }
  })
);

enum WorkpackCompletionStatus {
  'NotStarted',
  'InProgress',
  'Complete'
}

interface WorkpacksTableProps {
  loading?: boolean;
  error?: Error;
  workpacks?: WorkpackListItem[];
  onSelectionChange?: (changedRow: WorkpackListItem) => void;
}

type Sources = 'ALL' | 'FAVOURITES';

export default function WorkpacksTable(props: WorkpacksTableProps & RouteComponentProps): JSX.Element {
  const classes = useStyles();
  const [selectedSource, setSelectedSource] = useState<Sources>('ALL');
  const [showFullAssetText, setShowFullAssetText] = useState<boolean>(localStorage.getItem('showFullAssetText') === 'true');
  const [tableDataSource, setTableDataSource] = useState<WorkpackListItem[]>([]);

  const workpackStatus = (r: WorkpackListItem) =>
    r.progress.completedTaskCount === 0
      ? WorkpackCompletionStatus.NotStarted
      : r.progress.completedTaskCount === r.progress.totalTaskCount
      ? WorkpackCompletionStatus.Complete
      : WorkpackCompletionStatus.InProgress;

  useEffect(() => {
    if (props.workpacks) {
      setTableDataSource(props.workpacks);
    }
  }, [props.workpacks]);

  function handleWorkpackClick(_event: React.MouseEvent | undefined, rowData: WorkpackListItem | undefined) {
    if (rowData) {
      props.history.push(`/workpack-detail-dashboard/${rowData.id}`);
    }
  }

  function completionStatusStyle(status: WorkpackCompletionStatus): string {
    switch (status) {
      case WorkpackCompletionStatus.NotStarted:
        return classes.completionStatusNotStarted;
      case WorkpackCompletionStatus.InProgress:
        return classes.completionStatusInProgress;
      case WorkpackCompletionStatus.Complete:
        return classes.completionStatusComplete;
    }
  }

  function stopPropagation(e: MouseEvent, r: any) {
    r.isFavourite = !r.isFavourite;
    if (props.onSelectionChange && r) {
      const _t: WorkpackListItem = { ...r };
      props?.onSelectionChange(_t);
    }

    e.stopPropagation();
  }

  const handleShowFullAssetChange = () => {
    const newValue = !showFullAssetText;
    setShowFullAssetText(newValue);
    localStorage.setItem('showFullAssetText', newValue.toString());
  };

  // that weird data thing is some material-table weirdness, see https://github.com/mbrn/material-table/issues/893
  function getDataSet() {
    if (selectedSource === 'FAVOURITES') {
      return tableDataSource?.filter((x) => x.isFavourite) ?? [];
    } else {
      return tableDataSource;
    }
  }

  if (props.loading) {
    return <SkeletonTable />;
  }

  return (
    <MaterialTable
      title="Workpacks"
      columns={[
        {
          title: '',
          field: 'isFavourite',
          searchable: false,
          sorting: false,
          cellStyle: {
            padding: '0px 0px 0px 10px'
          },
          width: '1em',
          render: (r) => (
            <Tooltip title={!r.isFavourite ? 'Add to Favourites.' : 'Remove from favourites.'}>
              <Checkbox checkedIcon={<Star />} icon={<StarBorder />} checked={r.isFavourite} onClick={(e: any) => stopPropagation(e, r)} />
            </Tooltip>
          )
        },
        {
          title: 'Id',
          field: 'id',
          searchable: false,
          hidden: true
        },
        {
          title: 'Completion',
          field: 'completionStatus',
          width: '6em',
          render: (r) => (
            <Tooltip title={WorkpackCompletionStatus[workpackStatus(r)]}>
              <div>
                <div className={clsx(classes.completionStatus, completionStatusStyle(workpackStatus(r)))} />
                <div className={classes.progress}>
                  {r.progress.totalTaskCount === 0 ? 0 : Math.trunc((r.progress.completedTaskCount / r.progress.totalTaskCount) * 100)}%
                </div>
              </div>
            </Tooltip>
          ),
          searchable: false
        },
        {
          title: 'Start Date',
          field: 'startDate',
          width: '10em',
          render: (r) => format(new Date(r.startDate), 'd/MM/yy'),
          searchable: false,
          defaultSort: 'desc',
          type: 'datetime'
        },
        {
          title: 'Workpack Code',
          field: 'workpackCode',
          width: '15em',
          searchable: false
        },
        {
          title: 'Parent Assets',
          field: 'parentAsset',
          render: (r) => r.parentAsset.join(', '),
          cellStyle: {
            whiteSpace: showFullAssetText ? 'initial' : 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '20em'
          }
        },
        {
          title: 'Task Count',
          field: 'progress.totalTaskCount',
          width: '10em',
          searchable: false
        },
        {
          title: 'Site Anomalies',
          field: '_itemCount.anomaly',
          width: '15em',
          searchable: false
        },
        {
          title: 'Name',
          field: 'name',
          cellStyle: {
            whiteSpace: showFullAssetText ? 'initial' : 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '10em'
          }
        }
      ]}
      options={{
        sorting: true,
        emptyRowsWhenPaging: false,
        showSelectAllCheckbox: false,
        showTextRowsSelected: false,
        // padding: 'dense',
        pageSize: 10,
        headerStyle: {
          fontWeight: 'bold',
          borderBottom: `2px solid ${theme.palette.primary.main}`
        }
        // selection: true
      }}
      data={getDataSet()}
      onRowClick={handleWorkpackClick}
      isLoading={props.loading}
      components={{
        Toolbar: (p) => (
          <div className={classes.workpackHeader}>
            <MTableToolbar
              {...p}
              classes={{
                title: classes.titleOverriden,
                spacer: classes.spacerOverriden
              }}
              title={
                <div>
                  <Typography variant="h6" display="inline" style={{ float: 'left' }}>
                    Workpacks
                  </Typography>
                  <ButtonGroup style={{ marginLeft: '2em', float: 'right' }} variant="outlined">
                    <Button
                      classes={{
                        outlinedPrimary: classes.sourceButtonSelected
                      }}
                      color={selectedSource === 'ALL' ? 'primary' : undefined}
                      onClick={() => setSelectedSource('ALL')}
                    >
                      Show All
                    </Button>
                    <Button
                      classes={{
                        outlinedPrimary: classes.sourceButtonSelected
                      }}
                      color={selectedSource === 'FAVOURITES' ? 'primary' : undefined}
                      onClick={() => setSelectedSource('FAVOURITES')}
                    >
                      Favourites
                    </Button>
                  </ButtonGroup>
                </div>
              }
            />
          </div>
        ),
        Pagination: (p) => (
          <React.Fragment>
            <td className={classes.footer}>
              <div className={classes.toggleContainer}>
                <FormControlLabel label="Full Parent Asset" checked={showFullAssetText} onClick={handleShowFullAssetChange} control={<Switch />} />
              </div>
              <div className={classes.paginator}>
                <table>
                  <tbody>
                    <tr>
                      <TablePagination {...p} />
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </React.Fragment>
        )
      }}
    />
  );
}
