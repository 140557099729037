import { useLoader } from '@react-three/fiber';
import React, { useState, useEffect } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FocusedComponentProps } from './ModelViewerHelpers';
import ColorSchema from './ModelColourSchema/colorSchema';
import { ColoringMethod } from './ModelColourSchema/Constants';
import { BuildNormalRule, BuildTaskProgressRule, BuildAnomalyRule, BuildHeatmapRule } from './ModelColourSchema/GenericRules';
import Rule from './ModelColourSchema/Rule';
import { PickingColor } from './ModelColourSchema/Constants';
import { HeatmapData } from '../Interfaces';

interface ModelProps {
  modelUrl: string;
  modelData: any;
  heatmapData?: HeatmapData;
  focused: FocusedComponentProps;
  onChangedFocus: (e: any) => void;
  coloringMethod: ColoringMethod;
  transparency: boolean;
  modelRef: React.MutableRefObject<undefined>;
}

export default function Model(props: ModelProps): JSX.Element {
  const [initialRun, setInitialRun] = useState(false);

  const modelUrl = props.modelUrl;
  const gltf = useLoader(GLTFLoader, modelUrl);
  const colorSchema = new ColorSchema();

  const pData = props.modelData;
  const pColoringMethod = props.coloringMethod;
  const pTransparency = props.transparency;
  const pFocusedComponents: FocusedComponentProps = props.focused;
  const heatmapData = props.heatmapData;

  useEffect(() => {
    if (pColoringMethod === undefined || (pColoringMethod as number) === -1) return;

    const rule = new Rule('core');

    resetSelectedMeshes();
    props.onChangedFocus({ component: null, lookAt: false });

    switch (pColoringMethod) {
      case ColoringMethod.Normal:
        BuildNormalRule(rule);
        break;
      case ColoringMethod.TaskProgress:
        BuildTaskProgressRule(rule, pData);
        break;
      case ColoringMethod.Anomalies:
        BuildAnomalyRule(rule, pData);
        break;
      case ColoringMethod.Heatmap:
        BuildHeatmapRule(rule, heatmapData);
        break;
    }

    colorSchema.AddRule(rule);

    gltf.scene.traverse((node: any) => {
      if (!node.isMesh) return;

      // (Run Once) First save a reference to the original mesh color so we can revert to it later.
      if (!initialRun) {
        // SIMS uses # to signify multicomponents, we can ignore it and treat them all as one component.
        if (node.name.includes('#')) {
          node.name = node.name.substring(node.name.indexOf('#') + 1);
        }

        node.originalColor = node.material.color.getHex();
        node.material.transparent = true; // Enable the mesh materials transprancy so we can toggle it on/off
      }

      // Apply our custom color rules, anomaly/task progress etc...
      if (colorSchema?.IsValid() === true) {
        colorSchema.UpdateMesh(node);
      }

      // Reset the transparency of all nodes to default
      node.material.opacity = pTransparency ? 0.3 : 1;
    });

    // console.log(gltf.scene.children[0].rotation.x);

    // gltf.scene.rotation.x = -90;

    if (!initialRun) setInitialRun(true);
  }, [pColoringMethod, props.heatmapData]);

  useEffect(() => {
    if (pFocusedComponents) {
      clearFocusedMeshes();

      const currentlyFocusedMeshes = pFocusedComponents.components.flatMap((mName: string) => {
        return findMeshesByComponentName(mName);
      });

      if (currentlyFocusedMeshes.length > 0) {
        currentlyFocusedMeshes.forEach((mesh: any) => toggleMesh(mesh, true));
      }
    }
  }, [pFocusedComponents]);

  useEffect(() => {
    toggleTransparency(pTransparency);
  }, [pTransparency]);

  function clearFocusedMeshes() {
    gltf.scene.traverse((node: any) => {
      if (!node.isMesh) return;

      if (node.focused) {
        const previousColor = node.currentHex;
        node.currentHex = node.material.color.getHex();
        node.material.opacity = pTransparency ? 0.3 : 1;
        node.material.color.setHex(previousColor);
        node.focused = false;
      }
    });
  }

  function resetSelectedMeshes() {
    pFocusedComponents.components.forEach((mesh: any) => {
      const meshes = findMeshesByComponentName(mesh);
      if (meshes.length > 0) {
        meshes.forEach((m: any) => {
          toggleMesh(m, false);
        });
      }
    });
  }

  function toggleTransparency(on: boolean) {
    gltf.scene.traverse((node: any) => {
      if (!node.isMesh) return;

      if (pFocusedComponents.components.filter((mesh: any) => mesh === node.name).length !== 0) return;

      node.material.opacity = on ? 0.3 : 1.0;
      node.material.transparency = on;
    });
  }

  function toggleMesh(mesh: any, highlight: boolean) {
    const previousColor = mesh.currentHex;

    if (highlight) {
      if (mesh?.focused === true) {
        return;
      }
      mesh.currentHex = mesh.material.color.getHex();
      mesh.material.opacity = 1;
      mesh.material.color.setHex(PickingColor);
      mesh.focused = true;
    } else {
      if (mesh?.focused === false) return;
      mesh.currentHex = mesh.material.color.getHex();
      mesh.material.opacity = pTransparency ? 0.3 : 1;
      mesh.material.color.setHex(previousColor);
      mesh.focused = false;
    }
  }

  function findMeshesByComponentName(meshName: string) {
    const rNodes: any[] = [];

    gltf.scene.traverse((node: any) => {
      if (!node.isMesh) return;

      if (node.name === meshName) rNodes.push(node);
    });

    return rNodes;
  }

  const onMeshClick = (e: any) => {
    const fMesh = e?.object;

    // Clearing the only highlighted component, clear everything.
    if (fMesh) {
      if (pFocusedComponents.components.length === 1 && fMesh.name === pFocusedComponents.components[0]) {
        props.onChangedFocus([]);
      } else {
        // If we're toggling off a mesh whilst having a group selected
        if (e.ctrlKey && pFocusedComponents.components.includes(fMesh.name)) {
          const meshes = findMeshesByComponentName(fMesh.name);
          meshes.forEach((mesh: any) => {
            toggleMesh(mesh, false);
          });
        } // If not holding control, then turn off all old ones
        else if (!e.ctrlKey) {
          pFocusedComponents.components.forEach((compName: string) => {
            const meshes = findMeshesByComponentName(compName);
            meshes.forEach((mesh: any) => {
              toggleMesh(mesh, false);
            });
          });
        }

        const alreadyHighlighted = pFocusedComponents.components.includes(fMesh.name);
        let newFocusedData: string[];

        if (!e.ctrlKey) {
          if (alreadyHighlighted && pFocusedComponents.components.length > 1) {
            // focus single when multiple selected
            newFocusedData = [...pFocusedComponents.components.filter((mesh: string) => mesh === fMesh.name)];
          } else if (alreadyHighlighted) {
            // deselecting current single component
            newFocusedData = [];
          } else {
            // No control click, focus single.
            newFocusedData = [fMesh.name];
          }
        } else {
          if (alreadyHighlighted) {
            // multiselect & clicking already selected component
            newFocusedData = [...pFocusedComponents.components.filter((mesh: string) => mesh !== fMesh.name)];
          } else {
            // multiselect & clicking new component
            newFocusedData = [...pFocusedComponents.components, fMesh.name];
          }
        }

        props.onChangedFocus({
          components: newFocusedData,
          lookAt: false
        });
      }
    }

    e.stopPropagation();
  };

  return <primitive object={gltf.scene} onClick={onMeshClick} ref={props.modelRef} />;
}
