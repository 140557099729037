import { createStyles, makeStyles, Fade } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      right: '3px',
      bottom: '10px',
      position: 'absolute',
      zIndex: 999,
      fontSize: '12px',
      // color: '#181b20',
      minWidth: '318px',
      maxHeight: '240px',
      overflowY: 'hidden',
      border: '1px solid white',
      borderRadius: '2px',
      color: 'white',
      backgroundColor: '#181b20',
      padding: '5px'
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      cursor: 'pointer',
      color: 'white'
    }
  })
);

export default function DebugTooltip(obj: any): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const classes = useStyles();
  const properties = obj?.entity?.object?.properties;

  function hOnCloseClick() {
    setIsOpen(false);
  }

  function getPropertyText() {
    let items = [<div style={{ height: '15px' }} key={''} />];
    if (properties) {
      items = Object.keys(properties).map((key: any) => {
        return (
          <div key={key}>
            <b>{key}</b>: {properties[key]}
          </div>
        );
      });
    }
    return items;
  }

  useEffect(() => {
    setIsOpen(obj.visible);
  }, [obj.visible]);

  return (
    <Fade in={isOpen}>
      <div key={properties?.EntityHandle} className={classes.tooltip}>
        <Close className={classes.closeButton} onClick={hOnCloseClick} />
        {getPropertyText()}
        <hr />
        <div>
          X: {properties ? obj?.entity?.x : ' '} Y: {properties ? obj?.entity?.y : ' '}
        </div>
        <div>
          Lat: {properties ? obj.entity.coordinate[0] : ' '} Lng: {properties ? obj.entity.coordinate[1] : ' '}
        </div>
      </div>
    </Fade>
  );
}
