import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { calculateTaskStatusDeckGl, getAllComponentsDeckGl, truncateTooltipText } from '../../MappingUtils';
import { Criticalities, Criticality, TaskStatistics, SelectedTab } from '../../MapUtils/SharedTypes';
import { MapTooltipProps } from '../../../../types/params.type';

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      color: 'black',
      display: 'inline-table',
      padding: '2px'
      // backgroundColor: 'red'
    },
    parentBarContainer: {
      paddingTop: '20px',
      float: 'left'
    },
    childBarContainer: {
      color: 'transparent',
      paddingLeft: '2px',
      pointerEvents: 'auto',
      float: 'left'
      // background: 'magenta',
    },
    childBarOffset: {
      paddingTop: '20px'
    },
    tooltipItem: {
      minWidth: '140px',
      border: '1px solid white',
      background: '#2d3035',
      color: 'white',
      padding: '2px',
      marginBottom: '1px',
      paddingLeft: '8px',
      paddingRight: '10px',
      pointerEvents: 'auto',
      zIndex: 9999,
      '&:hover': {
        background: '#757e8a',
        cursor: 'pointer',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none'
      }
    },
    tooltipItemFocused: {
      backgroundColor: '#757e8a'
    },
    tooltipItemStatusBox: {
      height: '18px',
      width: '18px',
      border: '1px solid white',
      float: 'left',
      marginRight: '5px',
      marginTop: '1px'
    }
  })
);

enum Header {
  None,
  Component,
  Completed,
  InProgress,
  NotStarted,
  Anomaly,
  Incident
}

enum SubHeader {
  None = 'None',
  Information = 'Information',
  Insignificant = 'Insignificant',
  Monitor = 'Monitor',
  Significant = 'Significant',
  Critical = 'Critical',
  Task = 'Task'
}

export default function ExpandableIconTooltip(props: any): JSX.Element {
  const styles = useStyles();
  const data: any[] = props.data;
  const isVisible: boolean = props.visible;
  const tooltipIconPosition: any = props.position;
  const totalComponents = new Set(data.map((a: any) => a.component)).size;

  const [focusedHeader, setFocusedHeader] = useState<Header>(Header.None);
  const [focusedSubHeader, setFocusedSubHeader] = useState<string>(SubHeader.None);
  const [focusedSubHeaderTaskCode, setFocusedSubHeaderTaskCode] = useState<string>('');

  const [stats, setStats] = useState<TaskStatistics>();
  const [childData, setChildData] = useState<Array<{ data: number; string: string }>>([]);
  const [subChildData, setSubChildData] = useState<Array<{ data: number; string: string }>>([]);
  const [pagingIndex, setPagingIndex] = useState<number>(0);
  const [subPagingIndex, setSubPagingIndex] = useState<number>(0);

  // Contains the data to be shown when paging is in use.
  const [visibleChildData, setVisibleChildData] = useState<Array<{ data: number; string: string }>>([]);
  const [visibleSubChildData, setVisibleSubChildData] = useState<Array<{ data: number; string: string }>>([]);

  useEffect(() => {
    if (data.length) {
      const _stats = calculateTaskStatusDeckGl(data);
      setStats(_stats);
      setChildData([]);
      setVisibleChildData([]);
      setVisibleSubChildData([]);
    }
  }, [data]);

  useEffect(onFocusedSubHeaderChanged, [focusedSubHeader]);

  // Fires when we click different task code items
  useEffect(onFocusedSubHeaderTaskCodeChanged, [focusedSubHeaderTaskCode]);

  useEffect(onVisibilityChanged, [isVisible]);

  useEffect(onPagingIndexChanged, [pagingIndex]);

  useEffect(onSubPagingIndexChanged, [subPagingIndex]);

  if (!stats) {
    return <React.Fragment />;
  }

  const hOnScrollChild = (event: any) => {
    if (event.deltaY < 0) {
      if (pagingIndex !== 0) {
        setPagingIndex(pagingIndex - 1);
      }
    } else if (event.deltaY > 0) {
      if (pagingIndex < childData.length - 7) {
        setPagingIndex(pagingIndex + 1);
      }
    }

    event.stopPropagation();
    event.preventDefault();
  };

  const hOnScrollSubChild = (event: any) => {
    if (event.deltaY < 0) {
      if (subPagingIndex !== 0) {
        setSubPagingIndex(subPagingIndex - 1);
      }
    } else if (event.deltaY > 0) {
      if (subPagingIndex < subChildData.length - 7) {
        setSubPagingIndex(subPagingIndex + 1);
      }
    }

    event.stopPropagation();
    event.preventDefault();
  };

  function onVisibilityChanged() {
    if (!isVisible) {
      setFocusedHeader(Header.None);
      setFocusedSubHeader(SubHeader.None);
    }
  }

  function onPagingIndexChanged() {
    if (childData?.length !== 0) {
      const page = childData.slice(pagingIndex, pagingIndex + 7);
      setVisibleChildData(page);
    }
  }

  function onSubPagingIndexChanged() {
    if (subChildData?.length !== 0) {
      const page = subChildData.slice(subPagingIndex, subPagingIndex + 7);
      setVisibleSubChildData(page);
    }
  }

  function onFocusedSubHeaderTaskCodeChanged() {
    if (!stats) {
      return;
    }

    let _result: any[] = [];

    switch (focusedHeader) {
      case Header.NotStarted:
        _result = stats.notStarted.filter((x) => x.incidentData.eventCode.code === focusedSubHeaderTaskCode);
        break;
      case Header.InProgress:
        _result = stats.inProgress.filter((x) => x.incidentData.eventCode.code === focusedSubHeaderTaskCode);
        break;
      case Header.Completed:
        _result = stats.completed.filter((x) => x.incidentData.eventCode.code === focusedSubHeaderTaskCode);
        break;
    }

    setSubChildData(_result);
    setVisibleSubChildData(_result.slice(0, 7));
  }

  function onFocusedSubHeaderChanged() {
    if (!stats) {
      return;
    }

    let _result: any[] = [];

    if (focusedHeader === Header.Anomaly) {
      const _index = Criticalities.findIndex((x: any) => x.Criticality === focusedSubHeader);
      const _data = stats.anomalies.filter((anomaly: any) => (anomaly.criticality ?? 0) === _index);
      const _sorted: any[] = [];

      _data.forEach((anomaly) => {
        const _code = `${anomaly.anomalyCode.description} (${anomaly.anomalyCode.code})`;
        const _existingItems = _sorted.filter((c) => c.code === _code);

        if (_existingItems.length > 0) {
          _existingItems[0].count += 1;
        } else {
          _sorted.push({ code: _code, count: 1 });
        }
      });

      _result = _sorted.map((anomalyGroup) => ({
        string: anomalyGroup.code,
        data: anomalyGroup.count
      }));
    }

    setSubChildData(_result);
  }

  function generateChildTooltip() {
    const hasPaging = childData.length > 7;
    const isAtTop = pagingIndex === 0;
    const isAtBottom = pagingIndex === childData.length - 7;

    if (focusedHeader !== Header.Anomaly && focusedHeader !== Header.None) {
      return (
        <div onWheel={hOnScrollChild} className={clsx(styles.childBarContainer, hasPaging && !isAtTop ? '' : styles.childBarOffset)}>
          {hasPaging && !isAtTop && (
            <div style={{ height: '100%' }}>
              <div style={{ textAlign: 'center' }}>
                <img alt="" src={'../../../../images/arrow-up.png'} />
              </div>
            </div>
          )}
          {visibleChildData.map((element, index) => {
            let focusedId = '';
            switch (focusedHeader) {
              case Header.Component:
                focusedId = 'component';
                break;
              case Header.Incident:
                focusedId = 'incident';
                break;
              default:
                focusedId = 'task';
                break;
            }
            return (
              <div
                id={focusedId}
                key={`child-${index}`}
                onClick={focusedHeader === Header.Component ? hOnClickRedirect : hOnClickSetSubHeader}
                title={element.string} // Store full component ID / taskcode in here so we can reference it later incase its truncated below.
                className={styles.tooltipItem}
              >
                {element.data === 0 ? '' : element.data} {focusedHeader === Header.Component ? truncateTooltipText(element.string) : element.string}
              </div>
            );
          })}
          {hasPaging && !isAtBottom && (
            <div style={{ height: '100%' }}>
              <div style={{ textAlign: 'center' }}>
                <img alt="" src={'../../../../images/arrow-down.png'} />
              </div>
            </div>
          )}
        </div>
      );
    } else if (focusedHeader === Header.Anomaly) {
      return (
        <div className={clsx(styles.childBarContainer, styles.childBarOffset)}>
          {Criticalities.map((x: Criticality) => {
            return (
              <div
                key={`${x.Criticality}`}
                id={x.Criticality}
                onClick={hOnClickSetSubHeader}
                className={clsx(
                  styles.tooltipItem,
                  isFocusedSubHeader(SubHeader[x.Criticality as keyof typeof SubHeader]) ? styles.tooltipItemFocused : ''
                )}
                style={{ paddingLeft: '2px' }}
              >
                <div className={styles.tooltipItemStatusBox} style={{ backgroundColor: x.Color }} />
                {x.Criticality} {getStatusAnomalyCount(x.Criticality)}
              </div>
            );
          })}
        </div>
      );
    } else {
      return;
    }
  }

  function getStatusAnomalyCount(status: any) {
    if (stats) {
      const index = Criticalities.findIndex((x: any) => x.Criticality === status);
      const count = stats.anomalies.filter((anomaly: any) => (anomaly.criticality ?? 0) === index).length;

      // const count = stats.anomalies?.filter((anomaly: any) => anomaly.criticality === status.toUpperCase()).length;
      return `(${count})`;
    } else {
      return '';
    }
  }

  function generateSubChildTooltip() {
    if (focusedSubHeader === SubHeader.None) {
      return;
    } else if (focusedSubHeader === SubHeader.Task) {
      const hasPaging = subChildData.length > 7;
      const isAtTop = subPagingIndex === 0;
      const isAtBottom = subPagingIndex === subChildData.length - 7;

      return (
        <div onWheel={hOnScrollSubChild} className={clsx(styles.childBarContainer, hasPaging && !isAtTop ? '' : styles.childBarOffset)}>
          {hasPaging && !isAtTop && (
            <div style={{ height: '100%' }}>
              <div style={{ textAlign: 'center' }}>
                <img alt="" src={'../../../../images/arrow-up.png'} />
              </div>
            </div>
          )}
          {visibleSubChildData.map((element: any, index) => {
            return (
              <div id={'task'} title={element.comp} key={`child-${index}`} className={styles.tooltipItem} onClick={hOnClickRedirect}>
                {truncateTooltipText(element.comp)}
              </div>
            );
          })}
          {hasPaging && !isAtBottom && (
            <div style={{ height: '100%' }}>
              <div style={{ textAlign: 'center' }}>
                <img alt="" src={'../../../../images/arrow-down.png'} />
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className={clsx(styles.childBarContainer, styles.childBarOffset)}>
          {subChildData.map((element, index) => {
            return (
              <div id={'anomaly'} key={`child-${index}`} className={styles.tooltipItem} onClick={hOnClickRedirect}>
                {element.data === 0 ? '' : element.data} {element.string}
              </div>
            );
          })}
        </div>
      );
    }
  }

  function hOnClickRedirect(args: any) {
    const _props: MapTooltipProps = {
      Filters: '<none>',
      Tab: SelectedTab.Media,
      IsComponent: false
    };

    let code = '';
    let index = -1;

    switch (args.currentTarget.id) {
      case 'component': {
        _props.IsComponent = true;
        _props.Filters = args.currentTarget.title;
        _props.Tab = SelectedTab.Tasks;
        break;
      }
      case 'anomaly': {
        const anomalyCode = args.currentTarget.innerText;
        const anomIndex = Criticalities.findIndex((x: any) => x.Criticality === focusedSubHeader);
        const focusedAnomalies = stats?.anomalies.filter((x) => (x.criticality ?? 0) === anomIndex && anomalyCode.includes(x.anomalyCode.code));

        _props.Tab = SelectedTab.Anomalies;
        if (focusedAnomalies) {
          _props.Filters = JSON.stringify(focusedAnomalies.map((y) => y.anomalyID));
        }
        break;
      }
      case 'incident': {
        code = args.currentTarget.innerText;
        index = code.indexOf(' ');
        code = code.slice(index + 1, code.length);

        const filteredIncidents = stats?.events.filter((x) => code === x.eventCode.code);

        _props.Tab = SelectedTab.Tasks;
        _props.Filters = JSON.stringify(filteredIncidents?.map((y) => y.id));

        break;
      }
      case 'task': {
        code = focusedSubHeaderTaskCode;
        _props.Filters = JSON.stringify({ code: code, comp: args.currentTarget.title });
        _props.Tab = SelectedTab.Tasks;
        break;
      }
    }

    props.onItemClick(_props);
  }

  function hOnClickComponents() {
    const _comps = getAllComponentsDeckGl(data);
    const _result = _comps.map((component) => ({ string: component, data: 0 }));
    setFocusedHeader(Header.Component);
    setFocusedSubHeader(SubHeader.None);
    setChildData(_result);
    setVisibleChildData(_result.slice(0, 7));
  }

  function hOnClickCompleted() {
    const _data: Array<{ code: string; count: number; comps: string[] }> = [];

    stats?.completed.forEach((task) => {
      const _code = task.incidentData.eventCode.code;
      const _comp = task.comp;
      const _existingItems = _data.filter((c) => c.code === _code);

      if (_existingItems.length > 0) {
        _existingItems[0].count += 1;
        _existingItems[0].comps.push(_comp);
      } else {
        _data.push({ code: _code, count: 1, comps: [_comp] });
      }
    });

    const _results = _data.map((task) => ({ string: task.code, data: task.count, comps: task.comps }));
    setFocusedHeader(Header.Completed);
    setFocusedSubHeader(SubHeader.None);
    setChildData(_results);
    setVisibleChildData(_results.slice(0, 7));
  }

  function hOnClickIncidents() {
    const _data: Array<{ code: string; count: number }> = [];

    stats?.events.forEach((incident) => {
      const _code = incident.eventCode.code;
      const _existingItems = _data.filter((c) => c.code === _code);

      if (_existingItems.length > 0) {
        _existingItems[0].count += 1;
      } else {
        _data.push({ code: _code, count: 1 });
      }
    });

    const _results = _data.map((incident) => ({ string: incident.code, data: incident.count }));
    setFocusedHeader(Header.Incident);
    setFocusedSubHeader(SubHeader.None);
    setChildData(_results);
    setVisibleChildData(_results.slice(0, 7));
  }

  function hOnClickInProgress() {
    const _data: Array<{ code: string; count: number; comps: string[] }> = [];

    stats?.inProgress.forEach((obj) => {
      const _code = obj.incidentData.eventCode.code;
      const _comp = obj.comp;
      const _existingItems = _data.filter((c) => c.code === _code);

      if (_existingItems.length > 0) {
        _existingItems[0].count += 1;
        _existingItems[0].comps.push(_comp);
      } else {
        _data.push({ code: _code, count: 1, comps: [_comp] });
      }
    });

    const _results = _data.map((task) => ({ string: task.code, data: task.count, comps: task.comps }));
    setFocusedHeader(Header.InProgress);
    setFocusedSubHeader(SubHeader.None);
    setChildData(_results);
    setVisibleChildData(_results.slice(0, 7));
  }

  function hOnClickOutstanding() {
    const _data: Array<{ code: string; count: number; comps: string[] }> = [];

    stats?.notStarted.forEach((task) => {
      const _code = task.incidentData.eventCode.code;
      const _comp = task.comp;
      const _existingItems = _data.filter((c) => c.code === _code);

      if (_existingItems.length > 0) {
        _existingItems[0].count += 1;
        _existingItems[0].comps.push(_comp);
      } else {
        _data.push({ code: _code, count: 1, comps: [_comp] });
      }
    });

    const _results = _data.map((task) => ({ string: task.code, data: task.count, comps: task.comps }));
    setFocusedHeader(Header.NotStarted);
    setFocusedSubHeader(SubHeader.None);
    setChildData(_results);
    setVisibleChildData(_results.slice(0, 7));
  }

  function hOnClickAnomalies() {
    const _data: Array<{ code: string; count: number }> = [];

    stats?.anomalies.forEach((anomaly) => {
      const _code = anomaly.anomalyCode.code;
      const _existingItems = _data.filter((c) => c.code === _code);

      if (_existingItems.length > 0) {
        _existingItems[0].count += 1;
      } else {
        _data.push({ code: _code, count: 1 });
      }
    });

    const _results = _data.map((anomaly) => ({ string: anomaly.code, data: anomaly.count }));
    setFocusedHeader(Header.Anomaly);
    setChildData(_results);
    setSubChildData([]);
    setVisibleSubChildData([]);
  }

  function hOnClickSetSubHeader(args: any) {
    const id: string = args.target.id;
    const taskCode: string = args.target.title;

    switch (id) {
      case 'None':
        setFocusedSubHeader(SubHeader.None);
        break;
      case 'Information':
        setFocusedSubHeader(SubHeader.Information);
        break;
      case 'Insignificant':
        setFocusedSubHeader(SubHeader.Insignificant);
        break;
      case 'Monitor':
        setFocusedSubHeader(SubHeader.Monitor);
        break;
      case 'Significant':
        setFocusedSubHeader(SubHeader.Significant);
        break;
      case 'Critical':
        setFocusedSubHeader(SubHeader.Critical);
        break;
      case 'task':
        setFocusedSubHeaderTaskCode(taskCode);
        setFocusedSubHeader(SubHeader.Task);
    }
  }

  function isFocusedHeader(header: Header) {
    return focusedHeader === header;
  }

  function isFocusedSubHeader(header: SubHeader) {
    return focusedSubHeader === header;
  }

  if (!isVisible) {
    return <React.Fragment />;
  }

  return (
    <div
      style={{
        display: isVisible ? 'block' : 'none',
        position: 'absolute',
        left: tooltipIconPosition?.x,
        top: tooltipIconPosition?.y,
        zIndex: 997
      }}
    >
      <div className={styles.tooltip} onMouseLeave={props.onMouseLeave}>
        <div className={styles.parentBarContainer}>
          <div
            id="components"
            className={clsx(styles.tooltipItem, isFocusedHeader(Header.Component) ? styles.tooltipItemFocused : '')}
            onClick={hOnClickComponents}
          >
            {totalComponents} Components
          </div>
          {stats.events.length !== 0 && (
            <div
              id="incidents"
              className={clsx(styles.tooltipItem, isFocusedHeader(Header.Incident) ? styles.tooltipItemFocused : '')}
              onClick={hOnClickIncidents}
            >
              {stats.events.length} Events
            </div>
          )}
          {stats.completed.length !== 0 && (
            <div
              id="completed"
              className={clsx(styles.tooltipItem, isFocusedHeader(Header.Completed) ? styles.tooltipItemFocused : '')}
              onClick={hOnClickCompleted}
            >
              {stats.completed.length} Completed Tasks
            </div>
          )}
          {stats.inProgress.length !== 0 && (
            <div
              id="in progress"
              className={clsx(styles.tooltipItem, isFocusedHeader(Header.InProgress) ? styles.tooltipItemFocused : '')}
              onClick={hOnClickInProgress}
            >
              {stats.inProgress.length} Tasks In-Progress
            </div>
          )}
          {stats.notStarted.length !== 0 && (
            <div
              id="outstanding"
              className={clsx(styles.tooltipItem, isFocusedHeader(Header.NotStarted) ? styles.tooltipItemFocused : '')}
              onClick={hOnClickOutstanding}
            >
              {stats.notStarted.length} Tasks Not Started
            </div>
          )}
          {stats.anomalies.length !== 0 && (
            <div
              id="anomalies"
              className={clsx(styles.tooltipItem, isFocusedHeader(Header.Anomaly) ? styles.tooltipItemFocused : '')}
              onClick={hOnClickAnomalies}
            >
              {stats.anomalies.length} Anomalies
            </div>
          )}
        </div>
        {generateChildTooltip()}
        {generateSubChildTooltip()}
      </div>
    </div>
  );
}
