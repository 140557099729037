import { useQuery } from '@apollo/react-hooks';
import { Box, CircularProgress, createStyles, Dialog, DialogContent, IconButton, makeStyles, Typography } from '@material-ui/core';
// tslint:disable-next-line: no-submodule-imports
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ErrorOutline, Pause, PlayArrow } from '@material-ui/icons';
import { gql } from 'apollo-boost';
import Hls from 'hls.js';
import React, { SyntheticEvent } from 'react';

export const MultiChannelVideoDialog = (props: { id: string; onClose: () => void }) => {
  interface MultiChannelMediaItemData {
    multiChannelMediaItem: Array<{
      channel: number;
      mediaItem: {
        url: string;
        urlSignature: string;
        skipForwardSeconds: number;
      };
    }>;
  }

  const { loading, error, data } = useQuery<MultiChannelMediaItemData, { id: string }>(
    gql`
      query multiChannelMediaItem($id: ID!) {
        multiChannelMediaItem(id: $id) {
          channel
          mediaItem {
            url
            urlSignature
            skipForwardSeconds
          }
        }
      }
    `,
    { variables: { id: props.id } }
  );

  const useStyles = makeStyles(() =>
    createStyles({
      dialogPaper: {
        backgroundColor: 'black',
        overflow: 'visible'
      },
      dialogContent: {
        textAlign: 'center',
        backgroundColor: 'black',
        color: 'white',
        position: 'relative'
      },
      container: {},
      circularProgressColour: { color: 'white' },
      hugeZIndex: {
        zIndex: 999
      },
      carouselButtonWrapper: {
        position: 'absolute',
        height: '100%',
        backgroundColor: 'transparent',
        top: 0
      },
      carouselButton: {
        margin: '0 10px',
        position: 'relative',
        top: 'calc(50% - 20px)',
        backgroundColor: '#494949',
        color: 'white',
        opacity: '0.5!important' as any,
        fontSize: '30px',
        transition: '200ms',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#888888!important' as any,
          opacity: '1!important' as any
        }
      },
      carouselButtonWrapperNext: {
        right: 0
      },
      carouselButtonWrapperPrev: {
        left: 0
      }
    })
  );
  const classes = useStyles();

  const loadingIndicator = (
    <CircularProgress
      classes={{
        colorPrimary: classes.circularProgressColour,
        colorSecondary: classes.circularProgressColour
      }}
    />
  );

  const MediaError = (errorProps: { message?: string; subMessage?: string }) => (
    <React.Fragment>
      <Box>
        <ErrorOutline fontSize="large" />
      </Box>
      <Typography>{errorProps.message ?? 'There was a problem loading this content.'}</Typography>
      {errorProps.subMessage ? <Typography variant="subtitle2">{errorProps.subMessage}</Typography> : null}
    </React.Fragment>
  );

  return (
    <Dialog
      fullScreen={false}
      maxWidth={false}
      fullWidth={false}
      open={true}
      scroll="paper"
      onClose={props.onClose}
      classes={{ paper: classes.dialogPaper, container: classes.container }}
    >
      <DialogContent className={classes.dialogContent}>
        {error || data == null ? (
          loading ? (
            loadingIndicator
          ) : (
            <MediaError subMessage={error?.message} />
          )
        ) : loading ? (
          loadingIndicator
        ) : (
          <MultiChannelVideoDialogContent
            hlsUrls={data.multiChannelMediaItem.map((v) => v.mediaItem.url)}
            urlSignature={data.multiChannelMediaItem[0].mediaItem.urlSignature}
            startPosition={data.multiChannelMediaItem[0].mediaItem.skipForwardSeconds}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

interface MultiChannelVideoDialogContentProps {
  hlsUrls: string[];
  urlSignature?: string;
  startPosition?: number;
}

interface MultiChannelVideoDialogContentState {
  playingState: 'unknown' | 'playing' | 'pause';
  currentTime: number;
  duration: number;
}

class MultiChannelVideoDialogContent extends React.Component<MultiChannelVideoDialogContentProps, MultiChannelVideoDialogContentState> {
  hlsInstance: Hls[] = [];
  videoPlayers: HTMLVideoElement[] = [];

  constructor(props: Readonly<MultiChannelVideoDialogContentProps>) {
    super(props);
    this.state = { playingState: 'unknown', currentTime: 0, duration: 0 };
  }

  componentDidMount() {
    this.videoPlayers = Array.from(document.getElementsByTagName('video'));

    this.props.hlsUrls.forEach((u, i) => this.hlsInstance.push(this.setupHls(this.videoPlayers[i], u, i === 0, this.props.urlSignature)));

    this.videoPlayers[0].addEventListener('timeupdate', () => {
      this.videoPlayers.forEach((v, i) => {
        if (i > 0 && Math.abs(v.currentTime - this.videoPlayers[0].currentTime) > 0.5) {
          v.currentTime = this.videoPlayers[0].currentTime;
        }
      });
      this.setState({ ...this.state, currentTime: this.videoPlayers[0].currentTime, duration: this.videoPlayers[0].duration });
    });

    this.videoPlayers[0].addEventListener('pause', () => {
      this.videoPlayers.forEach((v, i) => {
        if (i > 0) {
          v.pause();
        }
      });
      this.setState({ ...this.state, playingState: 'pause' });
    });
    this.videoPlayers[0].addEventListener('play', () => {
      this.videoPlayers.forEach((v, i) => {
        if (i > 0) {
          v.play();
        }
      });
      this.setState({ ...this.state, playingState: 'playing' });
    });

    this.videoPlayers[0].addEventListener('waiting', () => this.setState({ ...this.state, playingState: 'unknown' }));
    this.videoPlayers[0].addEventListener('playing', () => this.setState({ ...this.state, playingState: 'playing' }));
  }

  componentWillUnmount() {
    this.hlsInstance.forEach((h) => h.destroy());
  }

  playPauseButtonTriggered = () => {
    if (this.state.playingState === 'playing') {
      this.videoPlayers[0].pause();
      return;
    }
    if (this.state.playingState === 'pause') {
      this.videoPlayers[0].play();
      return;
    }
    return;
  };

  handleSeekChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, currentTime: e.currentTarget.valueAsNumber });
    this.videoPlayers[0].currentTime = e.currentTarget.valueAsNumber;
  };

  render() {
    const gridStyle: CSSProperties = {
      width: '100%',
      height: '80vh',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr 48px',
      gridAutoRows: '300px',
      gap: '0'
    };
    const videoElementStyle: CSSProperties = {
      maxWidth: '100%',
      maxHeight: '350px',
      height: 'auto',
      objectFit: 'scale-down'
    };

    const leftVideoWrapperStyle: CSSProperties = {
      textAlign: 'right'
    };

    const rightVideoWrapperStyle: CSSProperties = {
      textAlign: 'left'
    };

    return (
      <div style={gridStyle}>
        <div style={leftVideoWrapperStyle}>
          <video id="video1" controls={false} muted={false} style={videoElementStyle} />
        </div>
        <div style={rightVideoWrapperStyle}>
          <video id="video2" controls={false} muted={true} style={videoElementStyle} />
        </div>
        <div style={leftVideoWrapperStyle}>
          <video id="video3" controls={false} muted={true} style={videoElementStyle} />
        </div>
        <div style={rightVideoWrapperStyle}>
          <video id="video4" controls={false} muted={true} style={videoElementStyle} />
        </div>
        <div style={{ width: '100%', gridColumn: 'span 2', display: 'flex', flexDirection: 'row' }}>
          <IconButton size="small" style={{ flex: 'initial', color: 'white' }} onClick={this.playPauseButtonTriggered}>
            {this.state.playingState === 'unknown' ? (
              <CircularProgress size="1em" color="secondary" />
            ) : this.state.playingState === 'pause' ? (
              <PlayArrow />
            ) : (
              <Pause />
            )}
          </IconButton>
          <input
            type="range"
            min={0}
            max={this.state.duration}
            value={this.state.currentTime}
            onChange={this.handleSeekChange}
            style={{ flex: '1' }}
          />
        </div>
      </div>
    );
  }

  setupHls(video: HTMLVideoElement, hlsUrl: string, isFirst?: boolean, urlSignature?: string): Hls {
    const hls = new Hls({
      enableWorker: false,
      xhrSetup: (xhr, url) => {
        const actualUrl = urlSignature === null || urlSignature === undefined ? url : url + urlSignature;
        xhr.open('GET', actualUrl, true);
      }
    });
    hls.attachMedia(video);
    hls.on(Hls.Events.MEDIA_ATTACHED, () => {
      hls.loadSource(hlsUrl);
    });
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      if (isFirst) {
        this.setState({ ...this.state, playingState: 'pause' });
        if (this.props.startPosition) {
          video.currentTime = this.props.startPosition;
        }
        video.play();
      }
    });
    return hls;
  }
}
