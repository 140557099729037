import MaterialTable, { Column, Options } from '@material-table/core';
import { createStyles, Grid, IconButton, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import { PlayCircleOutline } from '@material-ui/icons';
import React from 'react';
import { MasterAnomalyStatus } from '../../../../../utils/enumerations';
import { Component, ComponentAnomalyAnomalyCode, ComponentAnomalyBase, ComponentMasterAnomaly } from '../../../Interfaces';
import { MAStatusColour, MAStatusText, renderComponentCode } from '../../../Functions';
import { format } from 'date-fns';
import StatusIcon from '../../StatusIcon';

interface MasterAnomalyTableProps {
  data: any;
  loading: boolean;
  filterByStatus: MasterAnomalyStatus | null;
  filterByCode: ComponentAnomalyAnomalyCode | null;
  setSelectedAnomalyMediaItemIds: (ids: string[]) => void;
  clearFilters: any;
}

export default function MasterAnomalyTable(props: MasterAnomalyTableProps): JSX.Element {
  const data = props.data;
  const loading = props.loading;
  const filterByStatus = props.filterByStatus;
  const filterByCode = props.filterByCode;

  const tableOptions: Options<any> = {
    padding: 'dense',
    search: false,
    toolbar: false,
    draggable: false,
    headerStyle: {
      fontWeight: 'bold',
      borderBottom: `2px solid gray` //${theme.palette.primary.main}`
    }
  };

  const handleAnomalyTableOpenViewer = (mediaIds: string[], e: any) => {
    if (!e || !mediaIds) {
      return;
    }
    e.stopPropagation();
    props.setSelectedAnomalyMediaItemIds(mediaIds);
  };

  const renderAnomalyTablePlayMedia = (anomaly: ComponentAnomalyBase) => (
    <div>
      {anomaly.media !== undefined && anomaly.media.length > 0 ? (
        <IconButton
          size="small"
          onClick={(e) =>
            handleAnomalyTableOpenViewer(
              anomaly.media.map((m) => m.id),
              e
            )
          }
        >
          <PlayCircleOutline fontSize="small" />
        </IconButton>
      ) : null}
    </div>
  );

  const StatusInline = (props: { status: MasterAnomalyStatus }) => {
    return (
      <Grid container spacing={1} justify="flex-start" alignItems="center">
        <Grid item>
          <StatusIcon status={props.status} fontSize="large" />
        </Grid>
        <Grid item>
          <Typography variant="button" display="inline" style={{ color: MAStatusColour(props.status) }}>
            {MAStatusText(props.status)}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const columns: Array<Column<ComponentMasterAnomaly>> = [
    { title: 'Component', field: 'component[0].componentCode', render: (d) => renderComponentCode(d.component) },
    { title: 'Media', field: 'media', type: 'boolean', render: renderAnomalyTablePlayMedia },
    { title: 'Description', field: 'description' },
    { title: 'Code', field: 'anomalyCode.code', render: (d) => <div title={d.anomalyCode.description}>{d.anomalyCode.code}</div> },
    { title: 'Date Found', field: 'dateFound', render: (d) => format(new Date(d.dateFound), 'd/MM/yy') },
    { title: 'ID', field: 'clientID' },
    { title: 'Status', field: 'status', render: (d) => <StatusInline status={d.status} /> }
  ];

  const maData = data?.components?.flatMap((comp: Component) => comp.masterAnomaly) ?? [];

  let filteredData = maData;
  filteredData = filterByStatus !== null ? filteredData.filter((ma) => ma.status === filterByStatus) : filteredData;
  console.log(filterByCode);
  filteredData = filterByCode !== null ? filteredData.filter((ma) => ma.anomalyCode.code === filterByCode.code) : filteredData;

  console.log(filteredData);

  const clearFilters = () => {
    props.clearFilters();
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h6">Master Anomalies</Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Link component="button" onClick={clearFilters}>
            Clear Filters
          </Link>
        </Grid>
      </Grid>

      <MaterialTable title="Master Anomalies" columns={columns} isLoading={loading} data={filteredData} options={tableOptions} />
    </React.Fragment>
  );
}
