import { ButtonBase, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

export const MediaThumb = (props: { thumbnail: string; mediaId: string; ignoreWidth: boolean; onSelect: (mediaId: string) => void }) => {
  const handleThumbClick = () => props.onSelect(props.mediaId);

  const useStyles = makeStyles(() =>
    createStyles({
      minWidth: {
        minWidth: props.ignoreWidth ? '70%' : '100%',
        '&:hover': {
          cursor: 'pointer'
        }
      }
    })
  );

  const classes = useStyles();

  return (
    <div>
      <ButtonBase className={classes.minWidth} onClick={handleThumbClick}>
        <img className={classes.minWidth} alt="Media" src={`data:image/png;base64,${props.thumbnail}`} />
      </ButtonBase>
    </div>
  );
};
