import React from 'react';
import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { Component, SelectedTab } from '../Interfaces';

export default function HeaderTabs(props: { data: any; selectedTab: SelectedTab; setSelectedTab: (tabIndex: number) => void }): JSX.Element {
  const data = props.data;
  const selectedTab = props.selectedTab;

  function handleTabChange(_: React.ChangeEvent<{}>, tabIndex: number) {
    props.setSelectedTab(tabIndex);
  }

  function getLastTaskCompletedText(components: Component[]) {
    if (components.length === 1) {
      const focusedComp = components[0];
      return focusedComp?.lastTaskCompleted ? 'Last Inspected ' + format(new Date(focusedComp?.lastTaskCompleted), 'd MMMM yyyy') : '';
    } else {
      return '';
    }
  }

  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab value={SelectedTab.CurrentState} label="Current State" />
          <Tab value={SelectedTab.History} label="History" />
          <Tab value={SelectedTab.MediaAndDocuments} label="Media and Documents" />
          <Tab value={SelectedTab.Anomalies} label="Anomalies" />
          <Tab value={SelectedTab.TaskTrends} label="Trends" />
        </Tabs>
      </Grid>
      <Grid item style={{ flexGrow: 1, textAlign: 'right' }}>
        <Typography style={{ fontWeight: 'bold' }} variant="subtitle2" display="block">
          {getLastTaskCompletedText(data?.components ?? [])}
        </Typography>
      </Grid>
    </Grid>
  );
}
