import { MediaType } from '../../utils/enumerations';

export function getMediaType(fileName: string): MediaType {
  const images: string[] = ['.jpg', '.png', '.jpeg', '.bmp'];
  const video: string[] = ['.mp4', '.mpg'];
  const document: string[] = ['.doc', '.docx', '.pdf'];

  for (const str of images) {
    if (fileName.includes(str)) {
      return MediaType.Image;
    }
  }

  for (const str of video) {
    if (fileName.includes(str)) {
      return MediaType.Video;
    }
  }

  for (const str of document) {
    if (fileName.includes(str)) {
      return MediaType.Document;
    }
  }

  return MediaType.Unknown;
}

export function groupBy(list: any, keyGetter: any) {
  const map = new Map();
  list.forEach((item: any) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function roundWithPrecision(value: number, precision: number) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function arrayMove(arr: any[], old_index: number, new_index: number) {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}
