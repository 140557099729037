import { Grid, Typography, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { format, isBefore } from 'date-fns';
import React from 'react';
import { useCardStyles } from '../../styles/CardStyles';
import { WorkpackStatsItem } from './workpackStatisticsDashboard';

export default function WorkpackStatisticsHeader(props: { loading: boolean; data?: WorkpackStatsItem }): JSX.Element {
  const classes = useCardStyles();

  const ProgressItem = (progressProps: { title: string; content: string | number | 'LOADING'; tooltip?: string }) => (
    <React.Fragment>
      <Grid item={true} xs={2} md={2} className={classes.cardHeaderPanel} style={{ zIndex: 1 }}>
        <div className={classes.cardHeaderContent}>
          <div>
            <Tooltip title={progressProps.tooltip ?? ''} arrow={true} classes={{ tooltip: classes.tooltip }}>
              <div>
                <Typography variant="caption">
                  <React.Fragment>
                    {progressProps.title}
                    <sup>?</sup>
                  </React.Fragment>
                </Typography>
                <Typography variant="h5">{progressProps.content === 'LOADING' ? <Skeleton animation="wave" /> : progressProps.content}</Typography>
              </div>
            </Tooltip>
          </div>
        </div>
      </Grid>
    </React.Fragment>
  );

  if (props.loading) {
    // Don't show loader here, looks odd.
    return (
      <Grid container={true} alignItems="center" justifyContent="center" spacing={4}>
        <ProgressItem title="Task Completion" content="LOADING" />
        <ProgressItem title="Task Time" content="LOADING" />
        <ProgressItem title="Site Anomalies" content="LOADING" />
        <ProgressItem title="Uptime" content="LOADING" />
        <ProgressItem title="Estimated Finish" content="LOADING" />
      </Grid>
    );
  }

  if (props.data === undefined) {
    return (
      <div className={classes.cardHeaderLoading} style={{ opacity: '0.4' }}>
        No Data Available.
      </div>
    );
  }

  return (
    <Grid container={true} alignItems="center" justifyContent="center" spacing={4}>
      <ProgressItem
        title="Task Completion"
        tooltip="Percentage of the number of tasks completed versus all tasks"
        content={
          props.data.progress.overallTaskTime === 0
            ? 'n/a'
            : `${Math.trunc((props.data.progress.completedTaskCount / props.data.progress.totalTaskCount) * 100)}%`
        }
      />
      <ProgressItem
        title="Task Time"
        tooltip="Percentage of the time of completed tasks versus all tasks"
        content={props.data.progress.overallTaskTime === 0 ? 'n/a' : `${Math.trunc(props.data.progress.overallTaskTime)}%`}
      />
      <ProgressItem title="Site Anomalies" tooltip="Number of site anomalies" content={props.data?._itemCount.anomaly} />
      <ProgressItem
        title="Uptime"
        tooltip="Percentage of time spent working on tasks versus non operational time"
        content={isNaN(props.data.uptime) ? 'n/a' : `${Math.round((props.data.uptime + Number.EPSILON) * 100) / 100}%`}
      />
      <ProgressItem
        title="Estimated Finish"
        tooltip="Estimated completion time, based on completed task duration and estimates of remaining tasks. Incorporates idle/down time in calculation."
        content={
          isBefore(new Date(props.data.progress.estimatedCompletionDate), 0)
            ? 'n/a'
            : format(new Date(props.data.progress.estimatedCompletionDate), 'd/MM/yyyy HH:mm')
        }
      />
    </Grid>
  );
}
