import { MVTLayer, ScatterplotLayer, Tile3DLayer } from 'deck.gl';
import { StructureItem } from '../overview/Overview';
import { ICON_MAPPING_SIMPLE } from './DeckGL/extras/Constants';
import { Anomaly, MvtProperties, Task } from './MapUtils/SharedTypes';
// @ts-ignore
import StructurePinTextLayer from '../overview/structure-pin-text-layer';
import { I3SLoader } from '@loaders.gl/i3s';

export function GetHumanReadableEsriUrl(url: string): string {
  const _url = url.toLowerCase();
  const preceedingIdentifier = '/services/';
  const hasIndex = _url.indexOf(preceedingIdentifier);

  let result = '';

  if (hasIndex !== -1) {
    const terminator = url.indexOf('/', hasIndex + preceedingIdentifier.length);
    result = url.substring(hasIndex + preceedingIdentifier.length, terminator);
    result = result.replaceAll('_', ' ');
  }

  return result;
}

export function buildDummy3DLayer(esriToken: string, esri3dTileUrls: string[]) {
  const loadOptions: any = {
    i3s: { token: esriToken },
    token: esriToken
  };

  const esriLayers: any[] = [];

  esri3dTileUrls
    .filter((url: string) => url !== '')
    .forEach((url: string) => {
      const newLayer = new Tile3DLayer({
        id: 'my-tile-3d-layer',
        data: `${url}?token=${esriToken}`,
        loader: I3SLoader,
        pickable: false,
        loadOptions,
        visible: true
      });
      esriLayers.push(newLayer);
    });

  return esriLayers;
}

export function buildPinLayer(structure: any, selectStructureCallback: () => any) {
  if (!structure || structure === '') return null;

  return new StructurePinTextLayer({
    id: 'structures',
    data: structure,
    pickable: true,
    getIcon: () => 'marker',
    getIconSize: () => 45,
    getPosition: (d: StructureItem) => [d.lnglat.longitude, d.lnglat.latitude],
    getIconColor: [128, 128, 128],
    getNameText: (d: StructureItem) => d.componentCode,
    getMAText: (d: StructureItem) => buildMapAnomPinText(d),
    iconAtlas: '/map/icon-atlas-update.png',
    iconMapping: ICON_MAPPING_SIMPLE,
    onSelectStructure: selectStructureCallback,
    visible: true
  });
}

// tslint:disable-next-line: max-line-length
export function buildFieldLayoutLayers(mvtUrls: string[], layerType: new (props: any) => MVTLayer<MvtProperties>, layerProperties: MvtProperties) {
  const results: any = [];

  mvtUrls
    .filter((url) => url !== '')
    .forEach((dataUrl, index) => {
      const newLayer = new layerType({
        id: `drawing-layer-${index}`,
        data: dataUrl,
        onTileError,
        ...layerProperties
      });

      results.push(newLayer);
    });

  return results;
}
// export const buildFieldLayoutLayers = (mvtUrls: string[], layerType: new (props: any) => MVTLayer<MvtProperties>, layerProperties: MvtProperties) =>
//   mvtUrls.filter((url) => url !== '').map((dataUrl, index) => {
//     return new layerType({
//       id: `drawing-layer-${index}`,
//       data: dataUrl,
//       onTileError,
//       ...layerProperties
//     });
//   });

const onTileError = (error: Error) => {
  // If we get a 403 Redirect, supress it, but we shouldnt cause S3 now returns a blank.pbf
  // (but it actually doesn't because i didnt save the changes)
  // Only print out non-403 errors.
  if (!error?.message?.includes('403')) {
    console.log(error);
  }
};

export const MAP_STYLE = '/map/styles/blackwater.json';
export const MAP_STYLE_TOKEN = 'pk.eyJ1Ijoib2xpdmVyYnVja2xlciIsImEiOiJja2VocjJjaW8wY2w4MnpwaHN2YWVjaWc4In0.kpO_W8IclNTWWkvoHcEScg';

export function trimExtension(filename: string) {
  const seperator = filename.lastIndexOf('.');
  return filename.substring(0, seperator);
}

export function isAnomaly(object: any): object is Anomaly {
  return 'anomalyCode' in object;
}

export function isEvent(object: any) {
  return Object.keys(object.workpackTask).length === 0;
}

export function isTask(object: any): object is Task {
  if ('eventCode' in object) {
    const ec = (object as Task).eventCode;
    if (ec.mode === undefined) {
      return true;
    } else {
      return ec.mode === 0 || ec.mode === 1;
    }
  }
  return false;
}

export function calculateBearing(p1: LatLng, p2: LatLng) {
  // const lengthUnit = {
  //   display: 'km',
  //   decimal: 2,
  //   factor: null,
  //   label: 'Distance:'
  // };
  // const angleUnit = {
  //   display: '&deg;',
  //   decimal: 2,
  //   factor: null,
  //   label: 'Bearing:'
  // };

  const f1 = p1.lat;
  const l1 = p1.lng;
  const f2 = p2.lat;
  const l2 = p2.lng;
  const toRadian = Math.PI / 180;

  // haversine formula
  // bearing
  const y = Math.sin((l2 - l1) * toRadian) * Math.cos(f2 * toRadian);
  const x = Math.cos(f1 * toRadian) * Math.sin(f2 * toRadian) - Math.sin(f1 * toRadian) * Math.cos(f2 * toRadian) * Math.cos((l2 - l1) * toRadian);
  // var brng = Math.atan2(y, x) * ((angleUnit.factor ? angleUnit.factor / 2 : 180) / Math.PI);
  let brng = Math.atan2(y, x) * (180 / Math.PI);
  brng += brng < 0 ? 360 : 0;
  // brng += brng < 0 ? (angleUnit.factor ? angleUnit.factor : 360) : 0;

  // distance
  // var R = lengthUnit.factor ? 6371 * lengthUnit.factor : 6371; // kilometres
  const R = 6371; // kilometres
  const deltaF = (f2 - f1) * toRadian;
  const deltaL = (l2 - l1) * toRadian;
  const a =
    Math.sin(deltaF / 2) * Math.sin(deltaF / 2) + Math.cos(f1 * toRadian) * Math.cos(f2 * toRadian) * Math.sin(deltaL / 2) * Math.sin(deltaL / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return {
    Bearing: brng.toFixed(2),
    Distance: distance.toFixed(3)
  };
}

export function calculateMarkerColor(data: any) {
  let color;

  if (data.anomalies.length !== 0) {
    color = [255, 0, 0, 255];
  } else if (data.notStarted.length !== 0) {
    color = [66, 66, 66, 255];
  } else if (data.inProgress.length !== 0) {
    color = [255, 174, 0, 255];
  } else if (data.completed.length !== 0) {
    color = [0, 138, 0, 255];
  } else if (data.events.length !== 0) {
    color = [0, 174, 255, 255];
  }

  return color;
}

export function clusterDataHasAnomaliesDeckGl(data: any[]) {
  return data
    .map((x) => x.data)
    .some((x) => {
      return isAnomaly(x);
    });
}

export function calculateTaskStatusDeckGl(data: any[]) {
  // tslint:disable-next-line: max-line-length
  const status: { completed: any[]; inProgress: any[]; notStarted: any[]; anomalies: any[]; events: any[] } = {
    completed: [],
    inProgress: [],
    notStarted: [],
    anomalies: [],
    events: []
  };
  const combined = data.map((x) => ({ comp: x.component, incidentData: x.data }));

  combined.forEach((x: any) => {
    if (isAnomaly(x.incidentData)) {
      status.anomalies.push(x.incidentData);
      return;
    }

    if (isEvent(x.incidentData)) {
      status.events.push(x.incidentData);
      return;
    }

    if (isTask(x.incidentData)) {
      // This is an Incident's related task, not a real WorkpackTask, so skip it.
      if (x.incidentData.workpackTask.taskProgresses === undefined) {
        return;
      }

      // Is a WorkpackTask
      const progresses = x.incidentData.workpackTask.taskProgresses;

      if (progresses.length === 0) {
        return;
      }

      progresses.sort((a: any, b: any) => +new Date(b.startTime) - +new Date(a.startTime));
      const lastTaskUpdatePercentage = progresses[0].percentage;

      if (lastTaskUpdatePercentage === 100) {
        status.completed.push(x);
      } else if (lastTaskUpdatePercentage === 0) {
        status.notStarted.push(x);
      } else {
        status.inProgress.push(x);
      }
    }
  });

  return status;
}

export function getAllComponentsDeckGl(data: any[]) {
  const comps: string[] = [];

  data.forEach((x: { component: string; name: string }) => {
    if (!comps.includes(x.component)) {
      comps.push(x.component);
    }
  });

  return comps;
}

export function truncateTooltipText(text: string) {
  const index = text.lastIndexOf('\\');
  let result = text.substring(index);
  result = `...${result}`;
  // Output should be like ...\COMP_BLA_BLA
  return result;
}

export function buildMapAnomPinText(d: StructureItem) {
  if (d.masterAnomalyCounts === undefined) {
    return undefined;
  }
  const sum = d.masterAnomalyCounts.reduce((s, c) => s + c.count, 0);
  if (sum === 0) {
    return undefined;
  }
  return `${sum} Master Anomalies`;
}

export class LatLng {
  constructor(latitude: number, longitude: number) {
    this.lat = latitude;
    this.lng = longitude;
  }

  lat: number;
  lng: number;
}
