export function extraDimensionsFromBase64Jpeg(b64: string) {
  const sig = 'FFC';
  // Base64 string into hex
  const imageHex = [...Array.from(atob(b64))]
    .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
    .join('')
    .toUpperCase();
  const index = imageHex.indexOf(sig);

  if (index !== -1) {
    // Size of the chunk which contains the width/height hex
    const range = imageHex.slice(index, index + 18);
    // extract hex values
    const hHeight = range.slice(10, 10 + 4);
    const hWidth = range.slice(14, 14 + 4);
    // convert to dec
    const dHeight = parseInt(hHeight, 16);
    const dWidth = parseInt(hWidth, 16);

    // console.log(`Has signature | Dimensions: ${parseInt(hWidth, 16)}x${parseInt(hHeight, 16)}`);
    return { width: dWidth, height: dHeight };
  }

  return { width: 0, height: 0 };
}

export function ExtractDimensionsFromBase64JpegArray(images: any[]) {
  const sizes: Array<{ width: number; height: number }> = [];

  images.forEach((image: any) => {
    const result = extraDimensionsFromBase64Jpeg(image.thumbnail);
    sizes.push(result);
  });

  return sizes;
}

export function determineAverageDimension(dimensions: Array<{ width: number; height: number }>) {
  const avgHeight = dimensions.reduce((a, b) => a + b.height, 0) / dimensions.length || 0;
  const avgWidth = dimensions.reduce((a, b) => a + b.width, 0) / dimensions.length || 0;

  // console.log(`AVG: ${avgWidth}x${avgHeight}`);
  return { width: avgWidth, height: avgHeight };
}
