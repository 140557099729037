import { colors } from '@material-ui/core';

export const palette = {
  infinityWhite: '#ffffff',
  quantumBlue: '#011e41',
  gravityGrey: '#d9d8d6',
  pulseBlue: '#6788b1',
  strataTurquoise: '#479caa',
  cosmicSand: '#d9be89',
  motionGreen: '#8cb680',
  PaleGoldenRod: '#EEE8AA',
  PaleTurquoise: '#AFEEEE',
  lightBlue: '#ADD8E6'
};

export const Colors = {
  StrataTurquoise: '#479caa',
  CardHover: '#ecedf1',
  TaskProgress: '#80add7',
  TimeWeightedProgress: '#d4dca9',
  PlannedProgress: '#f4874b'
};

export const OperationalCodeColors: string[] = ['#a3586d', '#5c4a72', '#f3b05a', '#f4874b', '#f46a4e', '#80add7', '#0abda0', '#d4dca9', '#fb9d7a'];

export const chartColors: string[] = ['#80add7', '#ee7879', '#d4dca9', '#fb9d7a', '#0abda0', '#f1ba48', '#aba6bf', '#dbb4da'];

export const dashboardColors: string[] = [
  'rgba(1, 30, 65, 1)',
  'rgb(219, 80, 74, 1)',
  'rgb(67, 170, 139, 1)',
  'rgb(255, 111, 89, 1)',
  'rgb(178, 176, 155, 1)'
];

export const dashboardColorsOpaque: string[] = [
  'rgba(1, 30, 65, 0.4)',
  'rgb(219, 80, 74, 0.4)',
  'rgb(67, 170, 139, 0.4)',
  'rgb(255, 111, 89, 0.4)',
  'rgb(178, 176, 155, 0.4)'
];

export const uptimeColors: string[] = ['rgba(1, 30, 65, 0.2)', 'rgba(1, 30, 65, 1)'];

export const downtimeColors: string[] = ['rgba(219, 80, 74, 0.2)', 'rgba(219, 80, 74, 1)'];

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: colors.indigo[500],
    light: colors.indigo[100]
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
};

export const authenticatorTheme = {
  button: { backgroundColor: palette.quantumBlue },
  navButton: { backgroundColor: palette.quantumBlue },
  a: { color: palette.quantumBlue, textDecoration: 'underline' },
  formSection: { top: '5vh' },
  toast: { top: '64px', backgroundColor: colors.red[600] },
  // oAuthButton doesn't pick up normal button styles, hance the ugly repetition
  oAuthSignInButton: {
    backgroundColor: palette.quantumBlue,
    color: palette.infinityWhite,
    border: 'none',
    fontSize: '12px',
    letterSpacing: '1.1px',
    textTransform: 'uppercase'
  }
};
