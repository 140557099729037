import { Vector3 } from 'three';

export function highlightTopMostCollidedEntity(currentlyIntersecting: any[], previouslyIntersected: any, usingTransparency: boolean) {
  if (currentlyIntersecting.length > 0) {
    if (previouslyIntersected != currentlyIntersecting[0].object) {
      if (previouslyIntersected) {
        previouslyIntersected.material.color.setHex(previouslyIntersected.currentHex);
        previouslyIntersected.material.transparent = usingTransparency;
      }
      previouslyIntersected = currentlyIntersecting[0].object;
      previouslyIntersected.currentHex = previouslyIntersected.material.color.getHex();
      previouslyIntersected.material.color.setHex(0xff00ff);
      previouslyIntersected.material.transparent = false;
    }
  } else {
    if (previouslyIntersected) {
      previouslyIntersected.material.color.setHex(previouslyIntersected.currentHex);
      previouslyIntersected.material.transparent = usingTransparency;
    }
    previouslyIntersected = null;
  }

  return previouslyIntersected;
}

export function convertGltfChildMeshToWorldSpace(meshNode: any) {
  meshNode.geometry.computeBoundingBox();

  const centroid = new Vector3();
  centroid.addVectors(meshNode.geometry.boundingBox.min, meshNode.geometry.boundingBox.max);
  centroid.multiplyScalar(0.5);
  centroid.applyMatrix4(meshNode.matrixWorld);

  return centroid;
}

export function getRandomEntity(model: any) {
  const entityId = Math.floor(Math.random() * 30 + 1);
  let index = 0;
  let focusedEntity;

  model.traverse((node: any) => {
    if (!node.isObject3D) return;

    index++;

    if (entityId == index) {
      focusedEntity = node;
    }
    // if (node.name === 'node_75')
    //   focusedEntity = node;
  });

  return focusedEntity;
}

export function isRootComponent(comp: string) {
  if (comp.split('\\').length === 1) {
    return true;
  }

  return false;
}

export interface FocusedComponentProps {
  components: string[];
  lookAt: boolean;
}
