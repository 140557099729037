import { colorLuminance } from '../../utils/colorUtils';

export interface LineChartData {
  labels: string[];
  datasets: ILineChartDataset[];
}

export interface ILineChartDataset {
  label: string;
  fill: boolean;
  lineTension: number;
  backgroundColor: string;
  borderColor: string;
  borderDash: number[];
  pointBorderColor: string;
  pointBackgroundColor: string;
  pointBorderWidth: number;
  pointHoverRadius: number;
  pointHoverBackgroundColor: string;
  pointHoverBorderColor: string;
  pointHoverBorderWidth: number;
  pointRadius: number;
  pointHitRadius: number;
  data: number[];
}

export class LineChartDataset implements ILineChartDataset {
  borderCapStyle: any;
  label: string;
  fill: boolean;
  lineTension: number;
  backgroundColor: string;
  borderColor: string;
  borderDash: number[];
  pointBorderColor: string;
  pointBackgroundColor: string;
  pointBorderWidth: number;
  pointHoverRadius: number;
  pointHoverBackgroundColor: string;
  pointHoverBorderColor: string;
  pointHoverBorderWidth: number;
  pointRadius: number;
  pointHitRadius: number;
  data: number[] | any[];

  constructor(label: string, lineColor: string, data: number[] | any[], borderDash?: number[]) {
    if (!borderDash) {
      borderDash = [];
    }
    this.label = label;
    this.fill = false;
    this.lineTension = 0.1;
    this.backgroundColor = colorLuminance(lineColor, 0.4);
    this.borderColor = lineColor;
    this.borderDash = borderDash;
    this.pointBorderColor = lineColor;
    this.pointBackgroundColor = '#fff';
    this.pointBorderWidth = 1;
    this.pointHoverRadius = 5;
    this.pointHoverBackgroundColor = lineColor;
    this.pointHoverBorderColor = '#dcdcdc';
    this.pointHoverBorderWidth = 2;
    this.pointRadius = 1;
    this.pointHitRadius = 1;
    this.data = data;
  }
}
