import React, { useCallback, useEffect, useState } from 'react';
import DeckGL from '@deck.gl/react';
import { StaticMap } from 'react-map-gl';
import { BASEMAP } from '@deck.gl/carto';
import { INITIAL_VIEW_STATE } from '../extras/Constants';
import { LngLat, MapProperties } from '../../MapUtils/SharedTypes';
import EsriLayerService, { LayerInfo } from './EsriLayerService';
import DeckLayerService from './DeckLayerService';
import { calculateMinMaxLngLat, createLayerPropsFromStorage } from '../extras/Functions';
import { WebMercatorViewport } from '@deck.gl/core';
// @ts-ignore
import RulerLayer from '../ruler-layer/ruler-layer';

export default function DeckMap(props: {
  properties: MapProperties;
  selectedLayers: any;
  onLoadingChanged: (state: boolean) => any;
  isMeasuring: boolean;
}): JSX.Element {
  const [esriService, setEsriService] = useState<EsriLayerService>();
  const [deckService, setDeckService] = useState<DeckLayerService>();
  const [esriLayers, setEsrilayers] = useState<any[]>();
  const [deckLayers, setDeckLayers] = useState<any[]>();
  const [measurementData, setMeasurementData] = useState<any>([]);

  const [extent, setExtent] = useState<LngLat[]>([]);
  const [viewport, setViewport] = useState(INITIAL_VIEW_STATE);

  function setInitialViewport() {
    if (viewport === undefined || extent.length <= 1) return;

    let newViewPort = new WebMercatorViewport({ ...viewport, width: 500, height: 500 });

    // @ts-ignore
    const { longitude, latitude, zoom } = newViewPort.fitBounds(
      [
        [extent[0].longitude, extent[0].latitude],
        [extent[1].longitude, extent[1].latitude]
      ],
      {}
    );

    console.log(longitude, latitude, zoom);

    newViewPort = {
      ...viewport,
      // @ts-ignore
      longitude,
      latitude,
      zoom: zoom // sometimes this defaults to 1.2? dont know why.
    };

    // @ts-ignore
    setViewport(newViewPort);
    props.onLoadingChanged(false);
  }

  function handleSelectedLayerChanged() {
    if (!props?.selectedLayers || props.selectedLayers.length === 0) return;

    // Create a new list of properties with visibility set accordingly.
    const newLayerInfo: LayerInfo[] = [];

    props?.selectedLayers.forEach((layer: any) => {
      const item: LayerInfo = { url: layer.originalUrl, props: { visible: layer.isChecked }, datumTransformationId: layer.transformationId };
      newLayerInfo.push(item);
    });
    // Rebuild the layers with the visibilty applied.
    const layers = esriService?.generateLayers(newLayerInfo);
    deckService?.setVisibleLayerUrls(props?.selectedLayers.filter((l) => l.isChecked).map((l) => l.originalUrl));
    setDeckLayers(deckService?.generate());
    setEsrilayers(layers);
  }

  function updateMeasuringLayer() {
    if (!deckLayers) return;

    const layer = new RulerLayer({
      id: 'ruler-layer',
      data: measurementData
    });

    const existing = deckLayers.filter((x) => x.props.id !== 'ruler-layer');

    setDeckLayers([layer, ...existing]);
  }

  const onClick = (args: any) => {
    if (props.isMeasuring) {
      setMeasurementData([...measurementData, args.coordinate]);
    }
  };

  const onKeyDown = (args: any) => {
    const key = args.key;

    switch (key) {
      case 'Escape':
        setMeasurementData([]);
        break;
    }
  };

  const createLayersAndSetExtent = useCallback(async () => {
    if (props.properties == null) return;

    const initialProps = createLayerPropsFromStorage(props.selectedLayers);
    const esriHandler = new EsriLayerService({ layerInfo: initialProps, token: props.properties.token });
    await esriHandler.initialize();
    await esriHandler.calculateExtent();
    const esriLayers = esriHandler.generateLayers(initialProps);

    const deckHandler = new DeckLayerService({
      ...props.properties.deckProperties,
      token: props.properties.token,
      visibleLayerUrls: props?.selectedLayers?.filter((l) => l.isChecked).map((l) => l.originalUrl)
    });
    const deckLayers = deckHandler.generate();

    const extent = props.properties.deckProperties.bounds
      ? {
          minLng: props.properties.deckProperties.bounds[0].longitude,
          minLat: props.properties.deckProperties.bounds[0].latitude,
          maxLng: props.properties.deckProperties.bounds[1].longitude,
          maxLat: props.properties.deckProperties.bounds[1].latitude
        }
      : calculateMinMaxLngLat([...(esriHandler.extent as Array<LngLat>), ...(deckHandler.extent as Array<LngLat>)]);

    setEsriService(esriHandler);
    setDeckService(deckHandler);
    setEsrilayers(esriLayers);
    setDeckLayers(deckLayers);
    setExtent([
      { longitude: extent.minLng, latitude: extent.minLat },
      { longitude: extent.maxLng, latitude: extent.maxLat }
    ]);
  }, [props.properties]);

  useEffect(() => {
    createLayersAndSetExtent();
  }, [createLayersAndSetExtent]);

  useEffect(() => {
    const interval = setInterval(() => {
      deckService?.rotateRefreshKey();
      setDeckLayers(deckService?.generate());
    }, 10000);
    return () => clearInterval(interval);
  }, [deckService]);

  useEffect(() => {
    setInitialViewport();
  }, [extent]);
  useEffect(handleSelectedLayerChanged, [props?.selectedLayers]);
  useEffect(updateMeasuringLayer, [measurementData]);

  if (esriLayers === undefined || deckLayers === undefined) return <React.Fragment />;

  return (
    <div onKeyDown={onKeyDown}>
      <DeckGL initialViewState={viewport} controller={true} onClick={onClick} layers={[...esriLayers, ...deckLayers]}>
        <StaticMap
          mapStyle={BASEMAP.POSITRON}
          mapboxApiAccessToken={'pk.eyJ1Ijoib2xpdmVyYnVja2xlciIsImEiOiJja2VocjJjaW8wY2w4MnpwaHN2YWVjaWc4In0.kpO_W8IclNTWWkvoHcEScg'}
        />
      </DeckGL>
    </div>
  );
}
