import { useQuery } from '@apollo/react-hooks';
import MaterialTable, { Column, Options } from '@material-table/core';
import { IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { AssignmentOutlined, ErrorOutlineOutlined, LocationOnOutlined, PlayCircleFilledOutlined, PlayCircleOutline } from '@material-ui/icons';
import { gql } from 'apollo-boost';
import { addSeconds, differenceInHours, differenceInMinutes, differenceInSeconds, format, startOfToday } from 'date-fns';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { MediaViewerDialog } from '../../../../workpacks/workpackDetails/MediaViewerDialog';
import { MultiChannelVideoDialog } from '../../../../workpacks/workpackDetails/MultiChannelVideoDialog';
import { FocusedComponentProps } from '../../../3D/ModelViewerHelpers';

export default function HistoryTab(props: { selectedComponents: string[] }): JSX.Element {
  const [focusedVideo, setFocusedVideo] = useState<TaskListItem>();
  const [focusedMultichannelVideo, setFocusedMultichannelVideo] = useState<TaskListItem>();

  interface TaskListItem {
    id: string;
    startTime: Date;
    endTime: Date;
    isVideoAvailable: boolean;
    isMultichannelVideoAvailable: boolean;
    // video: Array<{ channel: number, videoLogIds: string[] }>;
    isReviewed: boolean;
    durationSeconds: number;
    eventCode: { code: string; mode: 'TASK' | 'SYSTEM_TASK' | 'EVENT' | 'SYSTEM_EVENT' };
    subEventCode?: string;
    component: { smartComponent: string; fullComponent: string };
    comment: string;
    workpackTasks: any;
    navigation: { start: { easting: number; northing: number; kp: number } };
    location: string;
    isAnomalous: boolean;
  }

  const { error, data, loading } = useQuery<{ components: Array<{ id: string; incident: TaskListItem[] }> }, { fullComponent: string[] }>(
    gql`
      query assetDetailDetailsHistory($fullComponent: [String]) {
        components(fullComponent: $fullComponent) {
          id
          incident(includeChildComponents: true) {
            id
            startTime
            endTime
            isVideoAvailable
            isMultichannelVideoAvailable
            isReviewed
            durationSeconds
            eventCode {
              code
              mode
            }
            subEventCode
            component {
              id
              smartComponent
              fullComponent
            }
            workpackTasks {
              id
            }
            navigation {
              start {
                easting
                northing
                kp
              }
            }
            comment
            location
            isAnomalous
          }
        }
      }
    `,
    { variables: { fullComponent: props.selectedComponents } }
  );

  const renderStartTime = (a: TaskListItem) => format(new Date(a.startTime), 'd/MM/yy\u00a0HH:mm');

  const renderTaskCode = (a: TaskListItem) => (a.subEventCode ? `${a.eventCode.code}-${a.subEventCode}` : a.eventCode.code);

  const renderDuration = (a: TaskListItem) => {
    if (a.durationSeconds < 0) {
      return 'n/a';
    }
    const d1 = startOfToday();
    const d2 = addSeconds(d1, a.durationSeconds);
    const hours = differenceInHours(d2, d1);
    const minutes = differenceInMinutes(d2, d1) - hours * 60;
    const seconds = differenceInSeconds(d2, d1) - differenceInMinutes(d2, d1) * 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const renderAnomalous = (a: TaskListItem) => {
    return <div> {a.isAnomalous === true && <ErrorOutlineOutlined style={{ color: red[500] }} />}</div>;
  };

  const renderEventCodeMode = (a: TaskListItem) => {
    switch (a.eventCode.mode) {
      case 'EVENT':
      case 'SYSTEM_EVENT':
        return <LocationOnOutlined fontSize="small" color="action" titleAccess="Event" />;
      case 'TASK':
      case 'SYSTEM_TASK':
        return <AssignmentOutlined fontSize="small" color="action" titleAccess="Task" />;
      default:
        return null;
    }
  };

  const renderPlayMedia = (task: any) => (
    <div>
      {task.isVideoAvailable ? (
        task.isMultichannelVideoAvailable ? (
          <IconButton size="small" onClick={(e) => handleOpenMultichannelViewer(task, e)}>
            <PlayCircleFilledOutlined fontSize="small" />
          </IconButton>
        ) : (
          <IconButton size="small" onClick={(e) => handleOpenViewer(task, e)}>
            <PlayCircleOutline fontSize="small" />
          </IconButton>
        )
      ) : null}
    </div>
  );

  const handleCloseViewer = () => {
    setFocusedVideo(undefined);
    setFocusedMultichannelVideo(undefined);
  };

  const handleOpenViewer = (task: any, e: any) => {
    if (!e || !task) {
      return;
    }
    e.stopPropagation();
    setFocusedVideo(task);
  };

  const handleOpenMultichannelViewer = (task: any, e: any) => {
    if (!e || !task) {
      return;
    }
    e.stopPropagation();
    setFocusedMultichannelVideo(task);
  };

  const columns: Array<Column<TaskListItem>> = [
    {
      title: 'Start Time',
      field: 'startTime',
      type: 'datetime',
      searchable: true,
      defaultSort: 'asc',
      // columnWidth: '10em',
      render: renderStartTime
      // filter: 'UniversalDate'
    },
    {
      title: 'Duration',
      field: 'durationSeconds',
      type: 'numeric',
      align: 'left',
      searchable: false,
      defaultSort: 'asc',
      // columnWidth: '9em',
      render: renderDuration
      // filter: 'DateOnDay'
    },
    {
      title: 'Type',
      field: 'eventCode.mode',
      searchable: false,
      sorting: false,
      render: renderEventCodeMode,
      // filter: 'EventOrTask',
      // columnWidth: '10em',
      align: 'center',
      cellStyle: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    {
      title: 'Media',
      field: 'isVideoAvailable',
      type: 'boolean',
      searchable: false,
      sorting: false,
      render: renderPlayMedia,
      // filter: 'None',
      // columnWidth: '2em',
      align: 'center',
      cellStyle: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    {
      title: 'Reviewed',
      field: 'isReviewed',
      type: 'boolean',
      searchable: false,
      // filter: 'Checkbox',
      // columnWidth: '3em',
      align: 'center',
      hidden: true
    },
    {
      title: 'Task Code',
      field: 'eventCode.code',
      render: renderTaskCode
      // columnWidth: '10em',
      // filter: 'LookupAuto',
    },
    {
      title: 'Component',
      field: 'component.smartComponent'
      // columnWidth: '15em',
      // isCollapsible: true,
      // isRtlCollapsible: true,
      // filter: 'TextEditor'
    },
    {
      title: 'Easting',
      field: 'navigation.start.easting',
      type: 'numeric',
      // columnWidth: '5em',
      hidden: true
    },
    {
      title: 'Northing',
      field: 'navigation.start.northing',
      type: 'numeric',
      // columnWidth: '5em',
      hidden: true
    },
    {
      title: 'KP',
      field: 'navigation.start.kp',
      type: 'numeric',
      // columnWidth: '5em',
      hidden: true
    },

    {
      title: 'Comment',
      field: 'comment'
      // columnWidth: '15em',
      // isCollapsible: true,
      // filter: 'TextEditor'
    },
    {
      title: 'Location',
      field: 'location'
      // columnWidth: '15em',
      // isCollapsible: true,
      // filter: 'TextEditor'
    },
    {
      title: 'Anomalous',
      field: 'isAnomalous',
      render: renderAnomalous,
      type: 'boolean',
      // columnWidth: '2em',
      cellStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        textAlign: 'center'
      }
      // filter: 'Checkbox'
    }
  ];

  const tableOptions: Options<any> = {
    padding: 'dense',
    search: false,
    toolbar: false,
    draggable: false,
    pageSize: 15,
    pageSizeOptions: [5, 10, 15],
    headerStyle: {
      fontWeight: 'bold',
      borderBottom: `2px solid gray` //${theme.palette.primary.main}`
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div style={{ height: '70vh', maxHeight: '70vh' }}>
      <MaterialTable
        title="History"
        columns={columns}
        isLoading={loading}
        data={data?.components.flatMap((c: any) => c.incident) ?? []}
        options={tableOptions}
      />
      {focusedVideo ? <MediaViewerDialog itemType="TASK_MEDIA" id={focusedVideo?.id} onClose={handleCloseViewer} /> : null}
      {focusedMultichannelVideo ? <MultiChannelVideoDialog id={focusedMultichannelVideo?.id} onClose={handleCloseViewer} /> : null}
    </div>
  );
}
